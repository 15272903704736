import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import * as Trials from '../reducers/trialsReducer'
import * as Location from '../reducers/locationReducer'
import * as Comparison from '../reducers/comparisonReducer'
import * as Agronomists from '../reducers/agronomistReducer'
import * as TrialResults from '../reducers/trialResultsReducer'

const configureStore = () => {
    //place all reducers for the app
    const reducers = {
        trials: Trials.reducer,
        location: Location.reducer,
        comparisons: Comparison.reducer,
        agronomists: Agronomists.reducer,
        trialResults: TrialResults.reducer
    };

    const rootReducer = combineReducers({
        ...reducers
    });

    const middleware = [thunk];
    //const enhancers = [];

    //NEED TO ADD LOGIC HERE TO ONLY INCLUDE THIS EXTENSION IF WE ARE IN DEV
    //enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

    const store = createStore(
        rootReducer,
        compose(
            applyMiddleware(...middleware),
            //...enhancers

            //in order to work only on local and dev, before going to staging a .env file needs to be created
            process.env.NODE_ENV !== 'production' &&
                window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );




    //INITIALIZE SOCKETS
    //socketMiddleware(store)

    return store
};

export default configureStore;