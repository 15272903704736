import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { localeActionCreators } from '../../../actions/locationAction';
import { getVisibleTrials, getTrialsCategory, getPURL } from '../../../selectors/trialsSelector';
import { comparisonActionCreators } from '../../../actions/comparisonAction';
import './Menu.scss'
import IndividualTrial from './IndividualTrial/IndividualTrial'
import FilterSection from '../../../components/FilterSection/FilterSection'
import TrialSummaryChart from './TrialSummaryChart/TrialSummaryChart';
import Filter from "../../../utils/filter";
import { nodeserver_url } from '../../../constants/api';
import axios from 'axios';
import { faQuestionCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tabs from '../../../components/Tabs/Tabs';
import { mappingLink }  from '../../../constants/hybriddata'

let trialIndex = 0;
const TrialsCategory = getTrialsCategory();

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show_comparisons: false,
            comparisons: [],
            PURL: getPURL(),
            metadata: {
                technologies: [],
                hybrids_arr: [],
                treatments_arr: [],
                competitors_arr: [],
                year: 2020,
            },
            includedComparisonTrials: [],
            showIndividualTrailResults: true
        }
    }

    componentDidMount() {

        if (this.props.clearPURL) {
            this.setState({
                ...this.state,
                PURL: null
            }, () => { })
        }

        //Updated Trial Summary in Menu coming back from Trial summary
        let includedComparisonTrials = this.props.trials.trials.filter(t => !t.data.weather_exclude_from_summary)

        if (this.state.includedComparisonTrials.length === 0 && includedComparisonTrials) {
            this.setState({
                includedComparisonTrials
            })
        }

        if (TrialsCategory === 'DST') {
            this.setIndividualComparisonData(includedComparisonTrials, this.props.trials.trial_filters.data.filters);
        } else {
            this.setIndividualComparisonDataapt(includedComparisonTrials, this.props.trials.trial_filters.data.filters);
        }
        // this.setIndividualComparisonDataapt(this.props.trials.trials, this.props.trials.trial_filters.data.filters);

    }
    
    componentDidUpdate(prevProps) {
        if (Object.entries(prevProps.trials.trial_filters.data.filters).length !== Object.entries(this.props.trials.trial_filters.data.filters).length) {

            //this.setIndividualComparisonData(this.props.trials.trials, this.props.trials.trial_filters.data.filters);
            let includedComparisonTrials = this.props.trials.trials.filter(t => !t.data.weather_exclude_from_summary)
            this.setState({
                includedComparisonTrials: includedComparisonTrials
            })
            //console.log(includedComparisonTrials, "ICT");
            //console.log(this.props.trials.trials, "AT");

            if (TrialsCategory === 'DST') {
                this.setIndividualComparisonData(includedComparisonTrials, this.props.trials.trial_filters.data.filters);
            } else {
                this.setIndividualComparisonDataapt(includedComparisonTrials, this.props.trials.trial_filters.data.filters);
            }
        }
    }

    //Comparison to show popup
    comparisonTrialspopup = () => {
        //Comparing this.props.trials.trials and includedComparisonTrials
        let showPopup = false
        //console.log(this.props.trials.trials, "CTP")
        this.props.trials.trials.forEach((ele) => {
            //console.log(ele, "Element")
            if ((ele.data.weather_exclude_from_summary && ele.data.weather_exclude_from_summary === true)) {
                showPopup = true
            }
            //console.log(showPopup, "showpopup")
        })
        return showPopup
    }

    handlePopUpTrial = () => {
        document.getElementById("trial_popup").style.display = "block"
    }

    handlePopUpagroTrial = () => {
        document.getElementById("agrotrial_popup").style.display = "block"
    }

    /* HANDLE CHECK MARK CLASS STATE */
    handleChecked = (e) => {
        this.setState({ [e.target.name]: [e.target.checked] });
    }

    /* HANDLE ACTIVE YEAR CLASS STATE */
    toggleYear(divName) {
        this.setState({ yearFilter: divName });
        if (this.state.yearFilter === divName) this.setState({ yearFilter: '' });
    };

    /* HANDLE SEARCH STATE TEXT */
    setSearchString = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    getLocationString = () => {
        // let get_locations = this.props.trials.trials.map(a => a.data.city);

        // let unique_locations = [...new Set(get_locations)].sort();

        // if (!unique_locations.length)
        //     return "";
        // let locationString = "";
        // locationString += unique_locations[0];
        // let locations_to_display = (unique_locations.length < 3 ? unique_locations.length : 3);
        // for (let i = 1; i < locations_to_display; i++)
        //     locationString += ", " + unique_locations[i]

        // locationString += unique_locations.length < 3 ? "" : " and " + (unique_locations.length - 3) + " more"
        // return locationString;
        return this.props.location.nearestCity;
    }

    getSelectedTechnologiesString = () => {
        let selected_tech = []
        Object.entries(this.props.trials.trial_filters.data.filters).forEach(([k, v]) => {
            if (v.filt_type === 'technology') {
                selected_tech.push(v.filt_value);
            }
        })

        let techString = "";
        techString += selected_tech[0];
        for (let i = 1; i < selected_tech.length; i++)
            techString += ", " + selected_tech[i]

        return [techString, selected_tech.length];
    }

    getSummaryyeildType = () => {
        //  console.log("Trialssssssss", this.props.trials)
        
         const {trials} = this.props.trials
         let sw = 0
         let sc = 0

         for (const trial of trials){
             for (const hybrid of trial.hybrids){
                 if(hybrid?.swath_type?.toLowerCase() === 'straight cut' || hybrid?.harvest_method?.toLowerCase() === 'straight cut'){
                     sc++
                 }
                 if(hybrid?.swath_type?.toLowerCase() === 'swath' || hybrid?.harvest_method?.toLowerCase() === 'swath'){
                    sw++
                } 
                // console.log("SW", sw)
                // console.log("SC", sc)
             }
         }

         if(!sw){
             return ''
         } 
         else if (!sc){
             return 'Swathed at 60% SCC (seed colour change) except PSR hybrids, when swathed, will be swathed at 80% SCC.'
         }
         else {
            return 'Yield results include both swathed and straight cut results'
         }
	}

    getCurrentFilters = (type) => {
		let filters = [];	
		Object.entries(this.props.trials.trial_filters.data.filters).forEach(
			([k, v]) => {
				if (v.filt_type === type) {
					filters.push(v.filt_value);				
				}
			}
		);
		return filters;
	};

    getCTAInfo = () => {

        let hybridDescription = "";
        let hybridLink = "";
        
        if(this.getCurrentFilters('technology').length > 0){
            const selectedTech = this.getCurrentFilters('technology')[0]
            hybridDescription = mappingLink[selectedTech]?.description;
            hybridLink = mappingLink[selectedTech]?.link;
        }else if(this.getCurrentFilters('hybrid').length){
            const selectedHybrid = this.getCurrentFilters('hybrid')[0]
            hybridDescription = mappingLink[selectedHybrid]?.description;
            hybridLink = mappingLink[selectedHybrid]?.link;
        }else {
            hybridDescription = mappingLink['Pod Shatter Reduction']?.description;
            hybridLink = mappingLink['Pod Shatter Reduction']?.link;
        }

        return (
            <div class="hybridContainer">
                <div class="image-overlay">
                    <div className="hybridText">{hybridDescription}</div>
                    <a href={hybridLink} target='_blank'><button>More on These Hybrids</button></a>
                </div>
            </div>
        );
    }

    getSummaryInfo = () => {
        let invigor_selected = false;
        let comparison_selected = false;
        Object.entries(this.props.trials.trial_filters.data.filters).forEach(([k, v]) => {
           if (v.filt_type === 'comparison') comparison_selected = true ;
           if (v.filt_type === 'hybrid')  invigor_selected = true
        });

        if(!this.props.trialFilters.data.filters.DST && !this.props.trialFilters.data.filters.AE) {
            return (<div>
                <div className="trialSummaryHeader">
                    <h2>Trial Summary</h2>
                </div>
                <div className="trialSummaryBody">
                    <div className="tsBox">
                        <div className="tsBoxTopBorder"></div>
                        <div className="tsBoxText">Please set your Trial type filter criteria to select either DST or AE trials to see a trial summary.
                        {this.comparisonTrialspopup() ? <span style={{ float: "right", color: "#64ab1f", fontSize: "18px" }} onClick={this.handlePopUpTrial}><FontAwesomeIcon icon={faQuestionCircle} /></span> : null}</div>
                    </div>
                </div>
            </div>)
        };

        if (!invigor_selected) {
            return (<div>
                <div className="trialSummaryHeader">
                    <h2>Trial Summary</h2>
                </div>
                <div className="trialSummaryBody">
                    <div className="tsBox">
                        <div className="tsBoxTopBorder"></div>
                        <div className="tsBoxText">Please select InVigor hybrid(s) filter and Competitive hybrid with at least 3 trials available to see a trial summary</div>
                    </div>
                </div>
            </div>)
        }

        if (!comparison_selected) {
            return (<div>
                <div className="trialSummaryHeader">
                    <h2>Trial Summary</h2>
                </div>
                <div className="trialSummaryBody">
                    <div className="tsBox">
                        <div className="tsBoxTopBorder"></div>
                        <div className="tsBoxText">Please select a Competitive Hybrid to see a trial summary.</div>
                    </div>
                </div>
            </div>)
        }

        // RETURN IF ALL TRIAL TYPES ARE SELECTED (ONLY GENERATE WHEN AE OR DST).
        if(!this.props.trialFilters.data.filters.DST && !this.props.trialFilters.data.filters.AE) {
            return (<div>
                <div className="trialSummaryHeader">
                    <h2>Trial Summary</h2>
                </div>
                <div className="trialSummaryBody">
                    <div className="tsBox">
                        <div className="tsBoxTopBorder"></div>
                        <div className="tsBoxText">Please set your Trial type filter criteria to select either DST or AE trials to see a trial summary.
                        {this.comparisonTrialspopup() ? <span style={{ float: "right", color: "#64ab1f", fontSize: "18px" }} onClick={this.handlePopUpTrial}><FontAwesomeIcon icon={faQuestionCircle} /></span> : null}</div>
                    </div>
                </div>
            </div>)
        };



        //WEATHER
        //change this to check for eligible trials < 3
        //console.log(this.state.comparisons);
        //Checking the summaryTrials length --- DPS-486
        let summaryTrials = false;
        this.state.comparisons.forEach(c => {
            if(c.trials.length >= 3){
                summaryTrials = true;
                return;
            }
        })
        if(!summaryTrials){
            return (<div>
                <div className="trialSummaryHeader">
                    <h2>Trial Summary</h2>
                </div>
                <div className="trialSummaryBody">
                    <div className="tsBox">
                        <div className="tsBoxTopBorder"></div>
                        <div className="tsBoxText">Please set your filter criteria to return three or more individual trials to see a summary.
                        {this.comparisonTrialspopup() ? <span style={{ float: "right", color: "#64ab1f", fontSize: "18px" }} onClick={this.handlePopUpTrial}><FontAwesomeIcon icon={faQuestionCircle} /></span> : null}</div>
                    </div>
                </div>
            </div>)
        }
        else {
            let selected_season = []
            Object.entries(this.props.trials.trial_filters.data.filters).forEach(([k, v]) => {
                if (v.filt_type === 'year') {
                    selected_season.push(v.filt_value);
                }
            })
                        let selected_tech = this.getSelectedTechnologiesString();

            let counter = 0
            const temp = this.state.comparisons[0].trials
            const newTrials = temp.filter(item => {
                if(item.data.trial_type.includes('Agronomic')){
                    return item
                } else {
                    return item
                }
            })

            // let check = false
            // const newTrialstwenty = temp.filter(item => {
            //     if(item.data.year === 2020){
            //        check = true
            //     }
            // })

            const newlyUpdatedTrials = JSON.parse(JSON.stringify(this.state.comparisons))
            newlyUpdatedTrials[0].trials = newTrials

            // console.log(counter, "Counter")
            // console.log(this.state.comparisons[0].trials, "State")
             //console.log(newTrials, "New Trials")
            return (
                // check ? null :
                <div>
                <div className="trialSummaryHeader">
                    <h2>Trial Summary</h2>
                    <a ref="rel">{this.getLocationString()}</a>
                </div>
                <div className="trialSummaryBody">
                    <div className="tsBox">
                        <div className="tsBoxTopBorder"></div>
                        
                        {newTrials.length >= 3 ? 
                        <div className="tsBoxText">Trial Summary Results for {newTrials.length + " InVigor "}{selected_tech[1].length ? selected_tech[0] : ""}{" Trials"} in {selected_season.length ? selected_season : "All Seasons"}
                            {this.comparisonTrialspopup() ? <span style={{ float: "right", color: "#64ab1f", fontSize: "18px" }} onClick={this.handlePopUpTrial}><FontAwesomeIcon icon={faQuestionCircle} /></span> : null}
                            </div> : null }


                        <React.Fragment>
                        {newlyUpdatedTrials.map((comparison, index) => {
                            if(comparison.trials.length >= 3) {
                                comparison.trials = comparison.trials
                                return (<TrialSummaryChart
                                key={`comparison-${index}`}
                                comparisonId={index}
                                comparison={comparison}
                                page_router={this.props.page_router}
                                yearchanged={(event) => this.toggleComparisonYearHandler(event, index)} />)
                        }})}
                        
                        <div className="tsSummaryCont">
                        <p>{this.getSummaryyeildType()}</p>
                        </div>

                        <div className="tsButtonContainer">
                            <div 
                                className="tsBoxButton" 
                                onClick={() => { this.props.page_router.loadPage('trialsummary') }}
                                data-tealium-category="trial-summary-button" 
                                data-tealium-action="click" 
                                data-tealium-value="trial-summary-button" 
                                data-tealium-type="action"
                            >
                                See Results 
                            </div>
                        </div>
                        {this.props.trialFilters.data.filters.DST ?  
                        this.getCTAInfo()
                        : null}
                        <br></br><br></br>
                        </React.Fragment>   
                    </div>
                </div>
            </div>
            
            )
        }
    }

    getSummaryInfoAPT = () => {
        let comparison_selected = false;
        Object.entries(this.props.trials.trial_filters.data.filters).forEach(([k, v]) => {
            if (v.filt_type === 'comparison_APT') {
                comparison_selected = true;
            }
        })

        if (!comparison_selected) {
            return null;
        }

        //WEATHER
        if (this.state.includedComparisonTrials.length < 3 || this.props.trials.trials.length < 3) {
            return (
                <div>
                    <div className="trialSummaryHeader">
                        <h2>Trial Summary</h2>
                    </div>
                    <div className="trialSummaryBody">
                        <div className="tsBox">
                            <div className="tsBoxTopBorderapt"></div>
                            {/* <div className="tsBoxText">Please set your filter criteria to return three or more individual trials to see a summary.</div> */}
                            <div className="tsBoxText">
                            Please set your filter criteria to return three or more individual trials to see a summary.
                                {/* 2020 Trial Summaries will be available as more trials are harvested. For 2019 and 2018, please set your filter criteria to return 
                            three or more individual trials to see a summary. */}
                            </div>
                             
                        </div>
                    </div>
                </div>)
        }
        else {
            let selected_season = []
            Object.entries(this.props.trials.trial_filters.data.filters).forEach(([k, v]) => {
                if (v.filt_type === 'year') {
                    selected_season.push(v.filt_value);
                }
            })

            let selected_tech = this.getSelectedTechnologiesString();
            return (

                <div>
                    <div className="trialSummaryHeader">
                        <h2>Trial Summary</h2>
                        <a ref="rel">{this.getLocationString()}</a>
                    </div>
                    <div className="trialSummaryBody">
                        <div className="tsBox">
                            <div className="tsBoxTopBorderapt"></div>
                            <div className="tsBoxText">Trial Summary Results for {this.state.includedComparisonTrials.length}{" Trials"} in {selected_season.length ? selected_season : "All Seasons"}
                                {this.comparisonTrialspopup() ? <span style={{ float: "right", color: "#64ab1f", fontSize: "18px" }} onClick={this.handlePopUpTrial}><FontAwesomeIcon icon={faQuestionCircle} /></span> : null}</div>
                            {this.state.comparisons.map((comparison, index) => (
                                <TrialSummaryChart
                                    key={`comparison-${index}`}
                                    comparisonId={index}
                                    comparison={comparison}
                                    page_router={this.props.page_router}
                                    yearchanged={(event) => this.toggleComparisonYearHandler(event, index)} />
                            ))}
                            <div className="tsButtonContainer">
                                <div className="tsBoxButton" onClick={() => { this.props.page_router.loadPage('trialsummary') }}>See Results</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    getIndividualTrialInfo = () => {
        let selected_location = this.getLocationString();

        //Marketing Automation
        let selectedTrials = {}
        const { lat, long } = this.props.location    // NearestCity
        let trialDistances = []

        if (this.props.trials.trials && this.props.trials.trials.length > 0 && this.props.location.markercoordinates && this.props.location.markercoordinates.length > 0) {
            this.props.trials.trials.map(trial => {
                selectedTrials[trial.data.city] = {}
                selectedTrials[trial.data.city].city = trial.data.city
                selectedTrials[trial.data.city].trial = trial.data.trial
            })

            if (Object.keys(selectedTrials).length > 0) {
                //Cities
                for (let x in selectedTrials) {
                    const index = this.props.location.markercoordinates.filter(markers => markers.city === x)
                    if (index.length > 0) {
                        selectedTrials[x].lat = index[0].lat
                        selectedTrials[x].long = index[0].lng
                        //console.log(selectedTrials)
                    }
                }

                //NearestDistance
                for (let y in selectedTrials) {
                    const distance = this.trialsDistance(lat, long, selectedTrials[y].lat, selectedTrials[y].long, "K")  // parameters for nearest distance
                    selectedTrials[y].distancetonearestCity = distance
                    trialDistances.push(distance)
                }
            }
        }
        const closestTrial = Math.min(...trialDistances);
        const nearestTrial = Object.values(selectedTrials).filter(trial => trial.distancetonearestCity === closestTrial)

        if (nearestTrial.length > 0 && this.state.PURL) {
            let apiBaseUrl = `${nodeserver_url}`;

            /*Start of call */
            let data = {
                event: `${nearestTrial[0].trial}`,
                action: 'map',
                category: 'nearest_trial'
            };

            axios
                .post(apiBaseUrl + `/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`, data)
        }

        //console.log(this.props.trials.trials, "this.props.trials.trials")

        return (
            <>
                <div className="individualTSHeader" style={{ borderBottom: `${TrialsCategory === 'DST' ? "6px solid #0061a1" : "6px solid #65ac1e"}` }}>
                    {/* <h2>Individual Trial Results</h2> */}
                </div>
                <div className='locationDetailsContainer'>

                {this.state.showIndividualTrailResults ? (
                    <>
                        {TrialsCategory === 'DST' ?
                        <a className='locationDetails' ref="rel">{this.props.trials.trials.length + " InVigor Canola Trials"}{selected_location ? " near " : ""}<span className="city-name">{selected_location ? selected_location : ""}</span> </a> :
                        <a className = 'locationDetails' ref="rel">{this.props.trials.trials.length + " crop protection Trials"}{selected_location ? " near " : ""}<span className="city-name">{selected_location ? selected_location : ""}</span> </a>
                        }
                    </>
                ): (
                    <a className='locationDetails'>Please select a Competitive Hybrid to see a trial summary.</a>
                )}

                {/* {TrialsCategory === 'DST' ?
                    <a className='locationDetails' ref="rel">{this.props.trials.trials.length + " InVigor Canola Trials"}{selected_location ? " near " : ""}<span className="city-name">{selected_location ? selected_location : ""}</span> </a> :
                    <a className = 'locationDetails' ref="rel">{this.props.trials.trials.length + " crop protection Trials"}{selected_location ? " near " : ""}<span className="city-name">{selected_location ? selected_location : ""}</span> </a>
                } */}

                {/* {selected_location.length ? " in " : ""}{selected_location.length ? <a href="/" ref="rel">{selected_location}</a> : ""}</div> */}
                </div>
            
            </>
        )
    }

    //Calculate the Nearest Distance
    trialsDistance = (lat1, lon1, lat2, lon2, unit) => {
        var radlat1 = Math.PI * lat1 / 180
        var radlat2 = Math.PI * lat2 / 180
        var theta = lon1 - lon2
        var radtheta = Math.PI * theta / 180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        if (unit === "K") { dist = dist * 1.609344 }
        if (unit === "N") { dist = dist * 0.8684 }
        return dist
    }

    getComparisonHybridAverages = (trials, comparisonHybridName) => {
    
    //Filtering Agronomic Excellence Trials to be excluded from Trial Summary List - DPS-491
    const agroTrial = trials;
        // console.log(agroTrial, "TrialsCHA")
        let result = {
            harvest: { sum: 0, count: 0 },
            maturity: { sum: 0, count: 0 },
            net_yield: { sum: 0, count: 0 },
            locations: []
        }
        let technologies = [...this.state.metadata.technologies];
        let vsTrialAverages = [];

        agroTrial.map(trial => {

            //this should get changed to a better way of figuring out if the hybrid is BASF or not
            if (comparisonHybridName.indexOf('InVigor') > -1) result.locations.push(trial.data.city + ', ' + trial.data.province + " ");

            vsTrialAverages[trial.data.trial] = vsTrialAverages[trial.data.trial] ? vsTrialAverages[trial.data.trial] : {};
            trial.hybrids.map(hybrid => {

                if (hybrid.hybrid === comparisonHybridName) {
                    if ((typeof hybrid.seed_treatment !== 'undefined' ? hybrid.seed_treatment : '') !== '') {
                        if (hybrid.seed_treatment.indexOf('+') > -1) {
                            hybrid.seed_treatment.split(" + ").map((v, i) => {
                                if (technologies.indexOf(v) === -1) {
                                    technologies.push(v)
                                }
                                return null;
                            })
                        } else {
                            if (technologies.indexOf(hybrid.seed_treatment) === -1) {
                                technologies.push(hybrid.seed_treatment)
                            }
                        }

                        this.setState({ metadata: Object.assign(this.state.metadata, { technologies: technologies }) });
                    }

                    // sum up only if neight value is N/A
                    // if (hybrid.harvest && hybrid.maturity && hybrid.net_yield) {
                    if (trial.data.trial_type.indexOf("vs") > -1) {
                        //if the other hybrid in the 'vs' trial has been stored, add the average of both to the result
                        if (vsTrialAverages[trial.data.trial][hybrid.hybrid]) {
                            if (hybrid.harvest) {
                                result.harvest.sum += (hybrid.harvest + vsTrialAverages[trial.data.trial][hybrid.hybrid].harvest) / 2;
                                result.harvest.count += 1;
                            }
                            if (hybrid.maturity) {
                                result.maturity.sum += (hybrid.maturity + vsTrialAverages[trial.data.trial][hybrid.hybrid].maturity) / 2;
                                result.maturity.count += 1;
                            }
                            if (hybrid.net_yield) {
                                result.net_yield.sum += (hybrid.net_yield + vsTrialAverages[trial.data.trial][hybrid.hybrid].net_yield) / 2;
                                result.net_yield.count += 1;
                            }
                        } else { //if neither of the hybrids in the vs trial have been stored, add it to the array to be used later when the other one is found
                            vsTrialAverages[trial.data.trial][hybrid.hybrid] = {};
                            vsTrialAverages[trial.data.trial][hybrid.hybrid].harvest = hybrid.harvest;
                            vsTrialAverages[trial.data.trial][hybrid.hybrid].maturity = hybrid.maturity;
                            vsTrialAverages[trial.data.trial][hybrid.hybrid].net_yield = hybrid.net_yield;
                        }
                    }
                    else {
                        if (hybrid.harvest) {
                            result.harvest.sum += hybrid.harvest;
                            result.harvest.count += 1;
                        }
                        if (hybrid.maturity) {
                            result.maturity.sum += hybrid.maturity;
                            result.maturity.count += 1;
                        }
                        if (hybrid.net_yield) {
                            result.net_yield.sum += hybrid.net_yield;
                            result.net_yield.count += 1;
                        }
                    }
   
                    // } else if (hybrid.net_yield) { //some trials only have yield data
                    //     if (trial.data.trial_type.indexOf("vs") > -1) {
                    //         //if the other hybrid in the 'vs' trial has been stored, add the average of both to the result
                    //         if (vsTrialAverages[trial.data.trial][hybrid.hybrid]) {
                    //             result.net_yield += (hybrid.net_yield + vsTrialAverages[trial.data.trial][hybrid.hybrid].net_yield) / 2;
                    //             result.count += 1;
                    //         } else { //if neither of the hybrids in the vs trial have been stored, add it to the array to be used later when the other one is found
                    //             vsTrialAverages[trial.data.trial][hybrid.hybrid] = {};
                    //             vsTrialAverages[trial.data.trial][hybrid.hybrid].net_yield = hybrid.net_yield;
                    //         }
                    //     }
                    //     else {
                    //         result.net_yield += hybrid.net_yield;
                    //         result.count += 1;
                    //     }
                    //     // console.log('Yield ' + comparisonHybridName + ' : ' + hybrid.harvest)
                    //     // console.log('Maturity ' + comparisonHybridName + ' : ' + hybrid.maturity)
                    //     // console.log('Harvest ' + comparisonHybridName + ' : ' + hybrid.net_yield)

                    // }
                }
                return null;
            })
            return null;
        })
        return result;
    }

    //APT
    getComparisonTreatmentAverages = (trials, comparisonTreatmentName) => {
        let result = {
            yield: 0,
            count: 0,
            locations: [],
            displayed_name: ''
        };
        //let products = [...this.state.metadata.products];

        trials.map(trial => {

            // if (comparisonTreatmentName > -1) 
            result.locations.push(trial.data.city + ', ' + trial.data.province + " ");
            trial.treatments.map(treatment => {
                if (treatment.treatment === comparisonTreatmentName) {
                    // sum up only if neight value is N/A
                    if (treatment.yield) {
                        // console.log('Yield ' + comparisonHybridName + ' : ' + hybrid.harvest)
                        // console.log('Maturity ' + comparisonHybridName + ' : ' + hybrid.maturity)
                        // console.log('Harvest ' + comparisonHybridName + ' : ' + hybrid.net_yield)
                        // result.harvest += hybrid.harvest;
                        //result.maturity += hybrid.maturity;
                        result.yield += treatment.yield;
                        result.count += 1;
                        result.displayed_name = treatment.displayed_treatment;
                    }
                }
                return null;
            })
            return null;
        })
        return result;
    }

    setIndividualComparisonData = (trials, filters) => {
        let comparisons = [];
        let hybridsArr = [], competitorsArr = [];
        let year = 'All';
        let count = -1;

        for (var i in filters) {
            if (Object.entries(filters).find(x => x[1].filt_type === "comparison")) {
                if (!filters.hasOwnProperty(i) && typeof filters[i].filt_type === 'undefined') continue;
                if (filters[i].filt_type === "comparison") {
                    //DPS-486
                    let comparisonHybrid = filters[i].filt_value[0];
                    let comparisonCompetitor = filters[i].filt_value[1];

                    let filteredTrials = trials.filter(t => {
                        let includesHybrid = false;
                        let includesCompetitor = false;
                        if(t.hybrids) {
                            t.hybrids.forEach(h => {
                                if(h.hybrid === comparisonHybrid) includesHybrid = true;
                                if(h.hybrid === comparisonCompetitor) includesCompetitor = true;
                            })
                        }
                        return (includesHybrid && includesCompetitor)
                    })
                    //console.log(filteredTrials);

                    count += 1;
                    comparisons.push({ hybrid: { name: comparisonHybrid }, competitor: { name: comparisonCompetitor } });
                    hybridsArr.push(comparisonHybrid);
                    competitorsArr.push(comparisonCompetitor);

                    Object.assign(comparisons[count], { trials: filteredTrials, filters: new Filter(), year: year, swathtype: this.getSummaryyeildType([...filteredTrials])});
                    Object.assign(comparisons[count].filters.data, this.props.trials.trial_filters.data); //update the new filter.data with the current set of filters this.getSwathTypes([...filteredTrials]) 

                    filters[i].filt_value.map((hybrid, index) => {
                        if (index < 2) { //only process the name of the hybrid and competitor, not the competitor seed_treatment at index === 2
                            (index === 0 ?
                                Object.assign(comparisons[count].hybrid, this.getComparisonHybridAverages(filteredTrials, hybrid)) :
                                Object.assign(comparisons[count].competitor, this.getComparisonHybridAverages(filteredTrials, hybrid)))
                            return null;
                        }
                    })
                } else if (filters[i].filt_type === "year") {
                    year = filters[i].filt_value;
                }
            }
        }
        this.setState({ comparisons: comparisons }, () => this.props.doSetComparisons(comparisons));
        if (comparisons.length > 0) { this.setState({ show_comparisons: true }); }
        this.setState({ metadata: Object.assign(this.state.metadata, { hybrids_arr: [...new Set(hybridsArr)], competitors_arr: [...new Set(competitorsArr)], year: year }) });
        this.props.doSetComparisonMetadata([this.state.metadata]);
    }

    //APT
    setIndividualComparisonDataapt = (trials, filters) => {
        let comparisons = [];
        let treatmentsArr = [], competitorsArr = [];
        let year = 'All';
        let count = -1;

        for (var i in filters) {
            if (!filters.hasOwnProperty(i) && typeof filters[i].filt_type === 'undefined') continue;
            if (filters[i].filt_type === "comparison_APT") {
                count += 1;
                comparisons.push({ treatment: { name: filters[i].filt_value[0] }, competitor: { name: filters[i].filt_value[1] } });
                treatmentsArr.push(filters[i].filt_value[0]);
                competitorsArr.push(filters[i].filt_value[1]);

                Object.assign(comparisons[count], { trials: trials, filters: new Filter(), year: year });
                Object.assign(comparisons[count].filters.data, this.props.trials.trial_filters.data); //update the new filter.data with the current set of filters

                filters[i].filt_value.map((treatment, index) => {
                    if (index < 2) { //only process the name of the treatment and competitor, not the competitor seed_treatment at index === 2
                        (index === 0 ?
                            Object.assign(comparisons[count].treatment, this.getComparisonTreatmentAverages(trials, treatment)) :
                            Object.assign(comparisons[count].competitor, this.getComparisonTreatmentAverages(trials, treatment)))
                        return null;
                    }
                })
            } else if (filters[i].filt_type === "year") {
                year = filters[i].filt_value;
            }
        }
        this.setState({ comparisons: comparisons }, () => this.props.doSetComparisons(comparisons));
        if (comparisons.length > 0) { this.setState({ show_comparisons: true }); }
        this.setState({ metadata: Object.assign(this.state.metadata, { treatments_arr: [...new Set(treatmentsArr)], competitors_arr: [...new Set(competitorsArr)], year: year }) });
        this.props.doSetComparisonMetadata([this.state.metadata]);
    }


    getSwathTypes = (trials) => {
        let swathTypesList = [];
        let swathTypesString = "";

        trials.map(trial => {
            trial.hybrids.sort((a, b) => (a.swath_type < b.swath_type) ? -1 : (a.swath_type > b.swath_type) ? 1 : 0).forEach(h => {
                if (!swathTypesList.includes(h.swath_type)) {
                    swathTypesString += swathTypesList.length ? " & " : "";
                    swathTypesString += h.swath_type + " (" + h.swath_type_abbr + ")";
                    swathTypesList.push(h.swath_type);
                }
            })
            return null;
        })

        return swathTypesString;
    }

    render() {
        
        return (
            <div className="menuContainer">

                { /* ------------------ FILTER SECTION -------------------*/}
                <FilterSection clearPURL={this.props.clearPURL} updatedHerbicide={this.props.updatedHerbicide} updatedFacet={this.props.updatedFacet} updatedZidua={this.props.updatedZidua} updatedHeatLQ={this.props.updatedHeatLQ}
                    isHerbicide={this.props.isHerbicide} isFacet={this.props.isFacet} isZidua={this.props.isZidua} isHeatLQ={this.props.isHeatLQ} />

            <Tabs>
                <div label='Individual Trial Results' number=''>
                
                { /* ------------------ INDIVIDUAL TRIAL SUMMARY SECTION -------------------*/}

                {this.getIndividualTrialInfo(this.props.trials.trials)}
                
                {this.props.trials ?
                    <div className="trialList">
                        {this.props.trials.trials.map((trial) => (
                            <IndividualTrial key={`indTrial-${trial.data.trial}+${trialIndex++}`} trial={trial} page_router={this.props.page_router} clearPURL={this.props.clearPURL}
                                isHerbicide={this.props.isHerbicide} isFacet={this.props.isFacet} isZidua={this.props.isZidua} isHeatLQ={this.props.isHeatLQ} />
                        ))}
                        {this.props.trialFilters.data.filters.DST ?  
                        this.getCTAInfo()
                        : null}
                        <br></br><br></br>
                    </div>
                    : null
                }
                </div>
                <div label='Trial Summary' number=''>
                { /* ------------------ APT TRIAL SUMMARY SECTION -------------------*/}

                {TrialsCategory === 'APT' ?  this.getSummaryInfoAPT() : null}

                { /* ------------------ DST TRIAL SUMMARY SECTION -------------------*/}

                {TrialsCategory  === 'DST'? this.getSummaryInfo() : null}
                </div>
            </Tabs>

            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    trials: JSON.parse(JSON.stringify(getVisibleTrials(state.trials))),
    trialFilters: JSON.parse(JSON.stringify(state.trials.trial_filters)),
    allTrials: JSON.parse(JSON.stringify(state.trials.all_trials)),
    location: state.location
})

// export default Menu;
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({ ...localeActionCreators, ...comparisonActionCreators }, dispatch)
)(Menu);