import React, { Component } from 'react';
import {
    Rectangle
} from 'react-google-maps';
import { getCenter } from 'geolib';
import { getTrialsCategory } from '../../../selectors/trialsSelector';

const TrialsCategory = getTrialsCategory();

class MapRectangle extends Component {
    constructor(props) {
        super(props);
        this.state = {
        	dragging: false
        }
    }

    componentWillReceiveProps = (nextprops) => {
    	this.setState({ ...nextprops });
    }

    // updates rectangle bounds when dragged or stretched
    rectangleDrag = (dragged) => {
    	if(!this.state.dragging) {
	    	let bounds = this.rectangle.getBounds();
			//let center = getCenter([{longitude: bounds.da.h, latitude: bounds.ha.h},{longitude: bounds.da.h, latitude: bounds.ha.g},{longitude: bounds.da.g, latitude: bounds.ha.h},{longitude: bounds.da.g, latitude: bounds.ha.g}]);
			let center = getCenter([{longitude: bounds.getNorthEast().lng(), latitude: bounds.getNorthEast().lat()},{longitude: bounds.getNorthEast().lng(), latitude: bounds.getSouthWest().lat()},
				                    {longitude: bounds.getSouthWest().lng(), latitude: bounds.getNorthEast().lat()},{longitude: bounds.getSouthWest().lng(), latitude: bounds.getSouthWest().lat()}]);
			let currentLocation = (dragged ? {lat: center.latitude, lng: center.longitude} : null);

	    	this.props.rectangleDrag(bounds, center, currentLocation);
    	}
    }

    render() {
    	return (
    		<React.Fragment>
    			<Rectangle
					options={{ strokeColor:'#3598f0', strokeOpacity:1, strokeWeight:3, fillColor:'#3598f0', fillOpacity:0.3 }}
					//options={{ strokeColor:'transparent', strokeOpacity:0, strokeWeight:0, fillColor:'transparent', fillOpacity:0 }}
				    ref={(rec) => this.rectangle = rec}
				    draggable={true}
				    editable={true}
					//bounds={TrialsCategory === 'APT' ? this.props.bounds : ''}
					bounds={this.props.bounds}
				    onDragStart={() => this.setState({dragging: true})}
					onDragEnd={() => {(() => this.setState({dragging: false}))(); (() => this.rectangleDrag(true))();} }
					onBoundsChanged={this.rectangleDrag}
				/>
    		</React.Fragment>
    	)
    }
}

export default MapRectangle;