import React, { Component } from "react";
import { nodeserver_url_images } from "../../constants/api";
import "./PopUp.scss";
import { getTrialsCategory } from "../../selectors/trialsSelector";

let closeImg = `${nodeserver_url_images}/trial_results/images/common/close.png`;

const TrialsCategory = getTrialsCategory();

class PopUp extends Component {
  closePopUp = () => {
    document.getElementById("map_popup").style.display = "none";
  };

  render() {
    return (
      <React.Fragment>
        <div id="map_popup">
          <div id="popup_message">
            <div id="popup_no" onClick={this.closePopUp}>
              <img src={closeImg} alt="close" />
            </div>
            {TrialsCategory === "DST" ? (
              <React.Fragment>
                <div id="popup_text-reviewmap">
                  <h4>Need Help?</h4>
                  <p>
                  By default, the map will be set to your current location, showing any 2024 trials conducted within 200 square kilometers. If you are located outside of Western Canada, your default location will be Saskatoon.
                  </p>
                </div>

                <h4>Navigation using the location search:</h4>
                <p>
                  To view results from another specific location, enter it in
                  the “Search Cities” box on the upper right of the page.
                </p>

                <h4>Navigation using the box:</h4>
                <p>
                  To change the size of the selected area, select any corner of
                  the box and drag it outwards or inwards. You can also change
                  the size of the box vertically or horizontally only by
                  hovering the cursor over the center of any side (highlighted
                  as a small circle) and drag to preferred length or height. To
                  move the box to a different location, hover the cursor
                  anywhere inside the box, then click and hold to move to your
                  preferred location.
                </p>

                <h4>Navigation using a custom shape:</h4>
                <p>
                  To create a custom area search shape, select polygon shape
                  next to the square in upper right corner of the map. To
                  include additional trials within the selected area, select any
                  mid or end point (highlighted as a small circle) and drag in
                  any direction. To move this whole shape to a new location,
                  hover the cursor over the shape, click and move to your
                  preferred location.
                </p>

                <h4>Using Filters</h4>
                <p>
                  You can also search for results by using the following
                  filters:
                </p>

                <div className="popupRow">
                  <ol>
                    <li>Specific year or all years </li>
                    <li>Technology used (if applicable) </li>
                    <li>InVigor Hybrid(s) (Mandatory)</li>
                    <li>Competitive hybrid(s)</li>
                  </ol>
                </div>

                <h4>Trial Summary</h4>
                <p>To Generate a Trial Summary:</p>

                <div className="popupRow">
                  <ul>
                    <li>
                      A minimum of 3 individual trials are required to generate
                      a Trial Summary
                    </li>
                    <li>
                      When selecting filters, if you would like to see a trial
                      summary it is necessary to select a competitive hybrid.
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            ) : (
                <React.Fragment>
                  <div id="popup_text-reviewmap">
                    <h4>Need Help?</h4>
                    <p>
                    By default, the map will be set to your current location, showing any 2024 trials conducted within 200 square kilometers. If you are located outside of Western Canada, your default location will be Saskatoon.
                  </p>
                  </div>

                  <h4>Navigation using the location search:</h4>
                  <p>
                    To view results from another specific location, enter it in
                    the “Search Cities” box on the upper right of the page.
                </p>

                  <h4>Navigation using the box:</h4>
                  <p>
                    To change the size of the selected area, select any corner of
                    the box and drag it outwards or inwards. You can also change
                    the size of the box vertically or horizontally only by
                    hovering the cursor over the center of any side (highlighted
                    as a small circle) and drag to preferred length or height. To
                    move the box to a different location, hover the cursor
                    anywhere inside the box, then click and hold to move to your
                    preferred location.
                </p>

                  <h4>Navigation using a custom shape:</h4>
                  <p>
                    To create a custom area search shape, select polygon shape
                    next to the square in upper right corner of the map. To
                    include additional trials within the selected area, select any
                    mid or end point (highlighted as a small circle) and drag in
                    any direction. To move this whole shape to a new location,
                    hover the cursor over the shape, click and move to your
                    preferred location.
                </p>

                  <h4>Using Filters</h4>
                  <p>
                    You can also search for results by using the following filters:
                </p>

                  <div className="popupRow">
                    <ol>
                      <li>Specific year or all years </li>
                      <li>Indication used (if applicable)  </li>
                      <li>Crop Type (Mandatory)</li>
                      <li>Product</li>
                      <li>Competitive Product</li>
                    </ol>
                  </div>

                  <h4>Trial Summary</h4>
                  <p>To Generate a Trial Summary:</p>

                  <div className="popupRow">
                    <ul>
                      <li>A minimum of 3 individual trials are required to generate a Trial Summary</li>
                      <li>When selecting filters, if you would like to see a trial summary it is necessary to select a competitive hybrid. </li>
                    </ul>
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PopUp;
