import { createSelector } from 'reselect'
import QueryString from 'qs';

const getTrials = (state) => state.trials
const getFilters = (state) => state.trial_filters

let trialIncludesHybrid = (hybrids) => {
  // hybrids.map(h => console.log(h.hybrid));
  if (hybrids.find(h => { return (h.seed_treatment && h.seed_treatment.includes('Clubroot Resistant')) })) {
    return true;
  }
  return false;
}

export const getVisibleTrials = createSelector([getTrials, getFilters], (trials, filters) => {
  return getFilteredTrials(trials, filters);
});

export const getPURL = createSelector([], () => {
  let PURL = QueryString.parse(window.location.search).PURL;
  return PURL ? PURL : '';
})
export const getTrialsCategory = createSelector([], () => {
  return QueryString.parse(window.location.search).t === 'APT' ? 'APT' : 'DST';
})

export const getTrialsRegion = createSelector([], () => {
  return QueryString.parse(window.location.search).r === 'east' ? 'east' : 'west';
})

export const getDataset = createSelector([], () => {
  return QueryString.parse(window.location.search).dataset === 'prd' ? 'prd' : '';
})

export const getFilteredTrials = (trials, filters) => {
  let Trials = trials;
  let UnboundTrials = trials; //not limited to selected map area

  if (filters.has_filter()) {
    //apply the filters here
    const values = Object.values(filters.data.filters).sort((a, b) => a.key === 'area' ? 1 : -1);
    for (var x = 0; x < values.length; x++) {
      //key, filt_type, filt_value
      let value = values[x].filt_value;
      switch (values[x].filt_type) {
         case 'trialTypes':
          if(value === 'AE'){
            UnboundTrials = UnboundTrials.filter(t => (t.data.trial_type.includes('Agronomic')));
          } 
          if(value === 'DST'){
            UnboundTrials = UnboundTrials.filter(t => (!t.data.trial_type.includes('Agronomic')));
          }
          if(value === 'All'){
            UnboundTrials = UnboundTrials.filter(t => t);
          }  
          break;
        case 'year':
          UnboundTrials = UnboundTrials.filter(t => (t.data.year === value));
          break;
        case 'search':
          UnboundTrials = UnboundTrials.filter(t => {
            return (
              t.data.city.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
              t.data.province.toLowerCase().indexOf(value.toLowerCase()) >= 0
            )
          })
          break;
        case 'technology':
          //filter returns true if the trial has hybrids listed, and that array of hybrids includes a hybrid with the value
          UnboundTrials = UnboundTrials.filter(t => (t.hybrids && t.hybrids.find(h => {
            let technologyIncluded = false;
            values.forEach(v => {
              if (h.seed_treatment && h.seed_treatment.includes(v.filt_value)) {
                technologyIncluded = true;
                return;
              }
            });
            return technologyIncluded;
          })))
          break;
        case 'hybrid':
          UnboundTrials = UnboundTrials.filter(t => (t.hybrids && t.hybrids.find(h => {
            let hybridIncluded = false;
            values.forEach(v => {
              if (h.hybrid && h.hybrid.includes(v.filt_value)) {
                hybridIncluded = true;
                return;
              }
            });
            return hybridIncluded;
          })))
          break;
        case 'comparison':
          UnboundTrials = UnboundTrials.filter(t => (t.hybrids && t.hybrids.find(h => {
            let hybridIncluded = false;
            // values.forEach(v => {
            if (h.hybrid && h.hybrid === value[1]) {
              hybridIncluded = true;
              // return;
            }
            // });
            return hybridIncluded;
          })))
          break;

        /*
            APT Filters 
        */
        case 'crop_type':
          UnboundTrials = UnboundTrials.filter(t => {
            return t.data.crop_type === value;
          });
          break;

        case 'crop':
          UnboundTrials = UnboundTrials.filter(t => {
            return t.data.crop === value[1];
          });
          break;

        case 'trial_name':
            UnboundTrials = UnboundTrials.filter(t => {
              return t.data.trial_name === value;
            });
            break;

        case 'indication':
          UnboundTrials = UnboundTrials.filter(t => {
            return t.data.indication === value;
          });
          break;

        case 'treatment':
          UnboundTrials = UnboundTrials.filter(t => (t.treatments && t.treatments.find(h => {
            let treatmentIncluded = false;
            values.forEach(v => {
              if (h.treatment && h.treatment.includes(v.filt_value)) {
                treatmentIncluded = true;
                return;
              }
            });
            return treatmentIncluded;
          })))
          break;
        case 'comparison_APT':
          UnboundTrials = UnboundTrials.filter(t => (t.treatments && t.treatments.find(h => {
            let treatmentIncluded = false;
            values.forEach(v => {
              if (h.treatment && h.treatment === value[1]) {
                treatmentIncluded = true;
                return;
              }
            });
            return treatmentIncluded;
          })))
          break;
        case 'polygon':
          Trials = UnboundTrials.filter(trial => (
            inPolygon(values[x].filt_value.props.paths.length, values[x].filt_value.props.paths.map(p => p.lng), values[x].filt_value.props.paths.map(p => p.lat), trial.data.longitude, trial.data.latitude)
          ));
          break;
        case 'rectangle':
          Trials = UnboundTrials.filter(trial => (
            (trial.data.latitude >= values[x].filt_value.props.bounds.south && trial.data.latitude <= values[x].filt_value.props.bounds.north && trial.data.longitude <= values[x].filt_value.props.bounds.east && trial.data.longitude >= values[x].filt_value.props.bounds.west)
          ));
          break;

        default:
          break;

      }
    }
  }

  //    return {trialTypes:{TRIAL_TYPES},trial_type_filters:filters}
  return { trials: Trials, trial_filters: filters, unboundTrials: UnboundTrials }
}

// return (h.hybrid && h.hybrid.includes(value))
function inPolygon(verticesCount, xAry, yAry, markerX, markerY) {
  let i, j, c = 0;
  for (i = 0, j = verticesCount - 1; i < verticesCount; j = i++) {
    if (((yAry[i] > markerY) !== (yAry[j] > markerY)) &&
      (markerX < (xAry[j] - xAry[i]) * (markerY - yAry[i]) / (yAry[j] - yAry[i]) + xAry[i]))
      c = !c;
  }
  return c;
}