import React, { Component } from 'react'
import './ChartBar.scss';

class ChartBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {
                width: 0,
                transition: 'width 1500ms ease 0s'
            }
        }
    }

    componentDidMount() {
        // animate the hybrid bar transition when bar value gets set initially
        this.setBarUnitWidth();
    }

    componentDidUpdate(prevProps) {
        // animate the hybrid bar transition when bar value gets updated
        if (prevProps.value !== this.props.value) {
            this.setState({
                style: {
                    width: 0,
                    transition: 'none'
                }
            }, () => {
                this.setBarUnitWidth();
            });
        }
    }

    setBarUnitWidth = () => {
        setTimeout(() => this.setState({
            style: { ...this.props.style, ...{ width: this.props.style.width, transition: 'width 1500ms ease 0s' } }
        }), 5); // delay is for smooth animation purpose only
    }

    render() {
        return (
            <div className="chartBarContainer">
                
                <div className="barLabel" dangerouslySetInnerHTML={{ __html: this.props.label }}></div>
                <div className="barContent">
                    <div className="bar" style={(this.props.animate ? this.state.style : this.props.style)} >{this.props.value}</div>
                </div>
            </div>
        )
    }
}

export default ChartBar