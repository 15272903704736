import React, { Component } from 'react';
import { nodeserver_url_images } from '../../constants/api';
import './Header.css';

let BASFLogo = `${nodeserver_url_images}/trial_results/images/common/BASF_Logo.svg`;

class Header extends Component {


    render() {
        return (
            <header className="app-header">
                <button 
                    className="bannerLink"
                    data-tealium-category="navigator" 
                    data-tealium-action="click link" 
                    data-tealium-value="basf-logo-banner-link-button"
                    data-tealium-type="navigator" 
                    onClick={() => { this.props.page_router.loadPage('home') }}>
                    <img src={BASFLogo} alt="BASF Logo"/>
                </button>
            </header>
        );
    }

}

export default Header;