import React, { Component } from 'react';
import './PhotoPopup.scss';
import moment from 'moment';
import { getTrialsCategory } from '../../selectors/trialsSelector';
import { nodeserver_url_images, nodeserver_url_images_aptapi } from '../../constants/api';

const TrialsCategory = getTrialsCategory();

class PhotoPopup extends Component {
    constructor(props) {
		super(props);
        this.state = {
            photoIndex: this.props.indexSelected,
            toDisplay:  this.props.toDisplay
        }
    };

    changeSlideClickHandler = ( type ) => {
		if (type === 'next') {
			this.setState({
				photoIndex: this.state.photoIndex + 1 === this.props.photoData.length ? 0 : this.state.photoIndex + 1
			})
		}
		else {
			this.setState({
				photoIndex: this.state.photoIndex - 1 === -1 ? this.props.photoData.length-1 : this.state.photoIndex - 1
			})	
		}
	}

    
	closeClickHandler = () => {
		if (!this.state.toDisplay) {
			document.addEventListener("click", this.handleOutsideClick, false);
		} else {
			document.removeEventListener("click", this.handleOutsideClick, false);
		}
	
		this.setState(prevState => ({ toDisplay: false }));

        this.props.handleToDisplayStateChange(false);
	  };

	handleOutsideClick = e => {
		if (!this.node.contains(e.target)) this.closeClickHandler();
	};

    getLink = (data, year) => {
        if(data.attachmentType === 'photo' && year >= 2021) return nodeserver_url_images_aptapi + data.photo.substring(2).replace("MEDIUM", "LARGE") 
        else if (data.attachmentType === 'photo' && year < 2021) return data.photo.replace("MEDIUM", "LARGE") 
        else if (data.attachmentType === 'pdf') return data.attachmentURL
        else return null
    };

    getSrc = (data, year) => {
        if(data.attachmentType === 'photo' && year >= 2021) return nodeserver_url_images_aptapi + data.photo.substring(2)
        else if (data.attachmentType === 'photo' && year < 2021) return data.photo 
        else if (data.attachmentType === 'pdf') return data.photo
        else return null
    };
    
    render() {
        const { photoData, year } = this.props;
        const { photoIndex, toDisplay } = this.state; 

        let metadata = toDisplay && photoData[photoIndex].metadata ? photoData[photoIndex].metadata : null ;

        return (						
            <div  ref={node => {this.node = node;}} className="imagecomponent-popup" onClick={() => {this.closeClickHandler()}} >
                <div className="imagecomponent-div">
                <div className="imagecomponent-block" onClick={e => {e.stopPropagation()}}>
                        <div style={{height: "25px"}}>
                            <div href="#" className="imagecomponent-close" onClick={() => {this.closeClickHandler()}} ></div>
                        </div>
                        <React.Fragment>
                            {photoData[photoIndex].attachmentType === 'video' ? 
                                <iframe 
                                    width="700" 
                                    height="394"
                                    src={`${photoData[photoIndex].attachmentURL}`}
                                    title="YouTube video player" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen="true"
                                    data-tealium-category="link" 
                                    data-tealium-action="click video" 
                                    data-tealium-value={`link: ${photoData[photoIndex].attachmentURL}`}
                                    data-tealium-type="link" 
                                >
                                </iframe>
                                :
                                <>
                                    <a href = {this.getLink(photoData[photoIndex], year)} target = '_blank'>
                                        <img 
                                            src = {this.getSrc(photoData[photoIndex], year)}
                                            alt="slideImg"
                                            data-tealium-category="link" 
                                            data-tealium-action="click photo" 
                                            data-tealium-value={`link: ${photoData[photoIndex].photo}`}
                                            data-tealium-type="link" 
                                        />
                                    </a>
                                    {   photoData[photoIndex].attachmentType === 'pdf' ? 
					                    <a href={photoData[photoIndex].attachmentURL} target = '_blank' style={{padding:"2px",textDecorationLine:"none",justifyContent:"center",alignItems:"center",display:"flex"}}>
                                            <div 
                                                className="carousel-pdf-button"
                                                data-tealium-category="link" 
                                                data-tealium-action="open pdf" 
                                                data-tealium-value={`link: ${photoData[photoIndex].attachmentURL}`}
                                                data-tealium-type="link" 
                                            >
                                                Open PDF 
                                            </div>
                                        </a>
                                        : 
                                        null
                                    }
                                    { photoData[photoIndex].attachmentType === 'photo' && year < 2021 ? 
                                        <div className="imagecomponent-slidetext"> <b> Date: {new Date(photoData[photoIndex].date).toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'long'})} </b> {photoData[photoIndex].comment} </div>
                                        : 
                                        null
                                    }
                                    { photoData[photoIndex].attachmentType === 'photo' && year >= 2021 && metadata && metadata.photoDate ?
                                        <div className="imagecomponent-slidetext"> <b> Date: {moment(metadata.photoDate).format('DD-MM-YYYY')} </b> </div>
                                        :
                                        null
                                    }
                                </>
                            }
                            {photoData[photoIndex].attachmentType === 'photo' && metadata ?                               
                                <div className='imagecomponent-metadata'>
                                    {metadata.treatment ? 
                                        <text className="metadata-component">
                                            <text className="metadata-SubHeader">Treatment: </text> 
                                            <text className="metadata-Text"> {metadata.treatment} </text>
                                        </text>
                                        : 
                                        null
                                    }
                                    {metadata.daysAfterSeeding ? 
                                        <text className="metadata-component">
                                            <text className="metadata-SubHeader">Days After Seeding: </text> 
                                            <text className="metadata-Text"> {metadata.daysAfterSeeding} </text>
                                        </text>
                                        : 
                                        null
                                    }
                                    {metadata.daysAfterTreatment ? 
                                        <text className="metadata-component">
                                            <text className="metadata-SubHeader">Days After Treatment: </text> 
                                            <text className="metadata-Text"> {metadata.daysAfterTreatment} </text>
                                        </text>
                                        : 
                                        null
                                    }													
                                </div>
                                :
                                null
                            }
                            <div className="imagecomponent-slide-arrowtext-row" >
                                <i class="arrow-left" onClick={() => this.changeSlideClickHandler('prev')}></i>
                                <div className="imagecomponent-slidePageText">{photoIndex+1} of {photoData.length}</div>
                                <i class="arrow-right" onClick={() => this.changeSlideClickHandler('next')}></i>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        )
    }
}

export default PhotoPopup
