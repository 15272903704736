export const setComparisons = 'SET_COMPARISONS';
export const setComparisonMetadata = 'SET_COMPARISON_METADATA';
export const setAutomatedComparisons = 'SET_AUTOMATED_COMPARISONS';

export const comparisonActionCreators = {
	doSetComparisons: (comparisons) => (dispatch, getState) => {
		dispatch({
			type: setComparisons,
			payload: comparisons
		});
	},
	doSetComparisonMetadata: (metadata) => (dispatch, getState) => {
		dispatch({
			type: setComparisonMetadata,
			payload: metadata
		});
	},
	doSetAutomatedComparisons: (automatedComparisons) => (dispatch, getState) => {
		dispatch({
			type: setAutomatedComparisons,
			payload: automatedComparisons
		});
	}
}

