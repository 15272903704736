
export function getAgronomist(id, agronomists) {
    //id = the id of the Agronomist you want
    //agronomists = array of all Agronomists
    id = id || 1;
    let agronomist = agronomists.filter(a => a.data.id === id);
    return agronomist[0];
}

/* base64toBlob()
    * Convert base64 encoded Buffer of pdf to blob
    * params: a base64 encoded string
    * returns: a url to the blob
   */
export function base64toBlob(base64str) {
    // decode base64 string, removing beginning MIME type
    var binary = atob(base64str.replace('data:application/pdf;base64,', ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    // convert to array
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"               
    var blob = new Blob([view], { type: "application/pdf" });
    // link blob with browser readable URL

    return blob;
}