import React from 'react';

const FilterYear = props => {
    return (      
        <div onClick={props.onclick} 
            data-action={props.action} 
            data-label={props.label} 
            data-value={props.value} 
            data-tealium-category="filter-year" 
            data-tealium-action="click filter-button" 
            data-tealium-value={`filter-year-button-${props.value}`}
            data-tealium-type="filter" 
            className= {props.selected ? 'yearSelect active' : 'yearSelect'}>
                {props.value}
        </div>
    ); 
}

export default FilterYear;