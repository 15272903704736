import React, { Component } from 'react';
import {
    Polygon
} from 'react-google-maps';
import { getCenter } from 'geolib';

class MapPolygon extends Component {
    constructor(props) {
        super(props);
    }

    componentWillReceiveProps = (nextprops) => {
    	this.setState({ ...nextprops });
    }

	// updates polygon bounds on drag or expand
	polyDrag = (dragged) => {
		let paths = [];
		this.poly.getPaths().getArray()[0].g.forEach(v => {
			paths.push({lat: v.lat(), lng: v.lng()});
		});
		let center = getCenter(paths);
		let currentLocation = (dragged ? {lat: center.latitude, lng: center.longitude} : null);

		this.props.polyDrag(paths, center, currentLocation);
	}


    render() {
    	return (
    		<React.Fragment>
    			<Polygon 
					paths={this.props.paths} 
					options={{strokeColor:'#3598f0',strokeOpacity:1,strokeWeight:3,fillColor:'#3598f0',fillOpacity:0.3}}
					draggable={true}
			    	editable={true}
			    	ref={(poly) => this.poly = poly}
			    	onDragEnd={() => this.polyDrag(true)}
			    	onMouseUp={() => this.polyDrag(false)}
				/>
    		</React.Fragment>
    	)
    }
}

export default MapPolygon;