import React from 'react';
import { nodeserver_url_images } from '../../constants/api';
import './Tooltip.scss';

let iIcon = `${nodeserver_url_images}/trial_results/images/common/i.png`;
let iIconBlack = `${nodeserver_url_images}/trial_results/images/common/i_bl.png`;

const tooltip = (props) => {
    return <span className="tooltip"><img src={props.color === "black" ? iIconBlack : iIcon} alt="i" /><div className="tooltiptext"><p>{props.text}</p></div></span>
}

export default tooltip;