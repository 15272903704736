import React, { Component } from 'react';
import { nodeserver_url_images } from '../../../constants/api';
import {
	InfoWindow
} from 'react-google-maps';

let leftArrow = `${nodeserver_url_images}/trial_results/images/googleMapIcons/left_arrow.png`;
let rightArrow = `${nodeserver_url_images}/trial_results/images/googleMapIcons/right_arrow.png`;

class MapInfoWindow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: props.content || [],
			contentIndex: props.contentIndex || 0,
			position: props.position,
			mapZoom: props.mapZoom || 6
		}
	}

	componentDidMount = () => {
		this.adjustOffset();
	}

	componentWillReceiveProps = (nextprops) => {
		this.setState({ ...nextprops }, () => this.adjustOffset());
	}

	// update infoWindow content when pager arrow clicked
	pageInfo = (direction, position) => {
		if (direction === 'right' && this.state.content.length - 1 > this.state.contentIndex) {
			this.setState({ contentIndex: this.state.contentIndex + 1 }, () => this.props.adjustIndex(this.state.contentIndex));
		} else if (direction === 'left' && 0 < this.state.contentIndex) {
			this.setState({ contentIndex: this.state.contentIndex - 1 }, () => this.props.adjustIndex(this.state.contentIndex));
		}
	}

	// how much to offset(lat) infoWindow based on zoom level
	getOffset = (modifier = 0) => {
		if ((this.props.mapZoom + modifier) <= 6) {
			return .4;
		} else if ((this.props.mapZoom + modifier) <= 7) {
			return .21;
		} else if ((this.props.mapZoom + modifier) <= 8) {
			return .1
		} else if ((this.props.mapZoom + modifier) <= 9) {
			return .05;
		} else if ((this.props.mapZoom + modifier) <= 10) {
			return .02;
		} else if ((this.props.mapZoom + modifier) <= 11) {
			return .01;
		} else if ((this.props.mapZoom + modifier) <= 12) {
			return .005;
		} else if ((this.props.mapZoom + modifier) <= 13) {
			return .001;
		} else if ((this.props.mapZoom + modifier) <= 14) {
			return .0005;
		}
		return .0002;
	}

	// how much to offset(lng) infoWindow (for clusters)
	getClusterOffset = (modifier = 0) => {
		if ((this.props.mapZoom + modifier) <= 6) {
			return .2;
		} else if ((this.props.mapZoom + modifier) <= 7) {
			return .1;
		} else if ((this.props.mapZoom + modifier) <= 8) {
			return .05;
		} else if ((this.props.mapZoom + modifier) <= 9) {
			return .025;
		} else if ((this.props.mapZoom + modifier) <= 10) {
			return .01;
		} else if ((this.props.mapZoom + modifier) <= 11) {
			return .005;
		} else if ((this.props.mapZoom + modifier) <= 12) {
			return .002;
		} else if ((this.props.mapZoom + modifier) <= 13) {
			return .0008;
		} else if ((this.props.mapZoom + modifier) <= 14) {
			return .0005;
		}
		return .0002;
	}

	// adjusts infoWindow offset on zoomchange
	adjustOffset = () => {
		let position = this.state.position;
		let zIndex = this.props.defaultZIndex;

		let lngOffset = (zIndex === 5 ? this.getClusterOffset() : 0);
		this.setState({ position: { lat: position.lat + this.getOffset(), lng: position.lng - lngOffset } });
	}


	render() {
		// const uniqueTrials = this.state.content.filter((trial, index) => {
        //     const trialJSON = JSON.stringify(trial);
        //     return index === this.state.content.findIndex(trialOBJ => {
        //       return JSON.stringify(trialOBJ) === trialJSON;
        //     });
        //});
		return (
			<React.Fragment>
				<InfoWindow position={this.state.position} onCloseClick={this.props.closeInfoWindow} defaultZIndex={this.props.defaultZIndex} >
					<React.Fragment>
						{this.state.content.length === 1 ? (
							<>
								{this.state.content[this.state.contentIndex]}
							</>
						): (
							<>
								<div>
									<p className='num-trials-text'>{`${this.state.content.length} Trials`}</p> 
								</div>

								<div>
									{this.state.content.map((c, i) => (
										<>
											{this.state.content[i]}
										</>
									))}
								</div>
							</>
						)}
					</React.Fragment>
				</InfoWindow>
			</React.Fragment>
		)
	}
}

export default MapInfoWindow;