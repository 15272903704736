import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Tab.scss'

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  }

  render() {
    const { onClick, props: { activeTab, label, number } } = this;

    let className = 'tab-list-item';

    if (activeTab === label) {
      className += ' tab-list-active';
    }

    return (
      <div className={className} onClick={onClick}>
        <div className="tabCircle">{number}</div>
        <div className="tabLabel">{label}</div>
      </div>
    );
  }
}


export default Tab;