import React, { Component } from 'react'
import { nodeserver_url_images } from '../../constants/api';
import '../AboutDST/AboutDST.scss'
import { getTrialsRegion } from '../../selectors/trialsSelector';
import NavBar from '../../components/NavBar/NavBar'
import Hero from '../../components/Hero/Hero'
import TalkToUs from '../../components/TalkToUs/TalkToUs'
import Footer from '../../components/Footer/Footer'

const TrialsRegion = getTrialsRegion();

let AboutHeroImg, contentImg1, contentImg2


if(TrialsRegion === 'east'){
    AboutHeroImg = `${nodeserver_url_images}/trial_results/images/aboutAPT/hero_apt_east.jpg`;
    contentImg1 =  `${nodeserver_url_images}/trial_results/images/aboutAPT/apt_content1_east.jpg`;
    contentImg2 = `${nodeserver_url_images}/trial_results/images/aboutAPT/apt_content2_east.jpg`;
} else {
    AboutHeroImg = `${nodeserver_url_images}/trial_results/images/aboutAPT/hero_apt.jpg`;
    contentImg1 = `${nodeserver_url_images}/trial_results/images/aboutAPT/apt_content1.jpg`;
    contentImg2 = `${nodeserver_url_images}/trial_results/images/aboutAPT/apt_content2.jpg`;
}


export class AboutAPT extends Component {

    pdfExportComponent;

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            text: [
                {
                    id: 1,
                    title: "About Ag Performance Trials",
                    para1: "BASF Ag Performance Trials provide an annual comparison of select BASF production systems, seed treatments, inoculants, herbicides and fungicides against competitive products. They consist of a series of field-scale trials (both side by side and replicated) in which participating growers are given BASF products to test and compare against competitive systems or treatments using their own commercial equipment on their own farms in collaboration with the BASF Technical Services staff. Results, comments and photos from the trials will help growers make more informed cropping decisions."
                },
                {
                    id: 2,
                    title: "What are Ag Performance Trials?",
                    para1: "BASF Ag Performance Trials deliver valuable information on how products perform year over year under a variety of conditions. They are executed in both managed and unmanaged regimes, so responses to crop protection products can also be observed. The trials highlight disease control strategies, weed management and other best practices that maximize yield in a diverse range of crops. Growers can take the knowledge gained from trial results and apply it to their own unique operations."
                },
                {
                    id: 3,
                    title: "Where can I find Ag Performance Trials?",
                    para1: 'Our crop protection trial sites are located across the entire Western Canadian growing region. This ensures that our crop protection products are exposed to the widest range of growing conditions, so growers can better understand how various crop protection products perform in their local area.',
                    para2: 'Our crop protection trial sites are located across the Eastern Canadian growing region. This ensures that our crop protection products are exposed to the widest range of growing conditions, so growers can better understand how various crop protection products perform in their local area.'
                }
            ]
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

 

    render() {
        const { width } = this.state;
        const isTablet = width <= 1024;
        if (isTablet) {
            return (
                <React.Fragment>
                    <NavBar from={"about"} page_router={this.props.page_router} />
                    <div className="aboutDSTContainer">
                        <Hero img={ AboutHeroImg } alt="Hero Image"/>
                        <div className="aboutAPTTopBar">
                            <h2>{this.state.text[0].title}</h2>
                            <p>{this.state.text[0].para1} </p>
                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg1} alt="" />
                            </div>
                            <div className="half">
                                <div className="content">
                                    <h2>{this.state.text[1].title}</h2>
                                    <p>{this.state.text[1].para1}</p>
                                </div>
                            </div>
                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg2} alt="" />
                            </div>
                            <div className="half1-apt">
                                <div className="content">
                                    <h2>{this.state.text[2].title}</h2>
                                    <p>{TrialsRegion === 'east' ? this.state.text[2].para2 : this.state.text[2].para1}</p>
                                </div>
                            </div>

                        </div>
                        <TalkToUs />
                        <Footer />
                    </div>
                </React.Fragment>
            );
        } else {
            return (

                <React.Fragment>
                    <NavBar from={"about"} page_router={this.props.page_router} />
                    <div className="aboutDSTContainer">
                        <Hero img={AboutHeroImg} />
                        <div className="aboutAPTTopBar">
                            <h2>{this.state.text[0].title}</h2>
                            <p>{this.state.text[0].para1}</p>
                        </div>
                        <div className="full">
                            <div className="half">
                                <div className="content">
                                    <h2>{this.state.text[1].title}</h2>
                                    <p>{this.state.text[1].para1}</p>
                                </div>
                            </div>
                            <div className="half-image">
                                <img src={contentImg1} alt="" />
                            </div>
                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg2} alt="" />
                            </div>
                            <div className="half1-apt">
                                <div className="content">
                                    <h2>{this.state.text[2].title}</h2>
                                    <p>{TrialsRegion === 'east' ? this.state.text[2].para2 : this.state.text[2].para1}</p>
                                </div>
                            </div>
                        </div>
                        <TalkToUs />
                        <Footer />
                    </div>
                </React.Fragment>
                
            );

        }
    }

}

export default AboutAPT
