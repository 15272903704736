import React, { Component } from 'react';
import Header from './components/Header/Header'
import AboutDST from './pages/AboutDST/AboutDST'
import IndividualTrialPage from './pages/IndividualTrialPage/IndividualTrialPage'
import TrialSummaryPage from './pages/TrialSummaryPage/TrialSummaryPage'
import ComparisonTrialsPage from './pages/ComparisonTrialsPage/ComparisonTrialsPage'
import Home from './pages/Home/Home'
import CanolaLeaderboard from './pages/CanolaLeaderboard/CanolaLeaderboard'
import AboutAPT from './pages/AboutAPT/AboutAPT'
import AboutAgronomicExcellence from './pages/AboutAgronomicExcellence/AboutAgronomicExcellence';
import PageRouter from './components/PageRouter/PageRouter.js';
import QueryString from 'qs';
import { trialsActionCreators } from './actions/trialsAction';
import { agronomistsActionCreators } from './actions/agronomistAction';
import { getVisibleTrials } from './selectors/trialsSelector'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BackToTop from './components/BackToTop/BackToTop'
import PopUp from './components/PopUp/PopUp';
import { getPURL } from './selectors/trialsSelector';
import TrialPopUp from './components/PopUp/TrialPopUp';
import TestPDF from './pages/PDFTest/TestPDF';
import AgroTrialPopUp from './components/PopUp/AgroTrialPopUp';
import { getTrialsCategory } from './selectors/trialsSelector';

const TrialsCategory = getTrialsCategory();


class App extends Component {
	constructor(props) {
		super(props);
		props.getTrials(this.state.t === 'APT' ? 'APT' : 'DST', TrialsCategory === 'DST' ? this.getDefaultFilters() : this.getDefaultFiltersAPT());
		//props.getAgronomists('DST');
	}

	state = {
		page: QueryString.parse(window.location.search).page,
		page_params: QueryString.parse(window.location.search),
		t: QueryString.parse(window.location.search).t,
		PURL: getPURL(),
		r: QueryString.parse(window.location.search).r,
		showpopup: true,
		clearPURL: false
	};

	loadPage = (page, page_params, clearPURL) => {

		let url = `${window.location.pathname}?`,
			params = QueryString.stringify(page_params || {}),
			t = this.state.t,
			PURL = clearPURL === true ? null : this.state.PURL,
			r = this.state.r;
		//default to invigor if not provided
		if (t === undefined) t = 'DST';
		//if (t === undefined) t = 'APT';
		if (r === undefined) r = 'west';

		url += `&t=${t}`
		url += (PURL && page !== 'trialsummary' ? `&PURL=${PURL}` : '')
		url += `&r=${r}`;
		if ((page || '') !== '')
			url += `&page=${page}`;
		if (params !== '')
			url += `&${params}`;

		window.history.pushState(undefined, undefined, url);
		this.setState({
			page: page,
			page_params: page_params,
			t: this.state.t,
			r: this.state.r,
			PURL: clearPURL === true ? null : this.state.PURL,
			clearPURL: true
		});
	}

	componentDidMount() {

		//console.log("PURL: " + this.state.PURL);

		//listens for browser back and forward buttons
		window.onpopstate = (event) => {
			this.setState({
				page: QueryString.parse(window.location.search).page,
				page_params: QueryString.parse(window.location.search),
				t: QueryString.parse(window.location.search).t,
				PURL: QueryString.parse(window.location.search).PURL
			});
		};

		//ReactGA.pageview(window.location.pathname + window.location.search);

		//this.showPopup();
	}

	//componentDidUpdate = () => ReactGA.pageview(window.location.pathname + window.location.search);

	getDefaultFilters = () => {
		//let fy = QueryString.parse(window.location.search).filt_yr || '2021';
		let fy = QueryString.parse(window.location.search).filt_yr || '2024';
		let ft = QueryString.parse(window.location.search).filt_tech || '';
		let fh = QueryString.parse(window.location.search).filt_ht || '';
		let tt = QueryString.parse(window.location.search).filt_tt || '';
		return { fy, ft, fh, tt }
	}

	getDefaultFiltersAPT = () => {
		//let fy = QueryString.parse(window.location.search).filt_yr || '2022';
		let fy = QueryString.parse(window.location.search).filt_yr || '2021';
		let ft = QueryString.parse(window.location.search).filt_tech || '';
		let fh = QueryString.parse(window.location.search).filt_ht || '';
		let tt = QueryString.parse(window.location.search).filt_tt || '';
		return { fy, ft, fh, tt }
	}
/*
	showPopup = () => {
		if (document.cookie.replace(/(?:(?:^|.*;\s*)doSomethingOnlyOnce\s*\=\s*([^;]*).*$)|^.*$/, "$1") !== "true") {
			//document.cookie = "doSomethingOnlyOnce=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
		} else {
			this.setState({
				showpopup: false
			});
		}
	}

	closePopUp = () => {
		document.getElementById("map_popup").style.display = "none"
	}
*/
	render() {
		return (
			<div className="App">
				{console.warn=()=>{}}
				<Header page_router={this} />
				{/*TrialsCategory === 'DST' ? null : <PopUp/> */}
				<TrialPopUp />
				<AgroTrialPopUp />
				{/*TrialsCategory === 'DST' ? null : !this.state.showpopup ? this.closePopUp() : ''*/}
				<PageRouter page={this.state.page}>
				<Home default={true} page="home" page_router={this} page_params={this.state.page_params} clearPURL={this.state.clearPURL}></Home>
				    <CanolaLeaderboard page="2024canolaleaderboard" page_router={this} page_params={this.state.page_params}></CanolaLeaderboard>
					<AboutDST page="about" page_router={this} page_params={this.state.page_params}></AboutDST>
					<AboutAPT page="aboutapt" page_router={this} page_params={this.state.page_params}></AboutAPT>
					<AboutAgronomicExcellence page="aboutagro" page_router={this} page_params={this.state.page_params}></AboutAgronomicExcellence>
					<IndividualTrialPage page="individualtrial" page_router={this} page_params={this.state.page_params} clearPURL={this.state.clearPURL}></IndividualTrialPage>
					<TrialSummaryPage page="trialsummary" page_router={this} page_params={this.state.page_params} clearPURL={this.state.clearPURL}></TrialSummaryPage>
					<ComparisonTrialsPage page="comparisontrials" page_router={this} page_params={this.state.page_params}></ComparisonTrialsPage>
					<TestPDF page="testpdf" page_router={this} page_params={this.state.page_params}></TestPDF>
				</PageRouter>
				<BackToTop />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		trials: getVisibleTrials(state.trials)
	}
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({ ...trialsActionCreators, ...agronomistsActionCreators }, dispatch)
)(App);