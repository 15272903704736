import React, { Component } from 'react';
import { nodeserver_url_images } from '../../constants/api';
import './NavBar.scss';
import FilterSection from '../FilterSection/FilterSection';
import { getTrialsCategory } from '../../selectors/trialsSelector';
import { trialsActionCreators } from '../../actions/trialsAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Help from '../Help/Help';

const TrialsCategory = getTrialsCategory();
let xbutton = `${nodeserver_url_images}/trial_results/images/common/close.png`;
let helpButton = `${nodeserver_url_images}/trial_results/images/trials/help.png`;

class NavBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			windowWidth: window.innerWidth,
			slideNav: false,
			slideFilter: false,
		};
	}

	componentDidMount = () => {
		window.onscroll = function () {
			stickyNav();
		};
		var navBar = document.getElementById('navBar');
		var sticky = navBar.offsetTop;

		function stickyNav() {
			if (window.pageYOffset >= sticky) {
				navBar.classList.add('sticky');
			} else {
				navBar.classList.remove('sticky');
			}
		}
	};

	updatedHerbicide = (isHerbicide) => {
		// console.log('adasasdafafaafaf', isHerbicide);
	};

	hanldeClickNav = () => {
		var x = document.getElementById('itemContainerBottom');
		x.classList.toggle('collapsed');
		this.setState({ slideNav: !this.state.slideNav });
	};

	handleClickFilter = () => {
		var x = document.getElementById('filterContainer');
		x.classList.toggle('collapsed');
		this.setState({ slideFilter: !this.state.slideFilter });
	};

	handlePopUp = () => {
		document.getElementById('map_popup').style.display = 'block';
	};

	navLinks = () => {
		return [
			<li
				key={'home-link'}
				className='noselect'
				 
				onClick={() => {
					this.props.page_router.loadPage('home');
				}}
			>
				<a
				data-tealium-category="link" 
				data-tealium-action="click nav-bar" 
				data-tealium-value="Home || Map" 
				data-tealium-type="navigation"
				>Trial Results</a>
			</li>,
			TrialsCategory === 'DST' ? (
				<React.Fragment>
					<li
						key={'about-link'}
						className='noselect'
						onClick={() => {
							this.props.page_router.loadPage('about');
						}}
					>
						<a
						data-tealium-category="link" 
                        data-tealium-action="click nav-bar" 
                        data-tealium-value="About Demonstration Strip Trials || AboutDST" 
                        data-tealium-type="navigation" 
						>About Demonstration Strip Trials</a>
					</li>
					<li
						key={'aboutagro-link'}
						className='noselect' 
						onClick={() => {
							this.props.page_router.loadPage('aboutagro');
						}}
					>
						<a
						data-tealium-category="link" 
                        data-tealium-action="click nav-bar" 
                        data-tealium-value="About Agronomic Excellence||AboutAgro" 
                        data-tealium-type="navigation"
						>About Agronomic Excellence</a>
					</li>
				</React.Fragment>
			) : (
				<li
					key={'aboutapt-link'}
					className='noselect'
					data-tealium-category="link" 
					data-tealium-action="click nav-bar" 
					data-tealium-value="About APT" 
					data-tealium-type="navigation" 
					onClick={() => {
						this.props.page_router.loadPage('aboutapt');
					}}
				>
					About APT
				</li>
			),
			// <li className="noselect" key={'help-link'} onClick={this.handlePopUp}>
			// TrialsCategory === 'DST' ? null :
			// <li className="noselect" key={'help-link'} onClick={this.handlePopUp}> <img className="help" src={helpButton} alt="Help" /></li>
		];
	};

	render() {
		const navBar = (
			<div id='navItemsContainer'>
				<div className='navBarlinks'>{this.navLinks()}</div>
				<div
					key={'2024canolaleaderboard-link'}
						
					className='canolaLink'
					onClick={() => {
						this.props.page_router.loadPage(
							'2024canolaleaderboard'
						);
					}}
				>
					<div className='canolaText'><a
					data-tealium-category="link" 
					data-tealium-action="click nav-bar" 
					data-tealium-value="2024 Canola Leaderboard || leaderboard" 
					data-tealium-type="navigation">
						<strong>2024 Canola Leaderboard</strong><br></br>
						See which hybrids are leading the field in 2024
					</a>
					</div>
					<div className='arrow-icon'></div>
				</div>
			</div>
		);

		const mobileNav = (
			<div className='mobileNavContainer'>
				<div className='itemContainerTop'>
					{this.state.slideNav ? (
						<div
							className='closeBtn noselect'
							onClick={this.hanldeClickNav}
						>
							<img src={xbutton} alt='' />
						</div>
					) : (
						<div className='bars'>
							<div
								className='navMenu'
								onClick={this.hanldeClickNav}
							></div>
							{/* <div className='buttonContainer'>
								<div className='helpBox'>
									<Help />
								</div>
								<div
									className='searchButton'
									onClick={() => {
										this.props.toggleMobileFilters();
									}}
								>
									SEARCH
								</div>
							</div> */}
						</div>
					)}
					{this.props.from === 'about' ? (
						''
					) : (
						<div className='filterBtnContainer'>
							<div
								className='filterBtn noselect'
								onClick={this.handleClickFilter}
							>
								Filters
							</div>
						</div>
					)}
				</div>

				<div id='itemContainerBottom' className='collapsed'>
					{this.navLinks()}
				</div>

				<div id='filterContainer' className='collapsed'>
					<FilterSection
						clearPURL={this.props.clearPURL}
						updatedHerbicide={this.props.updatedHerbicide}
						updatedFacet={this.props.updatedFacet}
						updatedZidua={this.props.updatedZidua}
						updatedHeatLQ={this.props.updatedHeatLQ}
					/>
				</div>
			</div>
		);
		const canolaMobile = (
			<div
						key={'2024canolaleaderboard-link'}
						 
						className='canolaLinkMobile'
						onClick={() => {
							this.props.page_router.loadPage(
								'2024canolaleaderboard'
							);
						}}
					>
						<div className='canolaText'><a
						data-tealium-category="link" 
						data-tealium-action="click nav-bar" 
						data-tealium-value="2024 Canola Leaderboard || leaderboard" 
						data-tealium-type="navigation"><strong>2024 Canola Leaderboard</strong><br></br>
						See how InVigor<sup>®</sup> canola hybrids are performing vs the competition.</a>
						</div>
						<div className='arrow-icon'></div>
					</div>
		);
		return (
			<ul id='navBar' className='navBar'>
				{mobileNav}
				{canolaMobile}
				{navBar}
			</ul>
		);
	}
}

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators({ ...trialsActionCreators }, dispatch)
)(NavBar);
