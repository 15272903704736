import React, { Component } from 'react';
import { nodeserver_url_images } from '../../constants/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';
//import { actionCreators } from '../../actions/trialTypesAction';
import { trialsActionCreators } from '../../actions/trialsAction';
import { localeActionCreators } from '../../actions/locationAction';
import './FilterSection.scss';
import FilterYear from './FilterYear';
import FilterTrialTypes from './FilterTrialTypes';
import CheckBox from '../CheckBox/CheckBox';
import {
	getVisibleTrials,
	getTrialsCategory,
} from '../../selectors/trialsSelector';
import { getPURL } from '../../selectors/trialsSelector';
import { nodeserver_url } from '../../constants/api';
import axios from 'axios';
import 'font-awesome/css/font-awesome.min.css';
import { Slide } from "@progress/kendo-react-animation";
import Help from '../Help/Help';


let mGlass = `${nodeserver_url_images}/trial_results/images/home/mglass.png`;

const searchOptions = {
	componentRestrictions: { country: 'ca' },
	types: ['(cities)'],
};

const TrialsCategory = getTrialsCategory();

class FilterSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			suggestions: [],
			text: '',
			open: true,
			collapseButton: 'DONE',
			showMore: {
				technology: false,
				hybrid: false,
				comparison: false,
				crop: false,
				indication: false,
				trial: false,
				trialname: false,
			},
			location: '',
			lat: null,
			lng: null,
			PURL: getPURL(),
			contentHybrid: false,
			contentTechnology: false,
			contentHybridCompare: false,
			contentTreatment: false,
			contentTrialName: false,
			contentCropType: false,
			contentIndication: false,
			contentTreatmentCompare: false,
			arrowed: false,
			showFilters: true,
			hybridSelected: 'All Hybrids Selected',
			showFilterMenu: false,
			showHelp: false,
		};
	}

	//For Marketing Automation
	generateTracker = (event, action, category) => {
		if (this.state.PURL) {
			let apiBaseUrl = `${nodeserver_url}`;

			/*Start of call */
			let data = {
				event,
				action,
				category,
			};

			axios.post(
				apiBaseUrl +
					`/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`,
				data
			);
		}
	};

	componentDidMount = () => {
		this.filterChangeWithUrl()
		if (this.props.clearPURL) {
			this.setState(
				{
					...this.state,
					PURL: null,
				},
				() => {}
			);
		}
	};

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.trials.trial_filters.reset_filters) {
			this.setState({ location: '' });
		}
	};

	getCurrentFilters = (type) => {
		let filters = [];	
		Object.entries(this.props.trials.trial_filters.data.filters).forEach(
			([k, v]) => {
				if (v.filt_type === type) {
					filters.push(v.filt_value);				
				}
			}
		);

		return filters;
	};

	/* HANDLE CHECK MARK CLASS STATE */
	handleChecked = (value, type, element) => {
		element.target.checked
			? this.applyFilter(value, type)
			: this.removeFilter(value, type);

		//remove all the comparison filters with this hybrid in them
		if (type === 'hybrid') {
			Object.entries(
				this.props.trials.trial_filters.data.filters
			).forEach(([k, v]) => {
				if (v.filt_value[0] === value) {
					this.removeFilter(v.filt_value, type);
				}
			});
		}

		if (element.target.checked) {
			const event = value;
			const action = 'filter';
			const category = type;
			this.generateTracker(event, action, category);
		}

		if (element.target.id === 'Herbicide') {
			this.props.updatedHerbicide(element.target.checked);
		}

		if (element.target.id === 'Facet L') {
			this.props.updatedFacet(element.target.checked);
		}

		if (element.target.id === 'Zidua SC') {
			this.props.updatedZidua(element.target.checked);
		}

		if (element.target.id === 'Heat LQ') {
			this.props.updatedHeatLQ(element.target.checked);
		}
	};

	handletrialTypes = (value, type) => {
		const event = value;
		const action = 'filter';
		const category = type;
		this.generateTracker(event, action, category);

		this.resetFilterType(type);

		if (value === 'All') return;
		this.applyFilter(value, type);
		if (value === 'All') {
			return this.resetFilterType('trialTypes');
		}
	};

	handleYear = (value, type) => {
		const event = value;
		const action = 'filter';
		const category = type;
		this.generateTracker(event, action, category);

		this.resetFilterType(type);

		if (value === 'All') return;
		const currentCompare = this.getCurrentFilters('comparison');
			//setTimeout(() => this.applyFilter(value, type), 100);
			if(currentCompare.length > 0)
			Promise.resolve().then(() => this.applyFilter(value, type));
			else{
			this.applyFilter(value, type);
		}
		if (value === 'All') {
			return this.resetFilterType('year');
		}
	};

	applyFilter = (value, type) => {
		let filter = { action: 'add', key: value, type: type, value: value };
		this.props.filterTrials(filter);
	};

	applySearchFilter = (props, e) => {
		let value = e.target.value;
		let suggestions = [];
		if (value.length > 0) {
			const regex = new RegExp({ value }, 'i');
			suggestions = this.props.trials.trials
				.sort()
				.filter((v) => regex.test(v));
		}
		this.setState(() => ({ suggestions, text: value }));

		let key = e.target.getAttribute('data-label');
		let type = e.target.getAttribute('data-label');
		let action = e.target.getAttribute('data-action');
		if (key === 'search') value = e.target.value;
		props.filterTrials({ key, type, value, action });
	};

	suggestionSelected(value) {
		this.setState({
			text: value,
			suggestions: [],
		});
	}

	renderSuggestions() {
		const { suggestions } = this.state;
		if (suggestions.length === 0) {
			return null;
		}
		return (
			<ul>
				{suggestions.map((a) => (
					<li onClick={() => this.suggestionSelected(a.data.city)}>
						{a.data.city}
					</li>
				))}
			</ul>
		);
	}

	removeFilter = (value, type) => {
		let filter = { action: 'remove', key: value, type: type, value: value };
		this.props.filterTrials(filter);
	};

	resetFilterType = (type) => {
		Object.entries(this.props.trials.trial_filters.data.filters).forEach(
			([k, v]) => {
				if (v.filt_type === type) {
					this.removeFilter(v.filt_value, type);
				}
			}
		);
	};

	showMoreOptions = (show, type) => {
		//get current states
		let show_tech = this.state.showMore.technology;
		let show_hybrid = this.state.showMore.hybrid;
		let show_comparison = this.state.showMore.comparison;
		let show_crop = this.state.showMore.crop;
		//let show_indication = this.state.showMore.indication;
		let show_trial_type = this.state.showMore.trial;
		let show_trialname = this.state.showMore.trialname;

		switch (type) {
			case 'technology':
				this.setState({
					showMore: {
						technology: show,
						hybrid: show_hybrid,
						comparison: show_comparison,
					},
				});
				break;
			case 'hybrid':
				this.setState({
					showMore: {
						technology: show_tech,
						hybrid: show,
						comparison: show_comparison,
					},
				});
				break;
			case 'comparison':
				this.setState({
					showMore: {
						technology: show_tech,
						hybrid: show_hybrid,
						comparison: show,
					},
				});
				break;
			//APT filters
			case 'crop':
				this.setState({
					showMore: {
						crop: show,
						trial: show_trial_type,
						trialname: show_trialname,
					},
				}); //indication: show_indication,
				break;
			// case 'indication':
			//     this.setState({ showMore: { crop: show_crop, indication: show, trial: show_trial_type, trialname: show_trialname } })
			//     break;
			case 'trialname':
				this.setState({
					showMore: {
						crop: show_crop,
						trial: show_trial_type,
						trialname: show,
					},
				}); //indication: show_indication,
				break;
			case 'trial':
				this.setState({
					showMore: {
						crop: show_crop,
						trial: show,
						trialname: show_trialname,
					},
				}); //indication: show_indication,
				break;
			default:
				this.setState({
					showMore: {
						technology: show_tech,
						hybrid: show_hybrid,
						comparison: show_comparison,
					},
				});
				break;
		}
	};

	togglePanel = (e) => {
		this.setState({
			open: !this.state.open,
		});
		if (!this.state.open) {
			this.setState({
				collapseButton: 'DONE',
			});
		} else {
			this.setState({
				collapseButton: 'SHOW FILTERS',
			});
		}
	};

	// applyFilter = (value, type, add) => {
	//     let filter = {"key": value, "filt_type": type, "filt_value": value};
	//     // this.props.trials.trial_filters.data.filters[value] = filter;
	//     // this.props.trials.trial_filters.data.count++;
	//    this.props.filterTrials({"action": add ? "add" : "remove", "value": value, "key": value, "type": type});
	// }

	filter_yearSelector_setClass(props, e) {
		/*  let filters = props.trialTypesView.trial_type_filters.data.filters;
          if (filters['year']){
              if (filters['year'].filt_value===e.target.getAttribute('data-value')){
                  return 'yearSelect active';
              }
          }*/
		return 'yearSelect';
	}

	removeAllFilters(type) {
		const isMobile = this.props.width < 1024 ? true : false;
		this.props.removeAllTrialFilters();
if(isMobile){
		if(document.getElementsByClassName('trialCBContainer')){
		document.getElementsByClassName('trialCBContainer')[0].style.display='none';
		document.getElementsByClassName('trialCBContainer')[1].style.display='none';
		}
		if(document.getElementsByClassName('trialList')){
			document.getElementsByClassName('trialList')[0].style.display='none';
			}	
		if(document.getElementsByClassName('locationDetailsContainer')){
			document.getElementsByClassName('locationDetailsContainer')[0].style.display='none';
				}
		}
		this.resetFilterType('trialTypes');
		// Object.entries(this.props.trials.trial_filters.data.filters).forEach(([k, v]) => {
		//     this.removeFilter(v.filt_value, type);
		// });
		// this.applyFilter(2018, "year");

		this.setState({
			text: '',
			showMore: {
				technology: false,
				hybrid: false,
				comparison: false,
				crop: false,
				indication: true,
				treatment: false,
				trialname: false,
			},
			
		},
		() =>
			this.props.doSetLocation({
				lat:  null,
				lng: null,
			})
		);
	}

	// removeAllFiltersAPT(type) {
	//     this.props.removeAllTrialFiltersAPT();
	// }

	haveTrialData = () => {
		return this.props.trials.trials.length > 0;
	};

	getFilterOptions_Year = () => {
		let options = ['All', 2024, 2023, 2022];
		//let options = ['All', 2019, 2018, 2017];
		//let options = ['All', 2021, 2020, 2019];
		//let options = [2021, 2020, 2019];
		// let get_years = this.props.trials.trials.map(a => a.data.year);
		// let unique_years = [...new Set(get_years)].sort().reverse();
		return options;
	};

	getFilterOptionsapt_Year = () => {
		let options = ['All', 2024, 2023, 2022];
		//let options = [2021, 2020, 2019];
		//let options = ['All', 2021, 2020, 2019];
		//let options = ['All', 2020, 2019, 2018];
		// let get_years = this.props.trials.trials.map(a => a.data.year);
		// let unique_years = [...new Set(get_years)].sort().reverse();
		return options;
	};

	getFilters_Year = () => {
		let filter_year = [];
		let current_filters = this.getCurrentFilters('year');
		
		if (current_filters.length == 0) {
			current_filters.push("All");
		}

		this.getFilterOptions_Year().forEach((ele) => {
			filter_year.push(
				<FilterYear
					key={ele}
					onclick={this.handleYear.bind(this, ele, 'year')}
					action='add'
					label='year'
					value={ele}
					selected={current_filters.includes(ele)}
				/>
			);
		});

		// this.getFilterOptions_Year().forEach((ele) => {
		// 	filter_year.push(
		// 		<option key={ele} value={ele}>
		// 			{ele}
		// 		</option>
		// 	);
		// });

		return filter_year;
	};

	getFiltersapt_Year = () => {
		let filter_year = [];
		let current_filters = this.getCurrentFilters('year');
		
		if (current_filters.length == 0) {
			current_filters.push("All");
		}

		this.getFilterOptionsapt_Year().forEach((ele) => {
			filter_year.push(
				<FilterYear
					key={ele}
					onclick={this.handleYear.bind(this, ele, 'year')}
					action='add'
					label='year'
					value={ele}
					data-tealium-category="filter-trial-types" 
					data-tealium-action="click filter-options" 
					data-tealium-value={`filter-trial-types-button-${ele}`}
					data-tealium-type="filter" 
					selected={current_filters.includes(ele)}
				/>
			);
		});

		/* 		this.getFilterOptionsapt_Year().forEach((ele) => {
			filter_year.push(
				<option key={ele} value={ele}>
					{ele}
				</option>
			);
		}); */

		return filter_year;
	};

	getFilterOptions_trialTypes = () => {
		let options = ['All', 'DST', 'AE'];
		return options;
	};

	getFilters_trialTypes = () => {
		let filter_trialTypes = [];
		let current_filters = this.getCurrentFilters('trialTypes');
		const isMobile = this.props.width < 1024 ? true : false;

		if (!current_filters.length) current_filters.push('All'); //if no filters, then all is selected

		if (isMobile) {
			this.getFilterOptions_trialTypes().forEach((ele) => {
				filter_trialTypes.push(
					<FilterTrialTypes
						key={ele}
						onclick={this.handletrialTypes.bind(
							this,
							ele,
							'trialTypes'
						)}
						action='add'
						label='trialTypes'
						value={ele}
						data-tealium-category="filter-trial-types" 
						data-tealium-action="click filter-options" 
						data-tealium-value={`filter-year-button-${ele}`}
						data-tealium-type="filter" 
						selected={current_filters.includes(ele)}
					/>
				);
			});
		}

		if (!isMobile) {
			this.getFilterOptions_trialTypes().forEach((ele) => {
				filter_trialTypes.push(
					<option 
						key={ele} 
						value={ele}
						data-tealium-category="filter-trial-types" 
						data-tealium-action="click filter-options" 
						data-tealium-value={`filter-year-button-${ele}`}
						data-tealium-type="filter" 
					>
						{ele === 'All'
							? 'All Trial Types'
							: ele === 'DST'
							? 'Demonstration Strip Trials'
							: 'Agronomic Excellence'}
					</option>
				);
			});
		}

		return filter_trialTypes;
	};

	convertSeedTreatment = (seed_treatment) => {
		let seed_treatments = [];
		if (seed_treatment) {
			if (seed_treatment.indexOf('+') !== -1) {
				seed_treatment.split(' + ').forEach((tech) => {
					seed_treatments.push(tech);
				});
			} else {
				seed_treatments.push(seed_treatment);
			}
		}
		return seed_treatments;
	};
	getFilterOptions_Technology = () => {
		let options = [];
		let counts = {};
		let get_tech = this.props.trials.trials.map((a) => a.hybrids || []);
		get_tech
			.map((a) => a.map((b) => b.seed_treatment || ''))
			.forEach((arr) => {
				let includedTech = [];
				arr.forEach((v) => {
					this.convertSeedTreatment(v).forEach((tech) => {
						options.push(tech);
						if (!includedTech.includes(tech)) {
							//if this tech hasn't already been counted in this trial
							includedTech.push(tech);
							counts[tech] = counts[tech] ? counts[tech] + 1 : 1;
						}
					});
				});
			});

		let unique_tech = [...new Set(options)].sort();
		return [unique_tech, counts];
	};

	getFilters_Technology = () => {
		const isMobile = this.props.width < 1024 ? true : false;
		if (this.haveTrialData()) {
			let filter_tech = [];
			let filterOptions = this.getFilterOptions_Technology();
			let current_filters = this.getCurrentFilters('technology');

			//filterOptions[0] is the unique_hybrids array, filterOptions[1] is the frequency array of those options
			filterOptions[0].forEach((ele) => {
				if (isMobile) {
					filter_tech.push(
						<CheckBox
							checked={current_filters.includes(ele)}
							key={ele}
							handleChecked={this.handleChecked.bind(
								this,
								ele,
								'technology'
							)}
							text={ele}
							count={(filterOptions[1][ele])}
						/>
					);
				}
				if (!isMobile) {
					filter_tech.push(
						<div className='dropdownItem' key={ele}>
							<CheckBox
								checked={current_filters.includes(ele)}
								key={ele}
								handleChecked={this.handleChecked.bind(
									this,
									ele,
									'technology'
								)}
								text={ele}
								count={(filterOptions[1][ele])}
							/>
						</div>
					);
				}
			});
			return filter_tech;
		} else {
			return null;
		}
	};

	getFilterOptions_Hybrid = () => {
		let options = [];
		let counts = {};
		let vsTrialHybrids = [];

		let get_hybrid = this.props.trials.trials.map((a) => {
			if (a.data.trial_type.indexOf('vs') > -1) {
				vsTrialHybrids[a.data.trial] = vsTrialHybrids[a.data.trial]
					? vsTrialHybrids[a.data.trial]
					: [];
				vsTrialHybrids[a.data.trial].push(a.hybrids || []);
				return [];
			} else {
				return a.hybrids || [];
			}
		});

		Object.entries(vsTrialHybrids)
			.map((h) => h[1])
			.forEach((j) => {
				let hybridsToAdd = { hybrids: [], data: [] };
				j[0].forEach((k) => {
					if (!hybridsToAdd.hybrids.includes(k.hybrid)) {
						hybridsToAdd.data.push(k);
						hybridsToAdd.hybrids.push(k.hybrid);
					}
				});
				get_hybrid.push(hybridsToAdd.data);
			});

		let tech_filters = [];
		Object.entries(this.props.trials.trial_filters.data.filters).forEach(
			([k, f]) => {
				if (f.filt_type === 'technology')
					tech_filters.push(f.filt_value);
			}
		);
		let hybrid_only_basf = get_hybrid.map((a) => {
			return a.filter((b) => {
				if (tech_filters.length) {
					let seed_treatment = b.seed_treatment
						? b.seed_treatment.split(' + ')
						: [];
					let hybrid_included = false;
					seed_treatment.forEach((s) => {
						hybrid_included = hybrid_included
							? true
							: tech_filters.includes(s);
					});
					return b.is_basf && hybrid_included;
				} else {
					return b.is_basf;
				}
			});
		});

		// hybrid_only_basf.map(a => a.map(b => b.hybrid || '')).forEach(arr => {
		//     arr.forEach(v => {
		//         if (v !== '') {
		//             options.push(v);
		//             counts[v] = counts[v] ? counts[v] + 1 : 1;
		//         }
		//     })
		// });

		hybrid_only_basf.forEach((h) =>
			h.forEach((i) => {
				if (i.hybrid !== '') {
					options.push([i.hybrid, i.hybrid_sequence]);
					counts[i.hybrid] = counts[i.hybrid]
						? counts[i.hybrid] + 1
						: 1;
				}
			})
		);

		let unique_hybrids = [
			...new Set(
				options
					.sort((a, b) => {
						return a[1] - b[1];
					})
					.map((h) => {
						return h[0];
					})
			),
		];

		return [unique_hybrids, counts];
	};

	getFilters_Hybrid = () => {
		const isMobile = this.props.width < 1024 ? true : false;
		if (this.haveTrialData()) {
			let filter_hybrid = [];
			let filterOptions = this.getFilterOptions_Hybrid();
			let current_filters = this.getCurrentFilters('hybrid');

			//filterOptions[0] is the unique_hybrids array, filterOptions[1] is the frequency array of those options
			filterOptions[0].forEach((ele) => {
				if (isMobile) {
					filter_hybrid.push(
						<CheckBox
							checked={current_filters.includes(ele)}
							key={ele}
							handleChecked={this.handleChecked.bind(
								this,
								ele,
								'hybrid'
							)}
							text={ele}
							count={(filterOptions[1][ele])}
						/>
					);
				}
				if (!isMobile) {
					filter_hybrid.push(
						<div className='dropdownItem' key={ele}>
							<CheckBox
								checked={current_filters.includes(ele)}
								key={ele}
								handleChecked={this.handleChecked.bind(
									this,
									ele,
									'hybrid'
								)}
								text={ele}
								count={(filterOptions[1][ele])}
							/>
						</div>
					);
				}
			});

			return filter_hybrid;
		} else {
			return null;
		}
	};

	getFilterOptions_HybridCompare = () => {
		let options = [];
		let options2 = []; //DPS-674
		let counts = [];
		let counts2 = []; //DPS-674
		let vsTrialHybrids = [];

		// let get_hybrid = this.props.trials.trials.map(a => a.hybrids || []);
		let get_hybrid = this.props.trials.trials.map((a) => {
			if (a.data.trial_type.indexOf('vs') > -1) {
				vsTrialHybrids[a.data.trial] = vsTrialHybrids[a.data.trial]
					? vsTrialHybrids[a.data.trial]
					: [];
				vsTrialHybrids[a.data.trial].push(a.hybrids || []);
				return [];
			} else {
				return a.hybrids || [];
			}
		});

		Object.entries(vsTrialHybrids)
			.map((h) => h[1])
			.forEach((j) => {
				let hybridsToAdd = { hybrids: [], data: [] };
				j[0].forEach((k) => {
					if (!hybridsToAdd.hybrids.includes(k.hybrid)) {
						hybridsToAdd.data.push(k);
						hybridsToAdd.hybrids.push(k.hybrid);
					}
				});
				get_hybrid.push(hybridsToAdd.data);
			});

		Object.entries(this.props.trials.trial_filters.data.filters).forEach(
			([k, v]) => {
				//for each hybrid filter
				if (v.filt_type === 'hybrid') {
					get_hybrid
						.filter((t) =>
							t.find((h) => h.hybrid && h.hybrid === v.filt_value)
						)
						.map((a) =>
							a.forEach((b) => {
								if (!b.is_basf) {
									let included = false;
									options.forEach((o) => {
										if (
											o[0] === v.filt_value &&
											o[1] === b.hybrid
										) {
											included = true;
											return;
										}
									});
									if (!included)
										options.push([
											v.filt_value,
											b.hybrid,
											b.seed_treatment,
										]);
									counts[
										[
											v.filt_value,
											b.hybrid,
											b.seed_treatment,
										]
									] = counts[
										[
											v.filt_value,
											b.hybrid,
											b.seed_treatment,
										]
									]
										? counts[
												[
													v.filt_value,
													b.hybrid,
													b.seed_treatment,
												]
										  ] + 1
										: 1;
								}
							})
						);
				}
			}
		);


		// SEED TREATMENT BREAKING COUNTS, CREATE NEW COUNTS ARRAY
		Object.entries(this.props.trials.trial_filters.data.filters).forEach(
			([k, v]) => {
				//for each hybrid filter
				if (v.filt_type === 'hybrid') {
					get_hybrid
						.filter((t) =>
							t.find((h) => h.hybrid && h.hybrid === v.filt_value)
						)
						.map((a) =>
							a.forEach((b) => {
								if (!b.is_basf) {
									let included = false;
									options2.forEach((o) => {
										if (
											o[0] === v.filt_value &&
											o[1] === b.hybrid
										) {
											included = true;
											return;
										}
									});
									if (!included)
										options2.push([
											v.filt_value,
											b.hybrid
										]);
									counts2[
										[
											v.filt_value,
											b.hybrid
										]
									] = counts2[
										[
											v.filt_value,
											b.hybrid
										]
									]
										? counts2[
												[
													v.filt_value,
													b.hybrid
												]
										  ] + 1
										: 1;
								}
							})
						);
				}
			}
		);

		return [options.sort(), counts2];
	};

	getFilters_HybridCompare = () => {
		const isMobile = this.props.width < 1024 ? true : false;
		let totalOptions = 0;
		if (this.haveTrialData()) {
			let filter_hybrid = [];
			let filterOptions = this.getFilterOptions_HybridCompare();
			let current_filters = this.getCurrentFilters('comparison');
			let tech_filters = this.getCurrentFilters('technology');


			//filterOptions[0] is the unique_hybrids array, filterOptions[1] is the frequency array of those options
			filterOptions[0].forEach((ele) => {
				if (
					tech_filters.length
						? this.arrayIntersection(
								tech_filters,
								this.convertSeedTreatment(ele[2])
						  )
						: true
				) {
					//if this comparison hybrid shares a seed_treatment with the current filter
					totalOptions++;
					//if (displayedOptions < defaultDisplayedOptions || this.state.showMore.comparison) {
					//displayedOptions++;
					if (isMobile) {
						filter_hybrid.push(
							<CheckBox
								checked={JSON.stringify(current_filters).includes(JSON.stringify(ele))}
								key={ele}
								handleChecked={this.handleChecked.bind(this,ele,'comparison')}
								text={ele[0] + ' vs ' + ele[1]}
								count={filterOptions[1][[ele[0],ele[1]]]}
							/>
						);
					}
					if (!isMobile) {
						filter_hybrid.push(
							<div className='dropdownItem'>
								<CheckBox
									checked={JSON.stringify(current_filters).includes(JSON.stringify(ele))}
									key={ele}
									handleChecked={this.handleChecked.bind(this,ele,'comparison')}
									text={ele[0] + ' vs ' + ele[1]}
									count={filterOptions[1][[ele[0],ele[1]]]}
								/>
							</div>
						);
					}

					// }
				}
			});
			// if (totalOptions > defaultDisplayedOptions) {
			//     if (!this.state.showMore.comparison) {
			//         filter_hybrid.push(<div key={"c" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(true, "comparison")}>+ ({totalOptions - 3}) More Comparisons</div>)
			//     }
			//     else {
			//         filter_hybrid.push(<div key={"c" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(false, "comparison")}>Collapse ({displayedOptions - 3}) Comparison{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			// }
			return filter_hybrid;
		} else {
			return null;
		}
	};

	arrayIntersection = (left, right) => {
		return left.filter((value) => {
			return right.includes(value);
		}).length
			? true
			: false;
	};

	handleChange = (location) => {
		this.setState({ location: location });
	};

	handleSelect = (location) => {
		geocodeByAddress(location)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				let offset =
					this.state.lat === latLng.lat &&
					this.state.lng === latLng.lng
						? 0.00001
						: 0;
				this.setState(
					{
						location: location,
						lat: latLng.lat + offset,
						lng: latLng.lng,
					},
					() =>
						this.props.doSetLocation({
							lat: latLng.lat + offset,
							lng: latLng.lng,
						})
				);
			})
			.catch((error) => console.error('Error', error));
	};

	handleClickFilter = () => {
		var x = document.getElementById('filterContainer');
		x.classList.toggle('collapsed');
		this.setState({ slideFilter: !this.state.slideFilter });
	};

	/* APT Filter Functions */

	getFilterOptions_CropType = () => {
		let counts = {};

		let crop_types = [];
		this.props.trials.trials.forEach((a) => {
			let crop_type = a.data.crop_type;
			let crop = a.data.crop;

			counts[crop_type] = counts[crop_type] ? counts[crop_type] + 1 : 1;

			if (crop_type !== crop) {
				//crops without higher crop types will have the crop type field the same as the crop
				counts[crop] = counts[crop] ? counts[crop] + 1 : 1;
				if (crop_types[crop_type]) {
					if (!crop_types[crop_type].includes(crop))
						crop_types[crop_type].push(crop);
				} else {
					crop_types[crop_type] = [crop];
				}
			} else {
				crop_types[crop_type] = [];
			}
		});

		// let get_crops = this.props.trials.trials.map(a => a.data.crop_type || "");

		// get_crops.forEach(c => {
		//     if (c !== "") {
		//         options.push(c)
		//         counts[c] = counts[c] ? counts[c] + 1 : 1;
		//     }
		// });

		// let unique_crops = [...new Set(options)].sort();
		return [crop_types, counts];
	};

	getFilters_CropType = () => {
		const isMobile = this.props.width < 1024 ? true : false;

		if (this.haveTrialData()) {
			let filter_crops = [];
			let filterOptions = this.getFilterOptions_CropType();
			let current_filters = this.getCurrentFilters('crop_type');
			let current_filters_crop = this.getCurrentFilters('crop');
			let crop_types = filterOptions[0];
			let counts = filterOptions[1];


			Object.entries(crop_types).forEach((crop_type) => {
				let crops = crop_type[1];

				if (isMobile) {
					filter_crops.push(
						<CheckBox
							checked={current_filters.includes(crop_type[0])}
							key={crop_type[0]}
							handleChecked={this.handleChecked.bind(this,crop_type[0],'crop_type')}
							text={crop_type[0]}
							count={counts[crop_type[0]]}
						/>
					);
				}
				if (!isMobile) {
					filter_crops.push(
						<div className='dropdownItem' key={crop_type}>
							<CheckBox
								checked={current_filters.includes(crop_type[0])}
								key={crop_type[0]}
								handleChecked={this.handleChecked.bind(this,crop_type[0],'crop_type')}
								text={crop_type[0]}
								count={counts[crop_type[0]]}
							/>
						</div>
					);
				}

				if (current_filters.includes(crop_type[0])) {
					if (crops.length) {
						crops.forEach((crop) => {
							if (isMobile) {
								filter_crops.push(
									<li>
										<CheckBox
											checked={JSON.stringify(current_filters_crop).includes(JSON.stringify([crop_type[0], crop]))}
											key={crop}
											handleChecked={this.handleChecked.bind(this,[crop_type[0], crop],'crop')}
											text={crop}
											count={counts[crop]}
										/>
									</li>
								);
							}
							if (!isMobile) {
								filter_crops.push(
									<li style={{margin:"0.10em 0em 0.10em 1.7em"}}>
										<div className='dropdownItem' key={crop}>
										<CheckBox
											checked={JSON.stringify(current_filters_crop).includes(JSON.stringify([crop_type[0], crop]))}
											key={crop}
											handleChecked={this.handleChecked.bind(this,[crop_type[0], crop],'crop')}
											text={crop}
											count={counts[crop]}
										/>
										</div>
									</li>
								);
							}
						});
					}
				}
			});



			// if (displayedOptions > defaultDisplayedOptions) {
			//     if (!this.state.showMore.crop) {
			//         filter_crops.push(<div key={"c" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(true, "crop")}>+ ({displayedOptions - 3}) More Crop Type{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			//     else {
			//         filter_crops.push(<div key={"c" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(false, "crop")}>Collapse ({displayedOptions - 3}) Crop Type{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			// }
			return filter_crops;
		} else {
			return null;
		}
	};

	getFilterOptions_Indication = () => {
		let options = [];
		let counts = {};
		let get_indications = this.props.trials.trials.map(
			(a) => a.data.indication || ''
		);

		get_indications.forEach((c) => {
			if (c !== '') {
				options.push(c);
				counts[c] = counts[c] ? counts[c] + 1 : 1;
			}
		});

		let unique_indications = [...new Set(options)].sort();
		return [unique_indications, counts];
	};

	getFilters_Indication = () => {
		const isMobile = this.props.width < 1024 ? true : false;

		if (this.haveTrialData()) {
			let filter_indications = [];
			let filterOptions = this.getFilterOptions_Indication();
			let current_filters = this.getCurrentFilters('indication');

			//filterOptions[0] is the unique_hybrids array, filterOptions[1] is the frequency array of those options
			filterOptions[0].forEach((ele) => {
				if (isMobile) {
					filter_indications.push(
						<CheckBox
							checked={current_filters.includes(ele)}
							key={ele}
							id={ele}
							handleChecked={this.handleChecked.bind(this,ele,'indication')}
							text={ele}
							count={filterOptions[1][ele]}
							isHerbicide={this.props.isHerbicide}
							updatedHerbicide={this.props.updatedHerbicide}
						/>
					);
				}
				if (!isMobile) {
					filter_indications.push(
						<div className='dropdownItem' key={ele}>
							<CheckBox
								checked={current_filters.includes(ele)}
								key={ele}
								id={ele}
								handleChecked={this.handleChecked.bind(this,ele,'indication')}
								text={ele}
								count={filterOptions[1][ele]}
								isHerbicide={this.props.isHerbicide}
								updatedHerbicide={this.props.updatedHerbicide}
							/>
						</div>
					);
				}
				// displayedOptions++;
			});

			// if (displayedOptions > defaultDisplayedOptions) {
			//     if (!this.state.showMore.indication) {
			//         filter_indications.push(<div key={"i" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(true, "indication")}>+ ({displayedOptions - 3}) More Indication Type{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			//     else {
			//         filter_indications.push(<div key={"i" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(false, "indication")}>Collapse ({displayedOptions - 3}) Indication Type{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			// }
			return filter_indications;
		} else {
			return null;
		}
	};

	getFilterOptions_TrialName = () => {
		let options = [];
		let counts = {};
		let get_trialnames = this.props.trials.trials.map(
			(a) => a.data.trial_name || ''
		);

		get_trialnames.forEach((c) => {
			if (c !== '') {
				options.push(c);
				counts[c] = counts[c] ? counts[c] + 1 : 1;
			}
		});

		let unique_trialnames = [...new Set(options)].sort();
		return [unique_trialnames, counts];
	};

	getFilters_TrialName = () => {
		const isMobile = this.props.width < 1024 ? true : false;

		if (this.haveTrialData()) {
			let filter_trialnames = [];
			let filterOptions = this.getFilterOptions_TrialName();
			let current_filters = this.getCurrentFilters('trial_name');

			filterOptions[0].forEach((ele) => {
				if (isMobile) {
					filter_trialnames.push(
						<CheckBox
							checked={current_filters.includes(ele)}
							key={ele}
							id={ele}
							handleChecked={this.handleChecked.bind(this,ele,'trial_name')}
							text={ele}
							count={filterOptions[1][ele]}
							isHerbicide={this.props.isHerbicide}
							updatedHerbicide={this.props.updatedHerbicide}
						/>
					);
				}
				if (!isMobile) {
					filter_trialnames.push(
						<div className='dropdownItem' key={ele}>
							<CheckBox
								checked={current_filters.includes(ele)}
								key={ele}
								id={ele}
								handleChecked={this.handleChecked.bind(this,ele,'trial_name')}
								text={ele}
								count={filterOptions[1][ele]}
								isHerbicide={this.props.isHerbicide}
								updatedHerbicide={this.props.updatedHerbicide}
							/>
						</div>
					);
				}

				//displayedOptions++;
			});

			// if (displayedOptions > defaultDisplayedOptions) {
			//     if (!this.state.showMore.trialname) {
			//         filter_trialnames.push(<div key={"i" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(true, "trialname")}>+ ({displayedOptions - 3}) More Trial Name{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			//     else {
			//         filter_trialnames.push(<div key={"i" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(false, "trialname")}>Collapse ({displayedOptions - 3}) Trial Name{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			// }
			return filter_trialnames;
		} else {
			return null;
		}
	};

	getFilterOptions_ProductType = () => {
		let options = [];
		let counts = {};
		let get_product = this.props.trials.trials.map(
			(a) => a.treatments || []
		);

		let product_only_basf = get_product.map((a) =>
			a.filter((b) => {
				return b.is_basf;
			})
		);

		product_only_basf.forEach((t) =>
			t.forEach((x) => {
				options.push(x.treatment);
				counts[x.treatment] = counts[x.treatment]
					? counts[x.treatment] + 1
					: 1;
			})
		);
		// let unique_products = [...new Set(options.sort((a, b) => { return a[1] - b[1] }).map(h => { return h[0] }))];
		let unique_products = [...new Set(options)];

		return [unique_products, counts];
	};

	getFilters_ProductType = () => {
		const isMobile = this.props.width < 1024 ? true : false;

		if (this.haveTrialData()) {
			let filter_trial_types = [];
			let filterOptions = this.getFilterOptions_ProductType();
			let current_filters = this.getCurrentFilters('treatment');

			//filterOptions[0] is the unique_hybrids array, filterOptions[1] is the frequency array of those options
			filterOptions[0].forEach((ele) => {
				if (isMobile) {
					filter_trial_types.push(
						<CheckBox
							checked={current_filters.includes(ele)}
							key={ele}
							id={ele}
							handleChecked={this.handleChecked.bind(this,ele,'treatment')}
							text={ele}
							count={filterOptions[1][ele]}
							isFacetL={this.props.isFacetL}
							isZidua={this.props.isZidua}
							isHeatLQ={this.props.isHeatLQ}
							updatedFacet={this.props.updatedFacet}
							updatedZidua={this.props.updatedZidua}
							updatedHeatLQ={this.props.updatedHeatLQ}
						/>
					);
				}
				if (!isMobile) {
					filter_trial_types.push(
						<div className='dropdownItem' key={ele}>
							<CheckBox
								checked={current_filters.includes(ele)}
								key={ele}
								id={ele}
								handleChecked={this.handleChecked.bind(this,ele,'treatment')}
								text={ele}
								count={filterOptions[1][ele]}
								isFacetL={this.props.isFacetL}
								isZidua={this.props.isZidua}
								isHeatLQ={this.props.isHeatLQ}
								updatedFacet={this.props.updatedFacet}
								updatedZidua={this.props.updatedZidua}
								updatedHeatLQ={this.props.updatedHeatLQ}
							/>
						</div>
					);
				}


				// displayedOptions++;
			});

			// if (displayedOptions > defaultDisplayedOptions) {
			//     if (!this.state.showMore.trial) {
			//         filter_trial_types.push(<div key={"tr" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(true, "trial")}>+ ({displayedOptions - 3}) More Treatment{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			//     else {
			//         filter_trial_types.push(<div key={"tr" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(false, "trial")}>Collapse ({displayedOptions - 3}) Treatment{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			// }
			return filter_trial_types;
		} else {
			return null;
		}
	};

	getFilterOptions_ProductCompare = () => {
		let options = [];
		let counts = [];
		let get_product = this.props.trials.trials.map(
			(a) => a.treatments || []
		);

		Object.entries(this.props.trials.trial_filters.data.filters).forEach(
			([k, v]) => {
				//for each treatment filter
				if (v.filt_type === 'treatment') {
					get_product
						.filter((t) =>
							t.find(
								(h) =>
									h.treatment && h.treatment === v.filt_value
							)
						)
						.map((a) =>
							a.forEach((b) => {
								if (b.treatment !== v.filt_value) {
									let included = false;
									options.forEach((o) => {
										if (
											o[0] === v.filt_value &&
											o[1] === b.treatment
										) {
											included = true;
											return;
										}
									});
									if (!included)
										options.push([
											v.filt_value,
											b.treatment,
										]);
									counts[[v.filt_value, b.treatment]] =
										counts[[v.filt_value, b.treatment]]
											? counts[
													[v.filt_value, b.treatment]
											  ] + 1
											: 1;
								}
							})
						);
				}
			}
		);

		return [options.sort(), counts];
	};

	getFilters_ProductCompare = () => {
		let totalOptions = 0;
		const isMobile = this.props.width < 1024 ? true : false;

		if (this.haveTrialData()) {
			let filter_product = [];
			let filterOptions = this.getFilterOptions_ProductCompare();
			let current_filters = this.getCurrentFilters('comparison_APT');

			//filterOptions[0] is the unique_hybrids array, filterOptions[1] is the frequency array of those options
			filterOptions[0].forEach((ele) => {
				totalOptions++;

				if (isMobile) {
					filter_product.push(
						<CheckBox
							checked={JSON.stringify(current_filters).includes(JSON.stringify(ele))}
							key={ele}
							handleChecked={this.handleChecked.bind(this,ele,'comparison_APT')}
							text={ele[0] + ' vs ' + ele[1]}
							count={filterOptions[1][ele]}
						/>
					);
				}
				if (!isMobile) {
					filter_product.push(
						<div className='dropdownItem'>
							<CheckBox
								checked={JSON.stringify(current_filters).includes(JSON.stringify(ele))}
								key={ele}
								handleChecked={this.handleChecked.bind(this,ele,'comparison_APT')}
								text={ele[0] + ' vs ' + ele[1]}
								count={filterOptions[1][ele]}
							/>								
						</div>
					);
				}
				//    }
			});
			// if (totalOptions > defaultDisplayedOptions) {
			//     if (!this.state.showMore.comparison) {
			//         filter_product.push(<div key={"c" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(true, "comparison")}>+ ({totalOptions - 3}) More Comparisons</div>)
			//     }
			//     else {
			//         filter_product.push(<div key={"c" + displayedOptions} className="showMoreOptions" onClick={() => this.showMoreOptions(false, "comparison")}>Collapse ({displayedOptions - 3}) Comparison{displayedOptions === 4 ? "" : "s"}</div>)
			//     }
			// }
			return filter_product;
		} else {
			return null;
		}
	};

	toggleTechDropdown = () => {
		const contentTechnology = !this.state.contentTechnology;
		this.setState({
			contentTechnology,
			contentHybrid: false,
			contentHybridCompare: false,
		});
	};

	toggleHybridDropdown = () => {
		const contentHybrid = !this.state.contentHybrid;
		this.setState({
			contentHybrid,
			contentTechnology: false,
			contentHybridCompare: false,
		});
	};

	toggleCompareDropdown = () => {
		const contentHybridCompare = !this.state.contentHybridCompare;
		this.setState({
			contentHybridCompare,
			contentTechnology: false,
			contentHybrid: false,
		});
	};

	toggleIndication = () => {
		const contentIndication = !this.state.contentIndication;
		this.setState({
			contentIndication,
			contentTreatment: false,
			contentTrialName: false,
			contentCropType: false,
			contentTreatmentCompare: false
		});
	};

	toggleTrialName = () => {
		const contentTrialName = !this.state.contentTrialName;
		this.setState({
			contentTrialName,
			contentTreatment: false,
			contentIndication: false,
			contentCropType: false,
			contentTreatmentCompare: false
		});		
	}

	toggleTreatment = () => {
		const contentTreatment = !this.state.contentTreatment;
		this.setState({
			contentTreatment,
			contentTrialName: false,
			contentIndication: false,
			contentCropType: false,
			contentTreatmentCompare: false
		});	
	}

	toggleTreatmentCompare = () => {
		const contentTreatmentCompare = !this.state.contentTreatmentCompare;
		this.setState({
			contentTreatmentCompare,
			contentTrialName: false,
			contentIndication: false,
			contentCropType: false,
			contentTreatment: false
		});			
	}

	toggleCropType = () => {
		const contentCropType = !this.state.contentCropType;
		this.setState({
			contentCropType,
			contentTrialName: false,
			contentIndication: false,
			contentTreatmentCompare: false,
			contentTreatment: false
		});			
	}

	closeDropdowns = () => {
		this.setState({
			contentHybrid: false,
			contentTechnology: false,
			contentHybridCompare: false,
		});
	};

	getFilters = (trialsCategory) => {
		const isMobile = this.props.width < 1024 ? true : false;
		if (trialsCategory === 'DST') {
			const currentHybrid = this.getCurrentFilters('hybrid');
			const currentTech = this.getCurrentFilters('technology');
			const currentCompare = this.getCurrentFilters('comparison');
			return (
				<React.Fragment>

					{/* TECHNOLOGY FILTER */}
					<div className='header'>
						<div className='title'>
							Technology
							<i class='fa fa-info-circle special-text' aria-hidden='true'>
								<span class='special-text__tooltip'>
									Refine your search by selecting results by canola hybrid traits.
								</span>
							</i>
						</div>
						{isMobile ? (
							<>
								<div onClick={() => this.resetFilterType('technology')} className='reset'>
									Clear
								</div>
								<div className='contentBlock' onClick={() => this.setState({contentTechnology:!this.state.contentTechnology,})}>
									{!this.state.contentTechnology ? (
										<i class='fa fa-chevron-down' aria-hidden='true'></i>
									) : (
										<i class='fa fa-chevron-up' aria-hidden='true'></i>
									)}
								</div>
							</>
						) : null}
					</div>
					{isMobile ? (
						<div className='trialCBContainer'>
							{!this.state.contentTechnology? null: this.getFilters_Technology()}
						</div>
					) : null}
					{!isMobile ? (
						<div className='dropdownContainer' onBlur={this.closeDropdowns}tabIndex={1}>
							<div className='dropdownPanel' onClick={this.toggleTechDropdown}>
								<div className='dropdownPanelText technology'>
									{!currentTech.length ? `All Technology Selected` : `${currentTech.length} Technology selected`}
								</div>
								<div className='dropdownPanelIcon'>
									{!this.state.contentTechnology ? (
										<i className='fa fa-chevron-up' aria-hidden='true'></i>
									) : (
										<i className='fa fa-times' aria-hidden='true'></i>
									)}
								</div>
							</div>
							<div className={`dropdownOptions technology ${!this.state.contentTechnology? 'hidden': ''}`}>
								{!this.state.contentTechnology ? null: this.getFilters_Technology()}
							</div>
						</div>
					) : null}


					{/* HYBRID FILTER*/ }					
					<div className='header'>
						<div className='title'>
							InVigor Hybrid
							<i class='fa fa-info-circle special-text' aria-hidden='true'>
								<span class='special-text__tooltip'>
									Refine results by selecting by InVigor hybrid (mandatory).
								</span>
							</i>
						</div>
						{isMobile ? (
							<>
								<div onClick={() => this.resetFilterType('hybrid')} className='reset'>
									Clear
								</div>
								<div className='contentBlock' onClick={() => this.setState({contentHybrid:!this.state.contentHybrid,})}>
									{!this.state.contentHybrid ? (
										<i class='fa fa-chevron-down' aria-hidden='true'></i>
									) : (
										<i class='fa fa-chevron-up' aria-hidden='true'></i>
									)}
								</div>
							</>
						) : null}
					</div>
					{isMobile ? (
						<div className='trialCBContainer'>
							{!this.state.contentHybrid ? null : this.getFilters_Hybrid()}
						</div>
					) : null}
					{!isMobile ? (
						<div className='dropdownContainer' onBlur={this.closeDropdowns} tabIndex={1}>
							<div className='dropdownPanel'onClick={this.toggleHybridDropdown}>
								<div className='dropdownPanelText hybrid'>
									{!currentHybrid.length ? `All Hybrids Selected`: `${currentHybrid.length} InVigor Hybrid selected`}
								</div>
								<div className='dropdownPanelIcon'>
									{!this.state.contentHybrid ? (
										<i className='fa fa-chevron-up' aria-hidden='true'></i>
									) : (
										<i className='fa fa-times' aria-hidden='true'></i>
									)}
								</div>
							</div>
							<div className={`dropdownOptions hybrid ${!this.state.contentHybrid ? 'hidden' : ''}`}>
								{!this.state.contentHybrid? null : this.getFilters_Hybrid()}
							</div>
						</div>
					) : null}



					{/* COMPETITOR HYBRID FILTER */ }
					{
						//filter the filters array to find all the ones with type hybrid. if any exist, enable the comparison section
						Object.entries(
							this.props.trials.trial_filters.data.filters
						).filter(([f, v]) => v.filt_type === 'hybrid')
							.length ? (
							<React.Fragment>
								<div className='header'>
									<div className='title'>
										Competitive Hybrid
										<i class='fa fa-info-circle special-text' aria-hidden='true'>
											<span class='special-text__tooltip'>
												Refine results to select only specific competitive comparisons.
											</span>
										</i>
									</div>
									{isMobile ? (
										<>
											<div onClick={() =>this.resetFilterType('comparison')} className='reset'>
												Clear
											</div>
											<div className='contentBlock' onClick={() => this.setState({contentHybridCompare:!this.state.contentHybridCompare,})}>
												{!this.state.contentHybridCompare ? (
													<i class='fa fa-chevron-down'aria-hidden='true'></i>
												) : (
													<i class='fa fa-chevron-up' aria-hidden='true'></i>
												)}
											</div>
										</>
									) : null}
								</div>
								{isMobile ? (
									<div className='trialCBContainer'>
										{!this.state.contentHybridCompare ? null : this.getFilters_HybridCompare()}
									</div>
								) : null}
								{!isMobile ? (
									<div className='dropdownContainer' onBlur={this.closeDropdowns} tabIndex={1}>
										<div className='dropdownPanel' onClick={this.toggleCompareDropdown}>
											<div className='dropdownPanelText hybrid'>
												{!currentCompare.length ? `All Comparison Hybrids Selected` : `${currentCompare.length} Competitive Hybrid selected`}
											</div>
											<div className='dropdownPanelIcon'>
												{!this.state.contentHybridCompare ? (
													<i className='fa fa-chevron-up' aria-hidden='true'></i>
												) : (
													<i className='fa fa-times' aria-hidden='true'></i>
												)}
											</div>
										</div>
										<div
											className={`dropdownOptions hybrid-compare ${!this.state.contentHybridCompare? 'hidden' : ''}`}
										>
											{!this.state.contentHybridCompare ? null: this.getFilters_HybridCompare()}
										</div>
									</div>
								) : null}
							</React.Fragment>
						) : null
					}
				</React.Fragment>
			);
		} else {
			const currentIndication = this.getCurrentFilters('indication');
			const currentCropType = this.getCurrentFilters('crop_type');
			const currentTrialName = this.getCurrentFilters('trial_name');
			const currentTreatment = this.getCurrentFilters('treatment');
			const currentTreatmentCompare = this.getCurrentFilters('comparison_APT');
			return (
				<React.Fragment>

					{/* PRODUCT TYPE FILTER */}
					<div className='header'>
						<div className='title'>
							Product Type
							<i class='fa fa-info-circle special-text' aria-hidden='true'>
								<span class='special-text__tooltip'>
									Refine your search by selecting by product type.
								</span>
							</i>
						</div>
						{isMobile ? 
							<>
								<div onClick={() => this.resetFilterType('indication')} className='reset'>
									Clear
								</div>	
								<div className='contentBlock' onClick={() => this.setState({ contentIndication: !this.state.contentIndication, })} >
									{!this.state.contentIndication ? (
										<i class='fa fa-chevron-down' aria-hidden='true'></i>
									) : (
										<i class='fa fa-chevron-up' aria-hidden='true'></i>
									)}
								</div>
							</>						
						: null}
					</div>
					{isMobile ? 
						<div className='trialCBContainer'>
							{!this.state.contentIndication ? null : this.getFilters_Indication()}
						</div>
					:
						<div className='dropdownContainer' onBlur={this.closeDropdowns} tabIndex={1}>
							<div className='dropdownPanel' onClick={this.toggleIndication}>
								<div className='dropdownPanelText technology'>
									{!currentIndication.length ? `All Product Type Selected` : `${currentIndication.length} Technology selected`}
								</div>
									<div className='dropdownPanelIcon'>
										{!this.state.contentIndication ? (
											<i className='fa fa-chevron-up' aria-hidden='true' ></i>
										) : (
											<i className='fa fa-times' aria-hidden='true' ></i>
										)}
									</div>
							</div>
							<div className={`dropdownOptions indication ${!this.state.contentIndication ? 'hidden' : ''}`}>
								{!this.state.contentIndication ? null : this.getFilters_Indication()}
							</div>
						</div>					
					}


					{/* CROP TYPE FILTER */}
					<div className='header'>
						<div className='title'>
							Crop Type
							<i class='fa fa-info-circle special-text' aria-hidden='true'>
								<span class='special-text__tooltip'>
									Refine your search by selecting by crop.
								</span>
							</i>
						</div>
						{isMobile ? 
							<>
								<div onClick={() => this.resetFilterType('crop_type')} className='reset'>
									Clear
								</div>
								<div className='contentBlock' onClick={() => this.setState({contentCropType: !this.state.contentCropType,})}>
									{!this.state.contentCropType ? (
										<i class='fa fa-chevron-down' aria-hidden='true'></i>
									) : (
										<i class='fa fa-chevron-up' aria-hidden='true'></i>
									)}
								</div>
							</>						
						: null}
					</div>
					{isMobile ? 
						<div className='trialCBContainer'>
							{!this.state.contentCropType ? null : this.getFilters_CropType()}
						</div>
					:
						<div className='dropdownContainer' onBlur={this.closeDropdowns} tabIndex={1}>
							<div className='dropdownPanel' onClick={this.toggleCropType}>
								<div className='dropdownPanelText technology'>
									{!currentCropType.length ? `All Crop Type Selected` : `${currentCropType.length} Technology selected`}
								</div>
									<div className='dropdownPanelIcon'>
										{!this.state.contentCropType ? (
											<i className='fa fa-chevron-up' aria-hidden='true' ></i>
										) : (
											<i className='fa fa-times' aria-hidden='true' ></i>
										)}
									</div>
							</div>
							<div className={`dropdownOptions croptype ${!this.state.contentCropType ? 'hidden' : ''}`}>
								{!this.state.contentCropType ? null : this.getFilters_CropType()}
							</div>
						</div>		
					}

					{/* TRIAL NAME FILTER */}
					<div className='header'>
						<div className='title'>
							Trial Name
							<i class='fa fa-info-circle special-text' aria-hidden='true'>
								<span class='special-text__tooltip'>
									Refine your search by selecting a trial name.
								</span>
							</i>
						</div>
						{isMobile ? 
							<>

							<div onClick={() => this.resetFilterType('trial_name')} className='reset'>
							Clear
						</div>
						<div className='contentBlock' onClick={() => this.setState({contentTrialName: !this.state.contentTrialName,})}>
							{!this.state.contentTrialName ? (
								<i class='fa fa-chevron-down' aria-hidden='true'></i>
							) : (
								<i class='fa fa-chevron-up' aria-hidden='true'></i>
							)}
						</div>
							</>						
						: null}
					</div>
					{isMobile ? 
					<div className='trialCBContainer'>
						{!this.state.contentTrialName ? null: this.getFilters_TrialName()}
					</div>
					:
						<div className='dropdownContainer' onBlur={this.closeDropdowns} tabIndex={1}>
							<div className='dropdownPanel' onClick={this.toggleTrialName}>
								<div className='dropdownPanelText technology'>
									{!currentTrialName.length ? `All Trial Name Selected` : `${currentTrialName.length} Technology selected`}
								</div>
									<div className='dropdownPanelIcon'>
										{!this.state.contentTrialName ? (
											<i className='fa fa-chevron-up' aria-hidden='true' ></i>
										) : (
											<i className='fa fa-times' aria-hidden='true' ></i>
										)}
									</div>
							</div>
							<div className={`dropdownOptions trialname ${!this.state.contentTrialName ? 'hidden' : ''}`}>
								{!this.state.contentTrialName ? null : this.getFilters_TrialName()}
							</div>
						</div>				
					}


					{/* TREATMENT FILTER */}
					<div className='header'>
						<div className='title'>
							Treatment
							<i class='fa fa-info-circle special-text' aria-hidden='true'>
								<span class='special-text__tooltip'>
									Refine your search by selecting a specific product.
								</span>
							</i>
						</div>
						{isMobile ? 
							<>
								<div onClick={() => this.resetFilterType('treatment')} className='reset'>
									Clear
								</div>
								<div className='contentBlock' onClick={() => this.setState({ contentTreatment: !this.state.contentTreatment,})}>
									{!this.state.contentTreatment ? (
										<i class='fa fa-chevron-down' aria-hidden='true'></i>
									) : (
										<i class='fa fa-chevron-up' aria-hidden='true'></i>
									)}
								</div>
							</>						
						: null}
					</div>
					{isMobile ? 
						<div className='trialCBContainer'>
							{!this.state.contentTreatment ? null: this.getFilters_ProductType()}
						</div>
					:
						<div className='dropdownContainer' onBlur={this.closeDropdowns} tabIndex={1}>
							<div className='dropdownPanel' onClick={this.toggleTreatment}>
								<div className='dropdownPanelText technology'>
									{!currentTreatment.length ? `All Treatments Selected` : `${currentTreatment.length} Technology selected`}
								</div>
									<div className='dropdownPanelIcon'>
										{!this.state.contentTreatment ? (
											<i className='fa fa-chevron-up' aria-hidden='true' ></i>
										) : (
											<i className='fa fa-times' aria-hidden='true' ></i>
										)}
									</div>
							</div>
							<div className={`dropdownOptions treatment ${!this.state.contentTreatment? 'hidden' : ''}`}>
								{!this.state.contentTreatment ? null : this.getFilters_ProductType()}
							</div>
						</div>				
					}


					{/*COMPARE WITH FILTER */}
					{
					//filter the filters array to find all the ones with type hybrid. if any exist, enable the comparison section
					Object.entries(this.props.trials.trial_filters.data.filters).filter(([f, v]) => v.filt_type === 'treatment').length ? (
							<React.Fragment>
								<div className='header'>
									<div className='title'>
										Compare with Treatment Type
										<i class='fa fa-info-circle special-text' aria-hidden='true'>
											<span class='special-text__tooltip'>
												Refine results to select only specific competitive comparisons.
											</span>
										</i>
									</div>
									{isMobile ? 
										<>
											<div onClick={() => this.resetFilterType('comparison_APT')} className='reset'>
												Clear
											</div>
											<div className='contentBlock' onClick={() => this.setState({contentTreatmentCompare:!this.state.contentTreatmentCompare,})}>
												{!this.state.contentTreatmentCompare ? (
													<i class='fa fa-chevron-down' aria-hidden='true'></i>
												) : (
													<i class='fa fa-chevron-up' aria-hidden='true'></i>
												)}
											</div>
										</>						
									: null}
								</div>
								{isMobile ? 
									<div className='trialCBContainer'>
										{!this.state.contentTreatmentCompare ? null: this.getFilters_ProductCompare()}
									</div>
								:
									<div className='dropdownContainer' onBlur={this.closeDropdowns} tabIndex={1}>
										<div className='dropdownPanel' onClick={this.toggleTreatmentCompare}>
											<div className='dropdownPanelText technology'>
												{!currentTreatmentCompare.length ? `All Product Type Selected` : `${currentTreatmentCompare.length} Technology selected`}
											</div>
												<div className='dropdownPanelIcon'>
													{!this.state.contentTreatmentCompare ? (
														<i className='fa fa-chevron-up' aria-hidden='true' ></i>
													) : (
														<i className='fa fa-times' aria-hidden='true' ></i>
													)}
												</div>
										</div>
										<div className={`dropdownOptions productcompare ${!this.state.contentTreatmentCompare? 'hidden' : ''}`}>
											{!this.state.contentTreatmentCompare ? null : this.getFilters_ProductCompare()}
										</div>
									</div>
								
								}
							</React.Fragment>
						) : null
					}
				</React.Fragment>
			);
		}
	};

	toggleFilters = (e) => {
		const showFilters = !this.state.showFilters;
		this.setState({ showFilters });
	};

	handleTypeChange = (e) => {
		this.handletrialTypes(e.target.value, 'trialTypes');
		this.resetFilterType('technology');
		this.resetFilterType('hybrid');
		this.resetFilterType('comparison');
	};

	handleYearChange = (e) => {
		this.handleYear(
			e.target.value === 'All'
				? e.target.value
				: parseInt(e.target.value),
			'year'
		);
	};

	filterChangeWithUrl = (e) => {
		let currentURL = window.location.href;
		var hash = currentURL.split('#')[1];

		const urlParams = new URLSearchParams(window.location.search);
   		const hybridParam = urlParams.get('hybrid');

		if(hybridParam != null){
			let hybrid = "InVigor "+hybridParam;
			this.applyFilter(hybrid, "hybrid")
		}
		
		if(hash == "AE"){
			this.applyFilter("AE", "trialTypes")
	  	}else if(hash == "DST"){
			this.applyFilter("DST", "trialTypes")
	  }
	}

	render () {
		const trialType = this.getCurrentFilters('trialTypes')[0];
		const filterYear = this.getCurrentFilters('year')[0];
		const isMobile = this.props.width < 1024 ? true : false;
		return (
			<div className={`filterContainer `}>
				{isMobile ?
								<div className='filtersButtonsContainer mobile'>
								<div
										className='searchButton'
										onClick={(e) => this.setState({ showFilterMenu: !this.state.showFilterMenu })}
										data-tealium-category="popup" 
										data-tealium-action="click button" 
										data-tealium-value="hide-filters-button" 
										data-tealium-type="popup" 
											>
											FILTERS
								</div>
								<div
										className='helpButton'
										onClick={(e) => this.setState({ showHelp: !this.state.showHelp })}
										data-tealium-category="popup" 
										data-tealium-action="click button" 
										data-tealium-value="hide-filters-button" 
										data-tealium-type="popup" 
											>
											<Help></Help>
								</div>
							</div>
			:
							<div className='filtersButtonsContainer desktop'>
							<div
									className='searchButton'
									onClick={(e) => this.setState({ showFilterMenu: !this.state.showFilterMenu })}
									data-tealium-category="popup" 
									data-tealium-action="click button" 
									data-tealium-value="hide-filters-button" 
									data-tealium-type="popup" 
										>
										FILTERS
							</div>
							<div
									className='helpButton'
									onClick={(e) => this.setState({ showHelp: !this.state.showHelp })}
									data-tealium-category="popup" 
									data-tealium-action="click button" 
									data-tealium-value="hide-filters-button" 
									data-tealium-type="popup" 
										>
										<Help></Help>
							</div>
						</div>
			
			}
				{/* <div className='filtersButtonsContainer desktop'>
					<div
							className='searchButton'
							onClick={(e) => this.setState({ showFilterMenu: !this.state.showFilterMenu })}
							data-tealium-category="popup" 
							data-tealium-action="click button" 
							data-tealium-value="hide-filters-button" 
							data-tealium-type="popup" 
								>
								FILTERS
					</div>
					<div
							className='helpButton'
							onClick={(e) => this.setState({ showHelp: !this.state.showHelp })}
							data-tealium-category="popup" 
							data-tealium-action="click button" 
							data-tealium-value="hide-filters-button" 
							data-tealium-type="popup" 
								>
								<Help></Help>
					</div>
				</div> */}
			{this.state.showFilterMenu ? (
			<>
				{this.state.open ? (
					<React.Fragment>
						<Slide direction='right'>
							<>
						<div
							className={`conditionalShow ${
								!this.state.showFilters ? 'hidden' : ''
							}`}
						>
							<div className='searchHeader'>
								{/* <div className='title'>
									{TrialsCategory === 'DST'
										? 'Search for Trials'
										: 'Search for APT Trials'}
								</div> */}
								<div
									onClick={() => this.removeAllFilters()}
									className='reset'
									data-tealium-category="filters" 
									data-tealium-action="click button" 
									data-tealium-value="remove-all-filters-bbutton" 
									data-tealium-type="filters" 
								>
									Clear All Filters
								</div>
							</div>

							{/* <div className='searchBarContainer'>
								<div 
									className='searchBar'
									search_term={`${this.state.location}`}
									search_results={`${this.state.location}`}
								>
									<PlacesAutocomplete
										value={this.state.location}
										searchOptions={searchOptions}
										onChange={this.handleChange}
										onSelect={this.handleSelect}

									>
										{({
											getInputProps,
											suggestions,
											getSuggestionItemProps,
											loading,
										}) => (
											<div>
												<input
													{...getInputProps({
														placeholder:
															'Search Towns ...',
														className:
															'location-search-input',
													})}
												/>
												<button type='submit'>
													<img
														src={mGlass}
														alt='Search'
													/>
												</button>
												<div className='autocomplete-dropdown-container'>
													{loading && (
														<div>Loading...</div>
													)}
													{suggestions.map(
														(suggestion) => {
															const className =
																suggestion.active
																	? 'suggestion-item--active'
																	: 'suggestion-item';
															// inline style for demonstration purpose
															const style =
																suggestion.active
																	? {
																			backgroundColor:
																				'#fafafa',
																			cursor: 'pointer',
																	  }
																	: {
																			backgroundColor:
																				'#ffffff',
																			cursor: 'pointer',
																	  };
															return (
																<div
																	key={
																		suggestion
																	}
																	{...getSuggestionItemProps(
																		suggestion,
																		{
																			className,
																			style,
																		}
																	)}
																>
																	<span>
																		{
																			suggestion.description
																		}
																	</span>
																</div>
															);
														}
													)}
												</div>
											</div>
										)}
									</PlacesAutocomplete>
								</div>
							</div> */}
						</div>

						{!this.state.showFilters && (
							<>
								<div className='desktopFilter'>
									<div className='searchButtonsContainer'>
										{/*<div className='searchButton reverse helpBox'>
											<Help />
										</div>*/}
										<div
											className='searchButton'
											onClick={(e) =>
												this.toggleFilters(e)
											}
										>
											MORE FILTERS
										</div>
										<div
											className='searchButton'
											onClick={this.handleSelect}
										>
											SEARCH
										</div>
									</div>
								</div>
								<div className='mobileFilter'>
									<div className='showFilterButtonsContainer'>
										<div
											className='searchButton'
											onClick={(e) =>
												this.toggleFilters(e)
											}
										>
											SHOW FILTERS
										</div>
									</div>
								</div>
							</>
						)}

						<div
							className={`filtersContainer ${
								!this.state.showFilters ? 'hidden' : ''
							}`}
						>
							{TrialsCategory === 'DST' ? (
								<div className='header'>
									<div className='title'>
										{' '}
										Trial Type
										<React.Fragment>
											<i
												class='fa fa-info-circle special-text'
												aria-hidden='true'
											>
												<span class='special-text__tooltip'>
													Refine your search by selecting Trial Type, see above for more details on each.
												</span>
											</i>
										</React.Fragment>
									</div>
									{/* <div
										onClick={() =>
											this.resetFilterType('trialTypes')
										}
										className='reset'
									>
										Clear
									</div> */}
								</div>
							) : null}
							{TrialsCategory === 'DST' && !isMobile ? (
								<div className='dropdownContainer'>
									<select
										value={
											trialType === undefined
												? 'All'
												: trialType
										}
										onChange={(e) => {
											this.handleTypeChange(e);
										}}
										className='filtersDropdown'
									>
										{this.getFilters_trialTypes()}
									</select>
								</div>
							) : null}

							{TrialsCategory === 'DST' && isMobile ? (
								<div className='yearSelectorContainer'>
									{this.getFilters_trialTypes()}
								</div>
							) : null}

							{isMobile ? (
								<>
									<div className='header'>
										<div className='title'>
											Year
											<React.Fragment>
												<i
													class='fa fa-info-circle special-text'
													aria-hidden='true'
												>
													<span class='special-text__tooltip'>
													Refine your search by selecting a year or all years.   If all years are selected, only the years that contain the InVigor and competitive hybrid will be included
													</span>
												</i>
											</React.Fragment>
										</div>
										<div
											onClick={() =>
												this.resetFilterType('year')
											}
											className='reset'
										>
											Clear
										</div>
									</div>
									<div className='yearSelectorContainer'>
										{TrialsCategory === 'DST'
											? this.getFilters_Year()
											: this.getFiltersapt_Year()}
									</div>
								</>
							) : null}

							{this.getFilters(TrialsCategory)}

							{!isMobile ? (
								<>
									<div className='header'>
										<div className='title'>
											Year
											<React.Fragment>
												<i
													class='fa fa-info-circle special-text'
													aria-hidden='true'
												>
													<span class='special-text__tooltip'>
													Refine your search by selecting a year or all years.   If all years are selected, only the years that contain the InVigor and competitive hybrid will be included
													</span>
												</i>
											</React.Fragment>
										</div>
									</div>
									<div className='yearSelectorContainer'>
										{TrialsCategory === 'DST'
											? this.getFilters_Year()
											: this.getFiltersapt_Year()}
									</div>
								</>
							) : null}

							{!isMobile ?<div className='filtersButtonsContainer desktop'>
								<div
									className='searchButton'
									// onClick={(e) => this.toggleFilters(e)}
									onClick={(e) =>  this.setState({ showFilterMenu: !this.state.showFilterMenu })}
									data-tealium-category="popup" 
									data-tealium-action="click button" 
									data-tealium-value="hide-filters-button" 
									data-tealium-type="popup" 
								>
									HIDE FILTERS
								</div>
								{/* <div
									className='searchButton'
									onClick={(e) => this.toggleFilters(e)}
								>
									APPLY FILTERS
								</div> */}
							</div> : 
							<div className='filtersButtonsContainer mobile'>
							<div
								className='searchButton'
								// onClick={() =>
								// 	this.props.toggleMobileFilters()
								// }
								onClick={(e) => this.toggleFilters(e)}
								data-tealium-category="popup" 
								data-tealium-action="click button" 
								data-tealium-value="hide-filters-button" 
								data-tealium-type="popup"
							>
								HIDE FILTERS
							</div>
						</div>}							
						</div>
						</>
						</Slide>
						
						{/* {TrialsCategory === 'DST' ? (
							<div className='header'>
								<div className='title'>
									{' '}
									Trial Types
									<React.Fragment>
										<i
											class='fa fa-info-circle special-text'
											aria-hidden='true'
										>
											<span class='special-text__tooltip'>
												Refine your search by filtering
												by Trial Types.
											</span>
										</i>
									</React.Fragment>
								</div>
								<div
									onClick={() =>
										this.resetFilterType('trialTypes')
									}
									className='reset'
								>
									Clear
								</div>
							</div>
						) : null}
						<div className='yearSelectorContainer'>
							{TrialsCategory === 'DST'
								? this.getFilters_trialTypes()
								: null}
						</div> */}
					</React.Fragment>
				) : null}
			</>
			) : null}

			{/* {this.state.showHelp ? (
				<>
				<Help></Help>
				</>

			):null} */}
			</div>
			
		)
	}
}

// 	render() {
// 		const trialType = this.getCurrentFilters('trialTypes')[0];
// 		const filterYear = this.getCurrentFilters('year')[0];
// 		const isMobile = this.props.width < 1024 ? true : false;
// 		return (
// 			<div className={`filterContainer `}>
// 				{this.state.open ? (
// 					<React.Fragment>
// 						<div
// 							className={`conditionalShow ${
// 								!this.state.showFilters ? 'hidden' : ''
// 							}`}
// 						>
// 							<div className='searchHeader'>
// 								<div className='title'>
// 									{TrialsCategory === 'DST'
// 										? 'Search for Trials'
// 										: 'Search for APT Trials'}
// 								</div>
// 								<div
// 									onClick={() => this.removeAllFilters()}
// 									className='reset'
// 									data-tealium-category="filters" 
// 									data-tealium-action="click button" 
// 									data-tealium-value="remove-all-filters-bbutton" 
// 									data-tealium-type="filters" 
// 								>
// 									Clear All Filters
// 								</div>
// 							</div>

// 							<div className='searchBarContainer'>
// 								<div 
// 									className='searchBar'
// 									search_term={`${this.state.location}`}
// 									search_results={`${this.state.location}`}
// 								>
// 									<PlacesAutocomplete
// 										value={this.state.location}
// 										searchOptions={searchOptions}
// 										onChange={this.handleChange}
// 										onSelect={this.handleSelect}

// 									>
// 										{({
// 											getInputProps,
// 											suggestions,
// 											getSuggestionItemProps,
// 											loading,
// 										}) => (
// 											<div>
// 												<input
// 													{...getInputProps({
// 														placeholder:
// 															'Search Towns ...',
// 														className:
// 															'location-search-input',
// 													})}
// 												/>
// 												<button type='submit'>
// 													<img
// 														src={mGlass}
// 														alt='Search'
// 													/>
// 												</button>
// 												<div className='autocomplete-dropdown-container'>
// 													{loading && (
// 														<div>Loading...</div>
// 													)}
// 													{suggestions.map(
// 														(suggestion) => {
// 															const className =
// 																suggestion.active
// 																	? 'suggestion-item--active'
// 																	: 'suggestion-item';
// 															// inline style for demonstration purpose
// 															const style =
// 																suggestion.active
// 																	? {
// 																			backgroundColor:
// 																				'#fafafa',
// 																			cursor: 'pointer',
// 																	  }
// 																	: {
// 																			backgroundColor:
// 																				'#ffffff',
// 																			cursor: 'pointer',
// 																	  };
// 															return (
// 																<div
// 																	key={
// 																		suggestion
// 																	}
// 																	{...getSuggestionItemProps(
// 																		suggestion,
// 																		{
// 																			className,
// 																			style,
// 																		}
// 																	)}
// 																>
// 																	<span>
// 																		{
// 																			suggestion.description
// 																		}
// 																	</span>
// 																</div>
// 															);
// 														}
// 													)}
// 												</div>
// 											</div>
// 										)}
// 									</PlacesAutocomplete>
// 								</div>
// 							</div>
// 						</div>

// 						{!this.state.showFilters && (
// 							<>
// 								<div className='desktopFilter'>
// 									<div className='searchButtonsContainer'>
// 										{/*<div className='searchButton reverse helpBox'>
// 											<Help />
// 										</div>*/}
// 										<div
// 											className='searchButton'
// 											onClick={(e) =>
// 												this.toggleFilters(e)
// 											}
// 										>
// 											MORE FILTERS
// 										</div>
// 										<div
// 											className='searchButton'
// 											onClick={this.handleSelect}
// 										>
// 											SEARCH
// 										</div>
// 									</div>
// 								</div>
// 								<div className='mobileFilter'>
// 									<div className='showFilterButtonsContainer'>
// 										<div
// 											className='searchButton'
// 											onClick={(e) =>
// 												this.toggleFilters(e)
// 											}
// 										>
// 											SHOW FILTERS
// 										</div>
// 									</div>
// 								</div>
// 							</>
// 						)}

// 						<div
// 							className={`filtersContainer ${
// 								!this.state.showFilters ? 'hidden' : ''
// 							}`}
// 						>
// 							{TrialsCategory === 'DST' ? (
// 								<div className='header'>
// 									<div className='title'>
// 										{' '}
// 										Trial Type
// 										<React.Fragment>
// 											<i
// 												class='fa fa-info-circle special-text'
// 												aria-hidden='true'
// 											>
// 												<span class='special-text__tooltip'>
// 													Refine your search by selecting Trial Type, see above for more details on each.
// 												</span>
// 											</i>
// 										</React.Fragment>
// 									</div>
// 									{/* <div
// 										onClick={() =>
// 											this.resetFilterType('trialTypes')
// 										}
// 										className='reset'
// 									>
// 										Clear
// 									</div> */}
// 								</div>
// 							) : null}
// 							{TrialsCategory === 'DST' && !isMobile ? (
// 								<div className='dropdownContainer'>
// 									<select
// 										value={
// 											trialType === undefined
// 												? 'All'
// 												: trialType
// 										}
// 										onChange={(e) => {
// 											this.handleTypeChange(e);
// 										}}
// 										className='filtersDropdown'
// 									>
// 										{this.getFilters_trialTypes()}
// 									</select>
// 								</div>
// 							) : null}

// 							{TrialsCategory === 'DST' && isMobile ? (
// 								<div className='yearSelectorContainer'>
// 									{this.getFilters_trialTypes()}
// 								</div>
// 							) : null}

// 							{isMobile ? (
// 								<>
// 									<div className='header'>
// 										<div className='title'>
// 											Year
// 											<React.Fragment>
// 												<i
// 													class='fa fa-info-circle special-text'
// 													aria-hidden='true'
// 												>
// 													<span class='special-text__tooltip'>
// 														Refine your search by selecting a year.
// 													</span>
// 												</i>
// 											</React.Fragment>
// 										</div>
// 										<div
// 											onClick={() =>
// 												this.resetFilterType('year')
// 											}
// 											className='reset'
// 										>
// 											Clear
// 										</div>
// 									</div>
// 									<div className='yearSelectorContainer'>
// 										{TrialsCategory === 'DST'
// 											? this.getFilters_Year()
// 											: this.getFiltersapt_Year()}
// 									</div>
// 								</>
// 							) : null}

// 							{this.getFilters(TrialsCategory)}

// 							{!isMobile ? (
// 								<>
// 									<div className='header'>
// 										<div className='title'>
// 											Year
// 											<React.Fragment>
// 												<i
// 													class='fa fa-info-circle special-text'
// 													aria-hidden='true'
// 												>
// 													<span class='special-text__tooltip'>
// 														Refine your search by selecting a year.
// 													</span>
// 												</i>
// 											</React.Fragment>
// 										</div>
// 									</div>
// 									<div className='yearSelectorContainer'>
// 										{TrialsCategory === 'DST'
// 											? this.getFilters_Year()
// 											: this.getFiltersapt_Year()}
// 									</div>
// 								</>
// 							) : null}

// 							<div className='filtersButtonsContainer desktop'>
// 								<div
// 									className='searchButton'
// 									onClick={(e) => this.toggleFilters(e)}
// 									data-tealium-category="popup" 
// 									data-tealium-action="click button" 
// 									data-tealium-value="hide-filters-button" 
// 									data-tealium-type="popup" 
// 								>
// 									HIDE FILTERS
// 								</div>
// 								{/* <div
// 									className='searchButton'
// 									onClick={(e) => this.toggleFilters(e)}
// 								>
// 									APPLY FILTERS
// 								</div> */}
// 							</div>
// 							<div className='filtersButtonsContainer mobile'>
// 								<div
// 									className='searchButton'
// 									// onClick={() =>
// 									// 	this.props.toggleMobileFilters()
// 									// }
// 									onClick={(e) => this.toggleFilters(e)}
// 									data-tealium-category="popup" 
// 									data-tealium-action="click button" 
// 									data-tealium-value="hide-filters-button" 
// 									data-tealium-type="popup"
// 								>
// 									HIDE FILTERS
// 								</div>
// 							</div>
// 						</div>
// 						{/* {TrialsCategory === 'DST' ? (
// 							<div className='header'>
// 								<div className='title'>
// 									{' '}
// 									Trial Types
// 									<React.Fragment>
// 										<i
// 											class='fa fa-info-circle special-text'
// 											aria-hidden='true'
// 										>
// 											<span class='special-text__tooltip'>
// 												Refine your search by filtering
// 												by Trial Types.
// 											</span>
// 										</i>
// 									</React.Fragment>
// 								</div>
// 								<div
// 									onClick={() =>
// 										this.resetFilterType('trialTypes')
// 									}
// 									className='reset'
// 								>
// 									Clear
// 								</div>
// 							</div>
// 						) : null}
// 						<div className='yearSelectorContainer'>
// 							{TrialsCategory === 'DST'
// 								? this.getFilters_trialTypes()
// 								: null}
// 						</div> */}
// 					</React.Fragment>
// 				) : null}

// 				{/* <div className='doneContainer'>
// 					<div
// 						className='doneBtn'
// 						onClick={(e) => this.togglePanel(e)}
// 					>
// 						{this.state.collapseButton}
// 					</div>
// 					<div className="doneBtnmob filterBtn noselect" onClick={(event) => { this.handleClickFilter(event); }}>{this.state.collapseButton}</div>
// 				</div> */}
// 			</div>
// 		);
// 	}
// }

const mapStateToProps = (state) => {
	return {
		trials: getVisibleTrials(state.trials),
		mobile_filters: state.trials.mobile_filters,
		width: state.trials.window_size,
	};
};

export default connect(
	//state => state.trialTypesView,
	mapStateToProps,
	(dispatch) =>
		bindActionCreators(
			{ ...trialsActionCreators, ...localeActionCreators },
			dispatch
		)
)(FilterSection);
