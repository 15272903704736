import { get } from '../utils/axios/axiosRequest';
import { getTrialsCategory } from '../selectors/trialsSelector';
import { nodeserver_url } from '../constants/api';
import axios from 'axios';

const TrialsCategory = getTrialsCategory();

const getAPTTrialResults = 'GET_APT_TRIAL_RESULTS';

export const actionTypes = {
    getAPTTrialResults
}

export const trialResultsActionCreators = {
    getAPTTrialResults: (enrollment_id, season) => async (dispatch) => {

        let apiBaseUrl = `${nodeserver_url}`;
        //console.log(apiBaseUrl)
       
        if (TrialsCategory === 'APT') {
            let response = await axios.get( apiBaseUrl + `/trial_results/api/sql/getTrialResults/?enrollment_id=${enrollment_id}&season=${season}`);
            console.log(response)
            if (response.data.agentstatus === 'success')
                dispatch({
                    type: getAPTTrialResults,
                    payload: response.data.data,
                });

        }
    }


}