import React, { Component } from 'react'; 
import './Hero.scss';


class Page extends Component {

    render() {
        return ( 
            <div className='hero'>
                <div>
                    <img src={this.props.img} alt={''} />   
                </div>
            </div> 
        );
    }
}

export default Page;