import React, { Component } from 'react'

class Range extends Component {
    constructor(props) {
        super(props);
        // this.updateRange = this.updateRange.bind(this);
        this.updateZoom = this.updateZoom.bind(this);
    }

    // updateRange(e) {
    //     this.props.updateRange(e.target.value);
    // }

    updateZoom(e) {
        this.props.updateZoom(e.target.value);
    }

    render() {
        const { range } = this.props;
        return (
            <div className="range">
                <div className="range-output">
                    <span id="output1">-</span>
                    <input id="range" type="range" className="e-range"
                    value={range}
                    min="0"
                    max="10"
                    step="1"
                    // onChange={this.updateRange}
                    onChange={this.updateZoom}
                />
                    <span id="output">+</span>
                </div>
            </div>
        )
    }
}

export default Range