import React, { Component } from 'react';
import { nodeserver_url_images } from '../../constants/api';
import "./AgroTrialPopUp.scss";

let closeImg = `${nodeserver_url_images}/trial_results/images/common/close.png`;

class AgroTrialPopUp extends Component {

    closePopUpTrial = () => {
        document.getElementById("agrotrial_popup").style.display = "none"
    }

    render() {
        return (
            <React.Fragment>
                <div id="agrotrial_popup">
                    <div id="popup_message">
                        <div id="popup_no" onClick={this.closePopUpTrial}>
                            <img src={closeImg} alt="close" />
                        </div>
                        <div id="popup_text-reviewmap">
                            <p>
                            Only DST results will be included in the Trial Summary as Agronomic Excellence trials are a different program with similar but slightly different methodologies including field execution.
                            </p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AgroTrialPopUp;