import { get } from '../utils//axios/axiosRequest';
import Filter from '../utils/filter';
import { getTrialsRegion, getDataset } from '../selectors/trialsSelector';
import { nodeserver_url } from '../constants/api';
import axios from 'axios';
//import {temp_data} from '../store/temp_data'  //this is temporary for when I do not have access to the DEV server

const getTrials = 'GET_TRIALS';
const filterTrials = 'FILTER_TRIALS';
const removeAllTrialFilters = 'REMOVE_ALL_TRIAL_FILTERS';
const GET_INDIVIDUAL_TRIAL = 'GET_INDIVIDUAL_TRIAL';
const CLEAR_INDIVIDUAL_TRIAL = 'CLEAR_INDIVIDUAL_TRIAL';
const TOGGLE_MOBILE_FILTERS = 'TOGGLE_MOBILE_FILTERS';
const WINDOW_SIZE = 'WINDOW_SIZE';
const MAP_ZOOM = 'MAP_ZOOM';

export const actionTypes = {
	getTrials,
	filterTrials,
	removeAllTrialFilters,
	GET_INDIVIDUAL_TRIAL,
	CLEAR_INDIVIDUAL_TRIAL,
	TOGGLE_MOBILE_FILTERS,
	WINDOW_SIZE,
	MAP_ZOOM,
};

export const trialsActionCreators = {
	// GET THE TRIALSFROM SERVER
	getTrials: (category, default_filters) => async (dispatch, getState) => {
		//console.log(getState());
		if (getState().trials && getState().trials.category === category)
			return;

		default_filters = default_filters || { fy: '2024' }; //2024
		//console.log(default_filters);

		let param = category ? category : 'dst';
		//console.log(category, "category");
		let region = getTrialsRegion();
		let dataset = getDataset();
		let apiBaseUrl = `${nodeserver_url}`;

		//let response = await get(`(public_site/get_published_trials)?openagent&category=${param}${param === 'APT' ? `&region=${region}&dataset=${dataset}` : ""}`);
		//let response = await get(`(public_site/get_published_trials)?openagent&category=${param}&region=${region}${dataset ? `&dataset=${dataset}` : ""}`);

		let response = await axios.get(
			apiBaseUrl +
				`/trial_results/api/sql/getPublishedTrials/?category=${category}&region=${region}`
		);

		//Taking out the duplicate trial records from APT 2019 trials - DPS 449
		const trialIds = [];
		let allData = [];
		{category == 'APT' ? allData = response.data.data : allData = response.data.data1.concat(response.data.data2, response.data.data3, response.data.data4, response.data.data5, response.data.data6)}
		const uniqueTrials = allData.filter((item) => {
			if (trialIds.indexOf(item.data.trial) === -1) {
				trialIds.push(item.data.trial);
				return item;
			}
		});
		response.data.data = [...uniqueTrials];
		let weatherData =response.data.weather;
		let hybridLinks =response.data.hybrid_links;

		//Filtering 2018 Herbicide Trials not to be published on public site - DPS 450
		const removedAPTTrials = response.data.data.filter((itemapt) => {
			return (
				!itemapt.data.trial_type.includes('Poast Ultra on Canola') &&
				!itemapt.data.trial_type.includes('Heat Harvest')
			);
		});
		response.data.data = [...removedAPTTrials];
		//console.log(response.data.data, "removedAPTTrials")

		let is_on_default_filter = true;
		if (
			default_filters.fy !== '' &&
			default_filters.ft !== '' &&
			default_filters.fh !== '' &&
			default_filters.tt !== '' 
		)
			is_on_default_filter = false;

		function setUpDefaultFilters(filt_string, filt_type) {
			if (filt_string !== undefined && filt_string !== '') {
				let this_filter = filt_string.split('_$_');
				this_filter.forEach(function (filt, index) {
					default_filter.add_filter(filt, filt_type, filt);
				});
			}
		}

		let default_filter = getState().trials.trial_filters || new Filter(); //what filter(s) will appear when the components load
		if (default_filters.fy !== '') {
			if (default_filters.fy === 'all') {
			} else {
				default_filter.add_filter(
					parseInt(default_filters.fy),
					'year',
					parseInt(default_filters.fy)
				);
			}
		}
		setUpDefaultFilters(default_filters.ftt, 'trialTypes');
		setUpDefaultFilters(default_filters.ft, 'technology');
		setUpDefaultFilters(default_filters.fh, 'hybrid');
		setUpDefaultFilters(default_filters.tt, 'treatment');

		if (response.data.agentstatus === 'success') {
			//filter out the manually uploaded 2019 trials if there if that trial is published in the 2019 data set instead
			let unduped_trials = response.data.data.filter((t) => {
				if (t.data.year !== 2019 || (param === 'APT' && t.data.year > 2018)) {
					return true;
				} else {
					let duplicated_trial = false;

					response.data.data.forEach((nt) => {
						// if (t.data.coopname === nt.data.coopname && t.data.trial_type === nt.data.trial_type)
						if (t.data.trial === nt.data.trial)
							duplicated_trial = true;
					});
					return !duplicated_trial;
				}
			});

			//combine 2019 DST table (data2) with legacy data, filtering out trials without a lat & long
			const trials2019 = unduped_trials.concat(
				response.data.data.filter((t) => {
					if (
						t.data.latitude &&
						t.data.longitude &&
						param.toLowerCase() !== 'apt'
					)
						return true;
				})
			);

			allData = response.data.data1.filter((t) => {
						if (
							t.data.latitude &&
							t.data.longitude &&
							param.toLowerCase() !== 'apt'
						)
							return true;
					})
				.concat(
					response.data.data2.filter((t) => {
						if (
							t.data.latitude &&
							t.data.longitude &&
							param.toLowerCase() !== 'apt'
						)
							return true;
					})
				)
				.concat(
					response.data.data3.filter((t) => {
						if (
							t.data.latitude &&
							t.data.longitude &&
							param.toLowerCase() !== 'apt'
						)
							return true;
					})
				)
				.concat(
					response.data.data4.filter((t) => {
						if (
							t.data.latitude &&
							t.data.longitude &&
							param.toLowerCase() !== 'apt'
						)
							return true;
					})
				)
				.concat(
					response.data.data5.filter((t) => {
						if (
							t.data.latitude &&
							t.data.longitude &&
							param.toLowerCase() !== 'apt'
						)
							return true;
					})
				)
				.concat(
					response.data.data6.filter((t) => {
						if (
							t.data.latitude &&
							t.data.longitude &&
							param.toLowerCase() !== 'apt'
						)
							return true;
					})
				);

			response.data.data.map((item) => {
				if (item.hybrids) {
					item.hybrids.map((hybrid) => {
						if (hybrid.hybrid.includes('®') && !(hybrid.hybrid.includes('<sup>'))) {
							let str = hybrid.hybrid.split('®');
							hybrid.hybrid = `${str[0]}<sup>®</sup>\u00A0${str[1]}`;
						}
					});
				}
			});

			dispatch({
				type: getTrials,
				payload: response.data.data,
				category: category ? category : 'dst',
				trial_filters: { ...default_filter, default_filter: false }, //is_on_default_filter
				weatherData: weatherData ? weatherData : {},
				hybridLinks: hybridLinks ? hybridLinks : {}
			});
		}
	},

	//UPDATE TRIAL FILTERS
	filterTrials: (filter) => (dispatch, getState) => {
		switch (filter.action) {
			case 'add':
				getState().trials.trial_filters.add_filter(
					filter.key,
					filter.type,
					filter.value
				);
				break;
			case 'remove':
				getState().trials.trial_filters.remove_filter(filter.key);
				break;
			default:
				break;
		}

		dispatch({
			type: filterTrials,
			payload: getState().trials.trials,
			category: getState().trials.category,
			trial_filters: {
				...getState().trials.trial_filters,
				default_filter: false,
				reset_filters: false,
			},
		});
	},
	//REMOVE ALL TRIAL FILTERS
	removeAllTrialFilters: () => (dispatch, getState) => {
		let default_filter =
			getState().trials.trial_filters.remove_all_filters() ||
			new Filter();
		//default_filter.add_filter(2021, 'year', 2021);
		default_filter.add_filter(2024, 'year', 2024);

		dispatch({
			type: removeAllTrialFilters,
			payload: getState().trials.trials,
			category: getState().trials.category,
			trial_filters: { ...default_filter, reset_filters: true },
		});
	},

	// removeAllTrialFiltersAPT: () => (dispatch, getState) => {
	//     let default_filter = getState().trials.trial_filters.remove_all_filters() || new Filter();
	//     default_filter.add_filter(2019, "year", 2019);

	//     dispatch({
	//         type: removeAllTrialFilters,
	//         payload: getState().trials.trials,
	//         category: getState().trials.category,
	//         trial_filters: { ...default_filter, reset_filters: true }
	//     });
	// },

	getIndividualTrial: (trialCode) => (dispatch) => {
		dispatch({
			type: GET_INDIVIDUAL_TRIAL,
			trialCode,
		});
	},

	clearIndividualTrial: () => (dispatch) => {
		dispatch({
			type: CLEAR_INDIVIDUAL_TRIAL,
		});
	},

	toggleMobileFilters: () => (dispatch) => {
		dispatch({
			type: TOGGLE_MOBILE_FILTERS,
		});
	},
	setWindowSize: (window_size) => (dispatch) => {
		dispatch({
			type: WINDOW_SIZE,
			window_size,
		});
	},
	setMapZoom: (mapZoom) => (dispatch) => {
		dispatch({
			type: MAP_ZOOM,
			mapZoom,
		});
	},
};
