import React, { Component } from 'react'
import { nodeserver_url_images } from '../../constants/api';
import { FloatingMenu, MainButton, ChildButton } from 'react-floating-button-menu';
import QueryString from 'qs';
import './Sharebuttons.css';

let icon_mail = `${nodeserver_url_images}/trial_results/images/trials/mail.png`;
let icon_twitter = `${nodeserver_url_images}/trial_results/images/trials/twit.png`;
let icon_share = `${nodeserver_url_images}/trial_results/images/trials/trio.png`;
let close_share = `${nodeserver_url_images}/trial_results/images/trials/close_share.png`;
// let banner_share = <img src="data:image/gif;base64,{{R0lGODlhFAAUAJEAAERERFBQUFJSUk9PTyH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4zLWMwMTEgNjYuMTQ1NjYxLCAyMDEyLzAyLzA2LTE0OjU2OjI3ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjY5QzFERDJBM0ExRDExRTNBQ0RBQzFEMzlGQ0I0N0M4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjY5QzFERDJCM0ExRDExRTNBQ0RBQzFEMzlGQ0I0N0M4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NjlDMUREMjgzQTFEMTFFM0FDREFDMUQzOUZDQjQ3QzgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NjlDMUREMjkzQTFEMTFFM0FDREFDMUQzOUZDQjQ3QzgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQAAAAAACwAAAAAFAAUAAACPYSPocvowGJ4Sa4jsjiXK6x5kwhm5Alom9GxH+VaIyy/dI3DsaVDdd/yzVQoYWllRBFdg8owtJMAf7qcrgAAOw==}}" />

class Sharebuttons extends Component {
	state={
		isOpen: false,
	}

	email =() => {
		let emailbutton = QueryString.stringify({
			subject:this.props.title,
			body: "Here’s the latest data from " + this.props.location + " on InVigorResults.ca. See how canola hybrids performed in this area: " + window.location.href 
	});
	let emailLink = "mailto:?"+emailbutton
	return window.open(emailLink, "_self")
	}

	twitter = () => {
		let tweetsharebutton = QueryString.stringify({
			size:"large",
			text: "InVigorResults.ca has all of the latest local trial data on canola hybrid performance. Here’s how things looked in " + this.props.location + ":",
			url:window.location.href,
	}) 
	let twitterLink = "https://twitter.com/intent/tweet?"+tweetsharebutton
	return window.open(twitterLink)
	}

    render() {
        return (
		<div className="share-button">

			<FloatingMenu
			className="first-button"
				slideSpeed={500}
				direction="left"
				spacing={8}
				isOpen={this.state.isOpen}>
				<MainButton 
				iconResting={<img src={icon_share} alt="share" className="second-button"/>}
				iconActive={<img src={close_share} alt="close" className="second-button"/>}
				onClick={() => this.setState({ isOpen: !this.state.isOpen })}
				size={35}
				/>
				<ChildButton
				className="boxshad"
				icon={<img src={icon_twitter} alt="twitter"/>}
				size={35}
				onClick={this.twitter}
				/>
				<ChildButton
				icon={<img src={icon_mail} alt="email"/>}
				className="boxshad"
				size={35}
				onClick={this.email}
				/>
			</FloatingMenu>
		</div>
        )
    }
}

export default Sharebuttons