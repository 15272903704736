import React from 'react';

const PageRouter = (props) => {
    let pages=[];
    let default_pages=[];    
    props.children.forEach((child) => {
        if(child.props.page===props.page)
            pages.push(child);
        if(child.props.default===true)
            default_pages.push(child);
    });

    return (
        <div>
            {pages.length === 0 ? default_pages : pages}
         </div>
    );
}

export default PageRouter;