/*global google*/
import React, { Component, Fragment } from 'react';
import { nodeserver_url_images } from '../../../constants/api.js';
import { connect } from 'react-redux';
import {
	computeDestinationPoint,
	getPreciseDistance,
	getCenter,
	isPointWithinRadius,
} from 'geolib';
import { bindActionCreators } from 'redux';
import { trialsActionCreators } from '../../../actions/trialsAction';
import { localeActionCreators } from '../../../actions/locationAction';
import {
	getVisibleTrials,
	getTrialsCategory,
} from '../../../selectors/trialsSelector';
import MapRectangle from './MapRectangle';
import MapPolygon from './MapPolygon';
import MapInfoWindow from './MapInfoWindow';
import './Map.scss';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { nodeserver_url } from '../../../constants/api';
import axios from 'axios';
import { getPURL } from '../../../selectors/trialsSelector';
import Help from '../../../components/Help/Help';
import DistanceBar from '../../../components/DistanceBar/DistanceBar';
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFilteredTrials } from '../../../selectors/trialsSelector';

let blueIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/blue-marker-new.png`;
let redIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/red-marker-new.png`;
let cornyellowIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/cornyellow-marker.png`;
let yellowIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/yellow-marker-new.png`;
let pinkIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/pink-marker-new.png`;
let greenIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/green-marker-new.png`;
let greyIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/grey-marker.png`;
let greyCluster = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/grey-cluster.png`;
let greenCluster = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/green-cluster-new.png`;
let mainMarker = `${nodeserver_url_images}/trial_results/images/googleMapIcons/main_mapmarker.png`;
let orangeIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/orange-marker.png`;
let purpleIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/newimages/purple-marker-new.png`;
let darkblueIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/darkblue-marker.png`;

const TrialsCategory = getTrialsCategory();

const {
	DrawingManager,
} = require('react-google-maps/lib/components/drawing/DrawingManager');
const {
	MarkerClusterer,
} = require('react-google-maps/lib/components/addons/MarkerClusterer');

class Map extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentLatLng: {
				lat: 52.157 || props.location.lat,
				lng: -106.67 || props.location.lng,
			},
			mapCenter: {
				lat: 52.157,
				lng: -106.67,
			},
			searchLatLng: {
				lat: null,
				lng: null,
			},
			distance: {
				X: 200, //850
				Y: 200, //850
			},
			rectangle: null,
			polygon: null,
			infoWindow: null,
			infoWindowContent: null,
			infoWindowIndex: null,
			infoWindowPosition: null,
			isMarkerShown: false,
			filteredTrials: [],
			mapZoom: 6.5,
			dragging: false,
			PURL: getPURL(),
			selectedMarkers: [],
			//range: 850
			mapZoomBar: 5,
		};
	}
	
	componentDidMount = () => {
		//console.log("Map mount")

		// if (this.props.clearPURL) {
		// 	this.setState({
		// 		...this.state,
		// 		PURL: null
		// 	}, () => { })
		// }
	
		// if redirecting from another page the previous Area is loaded
		if (this.props.trialFilters.data.filters.area) {
			if (
				this.props.trialFilters.data.filters.area.filt_type ===
				'rectangle'
			) {
				let bounds =
					this.props.trialFilters.data.filters.area.filt_value.props
						.bounds;
				let center = getCenter([
					{ longitude: bounds.east, latitude: bounds.north },
					{ longitude: bounds.east, latitude: bounds.south },
					{ longitude: bounds.west, latitude: bounds.north },
					{ longitude: bounds.west, latitude: bounds.south },
				]);

				let mapZoom = this.props.mapZoom;

				this.setState(
					{
						rectangle: this.createRectangle(bounds),
						mapCenter: {
							lat: center.latitude,
							lng: center.longitude,
						},
						currentLatLng: {
							lat: center.latitude,
							lng: center.longitude,
						},
						mapZoom: mapZoom
					},
					() => this.getDistance()
				);
			} else if (
				this.props.trialFilters.data.filters.area.filt_type ===
				'polygon'
			) {
				let center = getCenter(
					this.props.trialFilters.data.filters.area.filt_value.props
						.paths
				);
				this.setState(
					{
						polygon: this.createPolygon(
							this.props.trialFilters.data.filters.area.filt_value
								.props.paths
						),
						mapCenter: {
							lat: center.latitude,
							lng: center.longitude,
						},
						currentLatLng: {
							lat: center.latitude,
							lng: center.longitude,
						},
					},
					() => this.getDistance()
				);
			}
		} else {
			if (TrialsCategory === 'DST') {
				this.showCurrentLocation();
			} else {
				this.showCurrentLocationAPT();
			}
		}
	};

	componentWillReceiveProps = (nextProps) => {
		let location = nextProps.location.location;

		if (TrialsCategory === 'DST') {
			if (
				(location.lat &&
					location.lat !== this.state.searchLatLng.lat) ||
				(location.lng && location.lng !== this.state.searchLatLng.lng)
			) {
				/*
				let [east, west, north, south] = [
					computeDestinationPoint(
						{ lat: location.lat, lng: location.lng },
						200 * 1000,
						90
					).longitude,
					computeDestinationPoint(
						{ lat: location.lat, lng: location.lng },
						200 * 1000,
						270
					).longitude,
					computeDestinationPoint(
						{ lat: location.lat, lng: location.lng },
						200 * 1000,
						360
					).latitude,
					computeDestinationPoint(
						{ lat: location.lat, lng: location.lng },
						200 * 1000,
						180
					).latitude,
				];
				*/
				this.setState(
					{
						currentLatLng: { lat: location.lat, lng: location.lng },
						mapCenter: { lat: location.lat, lng: location.lng },
						searchLatLng: { lat: location.lat, lng: location.lng },
						polygon: null,
						/*
						rectangle: this.createRectangle({
							east: east,
							west: west,
							north: north,
							south: south,
						}),
						*/
						mapZoom: 6.75,
					},
					() => {
						(() => this.filterAreaMarkers())();
						(() => this.getDistance())();
						(() => this.getNearestCity(this.props.doSetNearestCity))();
						if (this._map && this._map.getBounds()) {
							(() => this.googleAPIHandleBounds())();
						}
					}
				);
			} else if (nextProps.trials.trial_filters.reset_filters) {
				this.props.getTrials(TrialsCategory);
				if (TrialsCategory === 'DST') {
					this.showCurrentLocation();
				} else {
					this.showCurrentLocationAPT();
				}
			}
		} else {
			if (
				(location.lat &&
					location.lat !== this.state.searchLatLng.lat) ||
				(location.lng && location.lng !== this.state.searchLatLng.lng)
			) {
				/*
				let [east, west, north, south] = [
					computeDestinationPoint(
						{ lat: location.lat, lng: location.lng },
						200 * 1000,
						90
					).longitude,
					computeDestinationPoint(
						{ lat: location.lat, lng: location.lng },
						200 * 1000,
						270
					).longitude,
					computeDestinationPoint(
						{ lat: location.lat, lng: location.lng },
						200 * 1000,
						360
					).latitude,
					computeDestinationPoint(
						{ lat: location.lat, lng: location.lng },
						200 * 1000,
						180
					).latitude,
				];
				*/
				this.setState(
					{
						currentLatLng: { lat: location.lat, lng: location.lng },
						mapCenter: { lat: location.lat, lng: location.lng },
						searchLatLng: { lat: location.lat, lng: location.lng },
						polygon: null,
						/*
						rectangle: this.createRectangle({
							east: east,
							west: west,
							north: north,
							south: south,
						}),
						*/
						mapZoom: 6.75,
					},
					() => {
						(() => this.filterAreaMarkers())();
						(() => this.getDistance())();
						(() =>
							this.getNearestCity(this.props.doSetNearestCity))();
						if (this._map && this._map.getBounds()) {
							(() => this.googleAPIHandleBounds())();
						}
				}
				);
			} else if (nextProps.trials.trial_filters.reset_filters) {
				this.props.getTrials(TrialsCategory);
				if (TrialsCategory === 'DST') {
					this.showCurrentLocation();
				} else {
					this.showCurrentLocationAPT();
				}
			}
		}
		//location based changes -- DPS-508
		// else if(nextProps.trials.unboundTrials.length > 0 && nextProps.trials.trials.length === 0){
		// 	this.showCurrentLocation();
		// }
	};

	// sets location of user if allowed and set initial rectangle area

	showCurrentLocation = () => {
		let [lat, lng] = [52.157, -106.67]; //Saskatoon
		//let [lat, lng] = [49.895, -97.138];  //Winnipeg

		//DPS-358: default location processed before asking for location access to avoid filtering issues while waiting for permission
		const rectangleBounds = {
			east: -95.15,
			west: -122.31,
			north: 60.01,
			south: 48.99,
		};

		const isWithinBounds = (lat, lng) => {
			return (
				lat >= rectangleBounds.south &&
				lat <= rectangleBounds.north &&
				lng >= rectangleBounds.west &&
				lng <= rectangleBounds.east
			);
		};


		let [east, west, north, south] = [
			computeDestinationPoint({ lat: lat, lng: lng }, 1000 * 1000, 90)
				.longitude,
			computeDestinationPoint({ lat: lat, lng: lng }, 1000 * 1000, 270)
				.longitude,
			computeDestinationPoint({ lat: lat, lng: lng }, 600 * 1000, 360)
				.latitude,
			computeDestinationPoint({ lat: lat, lng: lng }, 350 * 1000, 180)
				.latitude,
		];
		this.setState(
			{
				isMarkerShown: true,
				currentLatLng: { lat: lat, lng: lng },
				mapCenter: { lat: lat, lng: lng },
				polygon: null,
				rectangle: this.createRectangle({
					east: east,
					west: west,
					north: north,
					south: south,
				}),
				mapZoom: 6.5,
			},
			() => {
				(() => this.filterAreaMarkers())();
				(() => this.getDistance())();
				(() => this.getNearestCity(this.props.doSetNearestCity))();
				if (this._map && this._map.getBounds()) {
					(() => this.googleAPIHandleBounds())();
				}
			}
		);

		if (this.state.PURL) {
			let apiBaseUrl = `${nodeserver_url}`;

			/*Start of call */
			let data = {
				event: `${this.state.currentLatLng.lat},${this.state.currentLatLng.lng}`,
				action: 'map',
				category: 'location',
			};

			axios.post(
				apiBaseUrl +
					`/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`,
				data
			);
		}

		//this is where the change needs to happen

		//DPS-627: Default to saskatoon and dont check geolocation on initial load. Remove the false when we want to use geo location as initial location
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {

			if (isWithinBounds(position.coords.latitude, position.coords.longitude)) {
				let [east, west, north, south] = [
					computeDestinationPoint(
						{
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						},
						200 * 1000,
						90
					).longitude,
					computeDestinationPoint(
						{
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						},
						200 * 1000,
						270
					).longitude,
					computeDestinationPoint(
						{
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						},
						200 * 1000,
						360
					).latitude,
					computeDestinationPoint(
						{
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						},
						200 * 1000,
						180
					).latitude,
				];
				//console.log("allow location");

				//this.setState({ currentLatLng: { lat: 52.157, lng: -106.67 } });

				//Once back to normalcy uncomment this ---- Defaulted to Western canada irrespective of location

				this.setState(
					{
						isMarkerShown: true,
						currentLatLng: {
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						},
						mapCenter: {
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						},
						rectangle: this.createRectangle({
							east: east,
							west: west,
							north: north,
							south: south,
						}),
						polygon: null,
					},
					() => {
						(() => this.filterAreaMarkers())();
						(() => this.getDistance())();
						(() => this.getNearestCity(this.props.doSetNearestCity))();
						if (this._map && this._map.getBounds()) {
							(() => this.googleAPIHandleBounds())();
						}

						if (this.state.PURL) {
							let apiBaseUrl = `${nodeserver_url}`;

							/*Start of call */
							let data = {
								event: `${this.state.mapCenter.lat},${this.state.mapCenter.lng}`,
								action: 'map',
								category: 'location',
							};

							axios.post(
								apiBaseUrl +
									`/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`,
								data
							);
						}
					}
				);
			} else {
				this.setState({ currentLatLng: { lat: 52.157, lng: -106.67 } });
			}

			});
		} else {
			// default to Saskatoon
			this.setState({ currentLatLng: { lat: 52.157, lng: -106.67 } });

			// default to Winnipeg
			//this.setState({ currentLatLng: { lat: 49.895, lng: -97.138 } });
		}
	};

	showCurrentLocationAPT = () => {
		let [lat, lng] = [52.157, -106.67]; //Saskatoon
		//let [lat, lng] = [49.895, -97.138];  //Winnipeg

		//DPS-358: default location processed before asking for location access to avoid filtering issues while waiting for permission

		let [east, west, north, south] = [
			computeDestinationPoint({ lat: lat, lng: lng }, 1000 * 1000, 90)
				.longitude,
			computeDestinationPoint({ lat: lat, lng: lng }, 1000 * 1000, 270)
				.longitude,
			computeDestinationPoint({ lat: lat, lng: lng }, 600 * 1000, 360)
				.latitude,
			computeDestinationPoint({ lat: lat, lng: lng }, 350 * 1000, 180)
				.latitude,
		];
		this.setState(
			{
				isMarkerShown: true,
				currentLatLng: { lat: lat, lng: lng },
				mapCenter: { lat: lat, lng: lng },
				polygon: null,
				rectangle: this.createRectangle({
					east: east,
					west: west,
					north: north,
					south: south,
				}),
				mapZoom: 6.5,
			},
			() => {
				(() => this.filterAreaMarkers())();
				(() => this.getDistance())();
				(() => this.getNearestCity(this.props.doSetNearestCity))();
				if (this._map && this._map.getBounds()) {
					(() => this.googleAPIHandleBounds())();
				}
			}
		);

		if (this.state.PURL) {
			let apiBaseUrl = `${nodeserver_url}`;

			/*Start of call */
			let data = {
				event: `${this.state.currentLatLng.lat},${this.state.currentLatLng.lng}`,
				action: 'map',
				category: 'location',
			};

			axios.post(
				apiBaseUrl +
					`/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`,
				data
			);
		}


		//this is where the change needs to happen
		//DPS-627: Default to saskatoon and dont check geolocation on initial load. Remove the false when we want to use geo location as initial location

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {

					let [east, west, north, south] = [
						computeDestinationPoint(
							{
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							},
							200 * 1000,
							90
						).longitude,
						computeDestinationPoint(
							{
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							},
							200 * 1000,
							270
						).longitude,
						computeDestinationPoint(
							{
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							},
							200 * 1000,
							360
						).latitude,
						computeDestinationPoint(
							{
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							},
							200 * 1000,
							180
						).latitude,
					];
					//console.log("allow location");

					this.setState({
						currentLatLng: { lat: 52.157, lng: -106.67 },
					});

					//Once back to normalcy uncomment this ---- Defaulted to Western canada irrespective of location
					if (TrialsCategory === 'DST') {
					} else {
						this.setState(
							{
								isMarkerShown: true,
								currentLatLng: {
									lat: position.coords.latitude,
									lng: position.coords.longitude,
								},
								mapCenter: {
									lat: position.coords.latitude,
									lng: position.coords.longitude,
								},
								rectangle: this.createRectangle({
									east: east,
									west: west,
									north: north,
									south: south,
								}),
								polygon: null,
							},
							() => {
								(() => this.filterAreaMarkers())();
								(() => this.getDistance())();
								(() => this.getNearestCity(this.props.doSetNearestCity))();
								if (this._map && this._map.getBounds()) {
									(() => this.googleAPIHandleBounds())();
								}

								if (this.state.PURL) {
									let apiBaseUrl = `${nodeserver_url}`;

									/*Start of call */
									let data = {
										event: `${this.state.mapCenter.lat},${this.state.mapCenter.lng}`,
										action: 'map',
										category: 'location',
									};

									axios.post(
										apiBaseUrl +
											`/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`,
										data
									);
								}
							}
						);
					}
				}
				// runs if location blocked
				// () => {
				// 	let [east, west, north, south] = [
				// 		computeDestinationPoint({ lat: lat, lng: lng }, 200000, 90).longitude,
				// 		computeDestinationPoint({ lat: lat, lng: lng }, 200000, 270).longitude,
				// 		computeDestinationPoint({ lat: lat, lng: lng }, 200000, 360).latitude,
				// 		computeDestinationPoint({ lat: lat, lng: lng }, 200000, 180).latitude
				// 	];
				// 	console.log("block location")
				// 	this.setState({
				// 		isMarkerShown: true,
				// 		currentLatLng: { lat: lat, lng: lng },
				// 		mapCenter: { lat: lat, lng: lng },
				// 		polygon: null,
				// 		rectangle: this.createRectangle({
				// 			east: east,
				// 			west: west,
				// 			north: north,
				// 			south: south
				// 		})
				// 	}, () => {
				// 		(() => this.filterAreaMarkers())();
				// 		(() => this.getDistance())();
				// 		(() => this.getNearestCity(this.props.doSetNearestCity))();
				// 	})
				// }
			);
		} else {
			// default to Saskatoon
			this.setState({ currentLatLng: { lat: 52.157, lng: -106.67 } });

			// default to Winnipeg
			//this.setState({ currentLatLng: { lat: 49.895, lng: -97.138 } });
		}

	};

	//DPS-668: Change bounds on zoom to the current bounds of the map to filter out trials not showing on the map.
	googleAPIHandleBounds = () => {
		const center_lat = this._map.getCenter().lat();
		const center_lng = this._map.getCenter().lng();
		let ne = this._map.getBounds().getNorthEast();
        let sw = this._map.getBounds().getSouthWest();
		const mapZoom = this._map.getZoom();

		let bounds = {
			north: ne.lat(),
			south: sw.lat(),
			east: ne.lng(),
			west: sw.lng(),
		}

		this.setState({
			currentLatLng: { lat: center_lat, lng: center_lng },
			mapCenter: { lat: center_lat, lng: center_lng },
			rectangle: this.createRectangle(bounds)
		},
		() => {
			(() => this.filterAreaMarkers())();
			(() => this.getDistance())();
			(() => this.getNearestCity(this.props.doSetNearestCity))();
		});

		this.props.setMapZoom(mapZoom);

	};

	onZoomChanged = () => {
		this.setState({
			zoom: this.googleMap.current.getZoom(),
		});
	};

	/*****************MAP******************/
	// creates map and drawing manager
	CMap = withGoogleMap((props) => (
		<GoogleMap
			ref={(map) => (this._map = map)}
			zoom={this.state.mapZoom}
			onZoomChanged={this.adjustOffset}
			center={{
				lat: this.state.mapCenter.lat,
				lng: this.state.mapCenter.lng,
			}}
			defaultOptions={{
				mapTypeControl: false,
				streetViewControl: false,
				scaleControl: true,
			}}
			onDragEnd={this.googleAPIHandleBounds}

		>
			{/* 			//DrawingManager
			 <DrawingManager
				defaultDrawingMode={null}
				defaultOptions={{
					drawingControl: true,
					drawingControlOptions: {
						position: google.maps.ControlPosition.TOP_RIGHT,
						drawingModes: [
							google.maps.drawing.OverlayType.RECTANGLE,
							google.maps.drawing.OverlayType.MARKER
						]
					},
					rectangleOptions: { fillColor: `#3598f0`, fillOpacity: .3, strokeWeight: 3, strokeColor: '#3598f0', clickable: false, editable: true, zIndex: 1 },
					polygonOptions: { fillColor: `#3598f0`, fillOpacity: .3, strokeWeight: 3, strokeColor: '#3598f0', clickable: false, editable: false, zIndex: 1 }
				}}
				onRectangleComplete={this.updateDrawingMode}
				onMarkerComplete={this.updateDrawingMode}
				onOverlayComplete={(e) => this.handleOverlayComplete(e, this)}
			/>  */}

			{props.children}
		</GoogleMap>
	));

	// removes existing shapes and stores new shape
	handleOverlayComplete(over, map) {
		this.removeAllFilters();
		over.overlay.setMap(null);
		if (over.type === 'marker') {
			let [lat, lng] = [
				over.overlay.position.lat(),
				over.overlay.position.lng(),
			];
			let polygon = this.createPolygon([
				{ lat: lat - 0.4, lng: lng - 0.4 },
				{ lat: lat - 0.4, lng: lng + 0.4 },
				{ lat: lat + 0.1, lng: lng + 0.8 },
				{ lat: lat + 0.5, lng: lng },
				{ lat: lat + 0.1, lng: lng - 0.8 },
			]);

			this.setState(
				{
					polygon: polygon,
					rectangle: null,
					mapCenter: { lat: lat, lng: lng },
					currentLatLng: { lat: lat, lng: lng },
				},
				() => {
					(() => this.filterAreaMarkers())();
					(() => this.getDistance())();
					(() => this.getNearestCity(this.props.doSetNearestCity))();
				}
			);
		} else {
			//let [east, west, north, south] = [over.overlay.bounds.da.h, over.overlay.bounds.da.g, over.overlay.bounds.ha.h, over.overlay.bounds.ha.g];
			let [east, west, north, south] = [
				over.overlay.bounds.getNorthEast().lng(),
				over.overlay.bounds.getSouthWest().lng(),
				over.overlay.bounds.getNorthEast().lat(),
				over.overlay.bounds.getSouthWest().lat(),
			];
			let rectangle = this.createRectangle({
				east: east,
				north: north,
				south: south,
				west: west,
			});
			let center = getCenter([
				{ longitude: east, latitude: north },
				{ longitude: east, latitude: south },
				{ longitude: west, latitude: north },
				{ longitude: west, latitude: south },
			]);

			this.setState(
				{
					rectangle: rectangle,
					polygon: null,
					mapCenter: { lat: center.latitude, lng: center.longitude },
					currentLatLng: {
						lat: center.latitude,
						lng: center.longitude,
					},
				},
				() => {
					(() => this.filterAreaMarkers())();
					(() => this.getDistance())();
					(() => this.getNearestCity(this.props.doSetNearestCity))();
				}
			);

			if (this.state.PURL) {
				let apiBaseUrl = `${nodeserver_url}`;

				/*Start of call */
				let data = {
					event: `${this.state.currentLatLng.lat},${this.state.currentLatLng.lng}`,
					action: 'map',
					category: 'location',
				};

				axios.post(
					apiBaseUrl +
						`/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`,
					data
				);
			}
		}
	}

	// turns drawing mode off after shape creation
	updateDrawingMode(e) {
		this.setDrawingMode(null);
	}

	// Gets distance of area shape on map, x and y
	getDistance = () => {
		if (this.state.polygon) {
			let sortedVerticalPoints = this.state.polygon.props.paths
				.concat()
				.sort((a, b) => b.lat - a.lat);
			let sortedHorizontalPoints = this.state.polygon.props.paths
				.concat()
				.sort((a, b) => b.lng - a.lng);
			let horizontalDistance =
				getPreciseDistance(
					{
						latitude: sortedHorizontalPoints[0].lat,
						longitude: sortedHorizontalPoints[0].lng,
					},
					{
						latitude:
							sortedHorizontalPoints[
								sortedHorizontalPoints.length - 1
							].lat,
						longitude:
							sortedHorizontalPoints[
								sortedHorizontalPoints.length - 1
							].lng,
					}
				) / 1000;
			let verticalDistance =
				getPreciseDistance(
					{
						latitude: sortedVerticalPoints[0].lat,
						longitude: sortedVerticalPoints[0].lng,
					},
					{
						latitude:
							sortedVerticalPoints[
								sortedVerticalPoints.length - 1
							].lat,
						longitude:
							sortedVerticalPoints[
								sortedVerticalPoints.length - 1
							].lng,
					}
				) / 1000;
			this.setState({
				distance: {
					X: Math.round(horizontalDistance),
					Y: Math.round(verticalDistance),
				},
			});
		} else {
			let bounds = this.state.rectangle.props.bounds;
			let horizontalDistance =
				getPreciseDistance(
					{ latitude: bounds.north, longitude: bounds.east },
					{ latitude: bounds.north, longitude: bounds.west }
				) / 2000;
			let verticalDistance =
				getPreciseDistance(
					{ latitude: bounds.north, longitude: bounds.east },
					{ latitude: bounds.south, longitude: bounds.east }
				) / 2000;
			this.setState({
				distance: {
					X: Math.round(horizontalDistance),
					Y: Math.round(verticalDistance),
				},
			});
		}
	};

	// filter trials by selected area
	filterAreaMarkers = () => {
		if (this.state.polygon) {
			this.applyFilter(this.state.polygon, 'polygon');
		} else {
			this.applyFilter(this.state.rectangle, 'rectangle');
		}
	};

	// adds filter to store
	applyFilter = (value, type) => {
		let filter = { action: 'add', key: 'area', type: type, value: value };
		this.props.filterTrials(filter);
	};

	removeAllFilters = () => {
		let filtersContainDefault = false;
		Object.entries(this.props.trials.trial_filters.data.filters).forEach(
			([k, v]) => {
				if (v.key !== 'area') {
					// if (v.filt_value !== 2019) {
					// 	this.props.filterTrials({ action: 'remove', key: v.key, type: v.filt_type, value: v.filt_value });
					// }
					//else {
					filtersContainDefault = true;
					// }
				}
			}
		);
		if (!filtersContainDefault) {
			this.props.filterTrials({
				action: 'add',
				key: 2024,
				type: 'year',
				value: 2024,
			});
			// this.props.filterTrials({ action: 'add', key: 2019, type: 'year', value: 2019 })
		}
	};

	/***************RECTANGLE*********************/
	// creates rectangle shape
	createRectangle = (bounds) => {
		return (
			<MapRectangle bounds={bounds} rectangleDrag={this.rectangleDrag} />
		);
	};

	// updates rectangle bounds when dragged or stretched
	rectangleDrag = (bounds, center, currentLocation) => {
		//let rectangle = this.createRectangle({ east: bounds.da.h, north: bounds.ha.h, south: bounds.ha.g, west: bounds.da.g });
		let rectangle = this.createRectangle({
			east: bounds.getNorthEast().lng(),
			north: bounds.getNorthEast().lat(),
			south: bounds.getSouthWest().lat(),
			west: bounds.getSouthWest().lng(),
		});
		this.setState(
			{
				rectangle: rectangle,
				mapCenter: { lat: center.latitude, lng: center.longitude },
				currentLatLng: currentLocation
					? currentLocation
					: this.state.currentLatLng,
				range: (this.state.distance.X + this.state.distance.Y) / 2,
			},
			() => {
				//(() => console.log("Rectanglesssssssssssssssssssss"))();
				(() => this.filterAreaMarkers())();
				(() => this.getDistance())();
				(() => this.getNearestCity(this.props.doSetNearestCity))();
			}
		);

		if (this.state.PURL) {
			let apiBaseUrl = `${nodeserver_url}`;

			/*Start of call */
			let data = {
				event: `${this.state.currentLatLng.lat},${this.state.currentLatLng.lng}`,
				action: 'map',
				category: 'location',
			};

			axios.post(
				apiBaseUrl +
					`/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`,
				data
			);
		}
	};
	/*************POLYGON*****************/
	// update polygon bounds when dragged or stretched
	createPolygon = (paths) => {
		return <MapPolygon paths={paths} polyDrag={this.polyDrag} />;
	};

	// updates polygon bounds on drag or expand
	polyDrag = (paths, center, currentLocation) => {
		let polygon = this.createPolygon(paths);

		this.setState(
			{
				polygon: polygon,
				mapCenter: { lat: center.latitude, lng: center.longitude },
				currentLatLng: currentLocation
					? currentLocation
					: this.state.currentLatLng,
			},
			() => {
				(() => this.filterAreaMarkers())();
				(() => this.getDistance())();
				(() => this.getNearestCity(this.props.doSetNearestCity))();
			}
		);

		if (this.state.PURL) {
			let apiBaseUrl = `${nodeserver_url}`;

			/*Start of call */
			let data = {
				event: `${this.state.currentLatLng.lat},${this.state.currentLatLng.lng}`,
				action: 'map',
				category: 'location',
			};

			axios.post(
				apiBaseUrl +
					`/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`,
				data
			);
		}
	};

	// checks if point is inside polygon
	inPolygon = (verticesCount, xAry, yAry, markerX, markerY) => {
		let i,
			j,
			c = 0;
		for (i = 0, j = verticesCount - 1; i < verticesCount; j = i++) {
			if (
				yAry[i] > markerY !== yAry[j] > markerY &&
				markerX <
					((xAry[j] - xAry[i]) * (markerY - yAry[i])) /
						(yAry[j] - yAry[i]) +
						xAry[i]
			)
				c = !c;
		}
		return c;
	};
	
	/***********Marker**************/
	// creates map trial markers, filtered and unfiltered
	generateMarkers = () => {

		let markers = [];
		let trialIndex = 0;
		let yearFilter = Object.entries(
			this.props.trialFilters.data.filters
		).filter((f) => f[1].filt_type === 'year');
		if (this.props.allTrials) {
			let filteredTrials = getFilteredTrials(this.props.allTrials.all_trials, this.props.allTrials.trial_filters)
			filteredTrials.trials.forEach((trial) => {
				if (trial.data.latitude && trial.data.longitude) {
					let filteredTrial = this.props.trials.unboundTrials.find(
						(t) => t.data.trial === trial.data.trial
					);
					if (filteredTrial) {
						markers.push(
							<Marker
								key={trial.data.trial}
								noRedraw={true}
								position={{
									lat: parseFloat(
										trial.data.latitude.toFixed(4)
									),
									lng: parseFloat(
										trial.data.longitude.toFixed(4)
									),
								}}
								icon={
									TrialsCategory === 'DST'
										? {
												url: this.getIcon(
													trial.data.year,
													trial.data.trial_type,
													trial.data.harvest_method ? trial.data.harvest_method.toUpperCase() : trial.data.harvest_method
												),
												labelOrigin:
													new google.maps.Point(
														55,
														17
													),
										  }
										: {
												url: this.getIconapt(
													trial.data.crop_type
												),
										  }
								}
								// title={`${trial.data.city}, ${trial.data.province}\n${trial.data.trial_type}`}
								onClick={this.getMarkerContent}
							/>
						);
					} else if (
						yearFilter &&
						yearFilter.length > 0 &&
						yearFilter[0][1].filt_value === trial.data.year
					) {
						//console.log("DFFFFFTT", this.props.trialFilters.default_filter)
						markers.push(
							<Marker
								key={trial.data.trial}
								noRedraw={true}
								position={{
									lat: parseFloat(
										trial.data.latitude.toFixed(4)
									),
									lng: parseFloat(
										trial.data.longitude.toFixed(4)
									),
								}}
								icon={
									this.props.trialFilters.default_filter
										? TrialsCategory === 'DST'
											? {
													url: this.getIcon(
														trial.data.year,
														trial.data.trial_type,
														trial.data.harvest_method ? trial.data.harvest_method.toUpperCase() : trial.data.harvest_method
													),
													labelOrigin:
														new google.maps.Point(
															55,
															17
														),
											  }
											: {
													url: this.getIconapt(
														trial.data.crop_type
													),
											  }
										: { url: greyIcon }
								}
								visible={this.props.trialFilters.default_filter
									? true : false}
								// title={`${trial.data.city}, ${trial.data.province}\n${trial.data.trial_type}`}
								onClick={this.getMarkerContent}
							/>
						);
					} else if (yearFilter && yearFilter.length === 0) {
						markers.push(
							<Marker
								key={trial.data.trial}
								noRedraw={true}
								position={{
									lat: parseFloat(
										trial.data.latitude.toFixed(4)
									),
									lng: parseFloat(
										trial.data.longitude.toFixed(4)
									),
								}}
								icon={
									this.props.trialFilters.default_filter
										? TrialsCategory === 'DST'
											? {
													url: this.getIcon(
														trial.data.year,
														trial.data.trial_type,
														trial.data.harvest_method ? trial.data.harvest_method.toUpperCase() : trial.data.harvest_method
													),
													labelOrigin:
														new google.maps.Point(
															55,
															17
														),
											  }
											: {
													url: this.getIconapt(
														trial.data.crop_type
													),
											  }
										: { url: greyIcon }
								}
								visible={this.props.trialFilters.default_filter
									? true : false}
								// title={`${trial.data.city}, ${trial.data.province}\n${trial.data.trial_type}`}
								onClick={this.getMarkerContent}
							/>
						);
					}
				}
			});
		}
		return markers;
	};

	//Marketing Automation

	generateselectedTrialMarkers = () => {
		if (TrialsCategory === 'DST') {
			let selectedTrialMarkers = [];
			let yearFilter = Object.entries(
				this.props.trialFilters.data.filters
			).filter((f) => f[1].filt_type === 'year');
			if (this.props.allTrials) {
				this.props.allTrials.trials.forEach((trial) => {
					let filteredTrial = this.props.trials.unboundTrials.find(
						(t) => t.data.trial === trial.data.trial
					);
					if (filteredTrial) {
						selectedTrialMarkers.push({
							lat: parseFloat(trial.data.latitude.toFixed(4)),
							lng: parseFloat(trial.data.longitude.toFixed(4)),
							city: trial.data.city,
						});
					} else if (
						yearFilter &&
						yearFilter.length > 0 &&
						yearFilter[0][1].filt_value === trial.data.year
					) {
						selectedTrialMarkers.push({
							lat: parseFloat(trial.data.latitude.toFixed(4)),
							lng: parseFloat(trial.data.longitude.toFixed(4)),
							city: trial.data.city,
						});
					} else if (yearFilter && yearFilter.length === 0) {
						selectedTrialMarkers.push({
							lat: parseFloat(trial.data.latitude.toFixed(4)),
							lng: parseFloat(trial.data.longitude.toFixed(4)),
							city: trial.data.city,
						});
					}
				});

				// 			markers.push(<Marker
				// 				key={trial.data.trial}
				// 				position={{ lat: parseFloat(trial.data.latitude.toFixed(4)), lng: parseFloat(trial.data.longitude.toFixed(4)) }}
				// 				icon={{ url: this.getIcon(trial.data.trial_type) }}
				// 				title={`${trial.data.city}, ${trial.data.province}\n${trial.data.trial_type}`}
				// 				onClick={this.getMarkerContent}
				// 			/>);

				// 		} else if (yearFilter && yearFilter.length > 0 && yearFilter[0][1].filt_value === trial.data.year) {
				// 			markers.push(<Marker
				// 				key={trial.data.trial}
				// 				position={{ lat: parseFloat(trial.data.latitude.toFixed(4)), lng: parseFloat(trial.data.longitude.toFixed(4)) }}
				// 				icon={this.props.trialFilters.default_filter ? { url: this.getIcon(trial.data.trial_type) } : { url: greyIcon }}
				// 				title={`${trial.data.city}, ${trial.data.province}\n${trial.data.trial_type}`}
				// 				onClick={this.getMarkerContent}
				// 			/>);

				// 		} else if (yearFilter && yearFilter.length === 0) {
				// 			markers.push(<Marker
				// 				key={trial.data.trial}
				// 				position={{ lat: parseFloat(trial.data.latitude.toFixed(4)), lng: parseFloat(trial.data.longitude.toFixed(4)) }}
				// 				icon={this.props.trialFilters.default_filter ? { url: this.getIcon(trial.data.trial_type) } : { url: greyIcon }}
				// 				title={`${trial.data.city}, ${trial.data.province}\n${trial.data.trial_type}`}
				// 				onClick={this.getMarkerContent}
				// 			/>);
				// 		}
				// 	});

				// }

				this.props.doSetMarkerCoordinates(selectedTrialMarkers);
			}
		}
	};

	//Marketing Automation
	generateselectedTrialMarkers = () => {
		let selectedTrialMarkers = [];
		let yearFilter = Object.entries(
			this.props.trialFilters.data.filters
		).filter((f) => f[1].filt_type === 'year');
		if (this.props.allTrials) {
			this.props.allTrials.trials.forEach((trial) => {
				let filteredTrial = this.props.trials.unboundTrials.find(
					(t) => t.data.trial === trial.data.trial
				);
				if (filteredTrial) {
					if(trial.data.latitude && trial.data.longitude){
					selectedTrialMarkers.push({
						lat: parseFloat(trial.data.latitude.toFixed(4)),
						lng: parseFloat(trial.data.longitude.toFixed(4)),
						city: trial.data.city,
					});
				}
				} else if (
					yearFilter &&
					yearFilter.length > 0 &&
					yearFilter[0][1].filt_value === trial.data.year
				) {
					if(trial.data.latitude && trial.data.longitude){
					selectedTrialMarkers.push({
						lat: parseFloat(trial.data.latitude.toFixed(4)),
						lng: parseFloat(trial.data.longitude.toFixed(4)),
						city: trial.data.city,
					});
				}
				} else if (yearFilter && yearFilter.length === 0) {
					if(trial.data.latitude && trial.data.longitude){
					selectedTrialMarkers.push({
						lat: parseFloat(trial.data.latitude.toFixed(4)),
						lng: parseFloat(trial.data.longitude.toFixed(4)),
						city: trial.data.city,
					});
				}
				}
			});
		}

		this.props.doSetMarkerCoordinates(selectedTrialMarkers);
	};

	// finds if trial marker is within the selected area
	withinZone = (trial) => {
		if (this.state.rectangle) {
			return (
				trial.data.latitude >=
					this.state.rectangle.props.bounds.south &&
				trial.data.latitude <=
					this.state.rectangle.props.bounds.north &&
				trial.data.longitude <=
					this.state.rectangle.props.bounds.east &&
				trial.data.longitude >= this.state.rectangle.props.bounds.west
			);
		} else if (this.state.polygon) {
			return this.inPolygon(
				this.state.polygon.props.paths.length,
				this.state.polygon.props.paths.map((p) => p.lng),
				this.state.polygon.props.paths.map((p) => p.lat),
				trial.data.longitude,
				trial.data.latitude
			);
		}
		return false;
	};

	// get marker icon color
	// First check if AE trial
	// Second check year and use old logic if before 2021
	// else use harvest_method logic
	getIcon = ( year , trialType , harvestmethod ) => {
		if (trialType.includes('Agronomic')) {
			return orangeIcon
		}
		else if (trialType.includes('DST')) {
			return blueIcon;
		}
		else if (trialType.includes('Health')) {
			return redIcon;
		}
	};

	// APT get marker icon color
	getIconapt = (cropType) => {
		switch (cropType) {
			case 'Canola':
				return blueIcon;
			case 'Cereals':
				return redIcon;
			case 'Pulses':
				return yellowIcon;
			case 'Peas':
				return yellowIcon;
			case 'Soybeans':
				return pinkIcon;
			case 'Multiple Trials':
				return greenIcon;
			case 'Corn':
				return cornyellowIcon;
			default:
				return null;
		}
	};

	// custom MarkerCluster style calculator
	calc = (markers, numStyles) => {
		let visibleMarkers = markers.filter(marker => marker.visible !== false);
		for (let i = 0; i < visibleMarkers.length; i++) {
			if (
				visibleMarkers[i].title !== 'Unselected-trial' ||
				this.props.trialFilters.default_filter
			) {
				return { text: visibleMarkers.length, index: 1 };
			}
		}
		return { text: visibleMarkers.length, index: 2 };
	};

	generateTracker = (event, action, category) => {
		if (this.state.PURL) {
			// console.log('Generate Tracker Maps');
			let apiBaseUrl = `${nodeserver_url}`;

			/*Start of call */
			let data = {
				event,
				action,
				category,
			};

			axios.post(
				apiBaseUrl +
					`/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`,
				data
			);
		}
	};

	getTrialCount = (all_trials, e) => {
		let count = 0;
		let yearFilter = Object.entries(
			this.props.trialFilters.data.filters
		).filter((f) => f[1].filt_type === 'year');
		let [lat, lng] = e.minClusterSize_
			? [e.center_.lat(), e.center_.lng()]
			: [e.latLng.lat(), e.latLng.lng()];
			all_trials.trials.forEach((t) => {
			if ((t.data.latitude != undefined || t.data.longitude != undefined) &&
				isPointWithinRadius(
					{ latitude: t.data.latitude, longitude: t.data.longitude },
					{ latitude: lat, longitude: lng },
					5000
				)
			) {
				if (
					yearFilter &&
					(yearFilter.length === 0 ||
						(yearFilter.length > 0 &&
							yearFilter[0][1].filt_value === t.data.year))
				) {
					count++;
				}
			}
		})

		return count;
	}

	/***********INFO WINDOW**************/
	// On marker click get infoWindow content for that location
	getMarkerContent = (e) => {
		// console.log(e, 'GMC');
		let content = [];
		let yearFilter = Object.entries(
			this.props.trialFilters.data.filters
		).filter((f) => f[1].filt_type === 'year');
		let [lat, lng] = e.minClusterSize_
			? [e.center_.lat(), e.center_.lng()]
			: [e.latLng.lat(), e.latLng.lng()];
		let radius = e.minClusterSize_ ? 5000 : 100;
		let filteredTrials = getFilteredTrials(this.props.allTrials.all_trials, this.props.allTrials.trial_filters)
		let trial_count = this.getTrialCount(filteredTrials, e);
		filteredTrials.trials.forEach((t) => {
			if ((t.data.latitude != undefined || t.data.longitude != undefined) &&
				isPointWithinRadius(
					{ latitude: t.data.latitude, longitude: t.data.longitude },
					{ latitude: lat, longitude: lng },
					radius
				)
			) {
				if (
					yearFilter &&
					(yearFilter.length === 0 ||
						(yearFilter.length > 0 &&
							yearFilter[0][1].filt_value === t.data.year))
				) {
					let trial_color = '';
					if (this.getIcon(t.data.year, t.data.trial_type, t.harvest_method).includes('blue')) {
						trial_color = '#02a7f0'
					} else if (this.getIcon(t.data.year, t.data.trial_type, t.harvest_method).includes('orange')) {
						trial_color = '#ffa500'
					} else if (this.getIcon(t.data.year, t.data.trial_type, t.harvest_method).includes('red')) {
						trial_color = '#fe4156'
					}
					if (trial_count === 1) {
						content.push(
							<div class="map-info-container"> 
								<div class="map-info-container-top" style={{backgroundColor: trial_color}}></div> 
								<div class="map-info-container-content"> 
									<div class="text"> 
										<p>{`${t.data.city}, ${t.data.province}`}</p> 
										<p>{t.data.trial_type}</p>
									</div> 
									<div class="button"> 
										<span
											className='infoWindowBtn'
											onClick={() => {
												this.props.clearIndividualTrial();
												this.props.page_router.loadPage(
													'individualtrial',
													{ trialCode: t.data.trial }
												);
												this.generateTracker(
													t.data.trial,
													'Click',
													'View Button - Maps'
												);
											}}
										>
											<a
											data-tealium-category="map-info-window-button" 
											data-tealium-action="click" 
											data-tealium-value= {t.data.trial_type+"||"+t.data.trial}
											data-tealium-type="action"
											>
												<FontAwesomeIcon className="chevron-right-icon" icon={faChevronCircleRight}/>
											</a>
										</span>
									</div> 
								</div> 
							</div>
						);
					} else {
						content.push(
							<div class="map-info-container multi"> 
								<div class="map-info-container-left" style={{backgroundColor: trial_color}}></div> 
								<div class="map-info-container-content multi-trials"> 
									<div class="text"> 
										<p>{`${t.data.city}, ${t.data.province}`}</p> 
										<p>{t.data.trial_type}</p>
									</div> 
									<div class="button"> 
										<span
											className='infoWindowBtn'
											onClick={() => {
												this.props.clearIndividualTrial();
												this.props.page_router.loadPage(
													'individualtrial',
													{ trialCode: t.data.trial }
												);
												this.generateTracker(
													t.data.trial,
													'Click',
													'View Button - Maps'
												);
											}}
										>
											<a
											data-tealium-category="map-info-window-button" 
											data-tealium-action="click" 
											data-tealium-value= {t.data.trial_type+"||"+t.data.trial}
											data-tealium-type="action"
											>
												<FontAwesomeIcon className="chevron-right-icon" icon={faChevronCircleRight}/>
											</a>
										</span>
									</div> 
								</div> 
							</div>
						);
					}

				}
			}
		});
		let infoWindow = e.minClusterSize_
			? this.createInfoWindow({ lat: lat, lng: lng }, content, 0, true)
			: this.createInfoWindow({ lat: lat, lng: lng }, content, 0);
		this.setState({
			infoWindow: infoWindow,
			infoWindowContent: content,
			infoWindowIndex: 0,
			infoWindowPosition: { lat: lat, lng: lng },
			mapCenter: { lat: lat, lng: lng },
		}, () => {
			(() => this.googleAPIHandleBounds())();
		});

		//	this.setState({ sinfoWindow: infoWindow, infoWindowContent: content, infoWindowIndex: 0, infoWindowPosition: { lat: lat, lng: lng } });
	};

	// creates infoWindow component, with pager if needed
	createInfoWindow = (position, content, contentIndex, cluster = false) => {
		return (
			<MapInfoWindow
				position={position}
				onCloseClick={this.closeInfoWindow}
				defaultZIndex={cluster ? 5 : 6}
				content={content}
				contentIndex={contentIndex}
				closeInfoWindow={this.closeInfoWindow}
				mapZoom={this._map.getZoom()}
				adjustIndex={this.adjustIndex}
			/>
		);
	};

	// needed so that new infoWindows can open
	closeInfoWindow = () => {
		this.setState({ infoWindow: null });
	};

	// adjusts infoWindow offset on zoomchange
	adjustOffset = () => {
		if (this.state.infoWindow) {
			let zIndex = this.state.infoWindow.props.defaultZIndex;
			this.setState({
				infoWindow: this.createInfoWindow(
					{
						lat: this.state.infoWindowPosition.lat,
						lng: this.state.infoWindowPosition.lng,
					},
					this.state.infoWindowContent,
					this.state.infoWindowIndex,
					zIndex === 5 ? true : false
				),
				mapZoom: this._map.getZoom(),
			});
		} else {
			this.setState({ mapZoom: this._map.getZoom() });
		}


		this.googleAPIHandleBounds()
	};

	adjustIndex = (index) => {
		this.setState({ infoWindowIndex: index });
	};

	getNearestCity = (setCityFunc) => {
		const lat = this.state.currentLatLng.lat;
		const long = this.state.currentLatLng.lng;

		const geocoder = new google.maps.Geocoder();

		geocoder.geocode(
			{
				location: {
					lat: this.state.currentLatLng.lat,
					lng: this.state.currentLatLng.lng,
				},
			},
			function (results, status) {
				if (status === 'OK') {
					if (results[0]) {
						//console.log("Always firing",results[0] )
						// results[0].address_components.forEach((a) => {
						// 	if (a.types.includes('locality')) {
						// 		setCityFunc({ city: a.long_name, lat, long });
						// 	}
						// else {
						// 	setCityFunc({ city: "your selected location", lat, long });
						// }
						// });
						let locationFound = false;
						for (
							let i = 0;
							i < results[0].address_components.length &&
							!locationFound;
							i++
						) {
							if (
								results[0].address_components[i].types.includes(
									'locality'
								)
							) {
								setCityFunc({
									city: results[0].address_components[i]
										.long_name,
									lat,
									long,
								});
								locationFound = true;
							}
						}
						if (!locationFound) {
							setCityFunc({
								city: 'your selected area',
								lat,
								long,
							});
						}
					} else {
						console.log('No results found');
					}
				} else {
					console.log('Geocoder failed due to: ' + status);
				}
			}
		);
		this.generateselectedTrialMarkers();
	};

	//Range
	// updateRange = (range) => {
	// 	this.setState({
	// 		range: parseInt(range)
	// 	})
	// 	this.showCurrentLocation()
	// }

	updateZoom = (mapZoom) => {
		this.setState({mapZoom: parseInt(mapZoom)});
	};

	render() {
		return (
			<Fragment>
				<this.CMap
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div className='mainMapContainer' />}
					mapElement={<div style={{ height: `100%` }} />}
				>
					<MarkerClusterer
						ref={(rec) => (this.markerCluster = rec)}
						averageCenter
						enableRetinaIcons
						// gridSize={5}
						gridSize={20}
						defaultMaxZoom={9}
						defaultZoomOnClick={false}
						//onClick={this.getMarkerContent}
						onClick={(markerClusterer) => {  
							const clickedMarkers = markerClusterer.getMarkers();
							const Lat = clickedMarkers[0].position.lat();
							const Long = clickedMarkers[0].position.lng();
							if(this.state.mapZoom >= 8){
								this.getMarkerContent(markerClusterer)
							} else {
							this.setState({
								mapZoom: this.state.mapZoom + 1.5,
								mapCenter: { lat: Lat, lng: Long },
							}, () => {
								(() => this.googleAPIHandleBounds())();
							});
							}
						}}
						defaultStyles={[
							{
								textColor: 'white',
								fontSize: '12px',
								fontWeight: '900',
								url: greenCluster,
								height: 50,
								width: 50,
								anchorText: [-16, -14],
							},
							{
								textColor: 'white',
								fontSize: '12px',
								fontWeight: '900',
								url: greyCluster,
								height: 50,
								width: 50,
								anchorText: [-16, -14],
							},
						]}
						defaultCalculator={this.calc}
					>
						{this.props.allTrials ? (
							<div className='trial-markers'>
								{this.generateMarkers()}
							</div>
						) : null}
					</MarkerClusterer>

					{/*this.state.rectangle ? (
						<React.Fragment>{this.state.rectangle}</React.Fragment>
					) : null*/}

					{/*this.state.polygon ? (
						<React.Fragment>{this.state.polygon}</React.Fragment>
					) : null*/}

					{this.state.infoWindow}

					{/* {this.props.location.location.lat ?
						<Marker
							icon={mainMarker}
							onPositionChanged={this.locationChanged}
							position={{
								lat: this.state.currentLatLng.lat,
								lng: this.state.currentLatLng.lng,
							}}
						/>
						:
						null
					} */}

				</this.CMap>


				<div className='dbar'>
					<DistanceBar
						updateZoom={this.updateZoom}
						currentRange={this.state.range}
					/>
				</div>

				{/* <React.Fragment>
							<div className='distanceDisplay'>
							<h5>Distance (KM)</h5>
							<p>{`X: ${this.state.distance.X}, Y: ${this.state.distance.Y}`}</p>
							</div>
						</React.Fragment>
				*/}

				<div
					className='helpBox'
					data-tealium-category="popup"
					data-tealium-action="click toggle-help-box"
					data-tealium-value="help-box"
					data-tealium-type="popup"
				>
					 {/* <Help />  */}
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	trials: getVisibleTrials(state.trials),
	allTrials: state.trials,
	trialFilters: state.trials.trial_filters,
	location: state.location,
	mapZoom: state.trials.mapZoom,
});

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{ ...trialsActionCreators, ...localeActionCreators },
		dispatch
	)
)(Map);
