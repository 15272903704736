import React, { Component } from 'react'
import { nodeserver_url_images } from '../../constants/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { trialsActionCreators } from '../../actions/trialsAction';
import { localeActionCreators } from '../../actions/locationAction';
import { comparisonActionCreators } from '../../actions/comparisonAction';
import { getVisibleTrials, getFilteredTrials } from '../../selectors/trialsSelector';
import { getAllComparisons, getAllComparisonMetadata } from '../../selectors/comparisonsSelector';
import Filter from "../../utils/filter";
import TalkToUs from '../../components/TalkToUs/TalkToUs';
import Footer from '../../components/Footer/Footer';
import Comparison from '../../components/Comparison/Comparison';
import './TrialSummaryPage.scss';
import { getPURL } from '../../selectors/trialsSelector';
import { nodeserver_url } from '../../constants/api';
import { getTrialsCategory } from '../../selectors/trialsSelector';
const TrialsCategory = getTrialsCategory();

let backArrow = `${nodeserver_url_images}/trial_results/images/trials/backarrow.png`;

class TrialSummaryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_comparisons: false,
            comparisons: [],
            summaryResults: [],
            PURL: getPURL(),
            metadata: {
                technologies: [],
                hybrids_arr: [],
                competitors_arr: [],
                year: 2019
            }
        }
    }

    componentDidMount = () => {

        if (this.props.clearPURL) {
            this.setState({
                ...this.state,
                PURL: null
            }, () => { })
        }

        //Marketing Automation
        const PURL = this.state.PURL;
        console.log('PURL', PURL)

        async function trialsumResults() {
            let apiBaseUrl = `${nodeserver_url}`;
            const reponse = await fetch(apiBaseUrl + `/trial_results/api/sql/getSummaryInfo?PURL=${PURL}`)
            const results = await reponse.json();
            return results
        }

        if (!this.props.clearPURL && this.state.PURL) {
            trialsumResults()
                .then(results => {

                    this.setState({
                        summaryResults: results
                    })

                    let data = this.state.summaryResults.data;
                    let metadata = {
                        technologies: data.technologies,
                        hybrids_arr: data.hybrids_arr,
                        competitors_arr: data.competitors_arr,
                        year: '2019'
                    }
                    this.setIndividualComparisonDataPURL(this.state.summaryResults.data.trials, metadata);
                });
        }
        else {
            this.setState({ 
                comparisons: this.props.comparisons, 
                metadata: this.props.metadata
            });
        }

    }


    getSurroundingLocation = () => {
        return this.props.location.nearestCity;
    }

    getSwathTypes = (trials) => {
        let swathTypesList = [];
        let swathTypesString = "";

        trials.map(trial => {
            trial.hybrids.sort((a, b) => (a.swath_type < b.swath_type) ? -1 : (a.swath_type > b.swath_type) ? 1 : 0).forEach(h => {
                if (!swathTypesList.includes(h.swath_type)) {
                    swathTypesString += swathTypesList.length ? " & " : "";
                    swathTypesString += h.swath_type + " (" + h.swath_type_abbr + ")";
                    swathTypesList.push(h.swath_type);
                }
            })
            return null;
        })

        return swathTypesString;
    }

    setIndividualComparisonDataPURL = (trials, metadata) => {
        let comparisons = [];
        let hybridsArr = metadata.hybrids_arr, competitorsArr = metadata.competitors_arr;
        let year = '2019';
        let count = -1;

        for (let i in hybridsArr) {
            count += 1;
            let filtered_trials = trials.filter(t => {
                let includesBASF = false;
                let includesComp = false;

                t.hybrids.forEach(h => {
                    if (h.hybrid === hybridsArr[i])
                        includesBASF = true;
                    if (h.hybrid === competitorsArr[i])
                        includesComp = true;
                })
                return includesBASF && includesComp;

            });
            comparisons.push({ hybrid: { name: hybridsArr[i] }, competitor: { name: competitorsArr[i] } });
            Object.assign(comparisons[count], { trials: filtered_trials, filters: new Filter(), year: year, swathtype: this.getSwathTypes([...trials]) });
            Object.assign(comparisons[count].hybrid, this.getComparisonHybridAveragesPURL(filtered_trials, hybridsArr[i]))
            Object.assign(comparisons[count].competitor, this.getComparisonHybridAveragesPURL(filtered_trials, competitorsArr[i]))

        }

        comparisons = comparisons.filter(c => {
            if (c.hybrid.net_yield.sum > 0 && c.competitor.net_yield.sum > 0) return true;
        })

        this.setState({ comparisons: comparisons }, () => this.props.doSetComparisons(comparisons));
        if (comparisons.length > 0) { this.setState({ show_comparisons: true }); }

        let new_hybrids_arr = [];
        let new_competitors_arr = [];

        comparisons.forEach(c => {
            new_hybrids_arr.push(c.hybrid.name);
            new_competitors_arr.push(c.competitor.name)
        })

        metadata.hybrids_arr = [...new Set(new_hybrids_arr)];
        metadata.competitors_arr = [...new Set(new_competitors_arr)];
        this.setState({ metadata: Object.assign(this.state.metadata, metadata) });
        this.props.doSetComparisonMetadata([this.state.metadata]);

    }

    getComparisonHybridAveragesPURL = (trials, comparisonHybridName) => {

        let result = {
            harvest: { sum: 0, count: 0 },
            maturity: { sum: 0, count: 0 },
            net_yield: { sum: 0, count: 0 },
            locations: []
        }
        trials.forEach(trial => {

            if (comparisonHybridName.indexOf('InVigor') > -1) result.locations.push(trial.data.city + ', ' + trial.data.province + " ");

            trial.hybrids.forEach(hybrid => {
                if (hybrid.hybrid === comparisonHybridName) {

                    if (hybrid.harvest) {
                        result.harvest.sum += hybrid.harvest;
                        result.harvest.count += 1;
                    }
                    if (hybrid.maturity) {
                        result.maturity.sum += hybrid.maturity;
                        result.maturity.count += 1;
                    }
                    if (hybrid.net_yield) {
                        result.net_yield.sum += hybrid.net_yield;
                        result.net_yield.count += 1;
                    }
                }
            })
        });
        return result;
    }
    getComparisonHybridAverages = (trials, comparisonHybridName) => {
        // let result = {
        //     harvest: 0,
        //     maturity: 0,
        //     net_yield: 0,
        //     count: 0,
        //     locations: []
        // };

        let result = {
            harvest: { sum: 0, count: 0 },
            maturity: { sum: 0, count: 0 },
            net_yield: { sum: 0, count: 0 },
            locations: []
        }
        let technologies = [...this.state.metadata.technologies];

        trials.map(trial => {

            if (comparisonHybridName.indexOf('InVigor') > -1) result.locations.push(trial.data.city + ', ' + trial.data.province + " ");

            trial.hybrids.map(hybrid => {
                if (hybrid.hybrid === comparisonHybridName) {
                    if ((typeof hybrid.seed_treatment !== 'undefined' ? hybrid.seed_treatment : '') !== '') {
                        if (hybrid.seed_treatment.indexOf('+') > -1) {
                            hybrid.seed_treatment.split(" + ").map((v, i) => {
                                if (technologies.indexOf(v) === -1) {
                                    technologies.push(v)
                                }
                                return null;
                            })
                        } else {
                            if (technologies.indexOf(hybrid.seed_treatment) === -1) {
                                technologies.push(hybrid.seed_treatment)
                            }
                        }

                        this.setState({ metadata: Object.assign(this.state.metadata, { technologies: technologies }) });
                    }

                    // sum up only if neight value is N/A
                    // if (hybrid.harvest && hybrid.maturity && hybrid.net_yield) {
                    // console.log('Yield ' + comparisonHybridName + ' : ' + hybrid.harvest)
                    // console.log('Maturity ' + comparisonHybridName + ' : ' + hybrid.maturity)
                    // console.log('Harvest ' + comparisonHybridName + ' : ' + hybrid.net_yield)
                    if (hybrid.harvest) {
                        result.harvest.sum += hybrid.harvest;
                        result.harvest.count += 1;
                    }
                    if (hybrid.maturity) {
                        result.maturity.sum += hybrid.maturity;
                        result.maturity.count += 1;
                    }
                    if (hybrid.net_yield) {
                        result.net_yield.sum += hybrid.net_yield;
                        result.net_yield.count += 1;
                    }
                    // }
                }
                return null;
            })
            return null;
        })

        return result;
    }

    //APT
    getComparisonTreatmentAverages = (trials, comparisonTreatmentName) => {
        let result = {
            yield: 0,
            count: 0,
            locations: []
        };
        //let products = [...this.state.metadata.products];


        trials.map(trial => {

            trial.treatments.map(treatment => {
                if (treatment.treatment === comparisonTreatmentName) {
                    // sum up only if neight value is N/A
                    if (treatment.yield) {
                        // console.log('Yield ' + comparisonHybridName + ' : ' + hybrid.harvest)
                        // console.log('Maturity ' + comparisonHybridName + ' : ' + hybrid.maturity)
                        // console.log('Harvest ' + comparisonHybridName + ' : ' + hybrid.net_yield)
                        // result.harvest += hybrid.harvest;
                        //result.maturity += hybrid.maturity;
                        result.yield += treatment.yield;
                        result.count += 1;
                    }
                }
                if (treatment.is_basf > 0) result.locations.push(trial.data.city + ', ' + trial.data.province + " ");

                return null;
            })
            return null;
        })

        return result;
    }

    toggleComparisonYearHandler = (event, comparisonId) => {
        let value = event.target.getAttribute('value');
        let comparisonTrials = [];
        let comparisons = this.state.comparisons; //remaintains the reference to the redux state comparisons

        comparisons.map((comparison, index) => {
            if (comparisonId === index) {
                if (value === 'All') {
                    comparison.filters.remove_filter(this.state.comparisons[index].year);
                } else {
                    comparison.filters.remove_filter(this.state.comparisons[index].year);
                    comparison.filters.add_filter(parseInt(value), 'year', parseInt(value));
                }
                comparisons[index].year = value;

                comparisonTrials = getFilteredTrials(this.props.allTrials, comparison.filters).trials;
                comparisons[index].trials = comparisonTrials;

                if (TrialsCategory === 'DST') {
                    Object.assign(comparisons[index].hybrid, this.getComparisonHybridAverages(comparisonTrials, comparison.hybrid.name))
                    Object.assign(comparisons[index].competitor, this.getComparisonHybridAverages(comparisonTrials, comparison.competitor.name))
                } else {
                    Object.assign(comparisons[index].treatment, this.getComparisonTreatmentAverages(comparisonTrials, comparison.treatment.name))
                    Object.assign(comparisons[index].competitor, this.getComparisonTreatmentAverages(comparisonTrials, comparison.competitor.name))
                }

            }
            return null;
        });

        this.setState({ comparisons: comparisons }, () => this.props.doSetComparisons(comparisons));
        this.setState({ show_comparisons: (comparisons.length > 0 ? true : false) });
    }

    getTitle = () => {
        let header = `Trial Summary Results for InVigor`;
        if (this.state.metadata.technologies.length > 1) {
            this.state.metadata.technologies.map((technology, i) => {
                header += (i === this.state.metadata.technologies.length - 2 ? technology + ' and ' : (i === this.state.metadata.technologies.length - 1 ? technology : technology + ','));
                return null;
            })
        } else {
            header += this.state.metadata.technologies;
        }
        return header;
    }

    getTitleAPT = () => {
        let header = 'Trial Summary Results for ';
        if (this.state.metadata.treatments_arr.length > 1) {
            this.state.metadata.treatments_arr.map((treatment, i) => {
                header += (i === this.state.metadata.treatments_arr.length - 2 ? treatment + ' and ' : (i === this.state.metadata.treatments_arr.length - 1 ? treatment : treatment + ','));
                return null;
            })
        } else {
            header += this.state.metadata.treatments_arr;
        }
        return header;
    }

    getTrialSummaryHeader = () => {
        return (
            TrialsCategory === 'DST' ? this.state.comparisons.length > 0 ?
                <div className="headerContainer">
                    <div className="headerTitle">{this.getTitle()}</div>
                    <div className="headerDetails">
                        <div className="row">
                            <div className="cell one"><span>Location Surrounding: </span>{this.state.PURL ? this.state.summaryResults.data.nearest_city : this.getSurroundingLocation()}</div>
                            <div className="cell two"><span>Hybrid: </span>{this.state.metadata.hybrids_arr.join(', ')}</div>
                        </div>
                        <div className="row">
                            <div className="cell one"><span>Year: </span>{this.state.metadata.year}</div>
                            <div className="cell two" dangerouslySetInnerHTML={{ __html: `<span>Comparison: </span> ${this.state.metadata.competitors_arr.join(', ')}` }}></div>
                        </div>
                    </div>
                </div>
                : null
                :

                this.state.comparisons.length > 0 ?
                    <div className="headerContainer">
                        <div className="headerTitle">{this.getTitleAPT()}</div>
                        <div className="headerDetails">
                            <div className="row">
                                <div className="cell one"><span>Location Surrounding: </span>{this.state.PURL ? this.state.summaryResults.data.nearest_city : this.getSurroundingLocation()}</div>
                                <div className="cell two"><span>Treatment: </span>{this.state.metadata.treatments_arr.join(', ')}</div>
                            </div>
                            <div className="row">
                                <div className="cell one"><span>Year: </span>{this.state.metadata.year}</div>
                                <div className="cell two"><span>Comparison: </span>{this.state.metadata.competitors_arr.join(', ')}</div>
                            </div>
                        </div>
                    </div>
                    : null
        )
    }


    getTrialSummaryComparisons = () => {
        return (
            this.state.comparisons.length > 0 ?
                <div className="comparisonsContainer">
                    {this.state.comparisons.map((comparison, index) => {
                        if(comparison.trials.length >= 3) {
                            comparison.trials = comparison.trials
                        return (
                        <Comparison
                            key={`comparison-${index}`}
                            comparisonId={index}
                            comparison={comparison}
                            page_router={this.props.page_router}
                            yearchanged={(event) => this.toggleComparisonYearHandler(event, index)} />
                        )}
                })}
                </div>
                : null
        )
    }

    render() {
        return (
            <div className="trialSummaryPageContainer">
                <div className={"chartTabsNavBar"}>
                    <div className="trialNavContainer"></div>
                    <div 
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                PURL: null
                            }, () => this.props.page_router.loadPage('home', null, true))
                        }} 
                        className="chartTabsNavItem"
                        data-tealium-category="link" 
                        data-tealium-action="click individual-trial nav-bbar" 
                        data-tealium-value="Back to Map" 
                        data-tealium-type="navigation" 
                    >
                        <img src={backArrow} style={{ verticalAlign: "middle" }} alt="Back to Map"/> Back to Map
                    </div>
                </div>

                {this.getTrialSummaryHeader()}
                {this.getTrialSummaryComparisons()}
                {/* {this.getAutomatedComparisons()} */}

                <TalkToUs />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        location: state.location,
        trials: JSON.parse(JSON.stringify(getVisibleTrials(state.trials))),
        trialFilters: JSON.parse(JSON.stringify(state.trials.trial_filters)),
        allTrials: JSON.parse(JSON.stringify(state.trials.all_trials)),
        comparisons: getAllComparisons(state),
        metadata: getAllComparisonMetadata(state)
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({ ...trialsActionCreators, ...comparisonActionCreators, ...localeActionCreators }, dispatch)
)(TrialSummaryPage);