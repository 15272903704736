import React, { Component } from 'react';
import './Accordion.css';
import 'font-awesome/css/font-awesome.min.css';
import { getTrialsCategory } from '../../selectors/trialsSelector';

const TrialsCategory = getTrialsCategory();

export default class Accordion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accContent1: true,
			accContent2: false,
			accContent3: false,
			arrowed: true,
		};
	}
	render() {
		return (
			<div className='accordionContainer'>
				<div className='contentAcc'>

					<div className='contentAccparent'>
						<div
							className='contentTitle'
							onClick={() =>
								this.setState({
									accContent2: !this.state.accContent2,
								})
							}
						>
							<h3>Using Filters</h3>
							<div className='arrow'>
								{!this.state.accContent2 ? (
									<i
										className='fa fa-chevron-down'
										aria-hidden='true'
									></i>
								) : (
									<i
										className='fa fa-chevron-up'
										aria-hidden='true'
									></i>
								)}
							</div>
						</div>

						<div className='contentText'>
							{!this.state.accContent2 ? null : TrialsCategory ===
							  'DST' ? (
								<React.Fragment>
									<p>
										You can also search for results by using
										the following filters:
									</p>
									<p style={{ margin: '-15px 0px 0px 15px' }}>
										1. Trial Type (Demonstration Strip Trials, Agronomic Excellence Trials)
										<br />
										2. Specific year 
										<br />
										3. Technology used – Pod Shatter and/or clubroot resistant traits{' '}
										<br />
										4. InVigor hybrid(s) (Mandatory)
										<br />
										5. Competitive hybrid(s)
										<br />
									</p>
								</React.Fragment>
							) : (
								<React.Fragment>
									<p>
										You can also search for results by using
										the following filters:
									</p>
									<p style={{ margin: '-15px 0px 0px 15px' }}>
										1. Specific year
										<br />
										2. Product Type (Fungicide,
										Inoculant, Seed Treatment)
										<br />
										3. Crop Type 
										<br />
										4. Trial Name
										<br />
										5. Treatment/Product
										<br />
										6. Competitive Treatment/Product
										<br />
									</p>
								</React.Fragment>
							)}
						</div>
					</div>

					<div className='contentAccparent'>
						<div
							className='contentTitle'
							onClick={() =>
								this.setState({
									accContent3: !this.state.accContent3,
								})
							}
						>
							<h3>Trial Summary</h3>
							<div className='arrow'>
								{!this.state.accContent3 ? (
									<i
										className='fa fa-chevron-down'
										aria-hidden='true'
									></i>
								) : (
									<i
										className='fa fa-chevron-up'
										aria-hidden='true'
									></i>
								)}
							</div>
						</div>

						<div className='contentText'>
							{!this.state.accContent3 ? null : TrialsCategory ===
							  'DST' ? (
								<React.Fragment>
									<p>To Generate a Trial Summary:</p>
									<ul>
										<li>
											A minimum of 3 individual DST or Agronomic Excellence trials are required to generate a Trial Summary.
										</li>
										<li>
											When selecting filters, if you would like to see a trial summary it is necessary to select a competitive hybrid and either 
											DST or Agronomic Excellence as the Trial Type in the filters.
										</li>
									</ul>
									<p>
										Please note: Trial Summaries are generated separately for DST or Agronomic Excellence trials.  DST and Agronomic Excellence are different programs.  Each trial type uses similar but slightly different methodologies including field execution. 
									</p>
								</React.Fragment>
							) : (
								<React.Fragment>
									<p>To Generate a Trial Summary:</p>
									<ul>
										<li>
											A minimum of 3 individual APT trials
											are required to generate a Trial
											Summary.
										</li>
										<li>
											When selecting filters, if you would
											like to see a trial summary it is
											necessary to select a competitive
											treatment in the filters.
										</li>
									</ul>
								</React.Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
