import { setLocation, setNearestCity, setMarkerCoordinates } from '../actions/locationAction';

const initialState = {
    location: {
    	lat: null,
    	lng: null,
        nearestCity: 'Saskatoon',
        //nearestCity: 'Winnipeg', was Winnipeg now changed to Winnipeg ---- DPS-361
        markercoordinates:[]
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case setLocation:
            return { ...state, location: {lat: action.payload.lat, lng: action.payload.lng}};
        case setNearestCity:
            return { ...state, nearestCity: action.payload.city, lat: action.payload.lat, long:action.payload.long  };
        case setMarkerCoordinates:
        	return { ...state, markercoordinates: action.payload  };
        default:
            return state;
    }
};