import React from 'react';

const FilterTrialTypes = props => {
    return (      
        <div onClick={props.onclick} 
            data-action={props.action} 
            data-label={props.label} 
            data-value={props.value} 
            className= {props.selected ? 'yearSelect active' : 'yearSelect'}
        >
            {props.value}
        </div>
    ); 
}

export default FilterTrialTypes;