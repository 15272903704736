import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { comparisonActionCreators } from '../../../../actions/comparisonAction';
import { getAllComparisons } from '../../../../selectors/comparisonsSelector';
import './TrialSummaryChart.scss';
import { getTrialsCategory } from '../../../../selectors/trialsSelector'

const TrialsCategory = getTrialsCategory();

class TrialSummaryChart extends Component {

    componentDidUpdate(prevProps) {
        if (TrialsCategory === 'DST') {
            if (prevProps.avgHybridAmount !== this.props.avgHybridAmount && prevProps.avgComparisonAmount !== this.props.avgComparisonAmount) {
            }
        } else {
            if (prevProps.avgTreatmentAmount !== this.props.avgTreatmentAmount && prevProps.avgComparisonAmount !== this.props.avgComparisonAmount) {
            }
        }
    }

    getGaugeUnitNumberStartPoint = (maxAvgAmount) => {
        let i = 8;
        while (0 < i) {
            if (Math.round(maxAvgAmount) - i >= 0) return Math.round(maxAvgAmount) - i;
            i -= 2;
        }
    }

    getSurroundingLocation = () => {
        return this.props.location.nearestCity;
    }

    getComparison = (tag) => {

        let numOfTrials = this.props.comparison.trials.length;
        let label, tabTitle, tabSubTitle;
        let comparisonBarColor = "lightgrey";
        let avgHybridAmount = 0, avgComparisonAmount = 0, avgAmountDiffence = 0, maxAvgAmount = 0, avgTreatmentAmount = 0;
        let gaugeUnitNumberStartPoint = 0, numOfHybridGaugeUnits = 0, numOfComparisonGaugeUnits = 0, hybridBarWidth = 0, comparisonBarWidth = 0, numOfTreatmentGaugeUnits = 0, TreatmentBarWidth = 0;
        let gaugeUnitWidth = 100 / 6;

        let unit = "net_yield";
        let unit1 = "yield";

        if (TrialsCategory === 'DST') {
            if (numOfTrials > 0) {
                if (this.props.comparison.hybrid[unit].sum > 0 && this.props.comparison.competitor[unit].sum > 0) {
                    avgHybridAmount = Number(Math.round(this.props.comparison.hybrid[unit].sum / this.props.comparison.hybrid[unit].count + 'e2') + 'e-2');
                    avgComparisonAmount = Number(Math.round(this.props.comparison.competitor[unit].sum / this.props.comparison.competitor[unit].count + 'e2') + 'e-2');
                    avgAmountDiffence = Number(Math.round((avgHybridAmount > avgComparisonAmount ? avgHybridAmount - avgComparisonAmount : avgComparisonAmount - avgHybridAmount) + 'e2') + 'e-2');
                    maxAvgAmount = Math.max(avgHybridAmount, avgComparisonAmount);

                    gaugeUnitNumberStartPoint = this.getGaugeUnitNumberStartPoint(maxAvgAmount);
                    numOfHybridGaugeUnits = (avgHybridAmount - gaugeUnitNumberStartPoint) / 2;
                    numOfComparisonGaugeUnits = (avgComparisonAmount - gaugeUnitNumberStartPoint) / 2;
                    hybridBarWidth = numOfHybridGaugeUnits * gaugeUnitWidth;
                    comparisonBarWidth = numOfComparisonGaugeUnits * gaugeUnitWidth;
                }
            }
        } else {
            if (numOfTrials > 0) {
                if (this.props.comparison.treatment[unit1] > 0 && this.props.comparison.competitor[unit1] > 0) {
                    avgTreatmentAmount = Number(Math.round(this.props.comparison.treatment[unit1] / this.props.comparison.treatment.count + 'e2') + 'e-2');
                    avgComparisonAmount = Number(Math.round(this.props.comparison.competitor[unit1] / this.props.comparison.competitor.count + 'e2') + 'e-2');
                    avgAmountDiffence = Number(Math.round((avgTreatmentAmount > avgComparisonAmount ? avgTreatmentAmount - avgComparisonAmount : avgComparisonAmount - avgTreatmentAmount) + 'e2') + 'e-2');
                    maxAvgAmount = Math.max(avgTreatmentAmount, avgComparisonAmount);

                    gaugeUnitNumberStartPoint = this.getGaugeUnitNumberStartPoint(maxAvgAmount);
                    numOfTreatmentGaugeUnits = (avgTreatmentAmount - gaugeUnitNumberStartPoint) / 2;
                    numOfComparisonGaugeUnits = (avgComparisonAmount - gaugeUnitNumberStartPoint) / 2;
                    TreatmentBarWidth = numOfTreatmentGaugeUnits * gaugeUnitWidth;
                    comparisonBarWidth = numOfComparisonGaugeUnits * gaugeUnitWidth;
                }
            }
        }
        return (
            <React.Fragment>
                {this.props.trialFilters.data.filters.Herbicide || this.props.trialFilters.data.filters["Facet L"] || this.props.trialFilters.data.filters["Zidua SC"] ? " " :
                    <div className="comparisonContainer" comparisonid={this.props.comparisonId} key={this.props.comparisonId} >

                        <div className="comparisonHeader appppp">
                        {TrialsCategory === 'DST' ? <div className="comparisonTitle" dangerouslySetInnerHTML={{ __html: `${this.props.comparison.hybrid.name}  vs  ${this.props.comparison.competitor.name}` }}></div> :
                        <div className="comparisonTitle">{this.props.comparison.treatment.name + ' vs ' + this.props.comparison.competitor.name}</div>}
                        </div>

                        

                        <div className="comparisonContent">
                            <div className="comparisonTabsContainer" label={label} number="">

                                <div className="comparisonChartContainer">
                                    <div className="title">{tabTitle}</div>
                                    <div className="subTitle">{tabSubTitle}</div>
                                    <div className="chartContainer">
                                        <React.Fragment>
                                            <div className="chartItemContainer">
                                                <div className="barContainer">
                                                    {TrialsCategory === 'DST' ? <div className="itemText" dangerouslySetInnerHTML={{ __html: this.props.comparison.hybrid.name }}></div> : <div className="itemText">{this.props.comparison.treatment.displayed_name}</div>}
                                                    <div className="itemBarContainer">

                                                        <div className="itemBar" style={TrialsCategory === 'DST' ? { width: `${(avgHybridAmount / maxAvgAmount) * 100}%`, background: `#0061a1` } : { width: `${(avgTreatmentAmount / maxAvgAmount) * 100}%`, background: `#65ac1e` }} >
                                                            {TrialsCategory === 'DST' ? avgHybridAmount.toFixed(1) : avgTreatmentAmount.toFixed(1)}</div>
                                                    </div>
                                                </div>
                                                <div className="barContainer">
                                                    <div className="itemText" dangerouslySetInnerHTML={{ __html: this.props.comparison.competitor.name }}></div>
                                                    <div className="itemBarContainer">
                                                        <div className="itemBar" style={{ width: `${(avgComparisonAmount / maxAvgAmount) * 100}%`, background: `lightgrey` }} >{avgComparisonAmount.toFixed(1)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>

        );
    }

    render() {
        return this.getComparison();
    }
}

const mapStateToProps = (state) => {
    return {
        comparisons: getAllComparisons(state),
        trialFilters: JSON.parse(JSON.stringify(state.trials.trial_filters)),
        location: state.location
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(comparisonActionCreators, dispatch)
)(TrialSummaryChart);