import React from 'react';
import { nodeserver_url_images } from '../../constants/api';
import { getTrialsCategory } from '../../selectors/trialsSelector';
import './ChartTabs.scss';

const TrialsCategory = getTrialsCategory();

let prevDisabled = `${nodeserver_url_images}/trial_results/images/individualTrial/previous.png`
let prevEnabled = `${nodeserver_url_images}/trial_results/images/individualTrial/previous1.png`
let nextDisabled = `${nodeserver_url_images}/trial_results/images/individualTrial/next.png`
let nextEnabled = `${nodeserver_url_images}/trial_results/images/individualTrial/next1.png`
let listStations;

let getChartName = selectedChart => {
    switch (selectedChart) {
        case 1:
            return "Yield";
        case 2:
            return "Days to Maturity";
        case 3:
            return "Harvest Rating";
        case 4:
            return "Pod Shatter";
        case 5:
            return "Weather";
        default:
            return "Yield";
    }
}

const ChartTabs = props => {
    let trialHasField = { yield: false, maturity: false, harvest: false, weather: false, podShatter: false };
    // let trialHasField = { yield: false, maturity: false, harvest: false, podShatter: false };
    // let trialHasField = { yield: false, maturity: false, harvest: false};
    let chartTabs = 0;
    if(props.trials != undefined){
    listStations = Object.keys(props.trials.weatherData)}
    
    let isWeatherStation = false;

    if (props.trial) {

        if(listStations){
         listStations.map((station) => {
             if (props.trial.data.weather_station == station) {
                 isWeatherStation = true;
            }
         })}

        if (!props.comparison) {   // Tabs for the Individual Trial Page 
            if (TrialsCategory === 'DST') {
                props.trial.hybrids.forEach(h => {
                    if (h.net_yield) {
                        if (!trialHasField.yield) chartTabs++;
                        trialHasField.yield = true;
                    }
                    if (h.maturity) {
                        if (!trialHasField.maturity) chartTabs++;
                        trialHasField.maturity = true;
                    }
                    if (h.harvest) {
                        if (!trialHasField.harvest) chartTabs++;
                        trialHasField.harvest = true;
                    }

                    if (h.pod_shatter_loss) {
                        if (!trialHasField.podShatter) chartTabs++;
                        trialHasField.podShatter = true;
                    }
                })
                if (props.trial.data.weather_station && isWeatherStation) {
                    chartTabs++;
                    trialHasField.weather = true;
                }
                
            } else {
                //APT Tabs - only has yield
                props.trial.treatments.forEach(t => {
                    if (t.yield) {
                        if (!trialHasField.yield) chartTabs++;
                        trialHasField.yield = true;
                    }

                })
            }

        } else {
            if (TrialsCategory === 'DST') {   // Tabs for the Trial Summary Page
                trialHasField.yield = true;
                trialHasField.maturity = true;
                trialHasField.harvest = true;
                trialHasField.weather = true;
                trialHasField.podShatter = true;
                // chartTabs = 4;
                chartTabs = 5;
                // chartTabs = 3;
            } else {
                //APT Tabs - only has yield
                trialHasField.yield = true;
                chartTabs = 1;

            }
        }
    }
    else if (props.comparison) {
        if (TrialsCategory === 'DST') {

            let hybrid = props.comparison.hybrid;
            let competitor = props.comparison.competitor;

            if (hybrid.net_yield.count && competitor.net_yield.count) {
                if (!trialHasField.yield) chartTabs++;
                trialHasField.yield = true;
            }
            if (hybrid.maturity.count && competitor.maturity.count) {
                if (!trialHasField.maturity) chartTabs++;
                trialHasField.maturity = true;
            }
            if (hybrid.harvest.count && competitor.harvest.count) {
                if (!trialHasField.harvest) chartTabs++;
                trialHasField.harvest = true;
            }
            // if(!props.trial.data.weather_station) {
            //     chartTabs++;
            //     trialHasField.weather = true;
            // }
        }
        else {
            trialHasField.yield = true;
            chartTabs++;
        }
    }

    else {
        //set all defaults to true for comparison page
        trialHasField.yield = true;
        trialHasField.maturity = true;
        trialHasField.harvest = true;
        trialHasField.weather = true;
        trialHasField.podShatter = true;
        // chartTabs = 4;
        chartTabs = 5;
        // chartTabs = 3;
    }
    let selectedChart = props.selectedChart;



    return (
        <React.Fragment>
            <div className={`chartTabsContainer${!props.tabDivider ? " noTabDivider" : ""}`}>
            <div className="chartTabsNavBar">
                {trialHasField.yield ? <div className={selectedChart === 1 ? "chartTabsNavItem desktopChartButton active" : "chartTabsNavItem desktopChartButton"} onClick={() => props.desktopClick(1)} name='yield'>Yield</div> : null}
                {trialHasField.maturity ? <div className={selectedChart === 2 ? "chartTabsNavItem desktopChartButton active" : "chartTabsNavItem desktopChartButton"} onClick={() => props.desktopClick(2)} name='maturity'>Days to Maturity</div> : null}
                {trialHasField.harvest ? <div className={selectedChart === 3 ? "chartTabsNavItem desktopChartButton active" : "chartTabsNavItem desktopChartButton"} onClick={() => props.desktopClick(3)} name='harvest'>Harvest Ratings</div> : null}
                {trialHasField.podShatter ? <div className={selectedChart === 4 ? "chartTabsNavItem desktopChartButton active" : "chartTabsNavItem desktopChartButton"} onClick={() => props.desktopClick(4)} name='pod_shatter_loss'>Pod Shatter</div> : null}
                {trialHasField.weather ? <div className={selectedChart === 5 ? "chartTabsNavItem desktopChartButton active" : "chartTabsNavItem desktopChartButton"} onClick={() => props.desktopClick(5)} name='weather'>Weather</div> : null}
                <img className="sliderButton" src={selectedChart > 1 ? prevEnabled : prevDisabled} onClick={() => props.mobileClick(true)} alt="" />
                <div className="active sliderButton chartTabsNavItem">{getChartName(selectedChart)}</div>
                <img className="sliderButton" src={selectedChart < chartTabs ? nextEnabled : nextDisabled} onClick={() => { if (selectedChart < chartTabs) props.mobileClick(false) }} alt="" />
            </div>
        </div>
        </React.Fragment>

    )
}

export default ChartTabs;