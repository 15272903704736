import React from "react"
import { nodeserver_url_images } from '../../constants/api';
import './TalkToUs.scss'  

let twitterImg = `${nodeserver_url_images}/trial_results/images/common/twitter.png`;
let youTubeImg = `${nodeserver_url_images}/trial_results/images/common/youtube.png`;
let invigorImg = `${nodeserver_url_images}/trial_results/images/common/InVigor_Hybrid_Full_Lineup_Final.jpg`;

const TalkToUs = () => ( 
	<div className="talkContainer">
		<div className="left"> 
			<img src={invigorImg} alt="Invigor"></img>
			<p>See how the BASF canola solutions used above can help take canola from seeding to harvest seamlessly.</p>
			<a href="https://agriculture.basf.ca/west/invigor-hybrid-canola.html" target="_blank"><button >More on BASF canola solutions</button></a>
		</div>
		<div className="half">
			<h2 className="questions">Got any questions?</h2>
			<p className="questions">Call our team of experts at 1-877-371-BASF (2273) to reach <strong>AgSolutions<sup>®</sup></strong> Customer&nbsp;Care.</p>
			<div className="socialItems">
				<a 
					href="https://twitter.com/basfagsolutions" 
					target="_blank" 
					rel="noopener noreferrer"
					data-tealium-category="link" 
					data-tealium-action="click footer-link" 
					data-tealium-value="twitter||https://twitter.com/basfagsolutions" 
					data-tealium-type="twitter-link" 
				>
					<img src={twitterImg} alt="" />
				</a>
				<a 
					href="http://www.youtube.com/user/BASFAgSolutions" 
					target="_blank" 
					rel="noopener noreferrer"
					data-tealium-category="link" 
					data-tealium-action="click footer-link" 
					data-tealium-value="youtube||http://www.youtube.com/user/BASFAgSolutions" 
					data-tealium-type="youtube-link"
				>
					<img src={youTubeImg} alt="" />
				</a>
			</div>
		</div>  

	</div> 
)
 
export default TalkToUs
