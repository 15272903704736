import React, { Component } from 'react';
import { nodeserver_url_images } from '../../constants/api';
import "./TrialPopUp.scss";

let closeImg = `${nodeserver_url_images}/trial_results/images/common/close.png`;

class TrialPopUp extends Component {

    closePopUpTrial = () => {
        document.getElementById("trial_popup").style.display = "none"
    }

    render() {
        return (
            <React.Fragment>
                <div id="trial_popup">
                    <div id="popup_message">
                        <div id="popup_no" onClick={this.closePopUpTrial}>
                            <img src={closeImg} alt="close" />
                        </div>
                        <div id="popup_text-reviewmap">
                            <p>Based on your selected parameters a trial has been excluded from the trial summary results. Please refer to the flagged trial below and click on "See Results" for further details.</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TrialPopUp;