import React, { Component } from 'react';
import './Help.css';
import Accordion from './Accordion';

class Help extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
		};
	}
	handleClick = () => {
		const checked = !this.state.checked;
		this.setState({ checked });
	};

	showPopup = () => {
		if (document.cookie.replace(/(?:(?:^|.*;\s*)doSomethingOnlyOnce\s*\=\s*([^;]*).*$)|^.*$/, "$1") !== "true") {
			document.cookie = "doSomethingOnlyOnce=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
			this.setState({ checked: true });
		};
	}

	render() {
		this.showPopup()
		return (
			<div className='helpContainer'>
				<input
					id='helpClicker'
					type='checkbox'
					checked={this.state.checked}
					// onChange={this.handleClick}
				/>
				<div className='helpLabel'>
				<label
					htmlFor='helpClicker'
					className='clicker'
					onClick={this.handleClick}
				>
					Help 
				</label>
				</div>

				<div className='panel-wrap'>
					<div className='panel'>
						<div className='helpHeader'>
							<h3>Help ?</h3>
							<label
							className='closeBtn'
								htmlFor='helpClicker'
								onClick={this.handleClick}
							>
								Close
							</label>
						</div>

						<p>
						By default, the map will be set to your current location, showing any 2024 trials conducted within 200 square kilometers. If you are located outside of Western Canada, your default location will be Saskatoon.  You can change to another specific location by entering it in the “Search Towns” box at the top right of the page or using the distance bar at the top of the page.
						</p>

						<Accordion />
					</div>
				</div>
			</div>
		);
	}
}

export default Help;
