import React, { Component, useRef } from 'react'
import Moment from 'moment';
import { nodeserver_url, nodeserver_url_images } from '../../constants/api';
import './CanolaLeaderboard.scss'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { trialsActionCreators } from '../../actions/trialsAction';
import { Popup } from "@progress/kendo-react-popup";
import NavBar from '../../components/NavBar/NavBar'
import Hero from '../../components/Hero/Hero'
import TalkToUs from '../../components/TalkToUs/TalkToUs'
import Footer from '../../components/Footer/Footer'

let crHeroImg = `${nodeserver_url_images}/trial_results/images/canolarankings/InVigor_Results_carousel_banner.png`;
let postCardImg = `${nodeserver_url_images}/trial_results/images/common/3168_InVigor_Growing_Zone_PostCard.jpg`

const sortTypes = {
    up: {
      class: 'rank-headerSortUp',
      fn: (a, b) => a.Result_Rank - b.Result_Rank
    },
    down: {
      class: 'rank-headerSortDown',
      fn: (a, b) => b.Result_Rank - a.Result_Rank
    }
  }

class CanolaLeaderboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			results_data: [],
            final_data: [],
            updatedDate: null,
            currentSort: 'up',
            showRankingToolTip: false,
            prevTab : '',
            firstTab : true,
            showPopUp : false
		}
        this.anchorRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
	}
       
    
    componentDidMount() {  
        // Simple GET request using fetch
        fetch(`${nodeserver_url}/trial_results/api/sql/getDSTYieldResults`)
            .then(response => response.json())
            .then(data =>{ this.setState({ results_data: data.results,final_data: data.results.Overall.hybrids, updatedDate: data.UpdatedDate });
            this.seasonCallWithUrl()}
            );
    };

    toggleRankingToolTip = () => {
        //if open, close and remove event listener
        if (this.state.showRankingToolTip) {
            document.removeEventListener('mousedown', this.handleClickOutside);
        } 
        //if closed, open create event listener
        else {
            document.addEventListener('mousedown', this.handleClickOutside);
        }

        this.setState({ showRankingToolTip: !this.state.showRankingToolTip });
	}

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ showRankingToolTip: !this.state.showRankingToolTip });
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }
    growingZoneClick = () => {
        this.setState({ showPopUp: !this.state.showPopUp });
    }

    getdata(event, value) {
        
      if(value == "short"){
        this.setState({ final_data: this.state.results_data.Short.hybrids});
        }
        else if(value == "mid"){
        this.setState({ final_data: this.state.results_data.Mid.hybrids});
    }
        else if(value == "long"){
        this.setState({ final_data: this.state.results_data.Long.hybrids});
    }
        else{
        this.setState({ final_data: this.state.results_data.Overall.hybrids});
      }
    if(event == undefined){
         let eventID = document.getElementsByClassName(value)[0]
         this.setState({prevTab:eventID});

    }else{
        this.setState({prevTab:event.target});
    }

        if (!this.state.firstTab) {
            this.state.prevTab.style.backgroundColor = "white";
            this.state.prevTab.style.color = "#666666";
        } else {
            document.getElementsByClassName("flex-1")[0].style.backgroundColor = "white";
            document.getElementsByClassName("flex-1")[0].style.color = "#666666";
            this.setState({firstTab: false});
        }
    if(event == undefined){
        let eventColor = document.getElementsByClassName(value)[0]
        eventColor.style.backgroundColor = "#005ca5";
        eventColor.style.color = "white";
    }else{
        event.target.style.backgroundColor = "#005ca5";
        event.target.style.color = "white";
     }
    }

      seasonCallWithUrl(e){
          let currentURL = window.location.href;
          var hash = currentURL.split('#')[1];
          if(hash != undefined){
          this.getdata(e, hash);
        }
      }

      
    

    render() {
        return (
           
            <React.Fragment>
                <NavBar from={"2024canolaleaderboard"} page_router={this.props.page_router} clearPURL={this.props.clearPURL} />
                <div className="crContainer">
                    <Hero img={crHeroImg} />
                    <div className="crTopBar">
                        <p>
                        The results from  our 2024 Demonstration Strip Trials (DST) are coming in. Check out the chart below to see how InVigor® canola hybrids are stacking up against the competition. The hybrids represented here are pulled from our LibertyLink<sup>®</sup> replicated trials and represent the results to date.
                        </p>
                        <p>In addition to the rankings overall, this year the rankings for each growing zone (long, mid & short) have been provided.  This allows a grower to ensure that the results of the Demonstration Strip Trials more accurately reflect the conditions on their farm. Not sure of your growing zone?&nbsp; 
                        <button
                            onClick={this.growingZoneClick}
                            ref={this.anchorRef}
                        > Check here
                        </button>
                        <Popup anchor={this.anchorRef.current} show={this.state.showPopUp}>
                            <img src={postCardImg} height={400} width={600}></img>
                        </Popup></p>

                        <h2> 2024 Canola Rankings</h2>
                        <p>InVigor Hybrid Canola DST Results</p>
                        <div className="flex">
                            <div className="flex-grow flex">
                                <div className="season">Growing Zone: </div> 
                                <a className="flex-1"  onClick={(e) => this.getdata(e, 'overall')}
                                >Overall
                                </a>
                                <a className="flex-2 long"  onClick={(e) => this.getdata(e, 'long')}
                                >Long
                                </a>
                                <a className="flex-2 mid"  onClick={(e) => this.getdata(e, 'mid')}
                                >Mid
                                </a>
                                <a className="flex-2 short"  onClick={(e) => this.getdata(e, 'short')}
                                >Short
                                </a>
                            </div>
                        </div>
                        <div className="rowFlex">
                            <span className="small1">
                                Results as of: {this.state.updatedDate === null ? 'Unavailable' : Moment(this.state.updatedDate).format('DD-MM-YYYY')}
                            </span> 
                            <a>
                                <span className="ranking-tooltip">
                                    <a onClick={this.toggleRankingToolTip}>How rankings are calculated?</a>
                                    {this.state.showRankingToolTip ?
                                        <div ref={this.setWrapperRef} className="ranking-tooltip-text">
                                            The rank of the canola hybrid is determined based on yield, adjusted for moisture and dockage.  
                                            Yield is calculated to one decimal place.  
                                            If two hybrids tie in rank, there will not be a hybrid ranked in the next place in standings.  
                                            i.e. if two hybrids tie and are both ranked 2nd, there will be no 3rd place hybrid ranking.  
                                            The percentage in each of 1st, 2nd and third place is based on the number of times the hybrid is in each place divided by the total number trials that the hybrid is in.  
                                        </div>
                                        : null
                                    }
                                </span> 
                            </a>            
                        </div>
                        
                        <div class="tableFixHead">
                            <table class="table table-bordered">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Hybrid Name</th>
                                            <th># Times in Trials</th>
                                            <th>1st</th>
                                            <th>2nd</th>
                                            <th>3rd</th>
                                            <th style={{fontWeight: "bold"}}>Top 3*</th>
                                        </tr>
                                    </thead>
                                    <tbody> {
                                    this.state.final_data && this.state.final_data.length && this.state.final_data.sort(sortTypes[this.state.currentSort].fn).map( (element) => {
                                            return (
                                                    <tr key={element.Result_Rank}>
                                                        { this.props.trials?.hybridLinks ?
                                                        <>
                                                        <td>{ this.props.trials?.hybridLinks[element.Hybrid] ? <a href={this.props.trials?.hybridLinks[element.Hybrid]} target='_blank'>{element.Hybrid}</a> : element.Hybrid }</td> 
                                                        <td>{element.DST_COUNT}</td>
                                                        <td>{(100*(element.DST_RANK1)/element.DST_COUNT).toFixed(0)}%</td>
                                                        <td>{(100*(element.DST_RANK2)/element.DST_COUNT).toFixed(0)}%</td>
                                                        <td>{(100*(element.DST_RANK3)/element.DST_COUNT).toFixed(0)}%</td>
                                                        <td style={{fontWeight: "bold"}}>{(100*(element.DST_RANK1 + element.DST_RANK2 + element.DST_RANK3)/element.DST_COUNT).toFixed(0)}%</td>
                                                        </> 
                                                        : null}
                                                    </tr>
                                                    
                                                )
                                            })
                                         }
                                    </tbody>
                                </table>
                            </table>
                        </div>


                        <span className="small1">
                            <sup>*</sup>% in top 3 is calculated based off number of times a hybrid has been in the top 3 for yield, divided by the total number of trials the hybrid was present in<br />
                            <sup>**</sup>All percentage values have been rounded to their nearest whole value<br /> 
                            <br />
                                INVIGOR is a registered trademark of BASF; used under license by BASF Canada Inc. © 2024 BASF Canada Inc. <br/>
                                DEKALB<sup>®</sup> is a trademark of Bayer Group. © 2024 Bayer Group. All rights reserved.<br/>
                                Brevant<sup>®</sup> brand canola products and Pioneer<sup>®</sup> brand canola products are trademarks of Corteva Agriscience and its affiliated companies. © 2024 Corteva.<br/>
                                Results may vary on your farm due to environmental factors and preferred management practices. <br /><br />
                        </span>


                        <h2>Performance by the numbers.</h2>
                        <p>
                            Here’s how InVigor canola hybrids are performing versus the competition in all trials completed to date in 2024. Each hybrid was harvested at the
                            optimum time to maximize yield and seed quality. The numbers indicate how many times each hybrid placed 1st, 2nd or 3rd in net yield.
                        </p>

                        <p>In short, the results backup InVigor’s track record of consistency, performance and trust.</p>

                        <button className="button1">Contact your retailer to order your invigor hybrids today</button><br />
                        {/* <span className="small1">Some hybrids are in limited quantity</span><br /><br /> */}

                        <p>To see more trial results from your local area and beyond, please click below.</p>
                        <button className="button2" onClick={() => this.props.page_router.loadPage('home')}>More Trial Results</button>


                    </div>


                    <TalkToUs />
                    <Footer />
                </div>
            </React.Fragment>
        );

    }
}

const mapStateToProps = (state) => ({
	trials: state.trials
})

// export default CanolaLeaderboard;
export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({ ...trialsActionCreators }, dispatch)
)(CanolaLeaderboard);
