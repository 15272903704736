import { setComparisons, setComparisonMetadata, setAutomatedComparisons } from '../actions/comparisonAction';

const initialState = {
    data:[],
    automatedComparisonData:[]
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case setComparisons:
            return { ...state, data: [...action.payload] };
        case setComparisonMetadata:
            return { ...state, metadata: [...action.payload] };
        case setAutomatedComparisons:
            return { ...state, automatedComparisonData: action.payload };
        default:
            return state;
    }
};