import React, { Component, useRef } from 'react';
import { nodeserver_url_images , nodeserver_url_images_aptapi } from '../../constants/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './IndividualTrialPage.scss';
import Slider from 'react-slick';
import TalkToUs from '../../components/TalkToUs/TalkToUs';
import Footer from '../../components/Footer/Footer';
import Sharebuttons from '../../components/Sharebuttons/Sharebuttons.js';
import { getPreciseDistance } from 'geolib';
import { agronomistsActionCreators } from '../../actions/agronomistAction';
import { trialsActionCreators } from '../../actions/trialsAction';
import { trialResultsActionCreators } from '../../actions/trialResultsAction';
import { getAgronomist } from '../../utils/helpers';
import moment, { max } from 'moment'
import { generatePdf } from '../../utils/pdf';
import ChartTabs from '../../components/Chart/ChartTabs';
import Tooltip from '../../components/Tooltip/Tooltip';
import { getDataset, getTrialsCategory } from '../../selectors/trialsSelector';
import { firstBy } from "thenby";
import { nodeserver_url } from '../../constants/api';
import { TreatmentLinks } from '../../constants/hybriddata';
import axios from 'axios';
import { getPURL } from '../../selectors/trialsSelector';
//import { PDFExport } from '@progress/kendo-react-pdf';
import PhotoPopup from '../../components/PhotoPopup/PhotoPopup'
import { metadata_header_names } from '../../constants/metadata'
import {
	Chart,
	ChartTitle,
	ChartSeries,
	ChartSeriesItem,
	ChartCategoryAxis,
	ChartCategoryAxisTitle,
	ChartCategoryAxisItem,
	ChartLegend,
	ChartValueAxis,
	ChartValueAxisItem
  } from "@progress/kendo-react-charts";
  //import '@progress/kendo-theme-material/dist/all.scss';
  import './KendoStylings.scss';

  import "hammerjs";

let quotes = `${nodeserver_url_images}/trial_results/images/individualTrial/quotes.png`;
let backArrow = `${nodeserver_url_images}/trial_results/images/trials/backarrow.png`;

const TrialsCategory = getTrialsCategory();

let play_button = `${nodeserver_url_images}/trial_results/images/common/play-button.png`;

// let podShatterData = [
// 	0.298,
// 	0.293,
// 	0.283,
// 	0.334,
// 	0.397
// ]

// let podShatterChartData = [
// 	{
// 		hybrid: "InVigor L340PC",
// 		dropLoss: 0.162,
// 		shatterLoss: 0.136,
// 		total: 0.298
// 	},
// 	{
// 		hybrid: "InVigor L345PC",
// 		dropLoss: 0.132,
// 		shatterLoss: 0.161,
// 		total: 0.293
// 	},
// 	{
// 		hybrid: "InVigor L343PC",
// 		dropLoss: 0.21,
// 		shatterLoss: 0.073,
// 		total: 0.283
// 	},
// 	{
// 		hybrid: "InVigor L356PC",
// 		dropLoss: 0.217,
// 		shatterLoss: 0.117,
// 		total: 0.334
// 	},
// 	{
// 		hybrid: "InVigor L35XPC",
// 		dropLoss: 0.239,
// 		shatterLoss: 0.158,
// 		total: 0.397
// 	}
// ]

const valueAxis = [{
    name: "Precip Accumulated",
    // min: 0,
    // max: 100
}, {
    name: "Weekly Precipitation",
    // min: 0,
    // max: 100
}];

const valueAxisTempFlux = [{
    name: "MinT",
	title: "Min Temp"
}, {
	name: "MaxT",
	title: "Max Temp"
}] 

// const axisCrossingValue: Array<number> = [0, 0, 0];


const groupTreatments = array =>
	array.reduce((objectsByKeyValue, obj) => {
		let treatmentExists = false;
		const value = obj['application'];
		const applicationSequence = obj['applied_sequence'];
		const applicationGroup = obj['application_group'];
		//group treatments that have multiple treatments with 1+ in common
		//will put treatment A + B and treatment A on the same row
		Object.keys(objectsByKeyValue).forEach(o => {
			if (o.includes(applicationGroup) && applicationSequence > 1) { //if this treatment is a part of another treatments application group AND a second application of that treatment based on the applied_sequence field
				treatmentExists = true;
				objectsByKeyValue[o] = objectsByKeyValue[o].concat(obj); //add it to the row
				return;
			}
		})
		if (!treatmentExists)
			objectsByKeyValue[value] = [].concat(obj);

		return objectsByKeyValue;
	}, {});

// const groupTreatments = array =>
//     array.reduce((objectsByKeyValue, obj) => {
//         const value = obj['application'];
//         objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
//         return objectsByKeyValue;
//     }, {});

const getTrialResultsParams = (trial_id) => {
	return trial_id.split("-");
}



class IndividualTrialPage extends Component {

	constructor(props) {
		super(props);
		this.slider = React.createRef();
		this.state = {
			trialCode: null,
			selectedChart: 1,
			PURL: getPURL(),
			trial: null,
			aptFlag: false,
			imageIndex: null,
			toDisplay: false,
			aptTableData: {},
			//1: yield, 2: days to maturity, 3: harvest rating
			latLng: {
				lat: 52.129,
				lng: -106.670
			},
			windowWidth: window.innerWidth,
		};
		this.handleChartSlider = this.handleChartSlider.bind(this);
		this.handleChartTabs = this.handleChartTabs.bind(this);
		this.handleToDisplayStateChange = this.handleToDisplayStateChange.bind(this);
	}

	handleToDisplayStateChange(value) {
		this.setState({ toDisplay: value })
	}

 	handleResize = (e) => {
		this.setState({ windowWidth: window.innerWidth });
	   };

	componentDidMount = () => {

		if (this.props.clearPURL) {
			this.setState({
				...this.state,
				PURL: null
			}, () => { })
		}


		if (!this.props.page_params.trialCode) {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					position => {
						this.setState({ latLng: { lat: position.coords.latitude, lng: position.coords.longitude } },
							() => this.showNearestTrial(this.props.trials.trials))
					}
				)
			}
		}
		let params = getTrialResultsParams(this.props.page_params.trialCode);
		this.props.getAPTTrialResults(params[1], params[0]);

		//if (document.getElementById("trialTable") && this.state.treatments) this.generateTreatmentsTable2019(this.state.treatments);

		//if (document.getElementById("trialTable") && this.state.treatments) this.generateTreatmentsTable2019(this.state.treatments);

		window.addEventListener("resize", this.handleResize);

		
	}

	componentWillUnmount() {
		window.addEventListener("resize", this.handleResize);
	}



	componentWillReceiveProps = (nextProps) => {
		if (nextProps.page_params.trial_type) {
			this.showNearestTrial(nextProps.trials.trials);
		}

		// if (nextProps.page_params.trialCode && nextProps.trials.trials.length && (this.props.individualTrial && nextProps.individualTrial ? this.props.individualTrial.data.trial !== nextProps.individualTrial.data.trial : true)) {
		// 	const trial = this.props.getIndividualTrial(nextProps.page_params.trialCode)
		// 	console.log("get individual trial")
		// 	this.setState({ trial })
		// }
	}

	exportPDFWithComponent = () => {
		this.pdfExportComponent.save();
	}

	imgUpload = () => {
		const img = document.getElementById("agroImg");
		img.style.display = "none";
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.individualTrial !== prevProps.individualTrial &&
			(this.props.individualTrial.data.year >= 2019)
		) { //check if year is 2019
			if (this.props.individualTrial.applications) this.generateTreatmentsTable2019(this.props.individualTrial.applications);
		}


		if (this.props.page_params.trialCode && this.props.trials.trials.length && (prevProps.individualTrial && this.props.individualTrial ? prevProps.individualTrial.data.trial !== this.props.individualTrial.data.trial : true)) {
			const trial = this.props.getIndividualTrial(this.props.page_params.trialCode)
			this.setState({ trial })
		}

	}

	imgUpload = () => {
		const img = document.getElementById("agroImg");
		img.style.display = "none";
	}

	getSwathTypes = (hybrids) => {
		let swathTypesList = [];
		let swathTypesString = "";
		let swathTypesLabel = [];
		hybrids.sort((a, b) => (a.swath_type < b.swath_type) ? -1 : (a.swath_type > b.swath_type) ? 1 : 0).forEach(h => {
			if (!swathTypesList.includes(h.swath_type)) {
				swathTypesString = "";
				swathTypesString += swathTypesList.length ? " & " : "";
				swathTypesString += h.swath_type + " (" + h.swath_type_abbr + ")";
				swathTypesList.push(h.swath_type);
				swathTypesLabel.push(<span key={"swathTypeString"}>{swathTypesString} <React.Fragment> {h.swath_type_desc.length === 0 ? " " : <Tooltip text={h.swath_type_desc} color='black' />} </React.Fragment></span>)
			}
		})
		return swathTypesLabel;
	}

	getSummaryyeildType = (data, hybrids) => {
		if (data.trial_type.includes('InVigor Harvest Management')) {
			let sc = false;
			for (const item of hybrids) {
				if (item.harvest_method === 'Straight Cut') {
					sc = true;
				}
			}

			if (sc) {
				return 'Yield results include both swathed and straight cut results'
			} else {
				return 'Swathed at 60% SCC (seed colour change) except PSR hybrids, when swathed, will be swathed at 80% SCC.'
			}
		}
		else if (data.trial_type.includes('InVigor Straight Cut')) {
			let sw = false;
			for (const item of hybrids) {
				if (item.harvest_method === 'Swath') {
					sw = true;
				}
			}

			if (sw) {
				return 'Yield results include both swathed and straight cut results'
			} else {
				return 'Straight cut'
			}
		}
	}


	getChart = (trial, swathType, swathAbbr, swathDesc) => {

		let weatherData = this.getWeatherData(trial.data.weather_station);

		let maxValue, compareProperty, chartName, chartNameBracketText, chartTooltip, background;
	
		switch (this.state.selectedChart) {
			case 1:
				compareProperty = 'net_yield'
				chartName = 'Yield (bu/ac.)'
				chartNameBracketText = trial.data.trial_type.includes('2024 Agronomic Excellence') ? '(All yields have been adjusted for moisture but not for dockage)' : '(All yields have been adjusted for dockage & moisture)'
				chartTooltip = ""
				background = "#0061a1";
				break;
			case 2:
				compareProperty = 'maturity'
				chartName = 'Days to Maturity'
				chartTooltip = "Seeding date to 60% seed colour change."
				background = "#64AC1E";
				break;
			case 3:
				compareProperty = 'harvest'
				chartName = 'Harvest Rating'
				chartTooltip = "Rating by grower on ease of combining to other strips planted. Scale 1 - 10 with 10 being the best."
				background = "#0F8045";
				break;
			case 4:
				compareProperty = 'pod_shatter_loss'
				chartName = 'Pod Shatter Data (Total Bushels per acre loss for Hybrid)'
				break;
			case 5:
				compareProperty = 'weather'
				chartName = 'Weather'
				break;
			default:
				compareProperty = 'net_yield'
				chartName = 'Yield (bu/ac.s)'
				chartTooltip = "";
				break;
		}



		if (swathType) {
			let trialFilteredForSwathType = trial.hybrids.filter(h => {
				return h.swath_type === swathType;
			})
			maxValue = (trial && trial.hybrids) ? this.getMaxValue(trialFilteredForSwathType) : 0;
			return (maxValue ?
				<div key={swathType} className="tabChartContainer">
					<div className="chartLabel">
						<div className="chartLabelUnit">{chartName} {this.state.selectedChart === 2 || this.state.selectedChart === 3? <Tooltip text={chartTooltip} color="black" /> : ""}</div>
						<div className="chartLabelSwathType">{swathType} ({swathAbbr})  {swathDesc ? <Tooltip text={swathDesc} color="black" /> : ""} </div>
					</div>
					<React.Fragment>
						{trialFilteredForSwathType.sort(firstBy((a, b) => (a.is_basf === b.is_basf) ? 0 : a.is_basf ? -1 : 1).thenBy("hybrid_sequence")).map((h, i) => (
							<div key={`barItem-${h.hybrid}-${i}`} className="chartItemContainer">
								<div className="itemText">
									<div className="itemHybrid" dangerouslySetInnerHTML={{ __html: h.hybrid }}></div><div className="itemSwathType">{h.harvest_method ? h.harvest_method : h.swath_type}</div>
								</div>
								<div className="itemBarContainer">
									<div className="itemBar" style={{ width: `${(h[compareProperty] / maxValue) * 100}%`, background: `${h.is_basf ? background : "lightgrey"}` }} >{h[compareProperty].toFixed(1)}</div>
								</div>
							</div>
							// chart bar component will eventually be used here as well
							//	<ChartBar key={`bar-${h.hybrid}-${i}`} label={h.hybrid + '-' + h.swath_type_abbr} value={h[compareProperty]} style={{ width: `${(h[compareProperty] / maxValue) * 100}%`, background: `${h.is_basf ? this.getTrialColor(trial.data.trial_type, false) : "lightgrey"}` }} />
						))}
					</React.Fragment>
				</div >
				: null
			)
		}
		else {

			maxValue = (trial && trial.hybrids) ? this.getMaxValue(trial.hybrids) : 0;
			return (maxValue ? (
				<>
					<div className="tabChartContainer">
						<div className="chartLabel">
							<div className="chartLabelUnit">
								{chartName} 
								<div className="bracket-text">
									{chartNameBracketText}
								</div>
								{this.state.selectedChart === 2 || this.state.selectedChart === 3? <Tooltip text={chartTooltip} color="black" /> : ""}
							</div>
							<div className="chartLabelSwathType">
								{this.getSummaryyeildType(trial.data, trial.hybrids)}
							</div>
	
							{/* {this.getSwathTypes(trial.hybrids)} */}
						</div>

						{this.state.selectedChart == 4 ? (
							<React.Fragment>
								{trial.hybrids.sort(firstBy((a, b) => (a.is_basf === b.is_basf) ? 0 : a.is_basf ? -1 : 1).thenBy("hybrid_sequence")).map((h, i) => (
								<div key={`barItem-${h.hybrid}-${i}`} className="chartItemContainer">
									{h[compareProperty] ? (
										<div className="itemText">
											<div className="itemHybrid" dangerouslySetInnerHTML={{ __html: h.hybrid }}></div><div className="itemSwathType">{h.harvest_method ? h.harvest_method : h.swath_type}</div>
										</div> 
										) : (
											null
										)}
										{h[compareProperty] ? (
											<div className="itemBarContainer">
												<div className="itemBar" style={{width: `${( (h[compareProperty] + h.pod_drop_loss) / maxValue) *100}%`, background: "rgb(0, 97, 161) none repeat scroll 0% 0%" }} >{ (h[compareProperty] + h.pod_drop_loss).toFixed(2)}</div>
												</div>
										) : (
											null
										)}
								</div>
								//	<ChartBar key={`bar-${h.hybrid}-${i}`} label={h.hybrid + '-' + h.swath_type_abbr} value={h[compareProperty]} style={{ width: `${(h[compareProperty] / maxValue) * 100}%`, background: `${h.is_basf ? this.getTrialColor(trial.data.trial_type, false) : "lightgrey"}` }} />
								))}
							</React.Fragment>
						) : (
							null
						)} 

						{(this.state.selectedChart == 1 || this.state.selectedChart == 2 || this.state.selectedChart == 3) ? (
							<React.Fragment>
								{trial.hybrids.sort(firstBy((a, b) => (a.is_basf === b.is_basf) ? 0 : a.is_basf ? -1 : 1).thenBy("hybrid_sequence")).map((h, i) => (
								<div key={`barItem-${h.hybrid}-${i}`} className="chartItemContainer">
									<div className="itemText">
										<div className="itemHybrid" dangerouslySetInnerHTML={{ __html: h.hybrid }}></div><div className="itemSwathType">{h.harvest_method ? h.harvest_method : h.swath_type}</div>
									</div>
									<div className="itemBarContainer">
										<div className="itemBar" style={{ width: `${(h[compareProperty] / maxValue) * 100}%`, background: `${h.is_basf ? background : "lightgrey"}` }} >{h[compareProperty].toFixed(1)}</div>
									</div>
								</div>
								//	<ChartBar key={`bar-${h.hybrid}-${i}`} label={h.hybrid + '-' + h.swath_type_abbr} value={h[compareProperty]} style={{ width: `${(h[compareProperty] / maxValue) * 100}%`, background: `${h.is_basf ? this.getTrialColor(trial.data.trial_type, false) : "lightgrey"}` }} />
								))}
							</React.Fragment>
						) : (
							null
						)}
		
							{/* <React.Fragment>
								{trial.hybrids.sort(firstBy((a, b) => (a.is_basf === b.is_basf) ? 0 : a.is_basf ? -1 : 1).thenBy("hybrid_sequence")).map((h, i) => (
								<div key={`barItem-${h.hybrid}-${i}`} className="chartItemContainer">
									<div className="itemText">
										<div className="itemHybrid" dangerouslySetInnerHTML={{ __html: h.hybrid }}></div><div className="itemSwathType">{h.harvest_method ? h.harvest_method : h.swath_type}</div>
									</div>
									<div className="itemBarContainer">
										<div className="itemBar" style={{ width: `${(h[compareProperty] / maxValue) * 100}%`, background: `${h.is_basf ? background : "lightgrey"}` }} >{h[compareProperty].toFixed(1)}</div>
									</div>
								</div>
								//	<ChartBar key={`bar-${h.hybrid}-${i}`} label={h.hybrid + '-' + h.swath_type_abbr} value={h[compareProperty]} style={{ width: `${(h[compareProperty] / maxValue) * 100}%`, background: `${h.is_basf ? this.getTrialColor(trial.data.trial_type, false) : "lightgrey"}` }} />
								))}
							</React.Fragment> */}
					

						{this.state.selectedChart == 5 ? (
							<div>
								<div className="row mb-3">
									<div className="col-6">
										<div className="k-card">
										<Chart
											style={{
											height: 350,
											}}
										>
											<ChartTitle text="Cumulative and weekly Rainfall (mm)" />
											<ChartLegend position="top" orientation="horizontal" />
											<ChartCategoryAxis>
											<ChartCategoryAxisItem
											categories= {weatherData.dates}
												labels={{
												rotation: "auto",
												}}
											axisCrossingValues={[0, weatherData.dates.length]}
		
											/>
											</ChartCategoryAxis>
											<ChartSeries>
												<ChartSeriesItem
												gap={2}
												type="line"
												tooltip={{ visible: true }}
												data={weatherData.cumulative_precip}
												name = {"Precip Accumulated"}
												/>
												<ChartSeriesItem
												gap={2}
												type="column"
												tooltip={{ visible: true }}
												data={weatherData.precip}
												name = {"Weekly Precipitation"}
												/>
											</ChartSeries>
											<ChartValueAxis>
											{valueAxis.map((item, idx) => (
												<ChartValueAxisItem
												key={idx}
												name={item.name}
												title= {{text: item.name}}
														/>))}
											</ChartValueAxis>
											</Chart> 
											<div className="ChartX-axis">Week of</div>
											<Chart
											style={{
											height: 350,
											}}
										>
											<ChartTitle text="Weekly Average Max & Min Temperature (˚C)" />
											<ChartLegend position="top" orientation="horizontal" />
											<ChartValueAxis>
												<ChartValueAxisItem 
												title={{ text: "Weekly Average Temperature" }}
												min = {-10}
												max = {40}
												axisCrossingValue = {-10}
												/>
											</ChartValueAxis>
											<ChartCategoryAxis>
											<ChartCategoryAxisItem
											categories= {weatherData.dates}
												labels={{
												rotation: "auto",
												}}
											axisCrossingValues={[0, weatherData.dates.length]}
											/>
											</ChartCategoryAxis>
											<ChartSeries>
												<ChartSeriesItem
												gap={2}
												type="line"
												tooltip={{ visible: true }}
												data={weatherData.min_temp}
												name = {"MinT"}
												/>
												<ChartSeriesItem
												gap={2}
												type="line"
												tooltip={{ visible: true }}
												data={weatherData.max_temp}
												name = {"MaxT"}
												/>
											</ChartSeries>
											</Chart> 
											<div className="ChartX-axis">Week of</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							null
						)}
					</div>
				</>
				) : (
					null
				)
			)
		}
	}

	getAllCharts = (trial) => {
		if (trial.data.trial_type.includes("vs")) {
			let includedType = false;
			let swathTypesList = [];
			trial.hybrids.sort((a, b) => ((a.hybrid + a.swath_type) < (b.hybrid + b.swath_type)) ? -1 : ((a.hybrid + a.swath_type) > (b.hybrid + b.swath_type)) ? 1 : 0).forEach(h => {

				includedType = false;
				swathTypesList.forEach(s => {
					if (s[0] === h.swath_type) {
						includedType = true;
						return;
					}
				})
				if (!includedType) {
					swathTypesList.push([h.swath_type, h.swath_type_abbr, h.swath_type_desc]);
				}
			})

			return swathTypesList.map(t => {
				return this.getChart(trial, t[0], t[1], t[2]);
			})
		}
		else {
			return this.getChart(trial)
		}
	}

	getChartName = selectedChart => {
		switch (selectedChart) {
			case 1:
				return "Yield";
			case 2:
				return "Days to Maturity";
			case 3:
				return "Harvest Rating";
			default:
				return "Yield";
		}
	}

	generateTreatmentsTable = (trial, treatments, t) => {
		let maxNumApplications = 0;
		Object.keys(treatments).forEach(app => {
			if (treatments[app].length > maxNumApplications)
				maxNumApplications = treatments[app].length;
		})

		let thirdApplicationExists = maxNumApplications > 2;
		//warnings with non unique keys in mappings

		let thirdApplicationColumn = [];
		let fertilityRow = [];
		fertilityRow.push(<td key="blankFertility1" className="cell">_</td>);

		if (thirdApplicationExists) {
			thirdApplicationColumn.push(<td key="thirdAppHead" className={`cell cellHead`}>Treatments</td>);
			thirdApplicationColumn.push(<td key="thirdAppRate" className={`cell cellHead`}>Third Application (Rate)</td>)
			thirdApplicationColumn.push(<td key="thirdAppDate" className={`cell cellHead`}>Date</td>)
			fertilityRow.push(<td key="blankFertility2" className={`cell`}>_</td>)
			fertilityRow.push(<td key="blankFertility3" className={`cell`}>_</td>)
			fertilityRow.push(<td key="blankFertility4" className={`cell`}>_</td>)
		}

		return (
			<React.Fragment>
				<tr className="headerRow" id="treatmentTable">
					<th className={`headColStatic headColHead`}>Treatments</th>
					<td className={`cell cellHead`}>First Application (Rate) </td>
					<td className={`cell cellHead`}>Date </td>
					<td className={`cell cellHead`}>Treatments </td>
					<td className={`cell cellHead`}>Second Application (Rate)</td>
					<td className={`cell cellHead`}>Date </td>
					{thirdApplicationColumn.length ? thirdApplicationColumn : null}
				</tr>
				{Object.keys(treatments).map((t, j) => (
					<tr key={`${trial.data.trial}-${j}`}>
						<td className={`cell`}>{t}</td>
						{this.generateTreatments(treatments, t, thirdApplicationExists)}
					</tr>
				))}

				{/* <tr>
					<td className="cell">Fertility (actual lbs./ac. applied)</td>
					{trial.data.fertility.split(" ").map((f, i) => (
						<td key={`fertility-${i}`} className={`cell`}>{f}</td>
					))}
					{fertilityRow}
				</tr> */}
			</React.Fragment>)
	}

	// puts 2 of the same treatments in the same row of table, if there are 2
	generateTreatments = (treatments, t, thirdApplicationExists) => {
		let thirdApplicationEmpty = true;
		let treatmentRow = [];
		// console.log(treatments);
		treatments[t].forEach((app, i) => {
			if (treatments[t].length <= 1) {
				treatmentRow.push(<td key={`${t}-firstApp`} className={`cell`}>{app.concentration}</td>);
				treatmentRow.push(<td key={`${t}-date1`} className={`cell`}>{app.applied ? moment(new Date(app.applied)).format("DD/MM/YYYY") : 'N/A'}</td>);
				treatmentRow.push(<td key={`${t}-treatments`} className={`cell`}>N/A</td>);
				treatmentRow.push(<td key={`${t}-secondApp`} className={`cell`}>N/A</td>);
				treatmentRow.push(<td key={`${t}-date2`} className={`cell`}>N/A</td>);
			} else if (i === 0) {
				treatmentRow.push(<td key={`${t}-firstApp`} className={`cell`}>{app.concentration}</td>);
				treatmentRow.push(<td key={`${t}-date1`} className={`cell`}>{app.applied ? moment(new Date(app.applied)).format("DD/MM/YYYY") : 'N/A'}</td>);

			} else if (i === 1) {
				treatmentRow.push(<td key={`${t}-treatments`} className={`cell`}>{app.application}</td>);
				treatmentRow.push(<td key={`${t}-secondApp`} className={`cell`}>{app.concentration ? app.concentration : 'N/A'}</td>);
				treatmentRow.push(<td key={`${t}-date2`} className={`cell`}>{app.applied ? moment(new Date(app.applied)).format("DD/MM/YYYY") : 'N/A'}</td>);
			} else if (i === 2) {
				thirdApplicationEmpty = false;
				treatmentRow.push(<td key={`${t}-thirdAppTreatments`} className={`cell`}>{app.application}</td>);
				treatmentRow.push(<td key={`${t}-thirdApp`} className={`cell`}>{app.concentration ? app.concentration : 'N/A'}</td>);
				treatmentRow.push(<td key={`${t}-date3`} className={`cell`}>{app.applied ? moment(new Date(app.applied)).format("DD/MM/YYYY") : 'N/A'}</td>);
			}
		})
		if (thirdApplicationExists && thirdApplicationEmpty) {
			treatmentRow.push(<td key={`${t}-thirdAppTreatments`} className={`cell`}>N/A</td>);
			treatmentRow.push(<td key={`${t}-thirdApp`} className={`cell`}>N/A</td>);
			treatmentRow.push(<td key={`${t}-date3`} className={`cell`}>N/A</td>);
		}
		return treatmentRow;
	}

	getWeatherData = (station) => {
		let trial = this.props.individualTrial;
		const weatherData = {};

		let tempArr_measurementData = [];
		let tempArr_cumulativePrecip = [];
		let tempArr_precip = [];
		let tempArr_maxTemp = [];
		let tempArr_minTemp = [];
		// let tempArr_meanTemp = [];
		// let tempFlux = [];

		let listStations = Object.keys(this.props.trials.weatherData)

		listStations.map((stationName)=> {
			if (station == stationName) {			
				this.props.trials.weatherData[station].map((item) => {

					// To select weather data for specific year
				if(item.week_of_date){
					let yearOfTrial = item.week_of_date.substring(0, 4);
					if( yearOfTrial == trial.data.year){

					tempArr_measurementData.push(item.week_of_date);
					tempArr_cumulativePrecip.push(item.cumulative_precip);
					tempArr_precip.push(item.precip);
					tempArr_maxTemp.push(item.max_temp);
					tempArr_minTemp.push(item.min_temp);
					// tempArr_meanTemp.push(item.mean_temp);
				}
			  }
			})
			}
		})


		weatherData["dates"] = tempArr_measurementData;
		weatherData["cumulative_precip"] = tempArr_cumulativePrecip;
		weatherData["precip"] = tempArr_precip;
		weatherData["min_temp"] = tempArr_minTemp;
		weatherData["max_temp"] = tempArr_maxTemp;
		// weatherData["mean_temp"] = tempArr_meanTemp;
		// weatherData["temp_flux"] = tempFlux;

		return weatherData;

	}

	getMaxPodBushels = (hybrids) => {
		let maxPodBushels = []; 
		
		hybrids.map((h) => {
			if (h.pod_drop_loss && h.pod_shatter_loss) {
				maxPodBushels.push(h.pod_drop_loss + h.pod_shatter_loss)
			}
		})

		return Math.max.apply(Math, maxPodBushels.map(function (bushels) { return bushels}));
	}

	getMaxValue = (hybrids) => {
		//based on which chart is selected, return max value for that property
		switch (this.state.selectedChart) {
			case 1:
				return Math.max.apply(Math, hybrids.map(function (h) { return h.net_yield; }));
			case 2:
				return Math.max.apply(Math, hybrids.map(function (h) { return h.maturity; }));
			case 3:
				return Math.max.apply(Math, hybrids.map(function (h) { return h.harvest; }));
			case 4:
				return this.getMaxPodBushels(hybrids)
			default:
				return Math.max.apply(Math, hybrids.map(function (h) { return h.net_yield; }));
		}
	}


	// get trial color (pass true background parameter to get background color
	getTrialColor = (trialType, background) => {
		switch (trialType) {
			default:
				return '#0061a1';
		}
	}

	handleChartSlider = (isPrevious) => {

		if (isPrevious && this.state.selectedChart > 1) {
			this.setState({ selectedChart: this.state.selectedChart - 1 })
		}
		else if (!isPrevious && this.state.selectedChart < 5) {
			this.setState({ selectedChart: this.state.selectedChart + 1 })
		}
	}

	handleChartTabs = (selectedChart) => {
		this.setState({
			selectedChart: selectedChart
		})
	}
	// finds nearest trial based on trial type and location
	findNearestTrial = (latLng, trialType, trials) => {
		let nearestTrial = trials[0];

		trials.forEach((trial) => {
			if (trial.data.trial_type === trialType && trial.data.year === 2019) {
				if (getPreciseDistance({ latitude: latLng.lat, longitude: latLng.lng }, { latitude: trial.data.latitude, longitude: trial.data.longitude }) < getPreciseDistance({ latitude: latLng.lat, longitude: latLng.lng }, { latitude: nearestTrial.data.latitude, longitude: nearestTrial.data.longitude })) {
					nearestTrial = trial;
				}
			}
		});
		return nearestTrial;
	}

	// updates page to show nearest trial
	showNearestTrial = (trials) => {
		let trialCode = this.findNearestTrial({ lat: this.state.latLng.lat, lng: this.state.latLng.lng }, this.props.page_params.trial_type, trials);
		if (trialCode) {
			this.setState({ trialCode: trialCode.data.trial });
		}
	}

	getAgronomistName = (id) => {
		if (this.props.agronomists.agronomists.length === 0)
			return '';
		return getAgronomist(id, this.props.agronomists.agronomists).get_full_name() || '';
	}

	getAgronomistPhoto = (id) => {
		const photo_path = this.props.trials.individualTrial.data.agronomist_photo
		if (photo_path === '' || photo_path === undefined)
			return '';
		// return getAgronomist(id, this.props.agronomists.agronomists).get_agronomist_photo() || '';
		return nodeserver_url_images + "/trial_results/images/individualTrial/agronomist_images/" + photo_path;
	}
	

	createPdf = () => {

		let html = document.body;

		let template = html.cloneNode(true);
		template.querySelector(".buttonsContainer").remove();
		template.querySelector("#trial_popup").remove();


		let imgElement = template.querySelector(".bannerLink img");
			if (imgElement) {
				imgElement.src = `${nodeserver_url_images}/trial_results/images/common/BASF_InVigor_Logo.svg`; 
				const appHeader = template.querySelector(".app-header");
				appHeader.style.backgroundColor = "#004A96";
			}


		// Remove the OneTrust Consent DIV which is visible in PROD. 
		let onetrust_consent_div = template.querySelector("#onetrust-consent-sdk");
		if (onetrust_consent_div) template.querySelector("#onetrust-consent-sdk").remove();
	

		// template.querySelector(".talkContainer").remove();
		// template.querySelector(".footer").remove();


		template.querySelector(".chartTabsNavBar").remove();
		//template.querySelector(".chartTabsContainer").remove();
		
		//chartTabscontainer
		let chartTabscontainer = template.querySelector(".chartTabsContainer")
		if (chartTabscontainer) chartTabscontainer.remove();
		
		let downloadReportContainer = template.querySelector(".downloadReportContainer")
		if (downloadReportContainer) template.querySelector(".downloadReportContainer").remove();
		
		
		template.querySelector(".backtotop").remove();
		let photos = template.querySelector(".sliderContainer");
		if (photos) photos.remove();

		template.querySelector(".app-header").classList.add("appHeaderPDF");
		// template.querySelector(".individualTrialPageContainer").classList.add("individualTrialPageContainerPDF");

		let vsTrial = html.querySelector("#trialTypeHeader").textContent.includes("vs");

		let trialTable2019 = html.querySelector(".trialTable2019");

		if (trialTable2019) {
			let pageOne = template.cloneNode(true);
			pageOne.querySelector(".trialTableStatic").classList.add("trialTablePDF");
			pageOne.querySelector(".trialTableContainer").classList.add("trialTableContainerPDF");
			pageOne.querySelector(".testimonialContainer").remove();
			pageOne.querySelector(".trialTableContainer2019").remove();
			pageOne.querySelector("#agrotrial_popup").remove();

			pageOne.querySelector(".talkContainer").remove();
			pageOne.querySelector(".footer").remove();
			pageOne.querySelectorAll(".tooltip").forEach(t => {
				t.remove();
			})
			pageOne.querySelectorAll(".headColHead").forEach(h => {
				h.classList.add("headColPDF");
			})
			pageOne.querySelectorAll(".headCol").forEach(h => {
				h.classList.add("headColPDF");
			})


			let pageTwo = template.cloneNode(true);
			let weatherContainer2 = pageTwo.querySelector(".weatherContainer");
			if (weatherContainer2) weatherContainer2.remove();
			pageTwo.querySelector(".tabChartContainer").remove();
			pageTwo.querySelectorAll(".trialTableContainer").forEach(c => {
				c.remove();
			})
			pageTwo.querySelector("#agrotrial_popup").remove();
			pageTwo.querySelector(".fertility").remove();
			pageTwo.querySelector(".talkContainer").remove();
			pageTwo.querySelector(".footer").remove();
			pageTwo.querySelector(".testimonialContainer").remove();


			let pageThree = template.cloneNode(true);
			let weatherContainer3 = pageThree.querySelector(".weatherContainer");
			if (weatherContainer3) weatherContainer3.remove();
			pageThree.querySelector(".fertility").remove();
			pageThree.querySelector("#agrotrial_popup").remove();
			pageThree.querySelector(".tabChartContainer").remove();
			pageThree.querySelectorAll(".trialTableContainer").forEach(c => {
				c.remove();
			})
			pageThree.querySelector(".trialTableContainer2019").remove();

			pageThree.querySelectorAll(".tooltip").forEach(t => {
				t.remove();
			})


			generatePdf(pageOne.outerHTML + pageTwo.outerHTML + pageThree.outerHTML);


		} else {
			if (vsTrial) {
				let pageOne = template.cloneNode(true);

				pageOne.querySelectorAll(".trialTableContainer").forEach(c => {
					c.remove();
				})
				pageOne.querySelector(".testimonialContainer").remove();
				pageOne.querySelector(".talkContainer").remove();
				pageOne.querySelector(".footer").remove();
				let pageTwo = template.cloneNode(true);

				pageTwo.querySelectorAll(".tabChartContainer").forEach(c => {
					c.remove();
				});

				pageTwo.querySelector(".trialTableStatic").classList.add("trialTablePDF");
				pageTwo.querySelector(".trialTableContainer").classList.add("trialTableContainerPDF");
				pageTwo.querySelector(".testimonialContainer").remove();
				pageTwo.querySelector(".talkContainer").remove();
				pageTwo.querySelector(".footer").remove();
				pageTwo.querySelectorAll(".tooltip").forEach(t => {
					t.remove();
				})
				pageTwo.querySelectorAll(".headColHead").forEach(h => {
					h.classList.add("headColPDF");
				})
				pageTwo.querySelectorAll(".headCol").forEach(h => {
					h.classList.add("headColPDF");
				})

				let pageThree = template.cloneNode(true);

				pageThree.querySelectorAll(".tabChartContainer").forEach(c => {
					c.remove();
				});
				pageThree.querySelectorAll(".trialTableContainer").forEach(c => {
					c.remove();
				})


				generatePdf(pageOne.outerHTML + pageTwo.outerHTML + pageThree.outerHTML);

			} else {


				let pageOne = template.cloneNode(true);

				pageOne.querySelector(".trialTableStatic").classList.add("trialTablePDF");
				pageOne.querySelector(".trialTableContainer").classList.add("trialTableContainerPDF");
				pageOne.querySelector(".testimonialContainer").remove();
				pageOne.querySelector(".talkContainer").remove();
				pageOne.querySelector(".footer").remove();
				pageOne.querySelectorAll(".tooltip").forEach(t => {
					t.remove();
				})
				pageOne.querySelectorAll(".headColHead").forEach(h => {
					h.classList.add("headColPDF");
				})
				pageOne.querySelectorAll(".headCol").forEach(h => {
					h.classList.add("headColPDF");
				})


				let pageTwo = template.cloneNode(true);
				
				let pagetwotabChartContainer = pageTwo.querySelector(".tabChartContainer")
				if (pagetwotabChartContainer) pageTwo.querySelector(".tabChartContainer").remove();
				
				pageTwo.querySelectorAll(".trialTableContainer").forEach(c => {
					c.remove();
				})

				generatePdf(pageOne.outerHTML + pageTwo.outerHTML);

			}

		}

	}

	createPdf_apt = () => {

		let html = document.body;

		let template = html.cloneNode(true);
		template.querySelector("#trial_popup").remove();
		template.querySelector(".buttonsContainer").remove();


		// Remove the OneTrust Consent DIV which is visible in PROD. 
		let onetrust_consent_div = template.querySelector("#onetrust-consent-sdk");
		if (onetrust_consent_div) template.querySelector("#onetrust-consent-sdk").remove();

		template.querySelector(".chartTabsNavBar").remove();

		let chartTabscontainer = template.querySelector(".chartTabsContainer")
		if (chartTabscontainer) chartTabscontainer.remove();

		template.querySelector(".downloadReportContainer").remove();
		template.querySelector(".backtotop").remove();
		
		let photos = template.querySelector(".sliderContainer");
		if (photos) photos.remove();

		template.querySelector(".app-header").classList.add("appHeaderPDF");

		let pageOne = template.cloneNode(true);
		pageOne.querySelector(".apt_table")
		pageOne.querySelector(".trialTableStatic").classList.add("trialTablePDF");

		generatePdf(pageOne.outerHTML);

	}

	jsonCopy = (src) => {
		return JSON.parse(JSON.stringify(src));
	}

	assignLinksToTreatments = (products, td) => {
		if (products.length > 0) {
			for (var j = 0; j < products.length; j++) {
				var product = products[j];

				if (TreatmentLinks.hasOwnProperty(product)) {
					var anchor = document.createElement("a");
					anchor.textContent = product;
					anchor.setAttribute("href", TreatmentLinks[product]);
					anchor.setAttribute("target", '_blank');
					td.appendChild(anchor);
				} else {
					var textNode = document.createTextNode(product);
					td.appendChild(textNode);
				}
				if (j < products.length - 1) {
					td.appendChild(document.createTextNode(" + "));
				}
			}
		}
	}

	//Render function from APT-1229
	generateTreatmentsTable2019 = (treatments) => {
		var row = "";
		var rowspan = 0;
		var new_treatments = [];
		var treatmentTypeCounts = {}

		// explode the hybrids into multiple entries
		for (var i = 0; i < treatments.length; i++) {
			var hybrids = treatments[i].Hybrids.split(",")
			for (var h = 0; h < hybrids.length; h++) {
				new_treatments.push(this.jsonCopy(treatments[i]))
				if (hybrids[h].includes("®")) {
					let str = hybrids[h].split("®")
					hybrids[h] = `${str[0]}<sup>®</sup>${str[1]}`
				}
				new_treatments[new_treatments.length - 1].Hybrids = hybrids[h]  //override the hybrid name for this row
				new_treatments[new_treatments.length - 1].count = hybrids.length  //keep track of the number of hybrids included in this treatment combination so we can merge rows correctly
				new_treatments[new_treatments.length - 1].position = h + 1 //keep track of where this hybrid is in the treatment combinations so we can merge cells correctly
				// backfill any missing attributes with blanks
				if (new_treatments[new_treatments.length - 1].Treatment1 === undefined) {
					new_treatments[new_treatments.length - 1].Treatment1 = ""
				}
				if (new_treatments[new_treatments.length - 1].Rate1 === undefined) {
					new_treatments[new_treatments.length - 1].Rate1 = ""
				}
				if (new_treatments[new_treatments.length - 1].Date1 === undefined) {
					new_treatments[new_treatments.length - 1].Date1 = ""
				}
				if (new_treatments[new_treatments.length - 1].Treatment2 === undefined) {
					new_treatments[new_treatments.length - 1].Treatment2 = ""
				}
				if (new_treatments[new_treatments.length - 1].Rate2 === undefined) {
					new_treatments[new_treatments.length - 1].Rate2 = ""
				}
				if (new_treatments[new_treatments.length - 1].Date2 === undefined) {
					new_treatments[new_treatments.length - 1].Date2 = ""
				}
			}
		};
		treatments = new_treatments

		//keep track of how many entries each treatment type has so we can merge the rows
		for (var i = 0; i < treatments.length; i++) {

			if (treatmentTypeCounts.hasOwnProperty(treatments[i].Treatment_Type)) {
				treatmentTypeCounts[treatments[i].Treatment_Type].count++
			}
			else {
				treatmentTypeCounts[treatments[i].Treatment_Type] = {}
				treatmentTypeCounts[treatments[i].Treatment_Type].count = 1
				// treatmentTypeCounts[treatments[i].Treatment_Type].treatmentCounts = {}
			}

		};

		var tbl = document.getElementById("trialTable2019");

		for (var i = 0; i < treatments.length; i++) {

			let rowHasData = false;
			Object.keys(treatments[i]).forEach(c => {
				if ( c !== 'Treatment_Type' && c !== 'count' && c !== 'position') {
					if (treatments[i][c]) rowHasData = true;
				}
			})

			//build the row html

			if (rowHasData) {
				var tr = tbl.insertRow();

				var td = tr.insertCell();
				td.appendChild(document.createTextNode(treatments[i].Treatment_Type));
				if (treatments[i].Treatment_Type === 'Seed Treatment') {
					td.setAttribute("class", "seedTreatment");
				}
				if (treatments[i].Treatment_Type === 'Pre-Seed') {
					td.setAttribute("class", "preseed");
				}
				if (treatments[i].Treatment_Type === 'In-Crop Herbicide') {
					td.setAttribute("class", "incrop");
				}
				if (treatments[i].Treatment_Type === 'Fungicide') {
					td.setAttribute("class", "fungTT");
				}
				if (treatments[i].Treatment_Type === 'Harvest Aid') {
					td.setAttribute("class", "harvestAid");
				}
				if (treatments[i].Treatment_Type === 'Insecticide') {
					td.setAttribute("class", "insecticideTT");
				}
				if (treatmentTypeCounts[treatments[i].Treatment_Type].count > 1) {
					if ((treatments[i].Treatment_Type !== 'Fungicide' || (treatments[i].Hybrids !== 'Pioneer® brand 45CM39' && treatments[i].Rate1 !== '80 ac/case'))) {
						td.setAttribute("rowspan", treatmentTypeCounts[treatments[i].Treatment_Type].count)
						treatmentTypeCounts[treatments[i].Treatment_Type].count = 0
					}
				}
				else if (treatmentTypeCounts[treatments[i].Treatment_Type].count === 0) {
					td.setAttribute("hidden", "")
				}

				var td = tr.insertCell();
				//td.appendChild(document.createTextNode(treatments[i].Hybrids));
				td.insertAdjacentHTML("beforeend", `<span>${treatments[i].Hybrids}</span>`);
				if (treatments[i].Treatment_Type === 'Seed Treatment') {
					td.setAttribute("class", "seedTreatmentLight");
				}
				if (treatments[i].Treatment_Type === 'Pre-Seed') {
					td.setAttribute("class", "preseedLight");
				}
				if (treatments[i].Treatment1.includes('Roundup')) {
					td.setAttribute("class", "incropLight1");
				}
				if (treatments[i].Treatment1.includes('Liberty')) {
					td.setAttribute("class", "incropLight");
				}
				if (treatments[i].Treatment_Type === 'Fungicide') {
					td.setAttribute("class", "fungTTLight");
				}
				if (treatments[i].Treatment_Type === 'Harvest Aid') {
					td.setAttribute("class", "harvestAidLight");
				}
				if (treatments[i].Treatment_Type === 'Insecticide') {
					td.setAttribute("class", "insecticideTTLight");
				}
				if (treatments[i].count === 1) {
					rowspan = 0  //this will not add any additional attributes
				}
				else if (treatments[i].count > 1 && treatments[i].position === 1) {
					rowspan = treatments[i].count  //this will add a rowspan attribute for the first row of repeating treatments
				}
				else if (treatments[i].count > 1 && treatments[i].position > 1) {
					rowspan = -1   //this will hide td's that are already included in the rowspan
				}

				var td = tr.insertCell();
				var products = treatments[i].Treatment1.split(" + ");
				this.assignLinksToTreatments(products, td);

				if (treatments[i].Treatment_Type === 'Seed Treatment') {
					td.setAttribute("class", "seedTreatmentLight");
				}
				if (treatments[i].Treatment_Type === 'Pre-Seed') {
					td.setAttribute("class", "preseedLight");
				}
				if (treatments[i].Treatment1.includes('Roundup')) {
					td.setAttribute("class", "incropLight1");
				}
				if (treatments[i].Treatment1.includes('Liberty')) {
					td.setAttribute("class", "incropLight");
				}
				if (treatments[i].Treatment_Type === 'Fungicide') {
					td.setAttribute("class", "fungTTLight");
				}
				if (treatments[i].Treatment_Type === 'Harvest Aid') {
					td.setAttribute("class", "harvestAidLight");
				}
				if (treatments[i].Treatment_Type === 'Insecticide') {
					td.setAttribute("class", "insecticideTTLight");
				}
				if (rowspan === -1) {
					td.setAttribute("hidden", "")
				}
				else if (rowspan > 0) {
					td.setAttribute("rowspan", treatments[i].count)
				}

				var td = tr.insertCell();
				td.appendChild(document.createTextNode(treatments[i].Rate1));
				if (treatments[i].Treatment_Type === 'Seed Treatment') {
					td.setAttribute("class", "seedTreatmentLight");
				}
				if (treatments[i].Treatment_Type === 'Pre-Seed') {
					td.setAttribute("class", "preseedLight");
				}
				if (treatments[i].Treatment1.includes('Roundup')) {
					td.setAttribute("class", "incropLight1");
				}
				if (treatments[i].Treatment1.includes('Liberty')) {
					td.setAttribute("class", "incropLight");
				}
				if (treatments[i].Treatment_Type === 'Fungicide') {
					td.setAttribute("class", "fungTTLight");
				}
				if (treatments[i].Treatment_Type === 'Harvest Aid') {
					td.setAttribute("class", "harvestAidLight");
				}
				if (treatments[i].Treatment_Type === 'Insecticide') {
					td.setAttribute("class", "insecticideTTLight");
				}
				if (rowspan === -1) {
					td.setAttribute("hidden", "")
				}
				else if (rowspan > 0) {
					td.setAttribute("rowspan", treatments[i].count)
				}

				var td = tr.insertCell();
				td.appendChild(document.createTextNode(treatments[i].Date1));
				if (treatments[i].Treatment_Type === 'Seed Treatment') {
					td.setAttribute("class", "seedTreatmentLight");
				}
				if (treatments[i].Treatment_Type === 'Pre-Seed') {
					td.setAttribute("class", "preseedLight");
				}
				if (treatments[i].Treatment1.includes('Roundup')) {
					td.setAttribute("class", "incropLight1");
				}
				if (treatments[i].Treatment1.includes('Liberty')) {
					td.setAttribute("class", "incropLight");
				}
				if (treatments[i].Treatment_Type === 'Fungicide') {
					td.setAttribute("class", "fungTTLight");
				}
				if (treatments[i].Treatment_Type === 'Harvest Aid') {
					td.setAttribute("class", "harvestAidLight");
				}
				if (treatments[i].Treatment_Type === 'Insecticide') {
					td.setAttribute("class", "insecticideTTLight");
				}
				if (rowspan === -1) {
					td.setAttribute("hidden", "")
				}
				else if (rowspan > 0) {
					td.setAttribute("rowspan", treatments[i].count)
				}

				var td = tr.insertCell();
				var products = treatments[i].Treatment2.split(" + ");
				this.assignLinksToTreatments(products, td);
				if (treatments[i].Treatment_Type === 'Seed Treatment') {
					td.setAttribute("class", "seedTreatmentLight");
				}
				if (treatments[i].Treatment_Type === 'Pre-Seed') {
					td.setAttribute("class", "preseedLight");
				}
				if (treatments[i].Treatment1.includes('Roundup')) {
					td.setAttribute("class", "incropLight1");
				}
				if (treatments[i].Treatment1.includes('Liberty')) {
					td.setAttribute("class", "incropLight");
				}
				if (treatments[i].Treatment_Type === 'Fungicide') {
					td.setAttribute("class", "fungTTLight");
				}
				if (treatments[i].Treatment_Type === 'Harvest Aid') {
					td.setAttribute("class", "harvestAidLight");
				}
				if (treatments[i].Treatment_Type === 'Insecticide') {
					td.setAttribute("class", "insecticideTTLight");
				}
				if (rowspan === -1) {
					td.setAttribute("hidden", "")
				}
				else if (rowspan > 0) {
					td.setAttribute("rowspan", treatments[i].count)
				}

				var td = tr.insertCell();
				td.appendChild(document.createTextNode(treatments[i].Rate2));
				if (treatments[i].Treatment_Type === 'Seed Treatment') {
					td.setAttribute("class", "seedTreatmentLight");
				}
				if (treatments[i].Treatment_Type === 'Pre-Seed') {
					td.setAttribute("class", "preseedLight");
				}
				if (treatments[i].Treatment1.includes('Roundup')) {
					td.setAttribute("class", "incropLight1");
				}
				if (treatments[i].Treatment1.includes('Liberty')) {
					td.setAttribute("class", "incropLight");
				}
				if (treatments[i].Treatment_Type === 'Fungicide') {
					td.setAttribute("class", "fungTTLight");
				}
				if (treatments[i].Treatment_Type === 'Harvest Aid') {
					td.setAttribute("class", "harvestAidLight");
				}
				if (treatments[i].Treatment_Type === 'Insecticide') {
					td.setAttribute("class", "insecticideTTLight");
				}
				if (rowspan === -1) {
					td.setAttribute("hidden", "")
				}
				else if (rowspan > 0) {
					td.setAttribute("rowspan", treatments[i].count)
				}

				var td = tr.insertCell();
				td.appendChild(document.createTextNode(treatments[i].Date2));
				if (treatments[i].Treatment_Type === 'Seed Treatment') {
					td.setAttribute("class", "seedTreatmentLight");
				}
				if (treatments[i].Treatment_Type === 'Pre-Seed') {
					td.setAttribute("class", "preseedLight");
				}
				if (treatments[i].Treatment1.includes('Roundup')) {
					td.setAttribute("class", "incropLight1");
				}
				if (treatments[i].Treatment1.includes('Liberty')) {
					td.setAttribute("class", "incropLight");
				}
				if (treatments[i].Treatment_Type === 'Fungicide') {
					td.setAttribute("class", "fungTTLight");
				}
				if (treatments[i].Treatment_Type === 'Harvest Aid') {
					td.setAttribute("class", "harvestAidLight");
				}
				if (treatments[i].Treatment_Type === 'Insecticide') {
					td.setAttribute("class", "insecticideTTLight");
				}
				if (rowspan === -1) {
					td.setAttribute("hidden", "")
				}
				else if (rowspan > 0) {
					td.setAttribute("rowspan", treatments[i].count)
				}
			}

		}

		for (let i = 0; i < tbl.rows.length; i++) {
			let r = tbl.rows[i];
			for (let j = 0; j < r.cells[j].length; j++) {
				let c = r.cells[j];

				//if the next cell is hidden, hide it's top border (DPS-406)
				if (r.cells[j + 1] ? r.cells[j + 1].hidden : false) c.classList.add("noTopBorder");
			}
		}
	}

	getAdjustedTrial = (trialType) => {
		switch (trialType) {
			case 'Dyax on Field Peas':
				return 'Yield adjusted for dockage and to 16% moisture';
			case 'Cotegra on Canola':
				return 'Yield adjusted for dockage and to 10% moisture';
			case 'Dyax on Lentils':
				return 'Yield adjusted for dockage and to 14%(Green)/13%(Red) moisture';
			case '2-Pass Fungicide on Cereals':
				return 'Yield adjusted for dockage and to 14.5% moisture';
			case 'Fungicide Timing on Cereals':
				return 'Yield adjusted for dockage and to 14.5% moisture';
			default:
				return null;
		}
	}

	getYieldChartapt = (trial, treatmentType) => {
		let maxYield;
		maxYield = (trial && trial.treatments) ? this.getMaxYieldapt(trial.treatments) : 0;
		if (treatmentType) {
			let trialFilteredFortreatmentType = trial.treatments.filter(h => {
				return h.swath_type === treatmentType;
			})
			maxYield = (trial && trial.treatments) ? this.getMaxYieldapt(trialFilteredFortreatmentType) : 0;
			return (maxYield ?
				<div key={`${trial.data.trial}-${treatmentType}`}>
					{trialFilteredFortreatmentType.sort(firstBy("is_basf", -1).thenBy("treatment"))
						.map((h, i) => {
							return (
								<div className="tabChartContainer">
									<div className="chartLabel">
										<div className="chartLabelUnit">Yield (bu/ac.) </div>
									</div>

									<div key={`barItem-${h.treatment}-${i}-${h.yield}`} className="chartItemContainer">
										<div className="itemText">
											<div className="itemHybrid">{h.treatment}</div>
										</div>
										<div className="itemBarContainer">
											<div className="itemBar" style={{ width: `${(h.yield / maxYield) * 100}%`, background: `${h.is_basf ? "#65ac1e" : "#a3a1a2"}` }} >{h.yield.toFixed(1)}</div>
										</div>
									</div>
								</div>
							)
						})}
				</div>
				: null)
		}
		else {
			maxYield = (trial && trial.treatments) ? this.getMaxYieldapt(trial.treatments) : 0;
			return (maxYield ?
				<div className="tabChartContainer">
					{this.props.individualTrial.data.indication === 'Herbicide' ? " " :
						<div className="chartLabel">
							<div className="chartLabelUnit">Yield (bu/ac.) {trial.data.year === 2019 ? this.getAdjustedTrial(trial.data.trial_type, true) ? <sup style={{ color: 'red' }}>*</sup> : null : null} </div>
						</div>
					}

					{this.props.individualTrial.data.indication === 'Herbicide' ? " " :
						<React.Fragment>
							{trial.treatments.sort(firstBy("is_basf", -1).thenBy("treatment"))
								.map((h, i) => (
									<div key={`barItem-${h.treatment}-${i}-${h.yield}`} className="chartItemContainer">
										<div className="itemText">
											<div className="itemHybrid">{h.treatment}</div>
										</div>
										<div className="itemBarContainer">
											<div className="itemBar" style={{ width: `${(h.yield / maxYield) * 100}%`, background: `${h.is_basf ? "#65ac1e" : "#a3a1a2"}` }} >{h.yield.toFixed(1)}</div>
										</div>

									</div>

								))}
						</React.Fragment>
					}

					{trial.data.year === 2019 ?
						<div className="itemLabel">
							{this.getAdjustedTrial(trial.data.trial_type, true) ? <sup style={{ color: 'red' }}>*</sup> : null} {this.getAdjustedTrial(trial.data.trial_type, true)}
						</div>
						: null}
				</div>
				: null)
		}
	}


	getAPTTables = (trial, trialResults) => {

		const aptData = trialResults.trialResults;  //Destructuring to map trialresults
		//console.log(aptData, "aptData")

		//First Fungicide Application
		const ffa = aptData.map(ffapp => (
			ffapp.trial_results.filter(trial => trial.description === 'First Fungicide Application ')
		))

		//First Flower Fungicide Application
		const fffa = aptData.map(fffapp => (
			fffapp.trial_results.filter(trial => trial.description === 'First Flower Fungicide Application')
		))

		//First Flower Fungicide Application Date
		const fffad = aptData.map(fffappd => (
			fffappd.trial_results.filter(trial => trial.description === 'First Flower Fungicide Application Date')
		))

		//First Flower Fungicide Application Rate
		const fffar = aptData.map(fffappr => (
			fffappr.trial_results.filter(trial => trial.description === 'First Flower Fungicide Application Rate')
		))

		//First Flower Fungicide Application Rate
		const fffar1 = aptData.map(fffappr1 => (
			fffappr1.trial_results.filter(trial => trial.description === 'First Flower Fungicide App rate')
		))

		//Second Fungicide Application Date
		const sfad = aptData.map(sfappd => (
			sfappd.trial_results.filter(trial => trial.description === 'Second Fungicide Application Date')
		))

		//Second Fungicide Application 
		const sfa = aptData.map(sfapp => (
			sfapp.trial_results.filter(trial => trial.description === 'Second Fungicide Applied')
		))

		//Second Fungicide Application Date
		const sfar = aptData.map(sfappr => (
			sfappr.trial_results.filter(trial => trial.description === 'Second Fungicide Applied rate')
		))

		//Fungicide Application 
		const fungapp = aptData.map(fungappl => (
			fungappl.trial_results.filter(trial => trial.description === 'Fungicide Application')
		))

		//Fungicide Application Date
		const fungappd = aptData.map(fungappld => (
			fungappld.trial_results.filter(trial => trial.description === 'Fungicide Application Date')
		))

		//Fungicide Application Rate
		const fungappr = aptData.map(fungapplr => (
			fungapplr.trial_results.filter(trial => trial.description === 'Fungicide Applied rate')
		))

		//Sclerotinia Fungicide
		const scl_fung = aptData.map(sclfung => (
			sclfung.trial_results.filter(trial => trial.description === 'Sclerotinia Fungicide')
		))

		//Sclerotinia Fungicide Application Date
		const scl_fungad = aptData.map(sclfungad => (
			sclfungad.trial_results.filter(trial => trial.description === 'Sclerotinia Fungicide Application Date')
		))

		//Sclerotinia Fungicide Application Rate
		const scl_fungar = aptData.map(sclfungar => (
			sclfungar.trial_results.filter(trial => trial.description === 'Sclerotinia Fungicide Application Rate')
		))

		//Second Application Sclerotinia Fungicide
		const sascl_fung = aptData.map(sasclfung => (
			sasclfung.trial_results.filter(trial => trial.description === 'Second Application Sclerotinia Fungicide')
		))

		//Blackleg Fungicide
		const bfa = aptData.map(bfapp => (
			bfapp.trial_results.filter(trial => trial.description === 'Blackleg Fungicide')
		))

		//Flag Leaf Fungicide
		const flf = aptData.map(flfapp => (
			flfapp.trial_results.filter(trial => trial.description === 'Flag Leaf Fungicide')
		))

		//Seed Treatment Rate ----- Table Data
		const insectstr = aptData.map(insectstrapp => (
			insectstrapp.trial_results.filter(trial => trial.description === 'Insecticide Seed Treatment')
		))

		//Seed Treatment Rate ----- Table Data
		const insectstrar = aptData.map(insectstrarapp => (
			insectstrarapp.trial_results.filter(trial => trial.description === 'Insecticide Seed Treatment application rate')
		))

		//Treatment
		const teraxxatreatment = aptData.map(teraxxatreat => (
			teraxxatreat.trial_results.filter(trial => trial.description.includes('Fungicide Seed Treatment used'))
		))

		//Crop Stage at Fungicide Application
		const csfa = aptData.map(csfapp => (
			csfapp.trial_results.filter(trial => trial.description === 'Crop Stage at Fungicide Application')
		))

		//Seed Treatment Application Rate
		const star = aptData.map(sta => (
			sta.trial_results.filter(trial => trial.description === 'Seed Treatment Application Rate')
		))

		//Foliar Fungicide
		const fol_fung = aptData.map(folfung => (
			folfung.trial_results.filter(trial => trial.description === 'Foliar Fungicide Applied')
		))

		//FHB Fungicide
		const fhb_fung = aptData.map(fhbfung => (
			fhbfung.trial_results.filter(trial => trial.description === 'FHB Fungicide Applied')
		))

		//FHB Fungicide Application Date
		const fhb_fungad = aptData.map(fhbfungadate => (
			fhbfungadate.trial_results.filter(trial => trial.description === 'FHB Fungicide Application Date')
		))

		//FHB Fungicide Application Rate
		const fhb_fungar = aptData.map(fhbfungarate => (
			fhbfungarate.trial_results.filter(trial => trial.description === 'FHB Fungicide Application Rate')
		))

		//Moisture
		const moisture = aptData.map(moist => (
			moist.trial_results.filter(trial => trial.description === 'Moisture (%)')
		))
		
		//Dockage
		const dockage = aptData.map(dock => (
			dock.trial_results.filter(trial => trial.description === 'Dockage (%)')
		))

		//Grade
		const grade = aptData.map(grad => (
			grad.trial_results.filter(trial => trial.description === 'Grade')
		))

		//Base Seed Treatment Used
		const bstu = aptData.map(bst => (
			bst.trial_results.filter(trial => trial.description === 'Base Seed Treatment Used')
		))

		//Base Seed Treatment Application Rate
		const bstar = aptData.map(bsta => (
			bsta.trial_results.filter(trial => trial.description === 'Base Seed Treatment Application Rate')
		))

		//Insecticide Tank Mix Partner
		const itmp = aptData.map(itmpa => (
			itmpa.trial_results.filter(trial => trial.description === 'Insecticide Tank Mix Partner')
		))

		//Insecticide Tank Mix Partner Rate
		const itmpr = aptData.map(itmpar => (
			itmpar.trial_results.filter(trial => trial.description === 'Insecticide Tank Mix Partner Rate')
		))
		
		//Flag Leaf Fungicide Application Date
		const flfad = aptData.map(flfa => (
			flfa.trial_results.filter(trial => trial.description === 'Flag Leaf Fungicide Application Date')
		))

		//Flag Leaf Fungicide Application Rate
		const flfar = aptData.map(flfara => (
			flfara.trial_results.filter(trial => trial.description === 'Flag Leaf Fungicide Application Rate')
		))

		//Head Fungicide
		const headfung = aptData.map(headf => (
			headf.trial_results.filter(trial => trial.description === 'Head Fungicide')
		))

		//Head Fungicide Application Date
		const headfungad = aptData.map(headfungadate => (
			headfungadate.trial_results.filter(trial => trial.description === 'Head Fungicide Application Date')
		))

		//Head Fungicide Application Rate
		const headfungar = aptData.map(headfungarate => (
			headfungarate.trial_results.filter(trial => trial.description === 'Head Fungicide Application Rate')
		))

		//Herbicide Application
		const herba = aptData.map(herbapp => (
			herbapp.trial_results.filter(trial => trial.description === 'Herbicide Applied')
		))

		//Herbicide Application Date
		const herbad = aptData.map(herbappdate => (
			herbappdate.trial_results.filter(trial => trial.description === 'Herbicide Application Date')
		))

		//Last Year Canola was in Rotation
		const lycir = aptData.map(lycirotation => (
			lycirotation.trial_results.filter(trial => trial.description === 'Last Year Canola was in Rotation')
		))

		//Solutions
		const solutions = aptData.map(sol => (
			sol.trial_results.filter(trial => trial.description === 'Solutions')
		))

		//Herbicide Trials
		//Residual Pre-Seed Herbicide Rate
		const rsphr = aptData.map(rspherbicide => (
			rspherbicide.trial_results.filter(trial => trial.description === 'Residual Pre-Seed Herbicide')
		))

		//Pre-Seed Tank-mix Herbicide Rate
		const pstmhr = aptData.map(pstherbicide => (
			pstherbicide.trial_results.filter(trial => trial.description === 'Pre-Seed Tank-mix Herbicide')
		))

		//Pre-Harvest Application Date
		const phad = aptData.map(phadate => (
			phadate.trial_results.filter(trial => trial.description === 'Pre-Harvest Application Date')
		))

		//Pre-Harvest Application Herbicide
		const phah = aptData.map(phaherbicide => (
			phaherbicide.trial_results.filter(trial => trial.description === 'Pre-Harvest Application Herbicide')
		))

		//Pre-Seed Herbicide Application Date
		const pshad = aptData.map(pshadate => (
			pshadate.trial_results.filter(trial => trial.description === 'Pre-Seed Herbicide Application Date')
		))

		//Herbicide Water Volume
		const hwv = aptData.map(hwvolume => (
			hwvolume.trial_results.filter(trial => trial.description === 'Herbicide Water Volume')
		))

		//In-Crop Herbicide Tank-Mix Partner
		const ictmhr = aptData.map(ictherbicide => (
			ictherbicide.trial_results.filter(trial => trial.description === 'In-Crop Herbicide Tank-Mix Partner')
		))

		//Second In-Crop Herbicide
		const sichr = aptData.map(sicherbicide => (
			sicherbicide.trial_results.filter(trial => trial.description === 'Second In-Crop Herbicide')
		))

		//Second In-Crop Herbicide
		const glyphosate = aptData.map(glyph => (
			glyph.trial_results.filter(trial => trial.description === 'Glyphosate Used')
		))

		//FCC Trials
		//Harvest Date
		const harvest_date = aptData.map(harvest => (
			harvest.trial_results.filter(trial => trial.description === 'Harvest Date')
		))

		//On Seed Inoculant Used
		const inc_used = aptData.map(incused => (
			incused.trial_results.filter(trial => trial.description === 'On Seed Inoculant Used')
		))

		//In-Furrow Inoculant Used
		const infc_used = aptData.map(infcused => (
			infcused.trial_results.filter(trial => trial.description === 'In-Furrow Inoculant Used')
		))

		//Seed Treatment Used
		const seed_treatment = aptData.map(seedt => (
			seedt.trial_results.filter(trial => trial.description === 'Seed Treatment Used')
		))

		//Seed Treatment Used
		const seedingDate = aptData.map(seedD => (
			seedD.trial_results.filter(trial => trial.description === 'Seeding Date')
		))

		//Heading Timing Fungicide
		const headingTimingFung = aptData.map(seedD => (
			seedD.trial_results.filter(trial => trial.description === 'Head Timing Fungicide')
		))

		//Head Timing Fungicide Application Rate
		const headingTimingFungAppRate = aptData.map(seedD => (
			seedD.trial_results.filter(trial => trial.description === 'Head Timing Fungicide Application Rate')
		))

		//Head Timing Application Date
		const headingTimingFungAppDate = aptData.map(seedD => (
			seedD.trial_results.filter(trial => trial.description === 'Head Timing Application Date')
		))	
		
		//Head Timing Application Date
		const headingTimingFungAppRateUnits = aptData.map(seedD => (
			seedD.trial_results.filter(trial => trial.description === 'Head Timing Fungicide Application rate units')
		))		

		//console.log(trial.data.trial_type, "TT")

		// Start of Fungicide Tables - 2017 + 2018

		//2017 - Cotegra and 2018 - Cotegra on Soyabeans + Cotegra Timing on Canola
		if (((trial.data.year === 2017) && (trial.data.trial_type.includes('Cotegra'))) || ((trial.data.year === 2018) && (trial.data.trial_type.includes('Cotegra on Soybeans'))) ||
			((trial.data.year === 2018) && (trial.data.trial_type.includes('Cotegra Timing on Canola')))) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									{trial.data.year === 2018 ? <td className="cell cellHead">Solutions</td> : null}
									<td className="cell cellHead">Sclerotinia Fungicide</td>
									{trial.data.trial_type.includes('Cotegra Timing on Canola') ? <td className="cell cellHead">Second Application Sclerotinia Fungicide</td> : null}
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											{trial.data.year === 2018 ? <td className="cell">{solutions[i] && solutions[i].length > 0 ? solutions[i][0].data : "N/A"}</td> : null}
											<td className="cell">{scl_fung[i] && scl_fung[i].length > 0 ? scl_fung[i][0].data : "N/A"}</td>
											{trial.data.trial_type.includes('Cotegra Timing on Canola') ? <td className="cell">{sascl_fung[i] && sascl_fung[i].length > 0 ? sascl_fung[i][0].data : "N/A"}</td> : null}
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}

		else if (trial.data.trial_type.includes('Nexicor') || trial.data.trial_type === '2-Pass Fungicide on Canola' || trial.data.trial_type.includes('Early Disease Control in Oats')) {  //2017 + 2018 -- Everything related to Nexicor
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									{trial.data.year === 2018 ? <td className="cell cellHead">Solutions</td> : null}
									{(trial.data.trial_type.includes('Nexicor on Cereals (Timing)')) || (trial.data.trial_type.includes('Nexicor on Cereals')) ? <td className="cell cellHead">Flag Leaf Fungicide</td> : null}
									{trial.data.trial_type.includes('Nexicor on Cereals') ? <td className="cell cellHead">Crop Stage at Fungicide Application</td> : null}
									{(trial.data.trial_type.includes('Nexicor on Canola')) || (trial.data.trial_type === '2-Pass Fungicide on Canola') ? <td className="cell cellHead">Blackleg Fungicide</td> : null}
									{trial.data.trial_type === '2-Pass Fungicide on Canola' ? <td className="cell cellHead">Sclerotinia Fungicide</td> : null}
									{trial.data.trial_type.includes('Early Disease Control in Oats') ? <td className="cell cellHead">Seed Treatment Application Rate</td> : null}
									{trial.data.trial_type.includes('Early Disease Control in Oats') ? <td className="cell cellHead">Foliar Fungicide</td> : null}
									{trial.data.trial_type.includes('Early Disease Control in Oats') ? <td className="cell cellHead">Harvest Date</td> : null}
									{(trial.data.trial_type.includes('Nexicor on Cereals (Timing)')) || (trial.data.trial_type.includes('Nexicor on Cereals')) || (trial.data.trial_type === '2-Pass Fungicide on Canola') ||
										(trial.data.trial_type.includes('Early Disease Control in Oats')) || (trial.data.trial_type.includes('Nexicor on Cereals (Yield Only)')) || (trial.data.trial_type.includes('Nexicor on Canola'))
										? <td className="cell cellHead">Yield</td> : null}
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											{trial.data.year === 2018 ? <td className="cell">{solutions[i] && solutions[i].length > 0 ? solutions[i][0].data : "N/A"}</td> : null}
											{(trial.data.trial_type.includes('Nexicor on Cereals (Timing)')) || (trial.data.trial_type.includes('Nexicor on Cereals')) ? <td className="cell">{flf[i] && flf[i].length > 0 ? flf[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Nexicor on Cereals') ? <td className="cell">{csfa[i] && csfa[i].length > 0 ? csfa[i][0].data : "N/A"}</td> : null}
											{(trial.data.trial_type.includes('Nexicor on Canola')) || (trial.data.trial_type === '2-Pass Fungicide on Canola') ? <td className="cell">{bfa[i] && bfa[i].length > 0 ? bfa[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type === '2-Pass Fungicide on Canola' ? <td className="cell">{scl_fung[i] && scl_fung[i].length > 0 ? scl_fung[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Early Disease Control in Oats') ? <td className="cell">{star[i] && star[i].length > 0 ? star[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Early Disease Control in Oats') ? <td className="cell">{fol_fung[i] && fol_fung[i].length > 0 ? fol_fung[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Early Disease Control in Oats') ? <td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td> : null}
											{(trial.data.trial_type.includes('Nexicor on Cereals (Timing)')) || (trial.data.trial_type.includes('Nexicor on Cereals')) || trial.data.trial_type === '2-Pass Fungicide on Canola' ||
												(trial.data.trial_type.includes('Early Disease Control in Oats')) || (trial.data.trial_type.includes('Nexicor on Cereals (Yield Only)')) || (trial.data.trial_type.includes('Nexicor on Canola'))
												?
												<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td> : null}
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}

		else if ((trial.data.year === 2018 || trial.data.year === 2017) && (trial.data.trial_type.includes('Dyax') || trial.data.trial_type === '2-Pass Fungicide on Pulses')) { //2017 + 2018 -- Everything related to Dyax and One Pass
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									{trial.data.year === 2018 ? <td className="cell cellHead">Solutions</td> : null}
									{(trial.data.trial_type !== '2-Pass Fungicide on Pulses') && (trial.data.trial_type.includes('Dyax on Peas')) ? <td className="cell cellHead">Mycosphaerella Fungicide</td> : null}
									{trial.data.trial_type.includes('Dyax on Lentils') ? <td className="cell cellHead">First Fungicide Application</td> : null}
									{(trial.data.trial_type.includes('Dyax on Pulses')) || (trial.data.trial_type === '2-Pass Fungicide on Pulses') ? <td className="cell cellHead">First Flower Fungicide Application</td> : null}
									{trial.data.trial_type === '2-Pass Fungicide on Pulses' ? <td className="cell cellHead">Sclerotinia Fungicide</td> : null}
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											{trial.data.year === 2018 ? <td className="cell">{solutions[i] && solutions[i].length > 0 ? solutions[i][0].data : "N/A"}</td> : null}
											{(trial.data.trial_type !== '2-Pass Fungicide on Pulses') && (trial.data.trial_type.includes('Dyax on Peas')) ? <td className="cell"></td> : null}
											{trial.data.trial_type.includes('Dyax on Lentils') ? <td className="cell">{ffa[i] && ffa[i].length > 0 ? ffa[i][0].data : "N/A"}</td> : null}
											{(trial.data.trial_type.includes('Dyax on Pulses')) || (trial.data.trial_type === '2-Pass Fungicide on Pulses') ? <td className="cell">{fffa[i] && fffa[i].length > 0 ? fffa[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type === '2-Pass Fungicide on Pulses' ? <td className="cell">{scl_fung[i] && scl_fung[i].length > 0 ? scl_fung[i][0].data : "N/A"}</td> : null}
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}

		else if (((trial.data.year === 2018) && (trial.data.trial_type.includes('Caramba on Wheat'))) ||
			((trial.data.year === 2018) && (trial.data.trial_type.includes('Fusarium Management System')))) { //Caramba vs Prosaro XTR
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									{trial.data.year === 2018 ? <td className="cell cellHead">Solutions</td> : null}
									<td className="cell cellHead">FHB Fungicide</td>
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											{trial.data.year === 2018 ? <td className="cell">{solutions[i] && solutions[i].length > 0 ? solutions[i][0].data : "N/A"}</td> : null}
											<td className="cell">{fhb_fung[i] && fhb_fung[i].length > 0 ? fhb_fung[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>

								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
		else if ((trial.data.year === 2019) && (trial.data.trial_type === '2-Pass Fungicide on Cereals') || (trial.data.year === 2019) && (trial.data.trial_type === 'Fungicide Timing on Cereals')
			|| (trial.data.year === 2019) && (trial.data.trial_type === 'Cotegra on Canola') || (trial.data.year === 2019) && (trial.data.trial_type === 'Dyax on Field Peas') || (trial.data.year === 2019) && (trial.data.trial_type === 'Dyax on Lentils')) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									{(!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
										? <td className="cell cellHead">Flag Leaf Fungicide</td> : null}
									{(!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
										? <td className="cell cellHead">Flag Leaf Product Application Date</td> : null}
									{(!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
										? <td className="cell cellHead">Flag Leaf Fungicide Application Rate</td> : null}
									{(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
										? <td className="cell cellHead">FHB Fungicide</td> : null}
									{(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
										? <td className="cell cellHead">FHB Fungicide Application Date</td> : null}
									{(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
										? <td className="cell cellHead">FHB Fungicide Application Rate</td> : null}
									{trial.data.trial_type.includes('Fungicide Timing on Cereals') ? <td className="cell cellHead">Head Fungicide</td> : null}
									{trial.data.trial_type.includes('Fungicide Timing on Cereals') ? <td className="cell cellHead">Head Fungicide Application Date</td> : null}
									{trial.data.trial_type.includes('Fungicide Timing on Cereals') ? <td className="cell cellHead">Head Fungicide Application Rate</td> : null}
									{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell cellHead">First Flower Fungicide Application</td> : null}
									{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell cellHead">First Flower Fungicide Application Date</td> : null}
									{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell cellHead">First Flower Fungicide Application Rate</td> : null}
									{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') || trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Sclerotinia Fungicide</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Sclerotinia Fungicide Application Date</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Sclerotinia Fungicide Application Rate</td> : null}
									{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell cellHead">Herbicide Application</td> : null}
									{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell cellHead">Herbicide Application Date</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Blackleg Fungicide</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Last year Canola was in Rotation</td> : null}
									{trial.data.trial_type.includes('Fungicide Timing on Cereals') || trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Crop Stage at Fungicide Application</td> : null}
									{/* {(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
										? <td className="cell cellHead">Seed Treatment Used</td> : null}
									{(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
										? <td className="cell cellHead">Seed Treatment Rate</td> : null} */}
									<td className="cell cellHead">Harvest Date</td>
									<td className="cell cellHead">Moisture %</td>
									<td className="cell cellHead">Dockage %</td>
									<td className="cell cellHead">Grade</td>
									<td className="cell cellHead">Adjusted Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											{(!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
												? <td className="cell">{flf[i] && flf[i].length > 0 ? flf[i][0].data : "N/A"}</td> : null}
											{(!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
												? <td className="cell">{flfad[i] && flfad[i].length > 0 ? flfad[i][0].data : "N/A"}</td> : null}
											{(!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
												? <td className="cell">{flfar[i] && flfar[i].length > 0 ? flfar[i][0].data : "N/A"}</td> : null}
											{(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
												? <td className="cell">{fhb_fung[i] && fhb_fung[i].length > 0 ? fhb_fung[i][0].data : "N/A"}</td> : null}
											{(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
												? <td className="cell">{fhb_fungad[i] && fhb_fungad[i].length > 0 ? fhb_fungad[i][0].data : "N/A"}</td> : null}
											{(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
												? <td className="cell">{fhb_fungad[i] && fhb_fungar[i].length > 0 ? fhb_fungar[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Fungicide Timing on Cereals') ? <td className="cell">{headfung[i] && headfung[i].length > 0 ? headfung[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Fungicide Timing on Cereals') ? <td className="cell">{headfungad[i] && headfungad[i].length > 0 ? headfungad[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Fungicide Timing on Cereals') ? <td className="cell">{headfungar[i] && headfungar[i].length > 0 ? headfungar[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell">{fffa[i] && fffa[i].length > 0 ? fffa[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell">{fffad[i] && fffad[i].length > 0 ? fffad[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell">{fffar[i] && fffar[i].length > 0 ? fffar[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') || trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{scl_fung[i] && scl_fung[i].length > 0 ? scl_fung[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{scl_fungad[i] && scl_fungad[i].length > 0 ? scl_fungad[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{scl_fungar[i] && scl_fungar[i].length > 0 ? scl_fungar[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell">{herba[i] && herba[i].length > 0 ? herba[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Dyax on Lentils') || trial.data.trial_type.includes('Dyax on Field Peas') ? <td className="cell">{herbad[i] && herbad[i].length > 0 ? herbad[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{bfa[i] && bfa[i].length > 0 ? bfa[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{lycir[i] && lycir[i].length > 0 ? lycir[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Fungicide Timing on Cereals') || trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{csfa[i] && csfa[i].length > 0 ? csfa[i][0].data : "N/A"}</td> : null}
											{/* {(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
												? <td className="cell">{seed_treatment[i] && seed_treatment[i].length > 0 ? seed_treatment[i][0].data : "N/A"}</td> : null}
											{(!trial.data.trial_type.includes('Fungicide Timing on Cereals')) && (!trial.data.trial_type.includes('Dyax on Lentils')) && (!trial.data.trial_type.includes('Dyax on Field Peas')) && (!trial.data.trial_type.includes('Cotegra on Canola'))
												? <td className="cell">{star[i] && star[i].length > 0 ? star[i][0].data : "N/A"}</td> : null} */}
											<td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td>
											<td className="cell">{moisture[i] && moisture[i].length > 0 ? moisture[i][0].data : "N/A"}</td>
											<td className="cell">{dockage[i] && dockage[i].length > 0 ? dockage[i][0].data : "N/A"}</td>
											<td className="cell">{grade[i] && grade[i].length > 0 ? grade[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
		// End of Fungicide Tables - 2017 + 2018 + 2019
/*
		//Herbicide 2017 + 2018 Tables
		if ((trial.data.year === 2018) && (trial.data.trial_type === 'Heat LQ Pre-harvest on Canola (Water volume)') || ((trial.data.year === 2018 || trial.data.year === 2017) && (trial.data.trial_type === 'Heat LQ Pre-harvest on Cereals'))) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									<td className="cell cellHead">Pre-Harvest Application Herbicide</td>
									<td className="cell cellHead">Pre-Harvest Application Date</td>
									{trial.data.trial_type === 'Heat LQ Pre-harvest on Canola (Water volume)' ? <td className="cell cellHead">Herbicide Water Volume</td> : null}
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											<td className="cell">{phah[i] && phah[i].length > 0 ? phah[i][0].data : "N/A"}</td>
											<td className="cell">{phad[i] && phad[i].length > 0 ? phad[i][0].data : "N/A"}</td>
											{trial.data.trial_type === 'Heat LQ Pre-harvest on Canola (Water volume)' ? <td className="cell">{hwv[i] && hwv[i].length > 0 ? hwv[i][0].data : "N/A"}</td> : null}
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
*/

		if ((trial.data.year === 2018) && (trial.data.trial_type.includes('Heat Complete on Corn')) || (trial.data.year === 2017) && (trial.data.trial_type.includes('Heat Complete on Field Peas'))
			|| ((trial.data.year === 2018 || trial.data.year === 2017) && (trial.data.trial_type === 'Heat Complete on Lentils')) || (trial.data.year === 2018) && (trial.data.trial_type.includes('Heat Complete on Field Peas'))
			|| ((trial.data.year === 2018 || trial.data.year === 2017) && (trial.data.trial_type.includes('Heat Complete on Soybeans'))) || ((trial.data.year === 2018 || trial.data.year === 2017) && (trial.data.trial_type === 'Armezon + Zidua on Corn'))
			|| (trial.data.year === 2017) && (trial.data.trial_type === 'Armezon - 2 Applications on Corn') || ((trial.data.year === 2018 || trial.data.year === 2017) && (trial.data.trial_type === 'Facet L Pre-seed on Canola'))) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									<td className="cell cellHead">Residual Pre-Seed Herbicide</td>
									{trial.data.trial_type.includes('Heat Complete on Field Peas') || trial.data.trial_type === 'Heat Complete on Lentils' || trial.data.trial_type.includes('Heat Complete on Soybeans')
										|| trial.data.trial_type.includes('Heat Complete on Field Peas') ? <td className="cell cellHead">Pre-Seed Herbicide Application Date</td> : null}
									{trial.data.trial_type !== 'Facet L Pre-seed on Canola' ? <td className="cell cellHead">Pre-Seed Tank-mix Herbicide</td> : null}
									{trial.data.trial_type === 'Armezon + Zidua on Corn' ? <td className="cell cellHead">In-Crop Herbicide Tank-Mix Partner</td> : null}
									{trial.data.trial_type === 'Armezon - 2 Applications on Corn' ? <td className="cell cellHead">Second In-Crop Herbicide</td> : null}
									{(trial.data.year === 2017 && trial.data.trial_type === 'Facet L Pre-seed on Canola') ? <td className="cell cellHead">Glyphosate Used</td> : null}
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											<td className="cell">{rsphr[i] && rsphr[i].length > 0 ? rsphr[i][0].data : "N/A"}</td>
											{trial.data.trial_type.includes('Heat Complete on Field Peas') || trial.data.trial_type === 'Heat Complete on Lentils' || trial.data.trial_type.includes('Heat Complete on Soybeans')
												|| trial.data.trial_type.includes('Heat Complete on Field Peas') ? <td className="cell">{pshad[i] && pshad[i].length > 0 ? pshad[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type !== 'Facet L Pre-seed on Canola' ? <td className="cell">{pstmhr[i] && pstmhr[i].length > 0 ? pstmhr[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type === 'Armezon + Zidua on Corn' ? <td className="cell">{ictmhr[i] && ictmhr[i].length > 0 ? ictmhr[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type === 'Armezon - 2 Applications on Corn' ? <td className="cell">{sichr[i] && sichr[i].length > 0 ? sichr[i][0].data : "N/A"}</td> : null}
											{(trial.data.year === 2017 && trial.data.trial_type === 'Facet L Pre-seed on Canola') ? <td className="cell">{glyphosate[i] && glyphosate[i].length > 0 ? glyphosate[i][0].data : "N/A"}</td> : null}
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>

										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}


		// Start of FCC Trials - 2017 + 2018 ---- All the varied combinations in the table below
		else if ((trial.data.trial_type.includes('Insure Cereal FX4 on Cereals')) || (trial.data.trial_type.includes('Insure Pulse on Soybeans')) || (trial.data.trial_type.includes('Insure Pulse in Soybeans'))
			|| (trial.data.trial_type.includes('Double vs Single Inoculation in Pulses')) || ((trial.data.year === 2017) && (trial.data.trial_type.includes('Nodulator Duo on Peas or Lentils')))) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									{(trial.data.year === 2018 && (trial.data.trial_type.includes('Double vs Single Inoculation in Pulses'))) ? <td className="cell cellHead">Solutions</td> : null}
									{!trial.data.trial_type.includes('Double vs Single Inoculation in Pulses') ? <td className="cell cellHead">Seed Treatment Used</td> : null}
									<td className="cell cellHead">Seeding Date</td>
									{(trial.data.trial_type.includes('Insure Pulse on Soybeans')) || (trial.data.trial_type.includes('Insure Pulse in Soybeans')) || (trial.data.trial_type.includes('Double vs Single Inoculation in Pulses'))
										? <td className="cell cellHead">On Seed Inoculant Used</td> : null}
									{(trial.data.trial_type.includes('Insure Pulse on Soybeans')) || (trial.data.trial_type.includes('Insure Pulse in Soybeans')) || (trial.data.trial_type.includes('Double vs Single Inoculation in Pulses')) || (trial.data.trial_type.includes('Nodulator Duo on Peas or Lentils'))
										? <td className="cell cellHead">In-Furrow Inoculant Used</td> : null}
									<td className="cell cellHead">Harvest Date</td>
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											{(trial.data.year === 2018 && (trial.data.trial_type.includes('Double vs Single Inoculation in Pulses'))) ? <td className="cell">{solutions[i] && solutions[i].length > 0 ? solutions[i][0].data : "N/A"}</td> : null}
											{!trial.data.trial_type.includes('Double vs Single Inoculation in Pulses') ? <td className="cell">{seed_treatment[i] && seed_treatment[i].length > 0 ? seed_treatment[i][0].data : "N/A"}</td> : null}
											<td className="cell">{seedingDate[i] && seedingDate[i].length > 0 ? seedingDate[i][0].data : 'NA'}</td>
											{(trial.data.trial_type.includes('Insure Pulse on Soybeans')) || (trial.data.trial_type.includes('Insure Pulse in Soybeans')) || (trial.data.trial_type.includes('Double vs Single Inoculation in Pulses'))
												? <td className="cell">{inc_used[i] && inc_used[i].length > 0 ? inc_used[i][0].data : "N/A"}</td> : null}
											{(trial.data.trial_type.includes('Insure Pulse on Soybeans')) || (trial.data.trial_type.includes('Insure Pulse in Soybeans')) || (trial.data.trial_type.includes('Double vs Single Inoculation in Pulses')) || (trial.data.trial_type.includes('Nodulator Duo on Peas or Lentils')) ?
												<td className="cell">{infc_used[i] && infc_used[i].length > 0 ? infc_used[i][0].data : "N/A"}</td> : null}
											<td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}
		// End of FCC Trials - 2017 + 2018

		// 2019 Teraxxa
		if ((trial.data.year === 2019) && (trial.data.trial_type === 'Teraxxa F4 on Wheat & Barley')) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									<td className="cell cellHead">Base Seed Treatment used</td>
									<td className="cell cellHead">Base Seed Treatment Application rate</td>
									<td className="cell cellHead">Insecticide Tank Mix Partner</td>
									<td className="cell cellHead">Insecticide Tank Mix Partner rate</td>
									<td className="cell cellHead">Harvest Date</td>
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											<td className="cell">{bstu[i] && bstu[i].length > 0 ? bstu[i][0].data : "N/A"}</td>
											<td className="cell">{bstar[i] && bstar[i].length > 0 ? bstar[i][0].data : "N/A"}</td>
											<td className="cell">{itmp[i] && itmp[i].length > 0 ? itmp[i][0].data : "N/A"}</td>
											<td className="cell">{itmpr[i] && itmpr[i].length > 0 ? itmpr[i][0].data : "N/A"}</td>
											<td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}


		//2020 Tables
		if ((trial.data.year === 2020) && (trial.data.trial_type === 'Teraxxa F4 on Wheat & Barley')) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
								    <td className="cell cellHead">Treatment</td>
									<td className="cell cellHead">Seed Treatment Application Rate</td>
									<td className="cell cellHead">Insecticide Seed Treatment</td>
									<td className="cell cellHead">Insecticide Seed Treatment Application Rate</td>
									<td className="cell cellHead">Harvest Date</td>
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
                                            <td className="cell">{teraxxatreatment[i] && teraxxatreatment[i].length > 0 ? teraxxatreatment[i][0].data : "N/A"}</td>
											<td className="cell">{star[i] && star[i].length > 0 ? star[i][0].data : "N/A"}</td>
											<td className="cell">{insectstr[i] && insectstr[i].length > 0 ? insectstr[i][0].data : "N/A"}</td>
											<td className="cell">{insectstrar[i] && insectstrar[i].length > 0 ? insectstrar[i][0].data : "N/A"}</td>
											<td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}


		if ((trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') || (trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils')
		|| (trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra on Canola')) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ?
										<td className="cell cellHead">Fungicide Application </td> : null}
									{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ?
										<td className="cell cellHead">Fungicide Application Date</td> : null}
									{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ?
										<td className="cell cellHead">Fungicide application Rate</td> : null}
									{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ?
									<td className="cell cellHead">First Flower Fungicide Application </td> : null}
									{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ?
									<td className="cell cellHead">First Flower Fungicide Application Date</td> : null}
									{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ?
									<td className="cell cellHead">First Flower Fungicide application Rate</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Sclerotinia Fungicide</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Sclerotinia Fungicide Application Date</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Sclerotinia Fungicide Application Rate</td> : null}
									{(trial.data.trial_type !== 'Cotegra on Canola') ? <td className="cell cellHead">Second Fungicide Application </td> : null}
									{(trial.data.trial_type !== 'Cotegra on Canola') ? <td className="cell cellHead">Second Fungicide Application Date</td> : null}
									{(trial.data.trial_type !== 'Cotegra on Canola') ? <td className="cell cellHead">Second Fungicide Application Rate</td> : null}
									<td className="cell cellHead">Harvest Date</td>
									<td className="cell cellHead">Yield</td>
									<td className="cell cellHead">% Moisture</td>
									<td className="cell cellHead">Dockage</td>
									<td className="cell cellHead">Grade</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
										{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ?
										<td className="cell">{fungapp[i] && fungapp[i].length > 0 ? fungapp[i][0].data : "N/A"}</td> : null}
										{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ?
											<td className="cell">{fungappd[i] && fungappd[i].length > 0 ? fungappd[i][0].data : "N/A"}</td> : null}
											{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ?
											<td className="cell">{fungappr[i] && fungappr[i].length > 0 ? fungappr[i][0].data : "N/A"}</td> : null}
											{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ?
											<td className="cell">{fffa[i] && fffa[i].length > 0 ? fffa[i][0].data : "N/A"}</td> : null}
											{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ?
											<td className="cell">{fffad[i] && fffad[i].length > 0 ? fffad[i][0].data : "N/A"}</td> : null}
											{(trial.data.year === 2020) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ?
											<td className="cell">{fffar1[i] && fffar1[i].length > 0 ? fffar1[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{scl_fung[i] && scl_fung[i].length > 0 ? scl_fung[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{scl_fungad[i] && scl_fungad[i].length > 0 ? scl_fungad[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{scl_fungar[i] && scl_fungar[i].length > 0 ? scl_fungar[i][0].data : "N/A"}</td> : null}
											{(trial.data.trial_type !== 'Cotegra on Canola') ? <td className="cell">{sfa[i] && sfa[i].length > 0 ? sfa[i][0].data : "N/A"}</td> : null}
											{(trial.data.trial_type !== 'Cotegra on Canola') ? <td className="cell">{sfad[i] && sfad[i].length > 0 ? sfad[i][0].data : "N/A"}</td> : null}
											{(trial.data.trial_type !== 'Cotegra on Canola') ? <td className="cell">{sfar[i] && sfar[i].length > 0 ? sfar[i][0].data : "N/A"}</td> : null}
											<td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
											<td className="cell">{moisture[i] && moisture[i].length > 0 ? moisture[i][0].data : "N/A"}</td>
											<td className="cell">{dockage[i] && dockage[i].length > 0 ? dockage[i][0].data : "N/A"}</td>
											<td className="cell">{grade[i] && grade[i].length > 0 ? grade[i][0].data : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}

		if ((trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') || (trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils')
		|| (trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra on Canola')) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ? <td className="cell cellHead">Fungicide Application </td> : null}
									{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ? <td className="cell cellHead">Fungicide Application Date</td> : null}
									{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ? <td className="cell cellHead">Fungicide application Rate</td> : null}
									{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ? <td className="cell cellHead">First Flower Fungicide Application </td> : null}
									{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ? <td className="cell cellHead">First Flower Fungicide Application Date</td> : null}
									{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ? <td className="cell cellHead">First Flower Fungicide application Rate</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Sclerotinia Fungicide</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Sclerotinia Fungicide Application Date</td> : null}
									{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell cellHead">Sclerotinia Fungicide Application Rate</td> : null}
									<td className="cell cellHead">Harvest Date</td>
									<td className="cell cellHead">Grade</td>
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
										{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ?
										<td className="cell">{fungapp[i] && fungapp[i].length > 0 ? fungapp[i][0].data : "N/A"}</td> : null}
										{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ?
											<td className="cell">{fungappd[i] && fungappd[i].length > 0 ? fungappd[i][0].data : "N/A"}</td> : null}
											{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Lentils') ?
											<td className="cell">{fungappr[i] && fungappr[i].length > 0 ? fungappr[i][0].data : "N/A"}</td> : null}
											{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ?
											<td className="cell">{fffa[i] && fffa[i].length > 0 ? fffa[i][0].data : "N/A"}</td> : null}
											{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ?
											<td className="cell">{fffad[i] && fffad[i].length > 0 ? fffad[i][0].data : "N/A"}</td> : null}
											{(trial.data.year === 2021) && (trial.data.trial_type === 'Cotegra & Dyax on Peas') ?
											<td className="cell">{fffar1[i] && fffar1[i].length > 0 ? fffar1[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{scl_fung[i] && scl_fung[i].length > 0 ? scl_fung[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{scl_fungad[i] && scl_fungad[i].length > 0 ? scl_fungad[i][0].data : "N/A"}</td> : null}
											{trial.data.trial_type.includes('Cotegra on Canola') ? <td className="cell">{scl_fungar[i] && scl_fungar[i].length > 0 ? scl_fungar[i][0].data : "N/A"}</td> : null}
											<td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td>
											<td className="cell">{grade[i] && grade[i].length > 0 ? grade[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}


		if ((trial.data.year === 2021) && 
			(trial.data.trial_type === 'Sphaerex on Barley' || trial.data.trial_type === 'Sphaerex on Oats' || trial.data.trial_type === 'Sphaerex in Wheat')) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
									<td className="cell cellHead">Head Timing Fungicide</td>
									<td className="cell cellHead">Head Timing Fungicide Application Rate</td>
									<td className="cell cellHead">Head Timing Fungicide Application Rate units</td>
									<td className="cell cellHead">Head Timing Fungicide Appplication Date</td>
									<td className="cell cellHead">Harvest Date</td>
									<td className="cell cellHead">Grade</td>
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
											<td className="cell">{headingTimingFung[i] && headingTimingFung[i].length > 0 ? headingTimingFung[i][0].data : "N/A"}</td>
											<td className="cell">{headingTimingFungAppRate[i] && headingTimingFungAppRate[i].length > 0 ? headingTimingFungAppRate[i][0].data : "N/A"}</td>
											<td className="cell">{headingTimingFungAppRateUnits[i] && headingTimingFungAppRateUnits[i].length > 0 ? headingTimingFungAppRateUnits[i][0].data : "N/A"}</td>
											<td className="cell">{headingTimingFungAppDate[i] && headingTimingFungAppDate[i].length > 0 ? headingTimingFungAppDate[i][0].data : "N/A"}</td>
											<td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td>
											<td className="cell">{grade[i] && grade[i].length > 0 ? grade[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}

		if ((trial.data.year === 2021) && (trial.data.trial_type === 'Insure Cereal FX4 in Cereals')) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
								    <td className="cell cellHead">Seed Treatment</td>
									<td className="cell cellHead">Seed Treatment Application Rate</td>
									{/*<td className="cell cellHead">Seed Treatment Application Rate units</td>*/}
									<td className="cell cellHead">Harvest Date</td>
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
                                            <td className="cell">{seed_treatment[i] && seed_treatment[i].length > 0 ? seed_treatment[i][0].data : "N/A"}</td>
											<td className="cell">{star[i] && star[i].length > 0 ? star[i][0].data : "N/A"}</td>
											{/*<td className="cell">{star[i] && star[i].length > 0 ? star[i][0].data : "N/A"}</td>*/}
											<td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}

		if ((trial.data.year === 2021) && (trial.data.trial_type === 'Teraxxa F4 in Wheat & Barley')) {
			return (
				<div className="trialTablePDFWrapper">
					<div className="apt_table">
						<table className="trialTableStatic">
							<tbody>
								<tr className="headerRow">
								    <td className="cell cellHead">Insecticide Seed Treatment Used</td>
									<td className="cell cellHead">Insecticide Seed treatment Application Rate</td>
									<td className="cell cellHead">Fungicide Seed Treatment Used</td>
									<td className="cell cellHead">Seed Treatment Application Rate</td>
									<td className="cell cellHead">Harvest Date</td>
									<td className="cell cellHead">Yield</td>
								</tr>

								{trial.treatments.map((trials, i) => (
									<React.Fragment>
										<tr>
                                            <td className="cell">{insectstr[i] && insectstr[i].length > 0 ? insectstr[i][0].data : "N/A"}</td>
											<td className="cell">{insectstrar[i] && insectstrar[i].length > 0 ? insectstrar[i][0].data : "N/A"}</td>
                                            <td className="cell">{teraxxatreatment[i] && teraxxatreatment[i].length > 0 ? teraxxatreatment[i][0].data : "N/A"}</td>
											<td className="cell">{star[i] && star[i].length > 0 ? star[i][0].data : "N/A"}</td>										
											<td className="cell">{harvest_date[i] && harvest_date[i].length > 0 ? harvest_date[i][0].data : "N/A"}</td>
											<td className="cell">{trials.yield ? trials.yield.toFixed(1) : "N/A"}</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			)
		}


	} // End of APTTables


	getAPTHeaderData = (trial, trialResults) => {

		const aptData = trialResults.trialResults;  

		//Seeding Date
		let seeding_date = '';
		aptData.map(seed => (
			seed.trial_results.filter(trial => trial.description === 'Seeding Date' ? seeding_date = trial.data : null)
		))

		//Variety
		let variety = '';
		aptData.map(vari => (
			vari.trial_results.filter(trial => trial.description === 'Variety' ? variety = trial.data : null)
		))

		//Last year Canola was in Rotation
		let lycwr = '';
		aptData.map(cwr => (
			cwr.trial_results.filter(trial => trial.description === 'Last year Canola was in Rotation' ? lycwr = trial.data : null)
		))

		//Seed Treatment Used ----- Table Data
		let stu = '';
		aptData.map(stru => (
			stru.trial_results.filter(trial => trial.description === 'Seed Treatment Used' ? stu = trial.data : null)
		))

		//Seed Treatment Rate ----- Table Data
		let seedtr = '';
		aptData.map(seedtru => (
			seedtru.trial_results.filter(trial => trial.description === 'Seed Treatment Application Rate' ? seedtr = trial.data : null)
		))

		//Seeding Rate
		let seedrate = '';
		aptData.map(sr => (
			sr.trial_results.filter(trial => trial.description === 'Seeding Rate' ? seedrate = trial.data : null)
		))

		const newData = {
			seeding_date,
			variety,
			lycwr,
			stu,
			seedtr,
			seedrate
		}

		return newData

	}

	getMaxYieldapt = (treatments) => {
		let maxYield = Math.max.apply(Math, treatments.map(function (h) { return h.yield; }));
		return maxYield;
	}


	imageClickHandler = (id) => {
		this.setState({
			imageIndex: (!this.state.toDisplay) ? id : null,
			toDisplay: !this.state.toDisplay
		});
	}

	getCarouselText = (photo, year) => {
		// NEW 2021 LOGIC TEXT UNDER CAROUSEL PHOTO TEXT (AKA FILL WITH METADATA)
		if (year >= 2021) {
			// IF PHOTO
			if (photo.attachmentType === 'photo' && photo.metadata != undefined) {
				return (
					<div className="metadata-div" style={{height:"70px"}}>
					{
					Object.keys(photo.metadata).map((key) => {	
						if (metadata_header_names[key] != undefined) {
							return (
								<div> 
									<text className="metadata-key">{metadata_header_names[key]}: </text> 
									<text>{key == 'photoDate' ? moment(photo.metadata[key]).format('DD-MM-YYYY') : photo.metadata[key]}</text>
								</div>	
							)
							}
						})
					}
					</div>
				)
			} else if (photo.attachmentType === 'pdf') {
				return (
					<a href={photo.attachmentURL} target = '_blank' style={{height:"71px",padding:"2px",textDecorationLine:"none",justifyContent:"center",alignItems:"center",display:"flex"}}>
						<div 
							className="carousel-pdf-button"
							data-tealium-category="link" 
							data-tealium-action="open pdf" 
							data-tealium-value={`link: ${photo.attachmentURL}`}
							data-tealium-type="link" 							
						>
							Open PDF 
						</div>
					</a>
				)
			}
			else return <div style={{height: "75px"}} > </div>	
		// OLD CAROUSEL PHOTO TEXT (AKA FILL WITH OLD DATE COMMENT)
		} else {
			if (photo.attachmentType === 'photo') {
				return (
					<div className="slideText">
						<b>Date: {new Date(photo.date).toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'long'})}</b> {photo.comment}
					</div>
				)
			} else if (photo.attachmentType === 'pdf') {
				return ( 
					<a href={photo.attachmentURL} target = '_blank' style={{padding:"2px",textDecorationLine:"none",justifyContent:"center",alignItems:"center",display:"flex"}}>
						<div 
							className="carousel-pdf-button"
							data-tealium-category="link" 
							data-tealium-action="open pdf" 
							data-tealium-value={`link: ${photo.attachmentURL}`}
							data-tealium-type="link" 	
						>
							Open PDF 
						</div>
					</a>
				)
			}
			else return <div style={{height: "31px"}} > </div>
		}
	};



	render() {
		/*
		const trial = this.props.trials.trials.filter((trial) => {
			return trial.data.trial === this.state.trialCode
		})[0];
		*/
		let trial = this.props.individualTrial;

		const settings = {
			dots: true,
			infinite: trial && trial.photos && trial.photos.length > 3 ? true : false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 1300,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: trial && trial.photos && trial.photos.length > 1 ? true : false,
						dots: true
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: trial && trial.photos && trial.photos.length > 1 ? true : false,
						dots: true,
						variableWidth: false
					}
				},
				{
					breakpoint: 738,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						initialSlide: 1,
						infinite: trial && trial.photos && trial.photos.length > 1 ? true : false,
						dots: true,
						variableWidth: false
					}
				},
			]
		};

        
        let APTHeaderData = {
			seeding_date: '',
			variety: '',
			lycwr: '',
			stu: '',
			seedtr: '',
			seedrate: ''
		}
		if(TrialsCategory === 'APT' && this.props.trialResults){
           APTHeaderData = this.getAPTHeaderData(trial, this.props.trialResults)
		}

		let treatments = [];

		if (trial) {
			if (trial.data.year !== 2019) {
				let sortedApplications = trial && trial.applications ? trial.applications.sort((a, b) => {
					if (a.applied < b.applied) {
						return -1;
					}
					if (a.applied > b.applied) {
						return 1;
					}
					return 0;
				}) : [];
				treatments = (trial && trial.applications) ? groupTreatments(sortedApplications) : null;

			} else {
				treatments = (trial && trial.applications) ? trial.applications : null;
			}
		}



		// console.log(trial && trial.applications ? trial.applications : "");
		// console.log(trial ? trial : "");

		// const RenderPDF = () => (
		// 	trial ?
		// 	<PDFViewer style={{ width: '100%', height: '800px'}}>
		// 		<IndividualTrialPDF trial={trial} treatments={treatments} agronomist={this.getAgronomistName(trial.data.agronomist_id)}/>
		// 	</PDFViewer> : null
		// )

		// return <RenderPDF />

		//Chartbar selection
		let oddRow = false;
		let trialHasHybrids = ""
		TrialsCategory === 'DST' ? trialHasHybrids = trial && trial.hybrids : trialHasHybrids = trial && trial.treatments;

		let trialHybridsSorted = [];
		//sort hybrids based on harvest method, and then whether or not they're BASF, and then alphabetically
		//im sorry
		if (trial && trial.data.trial_type.indexOf("vs") > -1) {
			trialHybridsSorted = trial && trial.hybrids ? [...trial.hybrids].sort((a, b) => {
				if (a.swath_type === b.swath_type) {
					if (a.is_basf === b.is_basf) {
						return (a.hybrid_sequence > b.hybrid_sequence) ? 1 : (a.hybrid_sequence < b.hybrid_sequence) ? -1 : 0;
					}
					return (a.is_basf < b.is_basf) ? 1 : (a.is_basf > b.is_basf) ? -1 : 0;
				}
				return (a.swath_type > b.swath_type) ? 1 : (a.swath_type < b.swath_type) ? -1 : 0;
			}) : [];
		} else {
			trialHybridsSorted = trial && trial.hybrids ? [...trial.hybrids].sort((a, b) => {
				if (a.is_basf === b.is_basf) {
					return (a.hybrid_sequence > b.hybrid_sequence) ? 1 : (a.hybrid_sequence < b.hybrid_sequence) ? -1 : 0;
				}
				return (a.is_basf < b.is_basf) ? 1 : (a.is_basf > b.is_basf) ? -1 : 0;
			}) : [];

			if (trial && this.state.PURL) {

				let apiBaseUrl = `${nodeserver_url}`;

				/*Start of call */
				let data = {
					event: this.props.page_params.trialCode,
					action: 'trial',
					category: 'trial'
				};

				axios
					.post(apiBaseUrl + `/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`, data)

			}
		}

		if (trial && this.state.PURL) {
			let apiBaseUrl = `${nodeserver_url}`;

			/*Start of call */
			let data = {
				event: this.props.page_params.trialCode,
				action: 'trial',
				category: 'trial'
			};

			axios
				.post(apiBaseUrl + `/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`, data)

		}

		return (
			<div>
				{/* <PDFExport ref={(component) => this.pdfExportComponent = component} paperSize="A4"> */}
				<div className={"chartTabsNavBar"}>
					<div className="trialNavContainer"></div>
					<div 
						onClick={() => this.props.page_router.loadPage('home')} 
						className="chartTabsNavItem"
						data-tealium-category="link" 
                        data-tealium-action="click individual-trial nav-bbar" 
                        data-tealium-value="Back to Map" 
                        data-tealium-type="navigation" 
					>
						<img src={backArrow} style={{ verticalAlign: "middle" }} alt="Back to Map" /> Back to Map
					</div>
					<div 
						onClick={() => this.refs.trialResultsAnchor.scrollIntoView({ behaviour: 'smooth' })} 
						className="chartTabsNavItem"
						data-tealium-category="link" 
                        data-tealium-action="click individual-trial nav-bbar" 
                        data-tealium-value="Trial Results" 
                        data-tealium-type="navigation" 
					>
						Trial Results
					</div>
					{trial && trial.comments ?
						<div 
							onClick={() => this.refs.commentsAnchor.scrollIntoView({ behaviour: 'smooth' })} 
							className="chartTabsNavItem"
							data-tealium-category="link" 
							data-tealium-action="click individual-trial nav-bbar" 
							data-tealium-value="Comments" 
							data-tealium-type="navigation" 
						>
							Comments
						</div> : 
					null
					}
					{trial && trial.photos ?
						<div 
							onClick={() => this.refs.photosAnchor.scrollIntoView({ behaviour: 'smooth' })} 
							className="chartTabsNavItem"
							data-tealium-category="link" 
							data-tealium-action="click individual-trial nav-bbar" 
							data-tealium-value="Photos" 
							data-tealium-type="navigation" 
						>
							Photos
						</div> : 
					null
					}
				</div>
				<div className="individualTrialPageContainer">
					{trial ?
						<React.Fragment>
							<div className="indTrialHeaderContainer" style={{ borderTop: `${TrialsCategory === 'DST' ? "6px solid #0061a1" : "6px solid #65ac1e"}`, background: `${TrialsCategory === 'DST' ? "#dbf0fe" : "#f6ffed"}` }}>
								<div className="infoContainer">
									{/* <div className="indTrialHeaderContainer" > */}
									{/* <div className="infoContainer"> */}
									<div className="left">
										<div className="leftHeader">
											<div className="indTrialText indTrialHead">{`${trial.data.city}, ${trial.data.province}`}</div>
											<div className="indTrialText">{TrialsCategory === 'DST' ? `Co-operator: ${trial.data.coopname}` : `Farm Name: ${trial.data.farm_name}`}</div>
											<div className="indTrialText">{TrialsCategory === 'DST' ? `${trial.data.trial_type.includes('Agronomic') ? `Agronomist` : `Technical Service Specialist`}: ${trial.data.agronomist}` : `Grower Name: ${trial.data.grower_name}`}</div>

											{/* APT Table Data */}
											{TrialsCategory === 'APT' ? <div style={{ marginTop: '20px' }}>
												<div className="indTrialText">Crop: <b>{trial.data.crop}</b></div>
												<div className="indTrialText">Variety: <b>{APTHeaderData.variety ? APTHeaderData.variety : 'N/A'}</b></div>
												{(trial.data.trial_type.includes('Fusarium Management System')) || (trial.data.trial_type.includes('Caramba on Wheat'))
												|| (trial.data.trial_type.includes('Cotegra & Dyax on Peas')) || (trial.data.trial_type.includes('Cotegra & Dyax on Lentils')) || 
												(trial.data.trial_type.includes('Cotegra on Canola'))
												?
													<div className="indTrialText">Seeding Rate: <b>{APTHeaderData.seedrate ? APTHeaderData.seedrate : 'N/A'}</b></div> : null}
												{(trial.data.year === 2019) && (trial.data.trial_type === '2-Pass Fungicide on Cereals') ?
													<div className="indTrialText">Seed Treatment Rate: <b>{APTHeaderData.seedtr ? APTHeaderData.seedtr : 'N/A'}</b></div> : null}
											</div> : null}
										</div>

										<div className="rightHeader">
											<div className="indTrialText indTrialHead" id="trialTypeHeader">{trial.data.trial_type}</div>
											<div className="indTrialText">{trial.data.year}</div>
											<div className="indTrialText">{TrialsCategory === 'DST' ? " " : trial.data.indication}</div>

											{/* APT Table Data  */}
											{TrialsCategory === 'APT' ? <div style={{ marginTop: '20px' }}>
												{(!trial.data.trial_type.includes('Insure')) && (!trial.data.trial_type.includes('Nodulator Duo on Peas or Lentils')) ? <div className="indTrialText"> Seeding Date: <b>{APTHeaderData.seeding_date ? 
												new Date(APTHeaderData.seeding_date).toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'long', day: 'numeric'}) : 'N/A'}</b></div> : null}
												{trial.data.trial_type === '2-Pass Fungicide on Canola' ? <div className="indTrialText">Last year Canola was in Rotation: <b>{APTHeaderData.lycwr ? APTHeaderData.lycwr : 'N/A'}</b></div> : null}
												{trial.data.trial_type.includes('Caramba on Wheat') || (trial.data.year === 2019) && (trial.data.trial_type === '2-Pass Fungicide on Cereals') ? <div className="indTrialText">Seed Treatment Used: <b>{APTHeaderData.stu ? APTHeaderData.stu : 'N/A'}</b></div> : null}
											</div> : null}
										</div>

									</div>
									<div className="right">
										<div className="buttonsContainer">
											<Sharebuttons title={`${trial.data.city}, ${trial.data.province} | ${trial.data.trial_type}`} trail_type = {`${trial.data.trial_type}`} location = {`${trial.data.city}, ${trial.data.province}`}></Sharebuttons>
											<div 
												className="button" 
												data-tealium-category="download" 
												data-tealium-action="click download" 
												data-tealium-value={`individualtrial-${this.props.page_params.trialCode} pdf`}
												data-tealium-type="download" 
												onClick={TrialsCategory === 'DST' ? this.createPdf.bind(this) : this.createPdf_apt.bind(this)}
											></div>
											<div 
												data-tealium-category="print" 
												data-tealium-action="click print" 
												data-tealium-value={`individualtrial-${this.props.page_params.trialCode} print`}
												data-tealium-type="downprintload" 
												className="button" 
												onClick={TrialsCategory === 'DST' ? this.createPdf.bind(this) : this.createPdf_apt.bind(this)}
											></div>
										</div>
									</div>
								</div>
							</div>

							{trial.data.exclusion_comment ?
								<div className="weatherContainer">
									<p>{trial.data.exclusion_comment}</p>
								</div>
							: null}

							<div ref="trialResultsAnchor">
								{trialHasHybrids ?
									this.props.individualTrial.data.indication === 'Herbicide' ? " " :
										<ChartTabs tabDivider={true} selectedChart={this.state.selectedChart} mobileClick={this.handleChartSlider} desktopClick={this.handleChartTabs} trial={trial} trials = {this.props.trials} />
									: ""
								}
							</div>
							{this.getYieldChartapt(trial)}
							{TrialsCategory === 'DST' ? this.getAllCharts(trial) : null}

							<div className="trialTableContainer">
								<div className="trialTablePDFWrapper">
									<table className="trialTableStatic">
										<tbody>
											{trial.hybrids ?
												<React.Fragment>
													{this.state.selectedChart == 4 ? 
														(
															<>
																<tr className="headerRow">
																	<th className={"cell cellHead headCol"}>Hybrid</th>
																		<td className="cell cellHead">Pod Drop Loss (bu/ac)</td>
																		<td className="cell cellHead">Pod Shatter Loss (bu/ac)</td>
																		<td className="cell cellHead">Total Bushels per acre loss</td>
																</tr>
																{trial.hybrids.map((hybrid, i) => (
																	hybrid.pod_drop_loss && hybrid.pod_shatter_loss ? (
																		<tr key={`${trial.data.trial}-${hybrid.hybrid}-${i}`}>
																		<td className={`cell headCol headColLight`} dangerouslySetInnerHTML={{ __html: hybrid.hybrid }}></td>
																		<td className={`cell headColLight`}>{hybrid.pod_drop_loss}</td>
																		<td className={`cell headColLight`}>{hybrid.pod_shatter_loss}</td>
																		<td className={`cell headColLight`}>{ (hybrid.pod_shatter_loss + hybrid.pod_drop_loss).toFixed(2)}</td>
																	</tr>
																	) : (
																		null
																	)
																	// <tr key={`${trial.data.trial}-${hybrid.hybrid}-${i}`}>
																	// 	<td className={`cell headCol headColLight`} dangerouslySetInnerHTML={{ __html: hybrid.hybrid }}></td>
																	// 	<td className={`cell headColLight`}>{hybrid.pod_drop_loss}</td>
																	// 	<td className={`cell headColLight`}>{hybrid.pod_shatter_loss}</td>
																	// 	<td className={`cell headColLight`}>{ (hybrid.pod_shatter_loss + hybrid.pod_drop_loss).toFixed(2)}</td>
																	// </tr>
																))}
															</>
															
														) : (
														<>
														<tr className="headerRow">
															<th className={"cell cellHead headCol headColHead"}>Hybrid</th>
															<td className="cell cellHead">Harvest <br /> Method </td>
															<td className="cell cellHead">Yield <br /> (bu/ac.) </td>
															<td className="cell cellHead">Days to <br /> Maturity <Tooltip text="Seeding date to 60% seed colour change." color="white" /></td>
															<td className="cell cellHead">Lodging <br /> Rating <Tooltip text="Scale 1 to 5, 1 = upright, 5 = flat." color="white" /></td>
															<td className="cell cellHead">Swathing <br /> Rating
															{trial.data.trial_type.includes('Agronomic') ?
																	<Tooltip text="Rating by agronomist on ease of swathing compared to other strips planted. Scale 1 to 10 with 10 being the best" color="white" />
																	:
																	<Tooltip text="Rating by grower on ease of swathing compared to other strips planted. Scale 1 to 10 with 10 being the best." color="white" />
																}

															</td>
															<td className="cell cellHead">Combining <br /> Rating
															{trial.data.trial_type.includes('Agronomic') ?
																	<Tooltip text="Rating by agronomist on ease of combining to other strips planted. Scale 1 to 10 with 10 being the best." color="white" />
																	:
																	<Tooltip text="Rating by grower on ease of combining to other strips planted.  Scale 1 to 10 with 10 being the best." color="white" />
																}
															</td>
															{trial.data.trial_type.includes('Agronomic') && trial.data.year <= 2023 ?
																	<td className="cell cellHead">Dockage <Tooltip text="Any material other than canola seed that is removed during the cleaning process." color="white" /></td>
																	: null 
																}
															<td className="cell cellHead">Green <br /> Seed (%) <Tooltip text="% of green seeds within the sample." color="white" /></td>
														</tr>
														{trialHybridsSorted.map((hybrid, i) => (
															<tr key={`${trial.data.trial}-${hybrid.hybrid}-${i}`}>
																{ this.props.trials?.hybridLinks[hybrid.hybrid] ? 
																<td className={`cell headCol ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`}><a href={this.props.trials?.hybridLinks[hybrid.hybrid]} target='_blank'>{hybrid.hybrid}</a></td>  
																: <td className={`cell headCol ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`} dangerouslySetInnerHTML={{ __html: hybrid.hybrid }}></td> }
																<td className={`cell ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`}>{hybrid.harvest_method ? hybrid.harvest_method : hybrid.swath_type}</td>
																<td className={`cell ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`}>{hybrid.net_yield ? hybrid.net_yield.toFixed(1) : "N/A"}</td>
																<td className={`cell ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`}>{hybrid.maturity ? hybrid.maturity.toFixed(1) : "N/A"}</td>
																<td className={`cell ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`}>{hybrid.lodging ? hybrid.lodging.toFixed(1) : "N/A"}</td>
																<td className={`cell ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`}>{hybrid.swath ? hybrid.swath.toFixed(1) : "N/A"}</td>
																<td className={`cell ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`}>{hybrid.harvest ? hybrid.harvest.toFixed(1) : "N/A"}</td>
																{trial.data.trial_type.includes('Agronomic') && trial.data.year <= 2023 ?
																	<td className={`cell ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`}>{hybrid.dockage ? hybrid.dockage.toFixed(1) : "N/A"}</td> 
																	: null
																}
																<td className={`cell ${hybrid.is_basf === true ? "headColDark" : "headColLight"}`}>{hybrid.green_perc >= 0 ? hybrid.green_perc.toFixed(1) : "N/A"}</td>
															</tr>
														))}

														</>
														)
														
													}		
											
												</React.Fragment>
												: null
											}
											
										</tbody>
									</table>
								</div>
							</div>

							<div className="fertility">
								{trial.data.fertility ?
									(<React.Fragment>
										<b>Fertility:</b> {trial.data.fertility}
									</React.Fragment>
									)
									: null}
							</div>

						{TrialsCategory === 'APT' && this.props.trialResults ? this.getAPTTables(trial, this.props.trialResults) : null}

							{trialHasHybrids && treatments && TrialsCategory === 'DST' ?
								(trial.data.year < 2019) ?
									<div className="trialTableContainer">
										<table className="trialTable" id="trialTable">
											<tbody>
												{this.generateTreatmentsTable(trial, treatments)}
											</tbody>
										</table>
									</div>
									:
									<div className="trialTableContainer2019">
										<table className="trialTable2019" id="trialTable2019">
											<tbody>
												<tr>
													<th>Treatment Type</th>
													<th>Hybrids</th>
													<th>Treatments</th>
													<th>First Application(Rate)</th>
													<th>Date</th>
													<th>Treatments</th>
													<th>Second Application (Rate)</th>
													<th>Date</th>
												</tr>
											</tbody>
										</table>
									</div>
								: null}
							{trial.data.weather ?
								<div className="weatherContainer">
									<p>{trial.data.weather}</p>
								</div>
							: null}
							<div ref="commentsAnchor" className="testimonialContainer">
								{TrialsCategory === 'DST' ? <div className="photo"><img id="agroImg" src={this.getAgronomistPhoto(trial.data.agronomist_id)} alt="" onError={this.imgUpload} /></div> : null}
								{TrialsCategory === 'DST' ?
									<div className="testimonialTextContainer">
										{trial.comments ? trial.comments.map((c, i) => (
											<React.Fragment key={`comment-${i}`}>
												<div className="testimonialQuoteImg"><img src={quotes} alt="" />
													<p className="commentSignature">{trial.data.agronomist}<br />{new Date(c.date).toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'long'})}</p></div>
												<div className="testimonialText">
													<p>{c.comment}</p>
												</div>
											</React.Fragment>
										)) : <React.Fragment key={`comment-1`}>
												<div className="testimonialQuoteImg"><img src={quotes} alt="" />
													<p className="commentSignature">{trial.data.agronomist}</p></div>
												<div className="testimonialText">
													<p>Agronomist Comments Coming Soon</p>
												</div>
											</React.Fragment>}
									</div> :
									// APT Comment Section
									<React.Fragment>
										{trial.comments ? trial.comments.map((c, i) => (
											<React.Fragment key={`comment-${i}`}>
												<div className="testimonialTextContainer">
													<div className="testimonialQuoteImg"><img src={quotes} alt="" />
														<p className="commentSignature">{new Date(c.date).toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: 'long'})}</p></div>
													<div className="testimonialText">
														<p style={{ marginLeft: "8px" }}>{c.comment}</p>
													</div>
												</div>

											</React.Fragment>
										)) : ""}
									</React.Fragment>
								}
							</div>


						</React.Fragment>
						: null
					}

					{trial && trial.photos ?
						<div ref="photosAnchor" className="sliderContainer">
							<React.Fragment>
								<Slider {...settings}>
									{trial.photos.map((photo, i) => {
										return (
											<div className="slide" key={i}>
												<div className={this.state.windowWidth > 1024 ? "img-container" : ""}>
													<img 
														src={photo.attachmentType != 'photo' ? 
																photo.photo
																:
																(
																trial.data.year >= 2021 && photo.photo ? 
																	nodeserver_url_images_aptapi + photo.photo.substring(2)
																	:
																	photo.photo 
																)
															}
														alt="slideImg" 
														onClick={() => this.imageClickHandler(i)}
														data-tealium-category="link" 
														data-tealium-action="click carousel-photo" 
														data-tealium-value={`photo: ${photo.photo}`}
														data-tealium-type="link" 
													/>
													{photo.attachmentType === 'video' ?
														<img
															className = "play-button"
															src={play_button}
															alt="playButtonImg"
															onClick={() => this.imageClickHandler(i)}
														/>
														:
														null
													}
												</div>
												{this.getCarouselText(photo, trial.data.year)}
											</div>
										)
									})}
								</Slider>
							</React.Fragment>
						</div>
						: null
					}

					{trial && trial.photos && this.state.toDisplay ?
						<PhotoPopup 
							photoData={trial.photos} 
							indexSelected={this.state.imageIndex} 
							toDisplay={this.state.toDisplay} 
							year={trial.data.year}
							handleToDisplayStateChange = {this.handleToDisplayStateChange}
						/>
						:
						null
					}
					
					{trial && trialHasHybrids ? <div className="downloadReportContainer">
						<div 
							className="downloadButton" 
							data-tealium-category="download" 
							data-tealium-action="click download" 
							data-tealium-value={`individualtrial-${this.props.page_params.trialCode} pdf`}
							data-tealium-type="download" 
							onClick={TrialsCategory === 'DST' ? this.createPdf.bind(this) : this.createPdf_apt.bind(this)}
						>
							Download Report as PDF
						</div>

						{/* <div className="downloadButton" onClick={this.exportPDFWithComponent}>Download Report as PDF</div> */}


					</div> : null}
				</div>
				<TalkToUs />
				<Footer />
				{/* </PDFExport> */}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	trials: state.trials,
	agronomists: state.agronomists,
	trialResults: state.trialResults,
	individualTrial: state.trials.individualTrial
})

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({ ...agronomistsActionCreators, ...trialResultsActionCreators, ...trialsActionCreators }, dispatch)
)(IndividualTrialPage);
