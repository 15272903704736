import axios from 'axios';
import { base64toBlob } from './helpers'
import { nodeserver_url } from '../constants/api'

var url = `${nodeserver_url}/trial_results/api/pdf`;
var isBuildingPdf = false;

export function generatePdf(html, props) {
    if (isBuildingPdf) { return }
    isBuildingPdf = true;

    //for safari browsers
    var windowReference
    if (!(window.navigator && window.navigator.msSaveOrOpenBlob))
        windowReference = window.open();

    // prepare the data obj with html to be printed.
    var data = { html: html }

    axios
        .post(url, data)
        .then(function (response) {
            var base64str = response.data.pdf;
            let blob = base64toBlob(base64str);

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
            } else {
                var objectUrl = URL.createObjectURL(blob);
                //window.open(objectUrl);  
                windowReference.location = objectUrl;
            }

            isBuildingPdf = false;
        }).catch(function (error) {
            isBuildingPdf = false;
        });
}