/**
 * FOR SERVER: * 
 * const homepage = '/invigorresults';
 * const server_url = 'http://dev.agconnect.org:8080/basf/agprocan/agsolutions/apt.nsf/';
 * //node server urls:
 * dev = http://dev.basf-agconnect.org:8080
 * tst = http://test.basf-agconnect.org
 * stg = https://staging.basf-agconnect.org
 * prd = https://basf-agconnect.org
 * FOR LOCAL:
 * const homepage = '';
 * const server_url = 'basf/agprocan/agsolutions/apt.nsf/';
 */
const env_0 = window.location.host.split('.')[0]; // dev, test, staging or agro
const env = (env_0 === 'agro') ? '' : env_0 + '.'; // 'dev.', 'test.', 'staging.' or '' (for production) 
const homepage = (window.location.hostname === 'localhost') ? '' : '/trial_results/'; // --- /itrial_results
const protocol = window.location.protocol;
const url_prefix = (window.location.hostname === 'localhost') ? 'https://dev.agconnect.org/' : `${protocol}//${window.location.host}/`;
const server_url = url_prefix + 'basf/agprocan/agsolutions/apt.nsf/'
//const url_port = (env === 'dev.') ? ':8080' : '';
//const url_port='';
//const nodeserver_url = (window.location.hostname === 'localhost') ? `http://localhost:3010` : `${protocol}//${env}agconnect.org`;

const nodeserver_url = (window.location.hostname === 'localhost') ? `http://localhost:3001` : 
(window.location.hostname.includes('dev') || window.location.hostname.includes('test') || window.location.hostname.includes('staging'))  
? `${protocol}//${env}agconnect.org` : `${protocol}//agro.basf.ca`;

const googleTagManagerCode = 'GTM-KT2BX73';
const googleAnalyticsCode = 'UA-3480885-3';
//const googleApiKey = (window.location.hostname === 'localhost') ? 'AIzaSyBc7l_nskJY5lv2KBbhaIOwjhW6du7anYg' : 'AIzaSyA0tMjWHVho_WNIst-q1-GFWhkKjob2NII'
//(window.location.hostname === 'localhost' || window.location.hostname.includes('dev')) ? `https://dev.agconnect.org`

const nodeserver_url_images = (window.location.hostname === 'localhost') ? `http://localhost:3001` : 
(window.location.hostname.includes('dev') || window.location.hostname.includes('test') || window.location.hostname.includes('staging'))  
? `${protocol}//${env}agconnect.org` : `${protocol}//agro.basf.ca`;

const nodeserver_url_images_aptapi = (window.location.hostname === 'localhost') ? `https://dev.agconnect.org` : 
(window.location.hostname.includes('dev') || window.location.hostname.includes('test') || window.location.hostname.includes('staging'))  
? `${protocol}//${env}agconnect.org` : `${protocol}//agro.basf.ca`;

export { homepage, googleTagManagerCode, googleAnalyticsCode, server_url, nodeserver_url, nodeserver_url_images, nodeserver_url_images_aptapi };
