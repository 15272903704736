import { actionTypes } from '../actions/agronomistAction';
import Agronomist from '../utils/agronomist';

const initialState = {
    agronomists: [],
    category: 'dst'    
};

export const reducer = (state = initialState, action) => {
switch (action.type) {
    case actionTypes.getAgronomists:
        function createAgronomist(a){
            return new Agronomist({
                id: a.id, 
                firstname: a.firstname, 
                lastname: a.lastname, 
                photo: a.photo
            })
        }

        let agron = action.payload.map(createAgronomist);

        return { ...state, 
            agronomists:[...agron],            
            category: action.category};
    default:
        return state;
}
};