import { createSelector } from 'reselect'

const getComparisons = (state) => state.comparisons.data;
const getComparisonMetadata = (state) => state.comparisons.metadata;

export const getAllComparisons = createSelector([getComparisons], (comparisons) => {
  return comparisons;
})

export const getAllComparisonMetadata = createSelector([getComparisonMetadata], (metadata) => {
  return metadata ? metadata[0] : metadata;
})

export const getComparisonDetails = (comparisons, comparisonId) => {
  return (typeof comparisons[comparisonId] !== 'undefined' ? comparisons[comparisonId] : {});
};


