import { get } from '../utils/axios/axiosRequest'
import { nodeserver_url } from '../constants/api';
import axios from 'axios';


const getAgronomists = 'GET_AGRONOMISTS';

export const actionTypes = {
    getAgronomists
}

export const agronomistsActionCreators = {
    getAgronomists: (category) => async (dispatch, getState) => {
        if (getState().agronomists && getState().agronomists.category === category)
            return;
        
        let param = category || 'dst';
        let apiBaseUrl = `${nodeserver_url}`;

        //let response = await get(`(public_site/get_agronomists)?openagent&category=${param}`);    

        let response = await axios.get(apiBaseUrl + `/trial_results/api/sql/getAgronomists/?category=${param}`);

        if (response.data.agentstatus === 'success')
            dispatch({               
                type: getAgronomists,
                payload: response.data.data,
                category: (category) ? category : 'dst'
            });

    }
}