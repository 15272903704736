import React, { Component } from 'react'
import './ChartLinearGauge.scss';

class ChartLinearGauge extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getGaugeMarginLeft = (num) => {
        if (num.toString().length === 1) return -4.455;
        else if (num.toString().length === 2) return -8.9;
        else if (num.toString().length === 3) return -13.35;
        else if (num.toString().length === 4) return -17.795;
        else return -10
    }

    render() {
        let left = 0;
        let gaugeUnitNumber = this.props.startPoint;

        return (
            <div className="linearGaugeContainer">
                <div className="linearGaugeText"></div>
                <div className="linearGaugeContent">
                    <div className="gauge">
                        <div className="top">
                            {[...Array(this.props.numOfUnits)].map((elem, i) => {
                                return (
                                    <div key={i} className={"line"} style={{ width: `${this.props.unitWidth}%` }}></div>
                                )
                            })}
                        </div>
                        <div className="bottom">
                            {[...Array(this.props.numOfUnits)].map((elem, i) => {
                                return (
                                    <div key={i} className={"line"} style={{ width: `${this.props.unitWidth}%` }}></div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="nums">
                        {[...Array(this.props.numOfUnits + 1)].map((elem, i) => {
                            if (i === 1) {
                                return (
                                    <div key={i} className={"num"} style={{ left: `0%`, marginLeft: `${this.getGaugeMarginLeft(gaugeUnitNumber)}px` }}>{gaugeUnitNumber - 2}</div>
                                )
                            } else {
                                gaugeUnitNumber += 2;
                                left += this.props.unitWidth;
                                return (
                                    <div key={i} className={"num"} style={{ left: `${left}%`, marginLeft: `${this.getGaugeMarginLeft(gaugeUnitNumber)}px` }}>{gaugeUnitNumber}</div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default ChartLinearGauge