import React from 'react';
import { nodeserver_url } from '../../constants/api';
import './BackToTop.scss';
import ScrollUpButton from 'react-scroll-up-button';

let topIcon = `${nodeserver_url}/trial_results/images/trials/topbutton.png`

export default function BackToTop() {
    return (
        <div>
            <ScrollUpButton ContainerClassName="backtotop" TransitionClassName="backtoptoptransition">
                <div className="backtoptop">
                    <img src={topIcon} alt="arrow"/>
                    <span>TOP</span>
                </div>
            </ScrollUpButton>


        </div>
    )
}
