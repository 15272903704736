import React, { Component } from 'react';
import './DistanceBar.scss';
import Range from './Range';

class DistanceBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rangeVal: 5.25
        }
        // this.updateRange = this.updateRange.bind(this);
        this.updateZoom = this.updateZoom.bind(this);
    }

    // componentDidMount() {
    //     this.setState({
    //       rangeVal:this.props.currentRange
    //     })
    // }

    // componentDidUpdate(prevProps) {
    //     //console.log("Distance bar update")
    //     if(prevProps.currentRange !== this.props.currentRange){
    //         this.setState({
    //             rangeVal:this.props.currentRange
    //           })
    //     }
        
    // }

    // updateRange(val) {
    //     this.setState({
    //         rangeVal: val,
    //     }, () => {this.props.updateRange(val)})
    // }

    updateZoom(val) {
        this.setState({
            rangeVal: val,
        }, () => {this.props.updateZoom(val)})
    }


    render() {
        const { rangeVal } = this.state;
        return (
            // <Range range={rangeVal} updateRange={this.updateRange} />
            <Range range={rangeVal} updateZoom={this.updateZoom} />
        )
    }
}

export default DistanceBar 