import React from 'react';
import { getTrialsCategory, getTrialsRegion } from '../../../selectors/trialsSelector'
import { nodeserver_url_images } from '../../../constants/api';

const TrialsCategory = getTrialsCategory();
const TrialsRegion = getTrialsRegion();

let blueIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/blue-marker-new.png`;
let redIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/red-marker-new.png`;
let yellowIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/yellow-marker-new.png`;
let pinkIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/pink-marker-new.png`;
let greenIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/green-marker-new.png`;
let greyIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/grey-marker.png`;
let orangeIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/orange-marker.png`;
let purpleIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/purple-marker-new.png`;
let cornyellowIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/cornyellow-marker1.png`;
let darkblueIcon = `${nodeserver_url_images}/trial_results/images/googleMapIcons/darkblue-marker.png`;

const MapLegend = props => {
    return (
        TrialsCategory === 'DST' ?
            <ul className="mapKeys">
                {/* <li><img src={blueIcon} height="20px" alt="Map Icon" /><span className="mapKey">Straight Cut vs Swathed</span></li> */}
                <li><img src={redIcon} height="20px" alt="Map Icon" /><span className="mapKey">Health Trials</span></li>
                <li><img src={blueIcon} height="20px" alt="Map Icon" /><span className="mapKey">DST Trials</span></li>
                <li><img src={orangeIcon} height="20px" alt="Map Icon" /><span className="mapKey">Agronomic Excellence</span></li>
                <li><img src={greenIcon} height="20px" alt="Map Icon" /><span className="mapKey">Multiple</span></li>
            </ul> 
            : TrialsRegion === 'east' ?
                <ul className="mapKeys">
                    <li><img src={blueIcon} height="20px" alt="Map Icon" /><span className="mapKey">Canola</span></li>
                    <li><img src={cornyellowIcon} height="20px" alt="Map Icon" /><span className="mapKey">Corn</span></li>
                    <li><img src={pinkIcon} height="20px" alt="Map Icon" /><span className="mapKey">Soybeans</span></li>
                    <li><img src={yellowIcon} height="20px" alt="Map Icon" /><span className="mapKey">Pulses</span></li>
                    <li><img src={greenIcon} height="20px" alt="Map Icon" /><span className="mapKey">Alfalfa</span></li>
                    <li><img src={greyIcon} height="20px" alt="Map Icon" /><span className="mapKey">Potatoes</span></li>
                </ul> :
                <ul className="mapKeys">
                    <li><img src={blueIcon} height="20px" alt="Map Icon" /><span className="mapKey">Canola</span></li>
                    <li><img src={redIcon} height="20px" alt="Map Icon" /><span className="mapKey">Cereals</span></li>
                    <li><img src={cornyellowIcon} height="20px" alt="Map Icon" /><span className="mapKey">Corn</span></li>
                    <li><img src={yellowIcon} height="20px" alt="Map Icon" /><span className="mapKey">Pulses</span></li>
                    <li><img src={pinkIcon} height="20px" alt="Map Icon" /><span className="mapKey">Soybeans</span></li>
                    <li><img src={greenIcon} height="20px" alt="Map Icon" /><span className="mapKey">Multiple</span></li>
                </ul>
    );
}

export default MapLegend;