import React, { Component } from 'react';
import "./CheckBox.scss";

class CheckBox extends Component {

    constructor(props){
        super(props)
    }


    componentDidMount(){
        if(this.props.id === 'Herbicide' && this.props.checked ) {
            this.props.updatedHerbicide(this.props.checked)
        }

        if(this.props.id === 'Facet L' && this.props.checked ) {
            this.props.updatedFacet(this.props.checked)
        }

        if(this.props.id === 'Zidua SC' && this.props.checked ) {
            this.props.updatedZidua(this.props.checked)
        }

        if(this.props.id === 'Heat LQ' && this.props.checked ) {
            this.props.updatedHeatLQ(this.props.checked)
        }
    }

    render() {
        let optionCount;
        optionCount = this.props.count ? "(" + this.props.count + ")" : null;

        return (
            <label 
                className="checkbox"
                data-tealium-category="filter"
                data-tealium-action="click filter-checkbox" 
                data-tealium-value={`${this.props.text} ${optionCount}`}
                data-tealium-type="filter" 
            >
            <input name={this.props.name} className="checkbox__trigger visuallyhidden" type="checkbox" onChange={this.props.handleChecked} checked={this.props.checked} id={this.props.id} />
            <span className="checkbox__symbol">
                <svg aria-hidden="true" className="icon-checkbox" width="28px" height="28px" viewBox="0 0 28 28" version="1" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
            <p  className="checkbox__textwrapper" 
                dangerouslySetInnerHTML={{ __html: `${this.props.text} ${optionCount}` }} 
            >     
            </p>
        </label> 
        )
    }
}
export default CheckBox;