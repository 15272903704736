import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar'
import Hero from '../../components/Hero/Hero'
import TalkToUs from '../../components/TalkToUs/TalkToUs'
import Footer from '../../components/Footer/Footer'
import { nodeserver_url_images } from '../../constants/api';
import '../AboutDST/AboutDST.scss'

let AboutHeroImg = `${nodeserver_url_images}/trial_results/images/aboutAgro/hero_abtagro.jpg`;
let contentImg1 = `${nodeserver_url_images}/trial_results/images/aboutAgro/abt_agro1.jpg`;
let contentImg2 = `${nodeserver_url_images}/trial_results/images/aboutAgro/abt_agro2.jpg`;
let contentImg3 = `${nodeserver_url_images}/trial_results/images/aboutAgro/abt_agro3.jpg`;
let contentImg4 = `${nodeserver_url_images}/trial_results/images/aboutAgro/abt_agromob1.jpg`;

class AboutAgronomicExcellence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            text: [
                {
                    id: 1,
                    title: "Excellence across the field",
                    para1: "The Agronomic Excellence team conducts field trials that are replicated throughout Western Canada using rigorous protocols, that reflect real on-farm conditions. This research is used to provide agronomic recommendations for maximizing the yield, performance, and consistency of your InVigor® hybrid canola. A great example of this is their study focused on canola survivability and target plant populations which lead to defining a target plant population of 5 to 7 plants/ft2 for optimal results with InVigor, also known as InVigor RATE.",
                },
                {
                    id: 2,
                    title: "What is an Agronomic Excellence Trial?",
                    para1: "Agronomic Excellence Trials are large-scale replicated field trials that have Agronomic Excellence Agronomists work alongside local growers across Western Canada to test the performance of canola hybrids. The agronomists treat each plot identically in terms of fertility, management practices and data collection using commercially equivalent equipment. The trials follow strict protocols to ensure uniformity in data collection.",
                },
                {
                    id: 3,
                    title: "Where do I find Agronomic Excellence Trial sites?",
                    para1: 'In canola growing regions across Western Canada.'
                },
                {
                    id: 4,
                    title: "What makes Agronomic Excellence data so credible?",
                    para1: "Agronomic Excellence trials are conducted using proven research protocols designed to test hybrid performance across multiple locations. Large plot sizes and randomized replication of hybrids provide accurate results that can help account for potential field variability. Every hybrid is treated the same to ensure differences can be attributed to hybrid performance. The fact that the trials are carried out across western Canada ensures the results accurately represent hybrid performance across varied soil types, environments and are conducted using local production practices for crop management. Our expert agronomists are highly skilled and trained in trial execution, data collection and analysis. If variability is found to be too high during one of the many routine quality checks performed through the season, the trials are cancelled. Plots are harvested with commercial combines that are equipped to measure plot weights directly on the combine for determination of hybrid yields.",
                }
            ]
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isTablet = width <= 1024;
        if (isTablet) {
            return (
                <React.Fragment>
                    <NavBar from={"about"} page_router={this.props.page_router} clearPURL={this.props.clearPURL} />
                    <div className="aboutDSTContainer">
                        <Hero img={AboutHeroImg} />
                        <div className="aboutDSTTopBar">
                            <h2>{this.state.text[0].title}</h2>
                            <p dangerouslySetInnerHTML={{ __html: this.state.text[0].para1 }}></p>
                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg4} alt="" />
                            </div>
                            <div className="half">
                                <div className="content">
                                    <h2>{this.state.text[1].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[1].para1 }}></p>
                                </div>
                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg2} alt="" />
                            </div>
                            <div className="half1-agro">
                                <div className="content para2">
                                    <h2 style={{color:'#fff'}}>{this.state.text[2].title}</h2>
                                    <p  style={{color:'#fff'}} dangerouslySetInnerHTML={{ __html: this.state.text[2].para1 }}></p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="full">
                            <div className="half">
                                <div className="content">
                                    <h2>{this.state.text[3].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[3].para1 }}></p>
                                </div>
                            </div>
                            <div className="half-image">
                                <img src={contentImg3} alt="" />
                            </div>
                        </div>


                        <TalkToUs />
                        <Footer />
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <NavBar from={"about"} page_router={this.props.page_router} clearPURL={this.props.clearPURL} />
                    <div className="aboutDSTContainer">
                        <Hero img={AboutHeroImg} />
                        <div className="aboutDSTTopBar-agro">
                        <div className="aboutDSTTopBar-agro2">
                            <h2 style={{color:'#fff', width:'65%'}}>{this.state.text[0].title}</h2>
                            <p style={{color:'#fff', width:'65%'}} dangerouslySetInnerHTML={{ __html: this.state.text[0].para1 }}></p>
                            </div>
                            <div className="aboutDSTTopBar-agro1">
                                <h2 style={{width:'65%'}}>{this.state.text[1].title}</h2>
                                <p style={{width:'65%'}} dangerouslySetInnerHTML={{ __html: this.state.text[1].para1 }}></p>   
                            </div>
                            <img src={contentImg1} alt="Agronomic Excellence"/>
                        </div>

                        
                        {/* <div className="full">
                            <div className="half-image">
                                <img src={contentImg1} alt="" />
                            </div>
                            <div className="half">
                                <div className="content">
                                    <h2>{this.state.text[1].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[1].para1 }}></p>
                                </div>
                            </div>
                        </div> */}
                        <div className="full">
                            <div className="half1-agro">
                                <div className="contentAgro1 para2">
                                    <h2>{this.state.text[2].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[2].para1 }}></p>
                                </div>
                            </div>
                            <div className="half-image">
                                <img src={contentImg2} alt="" />
                            </div>
                        </div>
                        <div className="full">
                            <div className="half">
                                <div className="contentAgro2">
                                    <h2>{this.state.text[3].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[3].para1 }}></p>
                                </div>
                            </div>
                            <div className="half-image">
                                <img src={contentImg3} alt="" />
                            </div>
                        </div>


                        <TalkToUs />
                        <Footer />
                    </div>
                </React.Fragment>
            );

        }
    }
}

export default AboutAgronomicExcellence