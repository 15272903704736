
    export const Podshatter = {
    link: 'https://agriculture.basf.ca/west/invigor-hybrid-canola/pod-shatter-reduction-hybrids.html',
    description: 'Pod Shatter Reduction hybrids from InVigor® hybrid canola are ideal for growers looking to straight cut or delay swathing with trusted harvest flexibility.'
    };
    export const Clubroot = {
    link: 'https://agriculture.basf.ca/west/invigor-hybrid-canola/clubroot-hybrid.html',
    description: 'InVigor® hybrid canola has the only clubroot-resistant hybrids with patented Pod Shatter Reduction technology.'
    };
    export const Choice = {
    link: 'https://agriculture.basf.ca/west/invigor-hybrid-canola/invigor-choice-hybrids.html',
    description: 'Choice hybrids from InVigor® contain stacked herbicide traits to help provide control of the toughest weeds, manage resistance and more.'
    };
    export const Health = {
    link: 'https://agriculture.basf.ca/west/invigor-hybrid-canola/health-hybrids.html',
    description: 'With high-yielding and consistent performance, InVigor® Health canola hybrids offer high-yield potential hybrids with a specialty oil profile.'
    };
    
    
    export const mappingLink = {
        'Pod Shatter Reduction': Podshatter,
        'Clubroot Resistant': Clubroot,
        'Health': Health,
        'Choice': Choice,
        'InVigor L233P': Podshatter,
        'InVigor L234PC': Podshatter,
        'InVigor L255PC': Podshatter,
        'InVigor L330PC': Podshatter,
        'InVigor L333PC': Podshatter,
        'InVigor L340PC': Podshatter,
        'InVigor L341PC': Podshatter,
        'InVigor L343PC': Podshatter,
        'InVigor L345PC': Podshatter,
        'InVigor L350PC': Podshatter,
        'InVigor L356PC': Podshatter,
        'InVigor L357P': Podshatter,
        'InVigor L233P': Podshatter,
        'InVigor L258HPC': Health,
        'InVigor L358HPC': Health,
        'InVigor L359HPC': Health,
        'InVigor LR354PC': Choice,
      };
    
export const TreatmentLinks = {
  'Liberty' : 'https://agriculture.basf.ca/west/products/solutions/liberty-150-sn.html',
  'Centurion' : 'https://agriculture.basf.ca/west/products/solutions/centurion.html',
  'Facet L' : 'https://agriculture.basf.ca/west/products/solutions/facet-l.html',
  'Cotegra' : 'https://agriculture.basf.ca/west/products/solutions/cotegra.html',
  'Lance' : 'https://agriculture.basf.ca/west/products/solutions/lance.html',
  'Lance AG' : 'https://agriculture.basf.ca/west/products/solutions/lance-ag.html',
  'Nexicor' : 'https://agriculture.basf.ca/west/products/solutions/nexicor.html', 
  'Priaxor' : 'https://agriculture.basf.ca/west/products/solutions/priaxor.html',
  'Heat LQ' : 'https://agriculture.basf.ca/west/products/solutions/heat-lq.html',
  'Certitude' :  'https://agriculture.basf.ca/west/products/solutions/certitude.html'
}