import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trialsActionCreators } from '../../actions/trialsAction';
import './Home.scss';
import Tabs from '../../components/Tabs/Tabs';
import NavBar from '../../components/NavBar/NavBar';
import Menu from './Menu/Menu';
import Map from './Map/Map';
import {
	getVisibleTrials,
	getTrialsCategory,
} from '../../selectors/trialsSelector';
import MapLegend from './MapLegend/MapLegend';
import 'font-awesome/css/font-awesome.min.css';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from 'react-places-autocomplete';
import { nodeserver_url_images } from '../../constants/api';
import { localeActionCreators } from '../../actions/locationAction';

const searchOptions = {
	componentRestrictions: { country: 'ca' },
	types: ['(cities)'],
};


let mGlass = `${nodeserver_url_images}/trial_results/images/home/mglass.png`;

const TrialsCategory = getTrialsCategory();

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLegend: false,
			width: window.innerWidth,
			location: '',
			lat: null,
			lng: null,
		};
	}

	componentDidMount() {
		if (this.props.clearPURL) {
			this.setState(
				{
					...this.state,
					PURL: null,
				},
				() => {}
			);
		}

		window.addEventListener('resize', this.handleWindowSizeChange);
		this.props.setWindowSize(window.innerWidth);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		this.props.setWindowSize(window.innerWidth);
		this.setState({ width: window.innerWidth });
	};

	toggleLegend = () => {
		this.setState({ showLegend: !this.state.showLegend });
	};

	updatedHerbicide = (isHerbicide) => {
		this.setState({
			isHerbicide,
		});
	};

	updatedFacet = (isFacet) => {
		this.setState({
			isFacet,
		});
	};

	updatedZidua = (isZidua) => {
		this.setState({
			isZidua,
		});
	};

	updatedHeatLQ = (isHeatLQ) => {
		this.setState({
			isHeatLQ,
		});
	};

	getMenu = () => {
		return [
			<div key='mapMenu' className='mapMenu'>
				<Menu
					trials={this.props.trials}
					page_router={this.props.page_router}
					updatedHerbicide={this.updatedHerbicide}
					updatedFacet={this.updatedFacet}
					updatedZidua={this.updatedZidua}
					updatedHeatLQ={this.updatedHeatLQ}
					isHerbicide={this.state.isHerbicide}
					isFacet={this.state.isFacet}
					isZidua={this.state.isZidua}
					isHeatLQ={this.state.isHeatLQ}
				/>
			</div>,
		];
	};

	handleChange = (location) => {
		this.setState({ location: location });
	};

	handleSelect = (location) => {
		geocodeByAddress(location)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				let offset =
					this.state.lat === latLng.lat &&
					this.state.lng === latLng.lng
						? 0.00001
						: 0;
				this.setState(
					{
						location: location,
						lat: latLng.lat + offset,
						lng: latLng.lng,
					},
					() =>
						this.props.doSetLocation({
							lat: latLng.lat + offset,
							lng: latLng.lng,
						})
				);
			})
			.catch((error) => console.error('Error', error));
	};

	render() {
		const { width } = this.state;
		const isDesktop = width > 1024;

		return (
			<React.Fragment>
				<NavBar
					page_router={this.props.page_router}
					updatedHerbicide={this.updatedHerbicide}
					updatedFacet={this.updatedFacet}
					updatedZidua={this.updatedZidua}
					updatedHeatLQ={this.updatedHeatLQ}
				></NavBar>
				<div className='searchBarParent'>
				<React.Fragment>
						<div
							className={`conditionalShow ${
								!this.state.showFilters ? 'hidden' : ''
							}`}
						>
							{/* <div className='searchHeader'>
								<div className='title'>
									{TrialsCategory === 'DST'
										? 'Search for Trials'
										: 'Search for APT Trials'}
								</div>
							</div> */}

							<div className='searchBarContainer'>
								<div 
									className='searchBar'
									search_term={`${this.state.location}`}
									search_results={`${this.state.location}`}
								>
									<PlacesAutocomplete
										value={this.state.location}
										searchOptions={searchOptions}
										onChange={this.handleChange}
										onSelect={this.handleSelect}

									>
										{({
											getInputProps,
											suggestions,
											getSuggestionItemProps,
											loading,
										}) => (
											<div>
												<input
													{...getInputProps({
														placeholder:
															'Enter Town, Postal Code or Province',
														className:
															'location-search-input',
													})}
												/>
												<button type='submit'>
													<img
														src={mGlass}
														alt='Search'
													/>
												</button>
												<div className='autocomplete-dropdown-container'>
													{loading && (
														<div>Loading...</div>
													)}
													{suggestions.map(
														(suggestion) => {
															const className =
																suggestion.active
																	? 'suggestion-item--active'
																	: 'suggestion-item';
															// inline style for demonstration purpose
															const style =
																suggestion.active
																	? {
																			backgroundColor:
																				'#fafafa',
																			cursor: 'pointer',
																	  }
																	: {
																			backgroundColor:
																				'#ffffff',
																			cursor: 'pointer',
																	  };
															return (
																<div
																	key={
																		suggestion
																	}
																	{...getSuggestionItemProps(
																		suggestion,
																		{
																			className,
																			style,
																		}
																	)}
																>
																	<span>
																		{
																			suggestion.description
																		}
																	</span>
																</div>
															);
														}
													)}
												</div>
											</div>
										)}
									</PlacesAutocomplete>
								</div>
							</div>
						</div>
					</React.Fragment>
				</div>
				<div className='homeMapContainer'>
					{/* <PopUp />  */}
					{isDesktop ? (
						<span className='hideOnMobile'>
							<div className='mapContainer'>
								<Map
									page_router={this.props.page_router}
									clearPURL={this.props.clearPURL}
									page_params={this.props.page_params}
									trials={this.props.trials}
								/>
								<div className='mapLegend'>
									{this.state.showLegend ? (
										<MapLegend></MapLegend>
									) : null}
									<button 
										onClick={this.toggleLegend}
										data-tealium-category="popup" 
										data-tealium-action="click toggle-legend" 
										data-tealium-value={`show-legend:${this.state.showLegend}`}
										data-tealium-type="popup" 
									>
										<text>Trial Legend</text>
										<text className='triangle'>▲</text>
									</button>
								</div>
							</div>
							{this.getMenu()}
						</span>
					) : (
						<div className='showOnMobile'>
							<Tabs>
								<div label='MAP' number=''>
									<div className='mapContainer'>
										<Map
											trials={this.props.trials}
											page_router={this.props.page_router}
											clearPURL={this.props.clearPURL}
											page_params={this.props.page_params}
										/>
										<div className='mapLegend'>
											{this.state.showLegend ? (
												<MapLegend></MapLegend>
											) : null}
											<button 
												onClick={this.toggleLegend}
												data-tealium-category="popup" 
												data-tealium-action="click toggle-legend" 
												data-tealium-value={`show-legend:${this.state.showLegend}`}
												data-tealium-type="popup" 
											>
												<text>Trial Legend</text>
												<text className='triangle'>
													▲
												</text>
											</button>
										</div>
									</div>
								</div>
								<div label='LIST' number=''>
									{this.getMenu()}
								</div>
							</Tabs>
						</div>
					)}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	trials: getVisibleTrials(state.trials),
});

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators({ ...trialsActionCreators, ...localeActionCreators }, dispatch)
)(Home);
