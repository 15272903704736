export const setLocation = 'SET_LOCATION';
export const setNearestCity = 'SET_NEAREST_CITY';
export const setMarkerCoordinates = 'SET_MARKER_COORDINATES';

export const localeActionCreators = {
	doSetLocation: (location) => (dispatch, getState) => {
		dispatch({
    		type: setLocation,
    		payload: location
		});
    },

    doSetNearestCity: (city) => (dispatch, getState) => {
    	dispatch({
    		type: setNearestCity,
    		payload: city
    	});
	},
	
	doSetMarkerCoordinates: (markers) => (dispatch, getState) => {
    	dispatch({
    		type: setMarkerCoordinates,
    		payload: markers
    	});
	}
}

