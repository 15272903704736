import { actionTypes } from '../actions/trialsAction';
import Filter from '../utils/filter';

const initialState = {
	individualTrial: null,
	trials: [],
	all_trials: [],
	category: 'dst',
	trial_filters: new Filter(),
	mobile_filters: false,
	window_size: 0,
	mapZoom: 5.25
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.getTrials:
			return {
				...state,
				trials: [...action.payload],
				all_trials: [...action.payload],
				category: action.category,
				trial_filters: action.trial_filters,
				weatherData: action.weatherData,
				hybridLinks: action.hybridLinks,
			};
		case actionTypes.filterTrials:
			return {
				...state,
				trials: [...action.payload],
				all_trials: [...action.payload],
				category: action.category,
				trial_filters: action.trial_filters,
			};
		case actionTypes.removeAllTrialFilters:
			return {
				...state,
				trials: [...action.payload],
				all_trials: [...action.payload],
				category: action.category,
				trial_filters: action.trial_filters,
			};
		case actionTypes.GET_INDIVIDUAL_TRIAL:
			const individualTrial = state.trials.find(
				(t) => t.data.trial === action.trialCode
			);
			return {
				...state,
				individualTrial,
			};
		case actionTypes.CLEAR_INDIVIDUAL_TRIAL:
			return {
				...state,
				individualTrial: null,
			};
		case actionTypes.TOGGLE_MOBILE_FILTERS:
			const mobile_filters = !state.mobile_filters;
			return {
				...state,
				mobile_filters,
			};
		case actionTypes.WINDOW_SIZE:
			return {
				...state,
				window_size: action.window_size,
			};
		case actionTypes.MAP_ZOOM:
			return {
				...state,
				mapZoom: action.mapZoom,
			};
		default:
			return state;
	}
};
