
class Filter {
    constructor(){
        this.data = {
            filters:{},
            count:0
        }
    }

    add_filter = (key, filt_type, filt_value) => {
        if (!this.data.filters[key]) this.data.count++;
        this.data.filters[key] = {key, filt_type, filt_value};        
    }

    remove_filter = (key) => {
        if (this.data.filters[key]) {
            delete this.data.filters[key]
            this.data.count--;
        }
    }

    remove_all_filters = () => {
        this.data.filters={};
        this.data.count=0;
    }

    has_filter = () => {
        return (this.data.count>0)
    }
}

export default Filter