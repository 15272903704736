import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { comparisonActionCreators } from '../../actions/comparisonAction';
import { getAllComparisons } from '../../selectors/comparisonsSelector';
import { generatePdf } from '../../utils/pdf'
import { getTrialsCategory } from '../../selectors/trialsSelector';

import ChartTabs from '../../components/Chart/ChartTabs';
import ChartBar from '../Chart/ChartBar'
import ChartLinearGauge from '../Chart/ChartLinearGauge'
import Sharebuttons from '../../components/Sharebuttons/Sharebuttons.js';
import './Comparison.scss';
import Tooltip from '../../components/Tooltip/Tooltip';
import { getPURL } from '../../selectors/trialsSelector';

const TrialsCategory = getTrialsCategory();
class Comparison extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedChart: 1,
            PURL: getPURL()
        };
        this.handleChartSlider = this.handleChartSlider.bind(this);
        this.handleChartTabs = this.handleChartTabs.bind(this);
    }

    componentDidMount() { }

    componentDidUpdate(prevProps) {
        if (TrialsCategory === 'DST') {
            if (prevProps.avgHybridAmount !== this.props.avgHybridAmount && prevProps.avgComparisonAmount !== this.props.avgComparisonAmount) {
            }
        } else {
            if (prevProps.avgTreatmentAmount !== this.props.avgTreatmentAmount && prevProps.avgComparisonAmount !== this.props.avgComparisonAmount) {
            }
        }
    }

    seeAllComparisonTrialsHandler = (comparisonId) => {
        this.props.page_router.loadPage('comparisontrials', { comparisonId: comparisonId });
    }

createComparisonPdfHandler(comparisonId) {
        let pdfDoc = document.implementation.createHTMLDocument("PDF Doc");
        let pdfPage1 = document.implementation.createHTMLDocument("PDF Page 1");
        let pdfPage2 = document.implementation.createHTMLDocument("PDF Page 2");
        pdfPage1.body = document.body.cloneNode(true);
        pdfPage2.body = document.body.cloneNode(true);

        // temp DOM nodes to hold html of each comparison tab view (Yield, Days to Maturity, Harvet Ratings)
        let _yieldComparison = document.createElement("div");
        let _maturityComparison = document.createElement("div");
        let _harvestComparison = document.createElement("div");

        // translate JSX to DOM nodes (html)
        ReactDOM.render(this.getComparison(1, 'pdf'), _yieldComparison);
        ReactDOM.render(this.getComparison(2, 'pdf'), _maturityComparison);
        ReactDOM.render(this.getComparison(3, 'pdf'), _harvestComparison);

        try {
            // format each comparison tab view
            _maturityComparison = _maturityComparison.querySelector('.comparisonContent');
            _harvestComparison = _harvestComparison.querySelector('.comparisonContent');
            _yieldComparison.querySelectorAll(".chartTabsNavBar .chartTabsNavItem:not(.active)").forEach(e => e.parentNode.removeChild(e));
            _maturityComparison.querySelectorAll(".chartTabsNavBar .chartTabsNavItem:not(.active)").forEach(e => e.parentNode.removeChild(e));
            _harvestComparison.querySelectorAll(".chartTabsNavBar .chartTabsNavItem:not(.active)").forEach(e => e.parentNode.removeChild(e));

            // format PDF Page 1
            let comparisonWithHeader = _yieldComparison.cloneNode(true);
            comparisonWithHeader.querySelector(".comparisonContainer").appendChild(_maturityComparison);
            // remove all the comparisons from the pdfPage1 Doc
            
            pdfPage1.body.querySelectorAll(".cell.two")[0].innerHTML = '<span>Hybrid: </span>' + _yieldComparison.querySelectorAll('.barLabel')[0].textContent
            pdfPage1.body.querySelectorAll(".cell.two")[1].innerHTML = '<span>Comparison: </span>' + _yieldComparison.querySelectorAll('.barLabel')[1].textContent
            let comparisonsContainer = pdfPage1.querySelector(".comparisonsContainer");
            while (comparisonsContainer.firstChild) comparisonsContainer.removeChild(comparisonsContainer.firstChild);
            pdfPage1.body.querySelector("#trial_popup").remove();
            pdfPage1.body.querySelector(".talkContainer").remove();
            pdfPage1.body.querySelector(".footer").remove();
            pdfPage1.body.querySelector('.chartTabsNavBar').remove();
            pdfPage1.body.querySelector(".backtotop").remove();
            pdfPage1.body.querySelector(".comparisonsContainer").appendChild(comparisonWithHeader.querySelector(".comparisonContainer"));

            // format PDF Page 2
            let comparisonWithOutHeader = _yieldComparison.cloneNode(true);
            comparisonWithOutHeader.querySelector('.comparisonHeader').remove();
            let comparisonContent = comparisonWithOutHeader.querySelector(".comparisonContent");
            while (comparisonContent.firstChild) comparisonContent.removeChild(comparisonContent.firstChild);
            comparisonWithOutHeader.querySelector(".comparisonContent").appendChild(_harvestComparison);
            pdfPage2.body.querySelector("#trial_popup").remove();
            pdfPage2.body.querySelector(".headerDetails").remove();
            pdfPage2.body.querySelector('.chartTabsNavBar').remove();
            pdfPage2.body.querySelector(".backtotop").remove();
            comparisonsContainer = pdfPage2.querySelector(".comparisonsContainer");
            while (comparisonsContainer.firstChild) comparisonsContainer.removeChild(comparisonsContainer.firstChild);
            pdfPage2.body.querySelector(".comparisonsContainer").appendChild(comparisonWithOutHeader.querySelector(".comparisonContainer"));
            // merge Page 1 and Page 2 into Pdf doc
            pdfDoc.body = pdfPage1.body.cloneNode(true);
            pdfDoc.getElementById('root').appendChild(pdfPage2.querySelector(".App"));

            // apply inline css rules globally
            pdfDoc.querySelectorAll('.chartTabsNavBar').forEach(e => e.style.justifyContent = "left");
            pdfDoc.querySelectorAll('.chartTabsNavItem').forEach(e => e.style.width = "25%");
            pdfDoc.querySelectorAll('.comparisonChartContainer').forEach(e => e.style.padding = "2% 2% 0% 2%");
            pdfDoc.querySelectorAll('.chartContainer').forEach(e => e.style.padding = "15px 0px 15px 0px");

            // console.log(pdfDoc.querySelector("#root"))
            generatePdf(pdfDoc.body.outerHTML);
        } catch (e) {
            console.log(e);
        }
    }

    createComparisonPdfHandlerAPT(comparisonId) {
        let pdfDoc = document.implementation.createHTMLDocument("PDF Doc");
        let pdfPage1 = document.implementation.createHTMLDocument("PDF Page 1");
        let pdfPage2 = document.implementation.createHTMLDocument("PDF Page 2");
        pdfPage1.body = document.body.cloneNode(true);
        pdfPage2.body = document.body.cloneNode(true);

        // temp DOM nodes to hold html of each comparison tab view (Yield, Days to Maturity, Harvet Ratings)
        let _yieldComparison = document.createElement("div");

        // translate JSX to DOM nodes (html)
        ReactDOM.render(this.getComparison(1, 'pdf'), _yieldComparison);

        try {
            // format each comparison tab view
            _yieldComparison.querySelectorAll(".chartTabsNavBar .chartTabsNavItem:not(.active)").forEach(e => e.parentNode.removeChild(e));

            // format PDF Page 1
            let comparisonWithHeader = _yieldComparison.cloneNode(true);
            // remove all the comparisons from the pdfPage1 Doc
            
            pdfPage1.body.querySelectorAll(".cell.two")[0].innerHTML = '<span>Hybrid: </span>' + _yieldComparison.querySelectorAll('.barLabel')[0].textContent
            pdfPage1.body.querySelectorAll(".cell.two")[1].innerHTML = '<span>Comparison: </span>' + _yieldComparison.querySelectorAll('.barLabel')[1].textContent
            let comparisonsContainer = pdfPage1.querySelector(".comparisonsContainer");
            while (comparisonsContainer.firstChild) comparisonsContainer.removeChild(comparisonsContainer.firstChild);
            pdfPage1.body.querySelector("#trial_popup").remove();
            pdfPage1.body.querySelector('.chartTabsNavBar').remove();
            pdfPage1.body.querySelector(".backtotop").remove();
            pdfPage1.body.querySelector(".comparisonsContainer").appendChild(comparisonWithHeader.querySelector(".comparisonContainer"));

            // format PDF Page 2
            pdfDoc.body = pdfPage1.body.cloneNode(true);
           // pdfDoc.getElementById('root').appendChild(pdfPage2.querySelector(".App"));

            // apply inline css rules globally
            pdfDoc.querySelectorAll('.chartTabsNavBar').forEach(e => e.style.justifyContent = "left");
            pdfDoc.querySelectorAll('.chartTabsNavItem').forEach(e => e.style.width = "25%");
            pdfDoc.querySelectorAll('.comparisonChartContainer').forEach(e => e.style.padding = "2% 2% 0% 2%");
            pdfDoc.querySelectorAll('.chartContainer').forEach(e => e.style.padding = "15px 0px 15px 0px");

            // console.log(pdfDoc.querySelector("#root"))
            generatePdf(pdfDoc.body.outerHTML);
        } catch (e) {
            console.log(e);
        }
    }

    getGaugeInfo = (maxAvgAmount, minAvgAmount) => {
        let i = 10;
        let result = { startingPoint: 0, unitWidth: 100 / 6, gaugeDivisions: 0 };
        while (0 < i) {
            if (Math.round(maxAvgAmount) - i >= 0) {
                result.startingPoint = Math.round(maxAvgAmount) - i;
                break;
            }
            i -= 2;
        }

        let gaugeDivisions = 6;
        while (((minAvgAmount - result.startingPoint - 2) / 2) < 0) {
            result.startingPoint--;
        }
        while (maxAvgAmount > ((result.startingPoint + (gaugeDivisions * 2)) - 2))
            gaugeDivisions++;
        result.gaugeDivisions = gaugeDivisions;
        result.unitWidth = 100 / gaugeDivisions;

        return result;
    }

    getGaugeInfo = (maxAvgAmount, minAvgAmount) => {
        let i = 10;
        let result = { startingPoint: 0, unitWidth: 100 / 6, gaugeDivisions: 0 };
        while (0 < i) {
            if (Math.round(maxAvgAmount) - i >= 0) {
                result.startingPoint = Math.round(maxAvgAmount) - i;
                break;
            }
            i -= 2;
        }

        let gaugeDivisions = 6;
        while (((minAvgAmount - result.startingPoint - 2) / 2) < 0) {
            gaugeDivisions++;
            result.unitWidth = 100 / gaugeDivisions;
            result.startingPoint--;
        }
        result.gaugeDivisions = gaugeDivisions;
        return result;
    }

    handleChartSlider = (isPrevious) => {
        if (isPrevious && this.state.selectedChart > 1) {
            this.setState({ selectedChart: this.state.selectedChart - 1 })
        } else if (!isPrevious && this.state.selectedChart < 3) {
            this.setState({ selectedChart: this.state.selectedChart + 1 })
        }
    }

    handleChartTabs = (selectedChart) => {
        this.setState({
            selectedChart: selectedChart,
        })
    }

    getSurroundingLocation = () => {
        return this.props.location.nearestCity;
    }

    getLocationFrequenices = () => {
        let counts = [];

        if (TrialsCategory === 'DST') {
            this.props.comparison.hybrid.locations.forEach(l => {
                counts[l] = counts[l] ? counts[l] + 1 : 1;
            })
        } else {
            this.props.comparison.treatment.locations.forEach(l => {
                counts[l] = counts[l] ? counts[l] + 1 : 1;
            })
        }


        return counts;
    }

    getGaugeInfo = (maxAvgAmount, minAvgAmount) => {
        let i = 10;
        let result = { startingPoint: 0, unitWidth: 100 / 6, gaugeDivisions: 0 };
        while (0 < i) {
            if (Math.round(maxAvgAmount) - i >= 0) {
                result.startingPoint = Math.round(maxAvgAmount) - i;
                break;
            }
            i -= 2;
        }

        let gaugeDivisions = 6;
        while (((minAvgAmount - result.startingPoint - 2) / 2) < 0) {
            result.startingPoint--;
        }
        while (maxAvgAmount > ((result.startingPoint + (gaugeDivisions * 2)) - 2))
            gaugeDivisions++;
        result.gaugeDivisions = gaugeDivisions;
        result.unitWidth = 100 / gaugeDivisions;

        return result;
    }

    getComparison = (selectedChart, tag) => {
        let trial = this.props.trials.trials;
        let numOfTrials = this.props.comparison.trials.length;
        let label, tabTitle, tabSubTitle, tabTooltip;
        let hybridBarColor, hybridBarColorapt;
        let comparisonBarColor = "lightgrey";
        let avgHybridAmount = 0, avgComparisonAmount = 0, avgAmountDiffence = 0, maxAvgAmount = 0, avgTreatmentAmount = 0, minAvgAmount = 0;
        let gaugeUnitNumberStartPoint = 0, numOfHybridGaugeUnits = 0, numOfComparisonGaugeUnits = 0, hybridBarWidth = 0, TreatmentBarWidth = 0, comparisonBarWidth = 0, numOfTreatmentGaugeUnits = 0;
        let numOfGaugeUnits = 6;
        let gaugeUnitWidth = 0;
        if (TrialsCategory === 'APT') { gaugeUnitWidth = 100 / numOfGaugeUnits }
        let gaugeInfo = {};
        let gaugeDivisions = 0;

        let charts = ['net_yield', 'maturity', 'harvest'];
        let chartsapt = ['yield'];
        let unit = charts[selectedChart - 1];

        let unitapt = chartsapt[selectedChart - 1];

        if (numOfTrials > 0) {
            if (TrialsCategory === 'DST') {

                if (this.props.comparison.hybrid[unit].sum > 0 && this.props.comparison.competitor[unit].sum > 0) {
                    avgHybridAmount = Number(Math.round(this.props.comparison.hybrid[unit].sum / this.props.comparison.hybrid[unit].count + 'e2') + 'e-2');
                    avgComparisonAmount = Number(Math.round(this.props.comparison.competitor[unit].sum / this.props.comparison.competitor[unit].count + 'e2') + 'e-2');
                    avgAmountDiffence = Number(Math.round((avgHybridAmount > avgComparisonAmount ? avgHybridAmount - avgComparisonAmount : avgComparisonAmount - avgHybridAmount) + 'e2') + 'e-2');
                    maxAvgAmount = Math.max(avgHybridAmount, avgComparisonAmount);
                    minAvgAmount = Math.min(avgHybridAmount, avgComparisonAmount);
                    gaugeInfo = this.getGaugeInfo(maxAvgAmount, minAvgAmount);
                    gaugeUnitNumberStartPoint = gaugeInfo.startingPoint;
                    gaugeUnitWidth = gaugeInfo.unitWidth;
                    gaugeDivisions = gaugeInfo.gaugeDivisions;
                    numOfHybridGaugeUnits = (avgHybridAmount - gaugeUnitNumberStartPoint) / 2;
                    numOfComparisonGaugeUnits = (avgComparisonAmount - gaugeUnitNumberStartPoint) / 2;
                    hybridBarWidth = numOfHybridGaugeUnits * gaugeUnitWidth;
                    comparisonBarWidth = numOfComparisonGaugeUnits * gaugeUnitWidth;
                }
            } else {

                if (this.props.comparison.treatment[unitapt] > 0 && this.props.comparison.competitor[unitapt] > 0) {
                    avgTreatmentAmount = Number(Math.round(this.props.comparison.treatment[unitapt] / this.props.comparison.treatment.count + 'e2') + 'e-2');
                    avgComparisonAmount = Number(Math.round(this.props.comparison.competitor[unitapt] / this.props.comparison.competitor.count + 'e2') + 'e-2');
                    avgAmountDiffence = Number(Math.round((avgTreatmentAmount > avgComparisonAmount ? avgTreatmentAmount - avgComparisonAmount : avgComparisonAmount - avgTreatmentAmount) + 'e2') + 'e-2');
                    maxAvgAmount = Math.max(avgTreatmentAmount, avgComparisonAmount);
                    minAvgAmount = Math.min(avgTreatmentAmount, avgComparisonAmount);
                    gaugeInfo = this.getGaugeInfo(maxAvgAmount, minAvgAmount);
                    gaugeUnitNumberStartPoint = gaugeInfo.startingPoint;
                    gaugeUnitWidth = gaugeInfo.unitWidth;
                    gaugeDivisions = gaugeInfo.gaugeDivisions;
                    numOfTreatmentGaugeUnits = (avgTreatmentAmount - gaugeUnitNumberStartPoint) / 2;
                    numOfComparisonGaugeUnits = (avgComparisonAmount - gaugeUnitNumberStartPoint) / 2;
                    // //increase the # of gauge units until both bars fit
                    // while (numOfComparisonGaugeUnits * gaugeUnitWidth > 100 || numOfTreatmentGaugeUnits * gaugeUnitWidth > 100) {
                    //     numOfGaugeUnits++;
                    //     gaugeUnitWidth = 100 / numOfGaugeUnits;
                    // }
                    TreatmentBarWidth = numOfTreatmentGaugeUnits * gaugeUnitWidth;
                    comparisonBarWidth = numOfComparisonGaugeUnits * gaugeUnitWidth;
                }
            }
        }

        if (TrialsCategory === 'DST') {
            switch (selectedChart) {
                case 1:
                    label = 'Yield';
                    tabTitle = `${this.props.comparison.hybrid['net_yield'].count } Trials* | ${avgAmountDiffence} bu/ac. Difference`;
                    tabSubTitle = `${`Yield (bu/ac) - ${this.props.comparison.swathtype}`}`;
                    tabTooltip = "";
                    hybridBarColor = "#0061a1";
                    break;
                case 2:
                    label = 'Days to Maturity';
                    tabTitle = `${this.props.comparison.hybrid['maturity'].count} Trials* | ${avgAmountDiffence} Days Difference`;
                    tabSubTitle = `Days`;
                    tabTooltip = "Seeding date to 60% seed colour change.";
                    hybridBarColor = "#64AC1E";
                    break;
                case 3:
                    label = 'Harvest Ratings';
                    tabTitle = `${this.props.comparison.hybrid['harvest'].count} Trials* | ${avgAmountDiffence} Point Difference`;
                    tabSubTitle = `1 – Worst 10 – Best`;
                    tabTooltip = "Rating by grower on ease of combining to other strips planted. Scale 1 - 10 with 10 being the best.";
                    hybridBarColor = "#0F8045";
                    break;
                case 4:
                    label = 'Weather';
                    tabTitle = `${this.props.comparison.hybrid['harvest'].count} Trials* | ${avgAmountDiffence} Point Difference`;
                    tabSubTitle = `1 – Worst 10 – Best`;
                    tabTooltip = "Rating by grower on ease of combining to other strips planted. Scale 1 - 10 with 10 being the best.";
                    hybridBarColor = "#0F8045";
                default:
                    label = 'Yield';
                    tabTitle = `${this.props.comparison.hybrid['net_yield'].count } Trials* | ${avgAmountDiffence} bu/ac. Difference`;
                    tabSubTitle = `${`Yield (bu/ac) - ${this.props.comparison.swathtype}`}`;
                    tabTooltip = "";
                    hybridBarColor = "#0061a1";
                    break;
            }
        } else {
            switch (selectedChart) {
                case 1:
                    label = 'Yield';
                    tabTitle = `${numOfTrials} Trials* | ${avgAmountDiffence} bu/ac. Difference`;
                    tabSubTitle = `Yield (bu/ac)`;
                    tabTooltip = "";
                    hybridBarColorapt = "#65ac1e";
                    break;
                default:
                    label = 'Yield';
                    tabTitle = `${numOfTrials} Trials* | ${avgAmountDiffence} bu/ac. Difference`;
                    tabSubTitle = `Yield (bu/ac)`;
                    tabTooltip = "";
                    break;
            }
        }

        let trialHasHybrids = ""
        if (trial) trialHasHybrids = true

        let counts = this.getLocationFrequenices();
        return (
            <div className="comparisonContainer" comparisonid={this.props.comparisonId} key={this.props.comparisonId} >

                <div className="comparisonHeader">
                    {TrialsCategory === 'DST' ? <div className="comparisonTitle" dangerouslySetInnerHTML={{ __html: `${this.props.comparison.hybrid.name}  vs  ${this.props.comparison.competitor.name}` }}></div> :
                        <div className="comparisonTitle">{this.props.comparison.treatment.name + ' vs ' + this.props.comparison.competitor.name}</div>}

                    {tag !== 'pdf' && !this.state.PURL ?
                        <div className="comparisonDatesContainer">
                            <div 
                                className={this.props.comparison.year === 'All' ? "date active" : "date"} 
                                onClick={this.props.yearchanged} 
                                value='All'
                                data-tealium-category="button" 
                                data-tealium-action="click year" 
                                data-tealium-value="All" 
                                data-tealium-type="button" 
                            >
                                All
                            </div>
                            <div 
                                className={parseInt(this.props.comparison.year) === 2024 ? "date active" : "date"} 
                                onClick={this.props.yearchanged} 
                                value='2024'
                                data-tealium-category="button" 
                                data-tealium-action="click year" 
                                data-tealium-value="2024" 
                                data-tealium-type="button" 
                            >
                                2024
                            </div>
                            <div 
                                className={parseInt(this.props.comparison.year) === 2023 ? "date active" : "date"} 
                                onClick={this.props.yearchanged} 
                                value='2023'
                                data-tealium-category="button" 
                                data-tealium-action="click year" 
                                data-tealium-value="2022" 
                                data-tealium-type="button" 
                            >
                                2023
                            </div>
                            <div 
                                className={parseInt(this.props.comparison.year) === 2022 ? "date active" : "date"} 
                                onClick={this.props.yearchanged} 
                                value='2022'
                                data-tealium-category="button" 
                                data-tealium-action="click year" 
                                data-tealium-value="2021" 
                                data-tealium-type="button" 
                            >
                                2022
                            </div>
                        </div>
                        : null}
                </div>
                <div className="comparisonContent">

                    {this.props.trialFilters.data.filters.Herbicide || this.props.trialFilters.data.filters["Facet L"] || this.props.trialFilters.data.filters["Zidua SC"] ? " " :
                        <div className="comparisonTabsContainer" label={label} number="">
                            {trialHasHybrids ?
                                <ChartTabs comparison={this.props.comparison} tabDivider={true} selectedChart={selectedChart} mobileClick={this.handleChartSlider} desktopClick={this.handleChartTabs} />
                                : ""
                            }

                            <div className="comparisonChartContainer">
                                <div className="title">{tabTitle}</div>
                                <div className="subTitle">{TrialsCategory === 'DST' ? tabSubTitle : ""} {this.state.selectedChart !== 1 ? <Tooltip text={tabTooltip} color="black" /> : ""}</div>
                                <div className="chartContainer">
                                    <React.Fragment>
                                        <ChartBar
                                            key={TrialsCategory === 'DST' ? `bar-${this.props.comparison.hybrid.name}-${0}` : `bar-${this.props.comparison.treatment.name}-${0}`}
                                            label={TrialsCategory === 'DST' ? this.props.comparison.hybrid.name : this.props.comparison.treatment.name}
                                            value={TrialsCategory === 'DST' ? avgHybridAmount.toFixed(1) : avgTreatmentAmount.toFixed(1)}
                                            animate={(tag !== 'pdf' ? true : false)}
                                            style={{ width: `${TrialsCategory === 'DST' ? `${hybridBarWidth}%` : `${TreatmentBarWidth}%`}`, background: `${TrialsCategory === 'DST' ? `${hybridBarColor}` : `${hybridBarColorapt}`}` }}
                                        />

                                        <ChartBar key={`bar-${this.props.comparison.competitor.name}-${1}`} label={this.props.comparison.competitor.name} value={avgComparisonAmount.toFixed(1)} animate={(tag !== 'pdf' ? true : false)} style={{ width: `${comparisonBarWidth}%`, background: `${comparisonBarColor}` }} />
                                        <ChartLinearGauge numOfUnits={gaugeDivisions} unitWidth={gaugeUnitWidth} startPoint={gaugeUnitNumberStartPoint} />
                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                    }

                    {/* Bottom action buttons */}
                    {tag !== 'pdf' ?
                        <React.Fragment>
                            <b className="m15">*Trial Locations:</b>
                            <div className="trials_list">
                                <div className="locations">
                                    {TrialsCategory === 'DST' ?

                                        [...new Set(this.props.comparison.hybrid.locations)].map((location) => (
                                            <div key={location}>
                                                {location} ({counts[location]})
                                    </div>
                                        ))
                                        :
                                        [...new Set(this.props.comparison.treatment.locations)].map((location) => (
                                            <div key={location}>
                                                {location} ({counts[location]})
                                    </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="actionButtonsContainer">
                                <div className="actionButtons">
                                    <Sharebuttons
                                        title={TrialsCategory === 'DST' ? `${this.getSurroundingLocation()} | ${this.props.comparison.hybrid.name + ' vs ' + this.props.comparison.competitor.name}` :
                                            `${this.getSurroundingLocation()} | ${this.props.comparison.treatment.name + ' vs ' + this.props.comparison.competitor.name}`}></Sharebuttons>
                                    <div className="button" onClick={TrialsCategory === 'DST' ? this.createComparisonPdfHandler.bind(this, this.props.comparisonId) : this.createComparisonPdfHandlerAPT.bind(this, this.props.comparisonId)}></div>
                                    <div className="button" onClick={TrialsCategory === 'DST' ? this.createComparisonPdfHandler.bind(this, this.props.comparisonId) : this.createComparisonPdfHandlerAPT.bind(this, this.props.comparisonId)}></div>
                                </div>
                                <div 
                                    disabled={(numOfTrials === 0 ? true : false)} 
                                    className="seeAllTrialsButton" 
                                    onClick={this.seeAllComparisonTrialsHandler.bind(this, this.props.comparisonId)}
                                    data-tealium-category="navigator" 
                                    data-tealium-action="click navigator" 
                                    data-tealium-value="comparison invidiual-filtered-trials" 
                                    data-tealium-type="navigator" 
                                >
                                    See all {numOfTrials} trials
                                </div>
                            </div>
                        </React.Fragment>
                        : null}
                </div>
            </div>
        );
    }

    render() {
        return this.getComparison(this.state.selectedChart);
    }
}

const mapStateToProps = (state) => {
    return {
        comparisons: getAllComparisons(state),
        trialFilters: JSON.parse(JSON.stringify(state.trials.trial_filters)),
        trials: state.trials,
        location: state.location
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(comparisonActionCreators, dispatch)
)(Comparison);