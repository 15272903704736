import { actionTypes } from '../actions/trialResultsAction';

const initialState = {
    trialResults: []
};

export const reducer = (state = initialState, action) => {
switch (action.type) {
    case actionTypes.getAPTTrialResults:
        
        return { ...state, 
            trialResults:action.payload };
        default:
        return state;
}
};