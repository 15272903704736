import React, { Component } from 'react'
import { nodeserver_url_images } from '../../constants/api';
import './AboutDST.scss'

import NavBar from '../../components/NavBar/NavBar'
import Hero from '../../components/Hero/Hero'
import TalkToUs from '../../components/TalkToUs/TalkToUs'
import Footer from '../../components/Footer/Footer'

let AboutHeroImg = `${nodeserver_url_images}/trial_results/images/aboutDST/hero.jpg`;
let contentImg1 = `${nodeserver_url_images}/trial_results/images/aboutDST/aboutdst_content1.jpg`;
let contentImg2 = `${nodeserver_url_images}/trial_results/images/aboutDST/aboutdst_content2.jpg`;
let contentImg3 = `${nodeserver_url_images}/trial_results/images/aboutDST/aboutdst_content3.jpg`;

class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            text: [
                {
                    id: 1,
                    title: "An established track record",
                    para1: "For over 20 years, our InVigor<sup>®</sup> hybrid canola Demonstration Strip Trial (DST) program has provided growers with detailed information on trial performance results, to help them make more informed decisions when selecting InVigor canola hybrids for their farm.",
                    para2: "All DSTs are managed by the local Technical Services Specialist and the grower cooperator. The grower fertilizes, seeds, and sprays and harvests each DST site using their own equipment and agronomic practices."
                },
                {
                    id: 2,
                    title: "What’s a DST?",
                    para1: "DST plots are a series of 5-8 hybrid strips seeded and grown side-by-side under identical soil, moisture and other climatic conditions to provide meaningful results. Each canola hybrid strip is one drill width wide and approximately 500 feet long. Each strip is replicated twice within the same field. Grower cooperators apply the appropriate crop protection products to each strip in the trial with guidance from Technical Service Specialists. Applications are made on the same day following label rates. ",
                    para2: "After being monitored and evaluated throughout the season. Each hybrid is harvested at the optimum time to maximize yield and seed quality. If the trial is swathed, hybrids will swathed at 80% seed color change for InVigor hybrids and at the appropriate time for competitor hybrids. Yield comparisons are completed at harvest and weigh wagons are used for accuracy. Net yield is reported after dockage and standardization for moisture are accounted for – the same parameters that determine the value of a grower’s canola crop."
                },
                {
                    id: 3,
                    title: "Where do I find DST sites?",
                    para1: 'Our DST sites are located across the entire Western Canadian canola growing region. That way, InVigor canola hybrids are exposed to the widest range of growing conditions, so growers can better understand how InVigor hybrids perform in their local area and across western Canada.'
                },
                {
                    id: 4,
                    title: "What makes DST info so credible?",
                    para1: "Using rigorous attention to detail our Technical Service Specialists ensure the highest quality of trial results data by replicating each hybrid strip twice within the trial. This allows for the use of statistical analysis to verify the results from each individual DST. Factors other than genetic potential can come into play, such as varying soil types, environmental extremes or pest damage. These factors can affect uniformity across the trial.",
                    para2: "By using statistical analysis by calculating a coefficient of variation (CV), it can be determined that the differences between hybrids are attributed to the genetic potential of the hybrid, eliminating other factors. If a CV is above 10%, the differences may be attributed to other factors. The Technical Service Team will use CV and other evaluation assessment standards in order to ensure we provide credible data for our customers. The DST summary uses only common site comparisons when tabulating yield results, so hybrids are only compared when they are grown at common sites."
                }
            ]
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isTablet = width <= 1024;
        if (isTablet) {
            return (
                <React.Fragment>
                    <NavBar from={"about"} page_router={this.props.page_router} clearPURL={this.props.clearPURL} />
                    <div className="aboutDSTContainer">
                        <Hero img={AboutHeroImg} />
                        <div className="aboutDSTTopBar">
                            <h2>{this.state.text[0].title}</h2>
                            <p dangerouslySetInnerHTML={{ __html: this.state.text[0].para1 }}></p><br />
                            <p dangerouslySetInnerHTML={{ __html: this.state.text[0].para2 }}></p>
                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg2} alt="" />
                            </div>
                            <div className="half">
                                <div className="content para2">
                                    <h2>{this.state.text[2].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[2].para1 }}></p>
                                </div>
                            </div>
                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg1} alt="" />
                            </div>
                            <div className="half">
                                <div className="content">
                                    <h2>{this.state.text[1].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[1].para1 }}></p>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[1].para2 }}></p>
                                </div>
                            </div>

                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg3} alt="" />
                            </div>
                            <div className="half">
                                <div className="content">
                                    <h2>{this.state.text[3].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[3].para1 }}></p>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[3].para2 }}></p>
                                </div>
                            </div>

                        </div>


                        <TalkToUs />
                        <Footer />
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <NavBar from={"about"} page_router={this.props.page_router} clearPURL={this.props.clearPURL} />
                    <div className="aboutDSTContainer">
                        <Hero img={AboutHeroImg} />
                        <div className="aboutDSTTopBar">
                            <h2>{this.state.text[0].title}</h2>
                            <p dangerouslySetInnerHTML={{ __html: this.state.text[0].para1 }}></p><br />
                            <p dangerouslySetInnerHTML={{ __html: this.state.text[0].para2 }}></p>
                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg1} alt="" />
                            </div>
                            <div className="half">
                                <div className="content">
                                    <h2>{this.state.text[1].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[1].para1 }}></p>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[1].para2 }}></p>
                                </div>
                            </div>
                        </div>
                        <div className="full">
                            <div className="half1">
                                <div className="content para2">
                                    <h2>{this.state.text[2].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[2].para1 }}></p>
                                </div>
                            </div>
                            <div className="half-image">
                                <img src={contentImg2} alt="" />
                            </div>
                        </div>
                        <div className="full">
                            <div className="half-image">
                                <img src={contentImg3} alt="" />
                            </div>
                            <div className="half">
                                <div className="content">
                                    <h2>{this.state.text[3].title}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[3].para1 }}></p>
                                    <p dangerouslySetInnerHTML={{ __html: this.state.text[3].para2 }}></p>
                                </div>
                            </div>

                        </div>


                        <TalkToUs />
                        <Footer />
                    </div>
                </React.Fragment>
            );

        }
    }
}

export default About;

// render() {
//     const { width } = this.state;
//     const isTablet = width <= 1024;
//     if (isTablet) {
//         return (
//             <React.Fragment>
//                 <NavBar from={"about"} page_router={this.props.page_router} />
//                 <div className="aboutDSTContainer">
//                     <Hero img={AboutHeroImg} />
//                     <div className="aboutDSTTopBar">
//                         <h2>{this.state.text[0].title}</h2>
//                         <p dangerouslySetInnerHTML={{ __html: this.state.text[0].para1 }}></p><br />
//                         <p>{this.state.text[0].para2}</p>
//                     </div>
//                     <div className="full">
//                         <div className="half-image">
//                             <img src={contentImg1} alt="" />
//                         </div>
//                         <div className="half">
//                             <div className="content">
//                                 <h2>{this.state.text[1].title}</h2>
//                                 <p>{this.state.text[1].para1}</p>
//                                 <p>{this.state.text[1].para2}</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="full">
//                         <div className="half-image">
//                             <img src={contentImg2} alt="" />
//                         </div>
//                         <div className="half1">
//                             <div className="content para2">
//                                 <h2>{this.state.text[2].title}</h2>
//                                 <p>{this.state.text[2].para1}</p>
//                             </div>
//                         </div>

//                     </div>
//                     <div className="full">
//                         <div className="half-image">
//                             <img src={contentImg3} alt="" />
//                         </div>
//                         <div className="half">
//                             <div className="content">
//                                 <h2>{this.state.text[3].title}</h2>
//                                 <p>{this.state.text[3].para1}</p>
//                                 <p>{this.state.text[3].para2}</p>
//                             </div>
//                         </div>

//                     </div>


//                     <TalkToUs />
//                     <Footer />
//                 </div>
//             </React.Fragment>
//         );
//     } else {
//         return (
//             <React.Fragment>
//                 <NavBar from={"about"} page_router={this.props.page_router} />
//                 <div className="aboutDSTContainer">
//                     <Hero img={AboutHeroImg} />
//                     <div className="aboutDSTTopBar">
//                         <h2>{this.state.text[0].title}</h2>
//                         <p dangerouslySetInnerHTML={{ __html: this.state.text[0].para1 }}></p><br />
//                         <p>{this.state.text[0].para2}</p>
//                     </div>
//                     <div className="full">
//                         <div className="half-image">
//                             <img src={contentImg1} alt="" />
//                         </div>
//                         <div className="half">
//                             <div className="content">
//                                 <h2>{this.state.text[1].title}</h2>
//                                 <p>{this.state.text[1].para1}</p>
//                                 <p>{this.state.text[1].para2}</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="full">
//                         <div className="half1">
//                             <div className="content para2">
//                                 <h2>{this.state.text[2].title}</h2>
//                                 <p>{this.state.text[2].para1}</p>
//                             </div>
//                         </div>
//                         <div className="half-image">
//                             <img src={contentImg2} alt="" />
//                         </div>
//                     </div>
//                     <div className="full">
//                         <div className="half-image">
//                             <img src={contentImg3} alt="" />
//                         </div>
//                         <div className="half">
//                             <div className="content">
//                                 <h2>{this.state.text[3].title}</h2>
//                                 <p>{this.state.text[3].para1}</p>
//                                 <p>{this.state.text[3].para2}</p>
//                             </div>
//                         </div>

//                     </div>


//                     <TalkToUs />
//                     <Footer />
//                 </div>
//             </React.Fragment>
//         );
