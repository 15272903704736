import React, { Component } from 'react'
import { nodeserver_url_images } from '../../constants/api';
import '../AboutDST/AboutDST.scss'
import { PDFExport} from '@progress/kendo-react-pdf';
import TalkToUs from '../../components/TalkToUs/TalkToUs'
import Footer from '../../components/Footer/Footer'
import Hero from '../../components/Hero/Hero'
import Header from '../../components/Header/Header'
let AboutHeroImg = `${nodeserver_url_images}/trial_results/images/aboutDST/hero.jpg`;


class TestPDF extends Component {

    exportPDFWithComponent = () => {
        this.pdfExportComponent.save();
    }

    pdfExportComponent;

    render() {
        return (
            <React.Fragment>
                <PDFExport forcePageBreak=".page-break" ref={(component) => this.pdfExportComponent = component} paperSize="A4" >
                    <div className="pdfHome">
                    <Header/>
                    </div>
                    
                    <div className="aboutDSTContainer">
                        <Hero img={AboutHeroImg} />
                        <div className="aboutDSTTopBar">

                            <p>What is Lorem Ipsum?
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem 
                            Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                            <button className="k-button" onClick={this.exportPDFWithComponent}>Download</button>
                        </div>
                        <div class="page-break" >
                        <TalkToUs/>
                        <Footer />
                        </div>
                        
                    </div>
                </PDFExport>
            </React.Fragment>

        )
    }
}

export default TestPDF;

