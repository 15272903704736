import React, { Component } from 'react'
import { nodeserver_url_images } from '../../constants/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllComparisons, getComparisonDetails } from '../../selectors/comparisonsSelector';
import { comparisonActionCreators } from '../../actions/comparisonAction';

import TalkToUs from '../../components/TalkToUs/TalkToUs'
import Footer from '../../components/Footer/Footer'
import IndividualTrial from '../Home/Menu/IndividualTrial/IndividualTrial'
import './ComparisonTrialsPage.scss'

let backArrow = `${nodeserver_url_images}/trial_results/images/trials/backarrow.png`;

class ComparisonTrialsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comparison: getComparisonDetails(this.props.comparisons, this.props.page_params.comparisonId)
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="comparisonTrialsPageContainer">
                <div className={"chartTabsNavBar"}>
					<div className="trialNavContainer"></div>
                <div onClick={() => this.props.page_router.loadPage('home')} className="chartTabsNavItem"><img src={backArrow} style={{verticalAlign:"middle"}} alt="Back to Map" /> Back to Map</div>
                </div>
                {this.state.comparison.trials ?
                    <div className="trialList">
                        {this.state.comparison.trials.map((trial) => (
                           trial.data.indication === 'Herbicide' ? <IndividualTrial key={`comparisonTrial-${trial.data.trial}`} blockYield={true} trial={trial} page_router={this.props.page_router} clearPURL={this.state.clearPURL}/> : 
                           <IndividualTrial key={`comparisonTrial-${trial.data.trial}`} blockYield={false} trial={trial} page_router={this.props.page_router} clearPURL={this.state.clearPURL}/>
                        ))}
                    </div>
                    : null
                }

                <TalkToUs />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        comparisons: getAllComparisons(state)
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(comparisonActionCreators, dispatch)
)(ComparisonTrialsPage);