import { nodeserver_url_images } from '../../src/constants/api';
//import { homepage, nodeserver_url, nodeserver_url_images } from '../../src/constants/api';

class Agronomist {
    constructor(data) {
        this.data = data;
    }

    get_full_name = () => {
        return [this.data.firstname, this.data.lastname].join(' ');
    }

    get_agronomist_photo = () => {
        // return homepage + this.data.photo;
        return nodeserver_url_images + "/trial_results/images/individualTrial/agronomist_images/" + this.data.photo;
    }
}

export default Agronomist